import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
    AuthenticationService as JwtAuthenticationService,
} from '../../core/services/authentication/authentication.service';
import { SharedService } from '../../core/services/shared.service';
import { NotificationType } from '../../shared/models/notification-type';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { UtilityService } from '../../shared/services/utility/utility.service';

@Component({
    selector: 'upsc-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../login/login.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {

    @ViewChild('notification') notification: ElementRef;
    public emailaddress: string;
    public username: string;
    public shownotification: boolean;
    public formGroup: UntypedFormGroup;
    public isInProgress = false;

    public constructor(private authenticationService: JwtAuthenticationService,
                       private formBuilder: UntypedFormBuilder,
                       private notificationService: NotificationService,
                       private dialogService: DialogService,
                       private errorHandlerService: ErrorHandlerService,
                       private sharedService: SharedService,
                       private toastrService: ToastrService,
                       private translateService: TranslateService,
                       private router: Router,
                       private readonly utilityService: UtilityService,
    ) {
        this.sharedService.isResetPasswordTokenValid.subscribe(isValid => {
            if (!isValid) {
                this.notificationService.notify(this.translateService.instant('resetPassword.passwordLinkExpired'),
                    '',
                    NotificationType.ERROR);
            }
        });
    }

    public get supportTel(): string {
        const currentDomainName = this.utilityService.getCurrentDomainName();
        switch (true) {
            case currentDomainName.includes('upscapitalde.com'):
                return '+49 69 6630 8330';
            case currentDomainName.includes('upscapitaluk.com'):
                return '+44 20 7949 0144';
            case currentDomainName.includes('upscapitalfr.com'):
                return '+33 1 73 01 84 55';
            case currentDomainName.includes('upscapitalit.com'):
                return '+39 02 4527 9099';
            default:
                return '+1 (888) 683-2300';
        }
    }

    public ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            userName: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
            email: ['', Validators.compose([Validators.required, Validators.email])],
        });

    }

    public onSubmit(event: Event, form): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.isInProgress = true;
        this.authenticationService.passwordReset(form.email, form.userName)
            .pipe(
                catchError((err) => {
                    // Ignore any observable errors and stop proceeding with any further observables.
                    return EMPTY;
                }),
                finalize(() => {
                    this.isInProgress = false;
                    this.confirmPasswordReset();
                }),
            )
            .subscribe();
    }

    public confirmPasswordReset(): void {
        this.dialogService.alert(
            '',
            this.translateService.instant('resetPassword.resetPasswordEmail'),
        ).subscribe((res) => {
            this.router.navigate(['/login']).then();
        });
    }
}
