<div class="required">
    * Required Fields
</div>
<div class="page-title">
    3. Billing Information
</div>
<div class="billing-information">
    <form class="ups-form" [formGroup]="billingInformationFormGroup">
        <div class="billing-address">
            <label class="title">
                Billing Address
            </label>
            <mat-slide-toggle [checked]="isBillingAddressSameAsBusiness"
                (change)="toggleBillingAddressSameAsBusiness()">Same
                as Business Address</mat-slide-toggle>
            <mat-form-field appearance="outline">
                <mat-label>Contact Name</mat-label>
                <input matInput required formControlName="contactName" maxlength="50" autocomplete="off">
                <mat-error *ngIf="billingInformationFormGroup.controls.contactName?.errors">
                    <span *ngIf="billingInformationFormGroup.controls.contactName?.errors?.required">
                        Contact Name is required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput required formControlName="phoneNumber" (input)="addHyphen($event.target)" maxlength="15" autocomplete="off">
                    <mat-error *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors">
                        <span *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors?.required">
                            Phone Number is required
                        </span>
                        <span *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors?.notInteger">
                            Phone Number should be number
                        </span>
                        <span *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors?.pattern">
                                Phone Number should be of format 999-999-9999
                            </span>
                    </mat-error>                
                </mat-form-field>
            <!-- <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput required formControlName="phoneNumber" maxlength="15" autocomplete="off">
                <mat-error *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors">
                    <span *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors?.required">
                        Phone Number is required
                    </span>
                    <span *ngIf="billingInformationFormGroup.controls.phoneNumber?.errors?.notInteger">
                        Phone Number should be number
                    </span>
                </mat-error>
            </mat-form-field>             -->
            <div *ngIf="!isBillingAddressSameAsBusiness" class="address" formGroupName="billingAddress">
                <mat-form-field class="country" appearance="outline">
                    <mat-label>Select Country/Territory</mat-label>
                    <mat-select placeholder="Select Country/Territory" formControlName="country">
                        <mat-option *ngFor="let country of signupDropdowns.countries" [value]="country.code">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Street Address</mat-label>
                    <input matInput required maxlength="50" formControlName="streetAddress" autocomplete="off">
                    <mat-error
                        *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].streetAddress?.errors">
                        <span
                            *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].streetAddress?.errors?.required">
                            Street Address is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Suite / Apt (Optional)</mat-label>
                    <input matInput maxlength="50" formControlName="suiteApt" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>City</mat-label>
                    <input matInput required  maxlength="50" formControlName="city" autocomplete="off">
                    <mat-error *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].city?.errors">
                        <span
                            *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].city?.errors?.required">
                            City is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <div class="twin-fields">
                        <mat-form-field appearance="outline">
                                <mat-label>Zip / Postal Code</mat-label>
                                <input matInput 
                                [maxlength]="maxLength"
                                [required]="(country=='US' || country=='CA') ? true : null"
                                formControlName="zip" autocomplete="off">
                                <mat-error *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors">
                                    <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.required">
                                        Zip / Postal Code is required
                                    </span>
                                    <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.pattern">
                                        Zip / Postal Code should be number
                                    </span>
                                    <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.alphabet">
                                        Zip / Postal Code should be AlphaNumeric
                                    </span>
                                    <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.number">
                                        Zip / Postal Code should be AlphaNumeric
                                    </span>
                                    <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.maxlength">
                                        Zip / Postal Code length should be {{billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.maxlength?.requiredLength}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Zip / Postal Code</mat-label>
                        <input matInput required maxlength="15" formControlName="zip" autocomplete="off">
                        <mat-error *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors">
                            <span
                                *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.required">
                                Zip is required
                            </span>
                            <span
                                *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].zip?.errors?.notInteger">
                                Postal Code should be number
                            </span>
                        </mat-error>
                    </mat-form-field> -->
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>State / Province</mat-label>
                        <input matInput required formControlName="stateProvince" maxlength="2" autocomplete="off">
                        <mat-error
                            *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].stateProvince?.errors">
                            <span
                                *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].stateProvince?.errors?.required">
                                State Province is required
                            </span>
                        </mat-error>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" *ngIf="country=='US' || country=='CA'">
                            <mat-label>State / Province</mat-label>
                            <mat-select required placeholder="Select State/Province" formControlName="stateProvince">
                                <mat-option *ngFor="let state of states" [value]="state.code">
                                    {{state.code}}
                                </mat-option>
                            </mat-select>                
                            <mat-error *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].stateProvince?.errors">
                                <span *ngIf="billingInformationFormGroup.controls.billingAddress['controls'].stateProvince?.errors?.required">
                                    State / Province is required
                                </span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="country!='US' && country!='CA'" >
                                <mat-label>State / Province</mat-label>
                                <input matInput formControlName="stateProvince" maxlength="15" autocomplete="off">
                        </mat-form-field>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="businessAddress" *ngIf="isBillingAddressSameAsBusiness"></ng-container>

        </div>
        <div class="separator">
        </div>
        <div class="billing-method">
            <label class="title">
                Billing Method
            </label>
            <label class="subtitle">
                Select your preferred method of billing
            </label>
            <div>
                <mat-radio-group class="method-types" formControlName="paymentType">
                    <div class="method">
                        <mat-radio-button value="Credit Card">AutoPay via Credit Card</mat-radio-button>
                        <p>
                            Credit card payments will be billed each week for all shipments sent.
                        </p>
                    </div>
                    <div class="method">
                        <mat-radio-button value="Terms (15 Days)">Bill My Account (15 Day Term)</mat-radio-button>
                        <p>
                            Statements are generated weekly and are due on or before the due date. Parcel Pro, Inc.
                            reserves
                            the right
                            to suspend service if payment is not received on or before the due date.
                        </p>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <!-- <div class="separator">
        </div>
        <div class="credit-card-info">
            <label class="title">
                Credit Card Information
            </label>
            <label class="subtitle">
                Enter the credit card information that will be billed for your account.
            </label>
            <div class="credit-card-details">
                <div class="card-no">
                    <label>Credit Card Number*</label>
                    <mat-form-field>
                        <input matInput placeholder="XXXX XXXX XXXX XXXX">
                    </mat-form-field>
                </div>
                <div class="twin-fields">
                    <div class="card-date">
                        <label>Expiration Date*</label>
                        <mat-form-field>
                            <input matInput placeholder="MM / YY">
                        </mat-form-field>
                    </div>
                    <div class="card-cvv">
                        <label>Security Code / CVV*</label>
                        <mat-form-field>
                            <input matInput placeholder="XXX">
                        </mat-form-field>
                    </div>
                    <div class="cvv-img">
                        <img src="../../../assets/images/Credit Card Logos/cvv.png" alt="cvv" />
                        <label>What is this?</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="billing-address">
            <label class="title credit-card-title">
                Credit Card Billing Address
            </label>
            <label class="subtitle">
                Name on Card
            </label>
            <div class="twin-fields">
                <mat-form-field appearance="outline">
                        <mat-label>First Name</mat-label>
                    <input matInput required formControlName="anetFirstName" placeholder="First Name*">
                    <mat-error *ngIf="billingInformationFormGroup.controls.anetFirstName?.errors">
                        <span *ngIf="billingInformationFormGroup.controls.anetFirstName?.errors?.required">
                            First Name is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                        <mat-label>Last Name</mat-label>
                    <input matInput required formControlName="anetLastName" placeholder="Last Name*">
                    <mat-error *ngIf="billingInformationFormGroup.controls.anetLastName?.errors">
                        <span *ngIf="billingInformationFormGroup.controls.anetLastName?.errors?.required">
                            Last Name is required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                <input matInput required formControlName="anetPhoneNumber" placeholder="Phone Number*">
                <mat-error *ngIf="billingInformationFormGroup.controls.anetPhoneNumber?.errors">
                    <span *ngIf="billingInformationFormGroup.controls.anetPhoneNumber?.errors?.required">
                        Phone Number is required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-slide-toggle [checked]="isCCBillingAddressSameAsBusiness"
                (change)="toggleCCBillingAddressSameAsBusiness()">Same
                as Business Address</mat-slide-toggle>
           
            <div *ngIf="!isCCBillingAddressSameAsBusiness" class="address" formGroupName="anetAddress">
                <mat-form-field class="country" appearance="outline">
                        <mat-label>Select Country/Territory</mat-label>
                    <mat-select placeholder="Select Country/Territory" formControlName="country">
                        <mat-option selected value="0">United States</mat-option>
                        <mat-option value="0"></mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                        <mat-label>Street Address</mat-label>
                    <input matInput required placeholder="Street Address*" formControlName="streetAddress">
                    <mat-error *ngIf="billingInformationFormGroup.controls.streetAddress?.errors">
                        <span *ngIf="billingInformationFormGroup.controls.streetAddress?.errors?.required">
                            Street Address is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                        <mat-label>Suite / Apt (Optional)</mat-label>
                    <input matInput placeholder="Suite / Apt (Optional)" formControlName="suiteApt">
                </mat-form-field>
                <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                    <input matInput required placeholder="City*" formControlName="city">
                    <mat-error *ngIf="billingInformationFormGroup.controls.city?.errors">
                        <span *ngIf="billingInformationFormGroup.controls.city?.errors?.required">
                            City is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <div class="twin-fields">
                    <mat-form-field appearance="outline">
                            <mat-label>Zip / Postal Code</mat-label>
                        <input matInput required placeholder="Zip / Postal Code*" formControlName="zip">
                        <mat-error *ngIf="billingInformationFormGroup.controls.zip?.errors">
                            <span *ngIf="billingInformationFormGroup.controls.zip?.errors?.required">
                                Zip is required
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                            <mat-label>State / Province</mat-label>
                        <mat-select required placeholder="State / Province*" formControlName="stateProvince">
                            <mat-option selected value="0">United States</mat-option>
                            <mat-option value="0"></mat-option>
                        </mat-select>
                        <mat-error *ngIf="billingInformationFormGroup.controls.stateProvince?.errors">
                            <span *ngIf="billingInformationFormGroup.controls.stateProvince?.errors?.required">
                                State Province is required
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="businessAddress" *ngIf="isCCBillingAddressSameAsBusiness"></ng-container>
        </div> -->
        <div class="separator">
        </div>


        <ng-template #businessAddress>
            <div class="business-address">
                {{signUp.Address1}}<br *ngIf="signUp.Address1">
                {{signUp.Address2}}<br *ngIf="signUp.Address2">
                {{signUp.City}}, {{signUp.StateProvince}} {{signUp.Zip}}<br
                    *ngIf="signUp.City || signUp.StateProvince || signUp.Zip">
                {{signUp.Country}}<br>
            </div>
        </ng-template>
    </form>
</div>
