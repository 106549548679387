<!-- Review Errors Header Section -->
<section class="review-errors-container">
    <div class="review-errors-header">
        <h2> Address Details </h2>
        <p> Please review the data uploaded. </p>
    </div>
    <div class="review-errors-progress-bar-container">
        <div class="progress-bar-wrapper">
            <img class="indicator-icon start-icon"
                 ngSrc="assets/icons/Indicator.svg"
                 alt="indicator-icon"
                 height="10"
                 width="10">
            <span class="start-span"> Upload File </span>
        </div>
        <div class="progress-bar-wrapper">
            <img class="indicator-icon middle-icon"
                 ngSrc="assets/icons/Indicator (open circle).svg"
                 alt="indicator-icon"
                 height="17"
                 width="17">
            <span class="middle-span"> Review Data </span>
        </div>
        <div class="progress-bar-wrapper">
            <img class="indicator-icon end-icon"
                 ngSrc="assets/icons/Indicator (gray open circle).svg"
                 alt="indicator-icon"
                 height="10"
                 width="10">
            <span class="end-span"> Submission Complete </span>
        </div>
    </div>

    <div *ngIf="isError" class="review-errors-message">
        <img class="error-icon" ngSrc="assets/icons/Error_16x16.svg" alt="error-icon" height="13" width="13">
        <p> {{ errorMessage }} <p>
    </div>

    <div *ngIf="!isError" class="no-errors-message">
        <img class="error-icon"
             ngSrc="assets/icons/CheckCircle_16x16.svg"
             alt="check-circle-icon"
             height="13"
             width="13">
        <p> {{ errorMessage }} <p>
    </div>

    <div class="review-errors-info">
        <div class="review-errors-wrapper">
            <img class="error-icon" ngSrc="assets/icons/Error_16x16.svg" alt="error-icon" height="13" width="13">
            <span> Error </span>
        </div>
        <div class="review-errors-wrapper">
            <img class="error-icon"
                 ngSrc="assets/icons/Error_16x16 (edited).svg"
                 alt="error-edited-icon"
                 height="13"
                 width="13">
            <span> Edited </span>
        </div>
        <!-- TODO: Uncomment this after deployment on 3/21/24 to include recently searched -->
        <!-- <div class="review-errors-wrapper">
            <img class="error-icon" src="../../../../assets/icons/Error_16x16 (recently searched).svg" alt="error-recently-searched-icon">
            <span> Recently Searched </span>
        </div> -->
    </div>

    <div class="action-toolbar">
        <button mat-stroked-button class="remove-all-error-addresses-btn" (click)="deleteRecords(-1, true)" [disabled]="!isError"> Remove All Error Addresses </button>
        <mat-button-toggle-group class="show-errors-toggle-slide">
            <mat-button-toggle value="all" [checked]="true" (click)="showAll()"> Show All </mat-button-toggle>
            <mat-button-toggle value="errors" (click)="showErrors()"> Show Errors </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</section>

<!-- Review Errors Table Section -->
<section class="review-errors-content">
    <div class="review-errors-grid-container">
        <div class="grid-wrapper" [class.show-edit]="isShowEdit">
            <table class="ups-table">
                <thead>
                    <tr class="ups-table-rows">
                        <th *ngFor="let header of tableHeaders; let first=first;" [ngClass]="{'more-padding-left': first, 'more-min-width': first}">
                            {{header.label}}
                        </th>
                    </tr>
                </thead>
                <tbody #tb>
                    <tr class="my-tr" *ngFor="let item of reviewErrorsData | paginate:paginate; let first=first; let i=index;">
                        <div class="street-container">
                            <div class="icon-container">
                                <img [src]="getIcon(item)">
                                <div class="img-wrapper">
                                    <img class="cursor-pointer"
                                         ngSrc="assets/icons/trashcan.svg"
                                         [ngClass]="{'no-icon': getIcon(item) === ''}" (click)="deleteRecords(i, false)"
                                         height="13"
                                         width="12">
                                </div>

                                <div class="img-wrapper">
                                    <img class="cursor-pointer"
                                         ngSrc="assets/icons/edit.svg"
                                         (click)="showEdit(i)"
                                         height="13"
                                         width="13">
                                </div>
                            </div>
                            <td [ngClass]="{'error-message': item?.invalidStreetaddress}" class="more-padding-left"> {{ item?.StreetAddress }}</td>
                        </div>
                        <td [ngClass]="{'error-message': item?.invalidCity}"> {{ item?.City}} </td>
                        <td [ngClass]="{'error-message': item?.invalidState}"> {{ item?.State }} </td>
                        <td [ngClass]="{'error-message': item?.invalidZipCode}"> {{ item?.Zip }} </td>

                        <div class="edit-address-container" *ngIf="isEditVisible(i) || isClosed">
                            <svg class="polygon-container" height="17" width="100">
                                <polygon class="polygon-1" points="50,0 0,100 100,100"/>
                            </svg>

                            <div class="edit-address-panel">
                                <div class="edit-address-wrapper">
                                    <h3 class="section-header"> Edit Address Data </h3>
                                    <img class="close-btn" (click)="closeEditAddressData(true, i)"
                                         ngSrc="assets/icons/close_black.svg"
                                         height="17"
                                         width="17">
                                </div>

                                <div class="form-field-address-edit-container">
                                    <form [formGroup]="editAddressFormGroup[i]" class="form-field-address-edit">
                                        <mat-form-field [ngClass]="{'has-errors': getStreetError(i) !== ''}" subscriptSizing="dynamic" class="address-edit-field street">
                                            <mat-label>Street</mat-label>
                                            <input matInput [value]="editAddressFormGroup[i].get('street').value" type="text" placeholder="Street" maxlength="35" formControlName="street" (blur)="onBlurFormInput($event, 'street', i)" required>
                                        </mat-form-field>

                                        <mat-form-field [ngClass]="{'has-errors': getCityError(i) !== ''}" subscriptSizing="dynamic" class="address-edit-field city">
                                            <mat-label>City</mat-label>
                                            <input matInput [value]="editAddressFormGroup[i].get('city').value" type="text" placeholder="City" maxlength="35" formControlName="city" (blur)="onBlurFormInput($event, 'city', i)" required>
                                        </mat-form-field>
                          
                                        <mat-form-field [ngClass]="{'has-errors': getStateError(i) !== ''}" subscriptSizing="dynamic" class="address-edit-field state">
                                            <mat-label>State</mat-label>
                                            <input matInput [value]="editAddressFormGroup[i].get('state').value" type="text" placeholder="State" minlength="2" maxlength="2" formControlName="state" (blur)="onBlurFormInput($event, 'state', i)" required>
                                        </mat-form-field>

                                        <mat-form-field [ngClass]="{'has-errors': getZipError(i) !== ''}" subscriptSizing="dynamic" class="address-edit-field zip">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput [value]="editAddressFormGroup[i].get('zipCode').value" type="text" placeholder="Zip Code" minlength="5" maxlength="5" formControlName="zipCode" (blur)="onBlurFormInput($event, 'zipCode', i)" required>
                                        </mat-form-field>
                                    </form>

                                    <div class="error-container">
                                        <div class="error-wrapper street">
                                            <mat-error class="error-messages"> <img ngSrc="assets/icons/Error_16x16.svg"
                                                                                    *ngIf="getStreetError(i) !== ''"
                                                                                    height="13"
                                                                                    width="13"
                                                                                    alt=""> {{ getStreetError(i) }} </mat-error>
                                        </div>

                                        <div class="error-wrapper city">
                                            <mat-error class="error-messages"> <img ngSrc="assets/icons/Error_16x16.svg"
                                                                                    *ngIf="getCityError(i) !== ''"
                                                                                    height="13"
                                                                                    width="13"
                                                                                    alt=""> {{ getCityError(i) }} </mat-error>
                                        </div>

                                        <div class="error-wrapper state">
                                            <mat-error class="error-messages"> <img ngSrc="assets/icons/Error_16x16.svg"
                                                                                    *ngIf="getStateError(i) !== ''"
                                                                                    height="13"
                                                                                    width="13"
                                                                                    alt=""> {{ getStateError(i) }} </mat-error>
                                        </div>

                                        <div class="error-wrapper zip">
                                            <mat-error class="error-messages"> <img ngSrc="assets/icons/Error_16x16.svg"
                                                                                    *ngIf="getZipError(i) !== ''"
                                                                                    alt=""
                                                                                    height="13"
                                                                                    width="13"> {{ getZipError(i) }} </mat-error>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="cancel-or-update-container">
                                    <button mat-stroked-button color="primary" (click)="closeEditAddressData(true, i)">
                                        <span class="material-symbols-outlined">chevron_left</span>
                                        <span> Cancel </span>
                                    </button>
                                    <button mat-flat-button color="primary" (click)="updateAddress(i)" [disabled]="(!onBlurInputHasNoError)">
                                        <span> Update </span>
                                        <span class="material-symbols-outlined">chevron_right</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </tr>

                    <tr *ngIf="reviewErrorsData.length === 0">
                        <td class="text-center" colspan="10">
                            <span style="align-items: center; font-size: 18px;"> {{'NoRecordsFound'|translate}} </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="reviewErrorsData.length > 0" class="pagination-wrapper">
            <upsc-custom-paginator (data)="paginationChange($event)" [length]="paginate.totalItems" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSizeLabel]="'Uploads per page'"> </upsc-custom-paginator>
        </div>

        <!-- Cancel or Submit Buttons -->
        <div class="cancel-or-submit-container">
            <button mat-stroked-button color="primary" (click)="cancelSubmission()">
                <span class="material-symbols-outlined">chevron_left</span>
                <span> Cancel </span>
            </button>
            <button mat-flat-button color="primary" [disabled]="isError" (click)="submitSubmission()">
                <span> Submit </span>
                <span class="material-symbols-outlined">chevron_right</span>
            </button>

            <button mat-button class="save-progress-btn" (click)="saveProgress()">
                <span> Save Progress </span>
            </button>
        </div>
    </div>
</section>
