import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, DoCheck, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import MobileDetect from 'mobile-detect';
import { filter, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SiteConfig } from '../config/site-config';
import { environment } from '../environments/environment';
import { AppStateService } from './app-state.service';
import { AppState } from './app.state';
import { ApiEndpointService } from './core/services/api-endpoint.service';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { EnvironmentService } from './core/services/environment.service';
import { ReactiveFormConfigService } from './core/services/reactive-form-config.service';
import { SpinnerService } from './core/services/spinner/spinner.service';
import { VersionCheckService } from './core/services/version/version-check.service';
import { IAppState } from './shared/models/app-state.interface';
import { UserService } from './shared/services/user/user.service';
import { UtilityService } from './shared/services/utility/utility.service';
import { SupportScriptService } from './support-scripts/support-script.service';

@Component({
    selector: 'upsc-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, DoCheck, OnDestroy {
    public isPageLoading = false;
    // country: string;
    // public isEndpointsReady = false;
    public isEndpointsReady = true;
    public idleTimeout = 3600000;
    public lang: string[];
    public destroy$ = new Subject<void>();

    public constructor(private router: Router,
                       private metaTagService: Meta,
                       private utilityService: UtilityService,
                       private spinnerService: SpinnerService,
                       private environmentService: EnvironmentService,
                       private apiEndpointService: ApiEndpointService,
                       private versionCheckService: VersionCheckService,
                       private cdRef: ChangeDetectorRef,
                       private route: ActivatedRoute,
                       private translateservice: TranslateService,
                       @Inject(DOCUMENT) private document: Document,
                       private readonly authenticationService: AuthenticationService,
                       private readonly userService: UserService,
                       private readonly supportScriptService: SupportScriptService,
                       private readonly reactiveFormConfigService: ReactiveFormConfigService,
                       private readonly appState: AppState,
                       private readonly appStateService: AppStateService,
    ) {
        this.idleTimeout = 1; // SiteConfig.reload.idle.timeout / 60000;

        // this.environmentService.initEnvironment()
        //   .subscribe(
        //     (env) => {
        //       if (!env) {
        //         return;
        //       }
        //       this.apiEndpointService.constructEndpoints(env);
        //       this.isEndpointsReady = true;
        //     },
        //     (err) => {
        //       console.error('Failed initializing environment.', err);
        //     },
        //   );

        this.router.events.subscribe(event => this.navigationInterceptor(event));
        const navigatorLanguage = this.utilityService.getSiteLanguage() || window.navigator.language;
        const language = sessionStorage.getItem('lang') || navigatorLanguage || 'en';
        this.changeLanguage(language);

        // MV3-4409 - Cookie settings should change based on language
        this.metaTagService.updateTag({
            name: 'DCSext.pCC',
            content: this.utilityService.getCookieCountryCode(language),
        });
        this.metaTagService.updateTag({
            name: 'DCSext.pLL',
            content: this.utilityService.getCookieLanguageCode(language),
        });

        this.reactiveFormConfigService.init();
        this.authenticationService.setupRefreshTokenTimer(environment.refreshTokenConfig?.offsetInMinutes);
        this.refreshAppStates();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public ngOnInit(): void {
        this.spinnerService.spinnerStatus
            .subscribe(
                (status) => {
                    this.isPageLoading = status;
                },
            );

        // this.setHtmlEnvironments();

        // [MV3-2732] initialize version check.
        if (environment.production && SiteConfig.reload.polling.enable) {
            this.versionCheckService.initVersionCheck('/version.json', SiteConfig.reload.polling.interval);
        }

        this.loadSupportScript();
    }

    public ngDoCheck() {
        this.cdRef.detectChanges();
    }
    
    public changeLanguage(selectedLanguage: string): void {
        let usedLanguage = selectedLanguage?.toLowerCase();
        this.translateservice.setDefaultLang(usedLanguage);
        this.translateservice.use(usedLanguage)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    usedLanguage = 'en';
                    return this.translateservice.use('en');
                }),
                finalize(() => {
                    sessionStorage.setItem('lang', usedLanguage);
                    sessionStorage.setItem('site-locale', usedLanguage);
                }),
            )
            .subscribe(() => {
            });
    }

    public handleInactivityCallback(): void {
        if (!SiteConfig.reload.idle.enable) {
            return;
        }
        this.router.navigate(['/logout']);
        // Sign out current user or display specific message regarding inactivity
        // location.reload(true);
    }

    private refreshAppStates(): void {
        const user = this.appState.user$();
        const customer = this.appState.customer$();

        if (user && customer) {
            return;
        }

        this.authenticationService.isLoggedIn()
            .pipe(
                filter(isLoggedIn => !!isLoggedIn),
                switchMap(() => this.userService.getUser()),
                filter(user => !!user),
                tap(user => this.appState.user$.set(user)),
                switchMap(user => this.userService.getCustomer()),
                filter(customer => !!customer),
                tap(customer => this.appState.customer$.set(customer)),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {});
    }

    private loadSupportScript(): void {
        this.authenticationService.isLoggedIn().subscribe(
            (isLoggedIn) => {
                if (!isLoggedIn) {
                    this.supportScriptService.loadDefaultScript();
                    return;
                }

                const user = this.appState.user$();
                if (user) {
                    this.supportScriptService.loadUserSupportScript(user);
                } else {
                    this.userService.getUser().subscribe(
                        (user) => {
                            this.supportScriptService.loadUserSupportScript(user);
                        },
                    );
                }
            },
        );
    }

    private navigationInterceptor(event): void {
        if (event instanceof NavigationStart) {
            this.showSpinner();
        }

        if (event instanceof NavigationEnd) {
            this.hideSpinner();
        }

        if (event instanceof NavigationCancel) {
            this.hideSpinner();
        }

        if (event instanceof NavigationError) {
            this.hideSpinner();
        }
    }

    private showSpinner(): void {
        this.isPageLoading = true;
    }

    private hideSpinner(): void {
        this.utilityService.delay(
            () => {
                this.isPageLoading = false;

                const bodyEl = this.document.getElementsByTagName('body')[0];
                bodyEl?.classList.remove('noscroll');
            },
            250);
    }

    private setHtmlEnvironments() {
        const md = new MobileDetect(window.navigator.userAgent);

        const htmlEl = this.document.getElementsByTagName('html')[0];

        if (md.os()) {
            htmlEl.classList.add(md.os());
        }
    }
}
