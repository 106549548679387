<div class="sign-up">
    <div class="header">
        <div class="header-items">
            <div class="header-item">
                <upsc-logo></upsc-logo>
            </div>
            <div class="header-item" *ngIf="isMJSA">
                <h4>Welcome MJSA member! Create your account here.</h4>
            </div>
            <div class="header-item">
                <label> Already have an account? </label>
                <div>
                    <button type="button" mat-raised-button (click)="cancelSignup()" class="alternative-button login">
                        Login
                    </button>
                </div>
            </div>
        </div>
    </div>

    @if (welcomeMessage) {
        <div class="welcome">
            <div class="message">{{ welcomeMessage }}</div>
        </div>
    }

    <div class="sign-up-wrapper">
        <div class="account-registration">
            <div class="title">
                Account Registration
            </div>
            <div class="help-desk">
                {{ 'HaveQuestions'|translate }}? <span class="phone">(888) 683-2300</span>
            </div>
        </div>
        <div class="page-content">
            <mat-horizontal-stepper *ngIf="!submitted" labelPosition="bottom" #stepper [linear]="true">
                <mat-step [stepControl]="businessInformationFormGroup">
                    <ng-template matStepLabel>Business <br> Information</ng-template>
                    <ng-container [ngTemplateOutlet]="businessInformation"></ng-container>
                    <div class="actions">
                        <button mat-button (click)="cancelSignup()">Cancel</button>
                        <button mat-raised-button [disabled]="!businessInformationFormGroup?.valid" matStepperNext>Next:
                            Account Information
                        </button>
                    </div>
                </mat-step>
                <mat-step [stepControl]="accountInformationFormGroup">
                    <ng-template matStepLabel>Account <br> Information</ng-template>
                    <ng-container [ngTemplateOutlet]="accountInformation"></ng-container>
                    <div class="actions">
                        <button mat-button (click)="cancelSignup()">Cancel</button>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-raised-button [disabled]="!accountInformationFormGroup?.valid" matStepperNext>Next:
                            Billing Information
                        </button>
                    </div>

                </mat-step>
                <mat-step [stepControl]="billingInformationFormGroup">

                    <ng-template matStepLabel>Billing <br> Information</ng-template>
                    <ng-container [ngTemplateOutlet]="billingInformation"></ng-container>
                    <div class="actions">
                        <button mat-button (click)="cancelSignup()">Cancel</button>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-raised-button matStepperNext [disabled]="!billingInformationFormGroup?.valid">Next:
                            Insurance & Shipment History
                        </button>
                    </div>

                </mat-step>
                <mat-step [stepControl]="insuranceFormGroup">
                    <ng-template matStepLabel>Insurance & <br> Shipment History</ng-template>
                    <ng-container [ngTemplateOutlet]="insuranceAndShipmentHistory"></ng-container>
                    <div class="captcha">
                        <div #recaptcha></div>
                    </div>
                    <div class="actions">
                        <button mat-button (click)="cancelSignup()" [disabled]="isSubmitting">Cancel</button>
                        <button mat-button matStepperPrevious [disabled]="isSubmitting">Back</button>

                        <!-- MV3-6781: Submit application button not disabled once user clicks -->
                        <button mat-raised-button (click)="submitApplication()"
                                [disabled]="!billingInformationFormGroup?.valid || !businessInformationFormGroup?.valid || !accountInformationFormGroup?.valid || !insuranceFormGroup?.valid || isSubmitting">
                            Submit Application
                        </button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
            <div class="submitted" *ngIf="submitted">
                <p>
                    Thank you for submitting your online application!
                </p>
                <p>
                    A member of our account management team will
                    reach out to you with a response regarding your account status.
                </p>
                <button mat-raised-button (click)="goHome()">ParcelPro Home</button>
            </div>
        </div>

        <ng-template #businessInformation>
            <upsc-business-information (notifyForm)="businessInfoFormChanged($event)"></upsc-business-information>
        </ng-template>
        <ng-template #accountInformation>
            <upsc-account-information (notifyForm)="accountInfoFormChanged($event)"></upsc-account-information>
        </ng-template>
        <ng-template #billingInformation>
            <upsc-billing-information (notifyForm)="billingInfoFormChanged($event)"></upsc-billing-information>
        </ng-template>
        <ng-template #insuranceAndShipmentHistory>
            <upsc-insurance-shipments (notifyForm)="insuranceFormChanged($event)"></upsc-insurance-shipments>
        </ng-template>
        <div class="footer">
            © {{ getCurrentYear() }} United Parcel Service of America, Inc. All rights reserved.
        </div>
    </div>

    <upsc-element-blocker *ngIf="isSubmitting"></upsc-element-blocker>
</div>
