import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upsc-delivery-defense',
  templateUrl: './delivery-defense.component.html',
  styleUrls: ['./delivery-defense.component.scss']
})
export class DeliveryDefenseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
