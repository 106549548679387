<div class="dialog-header">
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <div class="title">Terms and Conditions</div>
    <div class="content">
        <upsc-terms-conditions-static></upsc-terms-conditions-static>
    </div>

    <div class="dialog-actions">
        <button mat-flat-button
                class="dialog-action"
                color="primary"
                type="button"
                mat-dialog-close>OK
        </button>
    </div>
</div>
