<div class="top-actions">
    <button (click)="closeFilter()" class="close-icon" tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>
<h2 class="title">Advanced Filter</h2>
<form [formGroup]="formGroup" (ngSubmit)="applyFilter()"> 
    <div class="score-history-filter-container">
        <h3>Score Range</h3>
        <div class="score-history-balance-container">
            <div class="score-history-balance-form minimum">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Minimum</mat-label>
                    <div class="score-history-value-input">
                        <input matInput type="number" formControlName="minRange" placeholder="100" [(ngModel)]="minRange">
                    </div>
                </mat-form-field>
            </div>
            <div class="score-history-balance-divider"></div>
            <div class="score-history-balance-form maximum">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Maximum</mat-label>
                    <div class="score-history-value-input">
                        <input matInput type="number" formControlName="maxRange" placeholder="1000" [(ngModel)]="maxRange">
                    </div>
                </mat-form-field>
            </div>
        </div>

        @if (formGroup?.controls.minRange.errors) {
            <mat-error>{{ formGroup?.controls.minRange.errors | firstErrorMessage }}</mat-error>
        }

        @if (formGroup?.controls.maxRange.errors) {
            <mat-error>{{ formGroup?.controls.maxRange.errors | firstErrorMessage }}</mat-error>
        }

        <div class="range-slider">
            <div class="label min">{{ minRange }}</div>
            <div class="label max">{{ maxRange }}</div>
            <mat-slider class="score-history-slider-container" min="100" max="1000">
                <input [value]="formGroup?.controls.minRange.value" (valueChange)="formGroup?.controls.minRange.setValue($event)" matSliderStartThumb>
                <input [value]="formGroup?.controls.maxRange.value" (valueChange)="formGroup?.controls.maxRange.setValue($event)" matSliderEndThumb>
            </mat-slider>
        </div>
    </div>

    <div class="divider"></div>

    <div class="bottom-actions">
        <button type="submit" [disabled]="formGroup?.invalid" mat-flat-button color="primary">
            <span>Apply</span>
        </button>
        <button type="reset" [disabled]="isResetDisabled()" (click)="resetForm($event)" mat-stroked-button color="primary">
            <span>Reset</span>
        </button>
    </div>
</form>

