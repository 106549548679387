import { Component, OnInit, OnDestroy, ViewChild, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Subscription, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import { PaginationInstance } from 'ngx-pagination';

import { UtilityService } from '../../shared/services/utility/utility.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { SiteConfig } from '../../../config/site-config';
import { CreditHistoryService } from '../services/credit-history.service';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'upsc-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss'],
})
export class CreditHistoryComponent implements OnInit, OnDestroy {

  public user: User;
  public currencyCode: string;
  public isHKUser = false;
  public customDateRangeFormGroup: UntypedFormGroup;
  public defaultStartDate = moment().subtract(365, 'days').startOf('day').toDate();
  public defaultEndDate = moment().toDate();
  public formattedStartDate;
  public formattedEndDate;
  public minDate = moment().subtract(3, 'years').startOf('day').toDate();
  public totalRemainingCredit = 0;

  public creditHistoryData: any[];
  public pageIndex = 1;

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  // Pagination
  public paginate: PaginationInstance;
  public tablePageSize = 25;
  public tablePageIndex = 0;
  public isDataLoading = false;
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public tableDateFormat = 'MM-dd-yyyy';

  private totalItems = 0;

  private getCreditHistorySubscription: Subscription;

  private dateFormat = 'MM/DD/YYYY';

  public sessionLanguage: string;

  constructor(private translateService: TranslateService,
    private notificationService: NotificationService,
    private creditHistoryService: CreditHistoryService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService, 
  ) {
    if (!this.user) {
      this.userService.getUser()
        .subscribe((user) => {
          this.setUserValues(user);

          this.formattedStartDate = moment().subtract(365, 'days').startOf('day').format(this.dateFormat);
          this.formattedEndDate = moment().format(this.dateFormat);
        });
    }

    this.translateService.onLangChange.subscribe((event) =>{
      this.sessionLanguage = event.lang;
      this.updateDTOptionsLanguage();
    })
  }

  ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('dashboard.noShipments'),
    };
    this.updatePaginateConfig();
    this.initFormGroup();
    this.getCreditHistory(this.pageIndex.toString());
  }

  initFormGroup() {
    if (!this.user) {
      this.userService.getUser()
        .subscribe((user) => {
          this.setUserValues(user);
        });
    }
    this.customDateRangeFormGroup = this.formBuilder.group({
      startDate: [this.defaultStartDate],
      endDate: [this.defaultEndDate],
    });
    this.subscribeDateRangeFormGroupValues();
  }

  private subscribeDateRangeFormGroupValues() {
    this.customDateRangeFormGroup.valueChanges.subscribe(() => {
      const startDate = this.customDateRangeFormGroup.controls.startDate.value;
      const endDate = this.customDateRangeFormGroup.controls.endDate.value;
      if (dayjs(startDate).isAfter(endDate)) {
        this.customDateRangeFormGroup.controls.startDate.setValue(endDate);
      }

      if (dayjs(endDate).isBefore(startDate)) {
        this.customDateRangeFormGroup.controls.endDate.setValue(startDate);
      }

      this.formattedStartDate = moment(this.customDateRangeFormGroup.controls.startDate.value).format(this.dateFormat);
      this.formattedEndDate = moment(this.customDateRangeFormGroup.controls.endDate.value).format(this.dateFormat);
      this.getCreditHistory(this.pageIndex.toString());
    });
  }

  getCreditHistory(pageIndex: string) {
    let startDate = moment(this.customDateRangeFormGroup.controls.startDate.value).format('YYYY-MM-DD');
    let endDate = moment(this.customDateRangeFormGroup.controls.endDate.value).format('YYYY-MM-DD');

    this.creditHistoryService.getCreditHistory(pageIndex, this.tablePageSize.toString(), startDate, endDate)
      .subscribe(
        (response) => {
          this.tablePageIndex = +pageIndex;

          if (response) {
            this.creditHistoryData = response;
          } else {
            this.creditHistoryData = [];
          }

          if (response && response.length) {
            this.totalItems = +response[0].CollectionSize; 
            this.totalRemainingCredit = response[0].RemainingAmount; 
          }

          this.dtTrigger.next(null);
          this.updatePaginateConfig();
        },
        (error) => {
          this.dtTrigger.next(null);
          this.notificationService.notify(error._body || error.error?.Message, 'Error', NotificationType.ERROR);
        });
  }

  public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
            emptyTable: this.translateService.instant('dashboard.noShipments')
        };

    this.rerender();
  }

  private rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        if(this.totalItems === 0) {
            dtInstance.destroy();
        }
        // Call the dtTrigger to rerender again
        this.dtTrigger.next(null);
    });
  }

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  private setUserValues(user: User) {
    this.user = user;
          
      switch (user?.CountryCode){
        case 'HK':
          this.currencyCode = 'HKD';
          break;
        case 'DE':
        case 'FR':
        case 'IT':
          this.currencyCode = 'EUR';
          this.dateFormat = 'DD/MM/YYYY';
          this.tableDateFormat = 'dd-MM-yyyy'
          break;
        case 'GB':
          this.currencyCode = 'GBP';
          this.dateFormat = 'DD/MM/YYYY';
          this.tableDateFormat = 'dd-MM-yyyy'
          break;
        default:
          this.currencyCode = 'USD';
          break;
      }
  }

  public onPageChanged(pageNumber: number) {
    this.getCreditHistory(pageNumber.toString());
  }

  ngOnDestroy() {
    this.utilityService.clearSubscriptions([this.getCreditHistorySubscription]);
  }

}
