import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { AppState } from '../../app.state';
import { CancelSubscriptionPopupComponent } from './cancel-subscription-popup/cancel-subscription-popup.component';
import { DdFeedbackComponent } from './dd-feedback/dd-feedback.component';
import { NotificationType } from '../../shared/models/notification-type';
import { User } from 'app/shared/services/user/models/user.model';
import { SharedService } from 'app/core/services/shared.service';
import { UserService } from 'app/shared/services/user/user.service';
import { S3Service } from 'app/shared/services/s3/s3.service';
import { DeliveryDefenseService } from '../services/delivery-defense.service';
import moment from 'moment';
import { StatusCount } from '../model/status-count.model';
import { map, take } from 'rxjs/operators';
import { ShipmentService } from 'app/ship/services/shipment.service';
import { Customer } from 'app/shared/models/customer/customer.model';
import { TablePopupComponent } from './table-popup/table-popup.component';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'upsc-dd-manage-subscription',
  templateUrl: './dd-manage-subscription.component.html',
  styleUrls: ['./dd-manage-subscription.component.scss']
})

export class DdManageSubscriptionComponent implements OnInit {
  public user: User;
  public customer: Customer;
  statusCounts: StatusCount[];
  public ddFullCredit = 0;
  public ddRemainingCredit = 0;
  public ddSubTypeID: number;
  public percentVal = 0;
  public labelPercent = 0;
  public labelVal = '';
  public limit = 0;
  public selecetedLevel = 0;
  public level = '';
  public remainingValue = 0;
  public showRenewelPanel = true;
  public countExceeded = false;
  public showPlanDetails = false;
  public showSubscriptionPlans = false;
  subscribedDetails: any;
  fullMonthNameYear: string;
  startDate: string;
  endDate: string;
  renewDate: string;
  public formattedRenewDate: string;
  isSubscriptionCancelled: boolean = false;

  public get toolTip_popupMessage () {
    return `Ship ${this.limit} shipments within the monthly cycle to earn an upgrade`;
  }

  public constructor(private elementRef: ElementRef,
                     private renderer: Renderer2, private dialog: MatDialog, private sharedService: SharedService,
                     private s3Service: S3Service, private userService: UserService,
                     private deliveryDefenseService: DeliveryDefenseService, private shipmentService: ShipmentService,
                     private notificationService: NotificationService,
                     private translateService: TranslateService,
                     private errorHandlerService: ErrorHandlerService,
                     private router: Router,
                     public readonly appState: AppState,
  ) {
    dayjs.extend(advancedFormat);

    this.user = this.appState.user$();
    this.customer = this.appState.customer$();
    const currentDate = moment();
    this.startDate = currentDate.clone().startOf('month').format('YYYY-MM-DD');
    this.endDate = currentDate.format('YYYY-MM-DD');
    this.getStatusCounts();
  }

  ngOnInit(): void {
    this.deliveryDefenseService.getSubscriberDetails().subscribe(response => {
      this.subscribedDetails = response;
      const date = moment(this.subscribedDetails.SubscribedOn);
      this.fullMonthNameYear = date.format('MMMM YYYY');
      const originalDateMoment = moment(this.subscribedDetails.CurrentSubStartDate);
      const newDateMoment = originalDateMoment.add(29, 'days');

      this.renewDate = newDateMoment.format('MMMM DD YYYY');
      const ordinal = (dayjs(newDateMoment.toDate()).format('Do')).replace(/[0-9]/g, '');
      this.formattedRenewDate = `${ dayjs(newDateMoment.toDate()).format('MMMM D') }<sup>${ ordinal }</sup>`;

      if ([1, 2, 3, 4].includes(this.subscribedDetails?.CurrentSubType) && this.subscribedDetails?.CancelledOn && this.subscribedDetails?.CurrentSubEndDate) {
        this.renewDate = this.subscribedDetails.CancelledOn;
        this.isSubscriptionCancelled = true;
      }
    }, error => {
      console.log(error)
    });

    setTimeout(() => {
      document.getElementById('gaugeContainer')?.classList?.add('animated');
    }, 500);
    this.updateDDCredit();

  }

  getStatusCounts() {

    this.userService.getStatusCount(this.startDate, this.endDate).pipe(
      map((response: any) => response as StatusCount[])
    ).subscribe(
      (statusCounts) => {
        this.statusCounts = statusCounts;
        this.percentVal = this.statusCounts[0].count;
        //this.percentVal = 72;
        this.labelVal = this.percentVal.toString();

        // this.limit = '100';
        // this.remainingValue = Number(this.limit) - this.percentVal;


        this.deliveryDefenseService.getSubscriberDetails().subscribe(response => {
          this.subscribedDetails = response;


          if (this.percentVal >= 25) {
            this.selecetedLevel = 1
          }
          if (this.percentVal >= 50) {
            this.selecetedLevel = 2
          }
          if (this.percentVal == 100) {
            this.selecetedLevel = 3
          }
          if (this.subscribedDetails?.CurrentSubType == 1) {
            //////////1///////
            this.level = 'Complimentary';
            this.limit = 100;
            this.remainingValue = 100 - this.percentVal;
            this.labelPercent = this.percentVal;
            if (this.percentVal === 100) {
              let isActive = document.getElementById("checkpoint" + 1);
              this.renderer.addClass(isActive, 'active');
            }
          }



          if (this.subscribedDetails?.CurrentSubType == 2) {
            //////////2///////
            this.level = 'High Volume Program 1';
            this.labelPercent = (this.percentVal / 200) * 100;
            this.limit = 200;
            this.remainingValue = 200 - this.percentVal;
            if (this.percentVal >= 100) {
              let isActive = document.getElementById("checkpoint2");
              this.renderer.addClass(isActive, 'active');
            }
            if (this.percentVal === 200) {
              let isActive = document.getElementById("checkpoint3");
              this.renderer.addClass(isActive, 'active');
            }

          }
          if (this.subscribedDetails?.CurrentSubType == 3 || this.subscribedDetails?.CurrentSubType == 4) {
            //////////3///////4\\\\\\\\\\\\
            if (this.subscribedDetails?.CurrentSubType == 3) {
              this.level = 'High Volume Program 2';
              this.limit = 300;
              this.labelPercent = (this.percentVal / 400) * 100;
              this.remainingValue = 400 - this.percentVal;
              if (this.percentVal >= 100) {
                let isActive = document.getElementById("checkpoint1");
                this.renderer.addClass(isActive, 'active');
              }
              if (this.percentVal >= 200) {
                let isActive = document.getElementById("checkpoint2");
                this.renderer.addClass(isActive, 'active');
              }
              if (this.percentVal === 400) {
                let isActive = document.getElementById("checkpoint3");
                this.renderer.addClass(isActive, 'active');
              }
            }
            if (this.subscribedDetails?.CurrentSubType == 4) {
              this.level = 'High Volume Program 3';
              this.limit = 400;
              if (this.percentVal <= 400) {
                this.labelPercent = (this.percentVal / 400) * 100;
                this.remainingValue = 400 - this.percentVal;
                if (this.percentVal >= 100) {
                  let isActive = document.getElementById("checkpoint1");
                  this.renderer.addClass(isActive, 'active');
                }
                if (this.percentVal >= 200) {
                  let isActive = document.getElementById("checkpoint2");
                  this.renderer.addClass(isActive, 'active');
                }
                if (this.percentVal === 400) {
                  let isActive = document.getElementById("checkpoint3");
                  this.renderer.addClass(isActive, 'active');
                }
              }

              if (this.percentVal > 400) {
                this.labelPercent = 100;
                //this.remainingValue = 400 - this.percentVal;
                this.countExceeded = true;

                for (let index = 1; index <= 3; index++) {
                  let isActive = document.getElementById("checkpoint" + index);
                  this.renderer.addClass(isActive, 'active');
                }
                // if (this.percentVal >= 100) {
                //   let isActive = document.getElementById("checkpoint1");
                //   this.renderer.addClass(isActive, 'active');
                // }
                // if (this.percentVal >= 200) {
                //   let isActive = document.getElementById("checkpoint2");
                //   this.renderer.addClass(isActive, 'active');
                // }
                //  if (this.percentVal === 400) {
                //   let isActive = document.getElementById("checkpoint3");
                //   this.renderer.addClass(isActive, 'active');
                // }
              }

            }


          }
        }, error => {
          console.log("error++++++++++++++++", error)
        });

      },
      (error) => {
      }
    );

  }
  //   private updateDDCredit(): void {
  //     this.shipmentService.getRemainingDDCredit().subscribe(
  //         (ddCredit) => {
  //             this.ddRemainingCredit = ddCredit?.ScoresRemaining;
  //             this.ddFullCredit = ddCredit?.TotalScores;

  //             if (this.ddRemainingCredit <= 0) {
  //                 this.shipmentService.moreDeliveryDefenseScoreRequired$.next({ currentFullCredit: this.ddFullCredit });
  //             }
  //         },
  //     );
  // }
  private updateDDCredit(): void {
    const isUSUser = this.user?.CountryCode === 'US';
    const isDDCustomer = this.customer?.DDEnabled === true;

    if (!isUSUser || !isDDCustomer) {
      return;
    }

    this.deliveryDefenseService.getRemainingDDCredit()
      .subscribe(
        (ddCredit) => {
          if (!ddCredit) {
            return;
          }

          this.ddRemainingCredit = +ddCredit?.ScoresRemaining;
          this.ddFullCredit = +ddCredit?.TotalScores;
          this.ddSubTypeID = +ddCredit?.SubTypeID;

          // this.deliveryDefenseService.handleZeroCredit(ddCredit);
        },
      );
  }

  openRenwelPanel() {
    this.showRenewelPanel = !this.showRenewelPanel;
  }
  setValue(event) {
  }
  private handleGetUserSuccess(user: User) {
    this.user = user;

  }
  private handleGetUserError(err) {
    console.log(err)
  }

  public cancelSubscription() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '966px',
      data: {
        editMode: false,

      },
    };
    const dialogRef: MatDialogRef<CancelSubscriptionPopupComponent> = this.dialog.open(CancelSubscriptionPopupComponent, dialogConfig);

    // DD-485: Disable cancel subscription link on cancelling DD subscription
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result?.isCancelled) {
          this.deliveryDefenseService.getSubscriberDetails().subscribe(
            (response) => {
              this.renewDate = new Date().toISOString().split('T')[0];
              this.subscribedDetails.CurrentSubEndDate = response?.CurrentSubEndDate;
              this.isSubscriptionCancelled = true;
            }
          )
        }
      },
    );

  }
  public feedback() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,

      data: {
        editMode: false,

      },
    };
    this.dialog.open(DdFeedbackComponent, dialogConfig);

  }
  public learnMore() {
    this.showPlanDetails = true;
    const dialogConfig: MatDialogConfig = {
      disableClose: true,

      data: {
        editMode: false,

      },
    };
    this.dialog.open(TablePopupComponent, dialogConfig);
  }

  shipIconClick() {
    this.router.navigate(['/ship/ups']);
  }
  fedexIconClick() {
    this.router.navigate(['/ship/fedex']);
  }

}
