<div class="required">
    * {{'requiredFields'|translate}}
</div>
<div class="page-title">
    2. Account Information
</div>
<div class="account-information">
    <form class="ups-form" [formGroup]="accountInformationFormGroup">
        <div class="company-info">
            <mat-form-field appearance="outline">
                <mat-label>Street Address</mat-label>
                <input matInput required formControlName="streetAddress" maxlength="50" autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.streetAddress?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.streetAddress?.errors?.required">
                        Street Address is required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Suite / Apt (Optional)</mat-label>
                <input matInput formControlName="suiteApt" maxlength="50" autocomplete="off">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput required formControlName="city" maxlength="50" autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.city?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.city?.errors?.required">
                        City is required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="country=='US' || country=='CA'">
                <mat-label>State / Province</mat-label>
                <mat-select required placeholder="Select State/Province" formControlName="stateProvince">
                    <mat-option *ngFor="let state of states" [value]="state.code">
                        {{state.code}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="accountInformationFormGroup.controls.stateProvince?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.stateProvince?.errors?.required">
                        State / Province is required
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="country!='US' && country!='CA'">
                <mat-label>State / Province</mat-label>
                <input matInput formControlName="stateProvince" maxlength="15" autocomplete="off">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Zip / Postal Code</mat-label>
                <input matInput [maxlength]="maxLength" [required]="(country=='US' || country=='CA') ? true : null"
                    formControlName="zip" autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.zip?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.zip?.errors?.required">
                        Zip / Postal Code is required
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.zip?.errors?.pattern">
                        Zip / Postal Code should be number
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.zip?.errors?.alphabet">
                        Zip / Postal Code should be AlphaNumeric
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.zip?.errors?.number">
                        Zip / Postal Code should be AlphaNumeric
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.zip?.errors?.maxlength">
                        Zip / Postal Code exceeded Maximum length
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Company Website</mat-label>
                <input matInput formControlName="website" autocomplete="off">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput required formControlName="phoneNumber" (input)="addHyphen($event.target)" maxlength="15" autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.phoneNumber?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.phoneNumber?.errors?.required">
                        Phone Number is required
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.phoneNumber?.errors?.notInteger">
                        Phone Number should be number
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.phoneNumber?.errors?.pattern">
                        Phone Number should be of format 999-999-9999
                    </span>
                </mat-error>
            </mat-form-field>
            
            <div class="empty-form-field"></div>
            
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput required type="email" formControlName="email" maxlength="256" autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.email?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.email?.errors?.required">
                        Email is required
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.email?.errors?.emailFormat">
                        {{'EmailAddressInvalidFormat'|translate}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Confirm Email</mat-label>
                <input matInput type="email" formControlName="confirmEmail" maxlength="256" required autocomplete="off">
                <mat-error *ngIf="accountInformationFormGroup.controls.confirmEmail?.errors">
                    <span *ngIf="accountInformationFormGroup.controls.confirmEmail?.errors?.required">
                        Confirm Email is required
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.confirmEmail?.errors?.emailFormat">
                        {{'EmailAddressInvalidFormat'|translate}}
                    </span>
                    <span *ngIf="accountInformationFormGroup.controls.confirmEmail.errors?.mustMatch">
                        Email and Confirm Email is not matching
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="separator"> </div>

        <div class="contact-preference">
            <label>How would you like to be contacted?<span class="red-asterik">*</span> </label>
            <mat-radio-group class="contact-wrapper" required formControlName="contactPreference">
                <mat-radio-button value="Phone">Phone</mat-radio-button>
                <mat-radio-button value="Email">Email</mat-radio-button>
                <!-- <mat-error *ngIf="accountInformationFormGroup.controls.contactPreference?.errors">
                        <span *ngIf="accountInformationFormGroup.controls.contactPreference?.errors?.required">
                            Contact Preference is required
                        </span>
                    </mat-error> -->
            </mat-radio-group>
        </div>

        <div class="separator"> </div>

        <div class="contact-preference">
            <label>Select your requested service<span class="red-asterik">*</span> </label>
        
            <mat-radio-group class="select-service-container" required formControlName="requestedService">
                <div [ngClass]="{'select-service-wrapper': true, 'selected-service-wrapper': accountInformationFormGroup.get('requestedService')?.value === 'Bundled'}">
                    <mat-radio-button value="Bundled">Parcel Pro Select Bundled</mat-radio-button>
                    <span> Transportation services + Insurance </span>
                </div>
    
                <div [ngClass]="{'select-service-wrapper': true, 'selected-service-wrapper': accountInformationFormGroup.get('requestedService')?.value === 'Insurance'}">
                    <mat-radio-button value="Insurance">Parcel Pro Select Insurance Only</mat-radio-button>
                    <span> Only for insurance </span>
                </div>
            </mat-radio-group>                
        </div>
        

    </form>
</div>
