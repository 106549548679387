<head>
    <title> Address Confidence Tool </title>
</head>
<body>
    <div class="address-confidence-tool-layout">
        <div class="address-confidence-tool-content">
            <div class="header-panel">
                <img src="assets/images/logos/logo-dd.png" alt="dd-logo">
                
                <div class="header">
                    <span class="section-header">Address Confidence Tool </span>
                </div>
            </div>

            <span class="page-description">Address Confidence uses intelligent models powered by multi-carrier data to determine the likelihood of a successful delivery to a particular address in the United States.</span>
            
            <div class="address-search-container" *ngIf="(!isSubmissionComplete && !showReviewAddressDetails && !isReviewingRecentUpload)">
                <span class="address-search-message">Please enter the required fields to search an address.</span>
                <span class="address-search-message required" *ngIf="addressLookupFormGroup['status'] == 'INVALID'">* Required fields.</span>
                
                <div class="address-search-form-container">
                    <div class="address-search-form">
                        <form [formGroup]="addressLookupFormGroup" (ngSubmit)="searchAddress()">
                            <div class="address-field-section">
                                <mat-form-field subscriptSizing="dynamic" class="address-field1">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput type="text" placeholder="Address Line 1" maxlength="35" formControlName="addressLineOne" (blur)="onBlurFormInput($event, 'Address Line 1')" required>
                                </mat-form-field>
    
                                <mat-form-field subscriptSizing="dynamic" class="address-field2">
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput type="text" placeholder="Address Line 2" maxlength="35" formControlName="addressLineTwo">
                                </mat-form-field>    
                            </div>

                            <div class="other-field-section">
                                <mat-form-field subscriptSizing="dynamic" class="zip">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput type="text" placeholder="Zip Code" minlength="5" maxlength="5" formControlName="zipCode" (blur)="populateCityStateByZipCode($event)" required>
                                </mat-form-field>
    
                                <mat-form-field subscriptSizing="dynamic" class="city">
                                    <mat-label>City</mat-label>
                                    <input matInput type="text" placeholder="City" maxlength="30" formControlName="city" id="city_search_input" (blur)="onBlurFormInput($event, 'City')" required>
                                </mat-form-field>
    
                                <mat-form-field subscriptSizing="dynamic" class="state">
                                    <mat-label>State</mat-label>
                                    <input matInput type="text" placeholder="State" minlength="2" maxlength="2" formControlName="state" (blur)="onBlurFormInput($event, 'State')" required>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>

                    <div class="right-actions">
                        <button class="search-button" type="submit" (click)="searchAddress()" mat-raised-button>
                            <span> Search </span>
                        </button>
                        <span class="divider"></span>
                        <upsc-dd-remaining-credits [fullCredit]="ddFullCredit" [remainingCredit]="ddRemainingCredit" [subTypeId]="ddSubTypeID" class="dd-remaining-credits"></upsc-dd-remaining-credits>
                    </div>
                </div>

                <span class="alert-message missing-field" *ngIf="missingFieldMessage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <g clip-path="url(#clip0_7802_9161)">
                            <path d="M12.753 10.757C13.1354 11.4151 12.6554 12.2376 11.8916 12.2376H1.94877C1.18347 12.2376 0.705641 11.4138 1.08733 10.757L6.0588 2.20069C6.44141 1.54237 7.39974 1.54356 7.78167 2.20069L12.753 10.757ZM6.92023 8.98803C6.39386 8.98803 5.96715 9.41161 5.96715 9.9341C5.96715 10.4566 6.39386 10.8802 6.92023 10.8802C7.4466 10.8802 7.87331 10.4566 7.87331 9.9341C7.87331 9.41161 7.4466 8.98803 6.92023 8.98803ZM6.01537 5.5874L6.16906 8.38448C6.17625 8.51537 6.28528 8.61783 6.41732 8.61783H7.42315C7.55519 8.61783 7.66422 8.51537 7.6714 8.38448L7.8251 5.5874C7.83287 5.44602 7.71947 5.32715 7.57684 5.32715H6.2636C6.12097 5.32715 6.0076 5.44602 6.01537 5.5874Z" fill="#DF2901"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_7802_9161">
                                <rect width="11.9342" height="11.9342" fill="white" transform="translate(0.953125 0.855164)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    {{missingFieldMessage}}
                </span>

                <span class="alert-message recent-search-address" *ngIf="isAddressRecentlySearched">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <g clip-path="url(#clip0_7838_11909)">
                            <path d="M12.7491 10.7291C13.1315 11.3871 12.6515 12.2096 11.8877 12.2096H1.94486C1.17956 12.2096 0.701735 11.3858 1.08342 10.7291L6.05489 2.17271C6.43751 1.51439 7.39583 1.51558 7.77777 2.17271L12.7491 10.7291ZM6.91633 8.96005C6.38996 8.96005 5.96325 9.38362 5.96325 9.90612C5.96325 10.4286 6.38996 10.8522 6.91633 10.8522C7.4427 10.8522 7.86941 10.4286 7.86941 9.90612C7.86941 9.38362 7.4427 8.96005 6.91633 8.96005ZM6.01146 5.55942L6.16516 8.3565C6.17235 8.48738 6.28137 8.58985 6.41341 8.58985H7.41924C7.55129 8.58985 7.66031 8.48738 7.6675 8.3565L7.82119 5.55942C7.82896 5.41804 7.71557 5.29916 7.57294 5.29916H6.2597C6.11707 5.29916 6.00369 5.41804 6.01146 5.55942Z" fill="#29A855"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_7838_11909">
                                <rect width="11.9342" height="11.9342" fill="white" transform="translate(0.949219 0.827179)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    This address was recently searched and will not affect your remaining searches.
                </span>

                <span class="alert-message invalid-address" *ngIf="isNotValidAddress">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <g clip-path="url(#clip0_7838_32446)">
                            <path d="M12.753 10.7291C13.1354 11.3871 12.6554 12.2096 11.8916 12.2096H1.94877C1.18347 12.2096 0.705641 11.3858 1.08733 10.7291L6.0588 2.17271C6.44141 1.51439 7.39974 1.51558 7.78167 2.17271L12.753 10.7291ZM6.92023 8.96005C6.39386 8.96005 5.96715 9.38362 5.96715 9.90612C5.96715 10.4286 6.39386 10.8522 6.92023 10.8522C7.4466 10.8522 7.87331 10.4286 7.87331 9.90612C7.87331 9.38362 7.4466 8.96005 6.92023 8.96005ZM6.01537 5.55942L6.16906 8.3565C6.17625 8.48738 6.28528 8.58985 6.41732 8.58985H7.42315C7.55519 8.58985 7.66422 8.48738 7.6714 8.3565L7.8251 5.55942C7.83287 5.41804 7.71947 5.29916 7.57684 5.29916H6.2636C6.12097 5.29916 6.0076 5.41804 6.01537 5.55942Z" fill="#FBD300"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_7838_32446">
                                <rect width="11.9342" height="11.9342" fill="white" transform="translate(0.953125 0.827179)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    There is currently not enough data on this address to provide a score.
                </span>
                
                <div *ngIf="isNoRemainingScores">
                    <span class="alert-message no-remaining-scores">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <g clip-path="url(#clip0_7802_9161)">
                                <path d="M12.753 10.757C13.1354 11.4151 12.6554 12.2376 11.8916 12.2376H1.94877C1.18347 12.2376 0.705641 11.4138 1.08733 10.757L6.0588 2.20069C6.44141 1.54237 7.39974 1.54356 7.78167 2.20069L12.753 10.757ZM6.92023 8.98803C6.39386 8.98803 5.96715 9.41161 5.96715 9.9341C5.96715 10.4566 6.39386 10.8802 6.92023 10.8802C7.4466 10.8802 7.87331 10.4566 7.87331 9.9341C7.87331 9.41161 7.4466 8.98803 6.92023 8.98803ZM6.01537 5.5874L6.16906 8.38448C6.17625 8.51537 6.28528 8.61783 6.41732 8.61783H7.42315C7.55519 8.61783 7.66422 8.51537 7.6714 8.38448L7.8251 5.5874C7.83287 5.44602 7.71947 5.32715 7.57684 5.32715H6.2636C6.12097 5.32715 6.0076 5.44602 6.01537 5.5874Z" fill="#DF2901"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_7802_9161">
                                    <rect width="11.9342" height="11.9342" fill="white" transform="translate(0.953125 0.855164)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        The number of included scores has been reached. 
                        <a (click)="showDeliveryDefenseSignUpDialog($event)"
                           [class.disabled]="ddRemainingCredit > 0"
                           class="credit-cta"
                           href="#">
                            <mat-icon>add_circle_outline</mat-icon>
                            <span [innerHTML]="'ship.shipUps.ddRemainingCredits.addCredits' | translate"></span>
                        </a>
                        to continue using DeliveryDefense Address Confidence Tool.
                    </span>

                    <div class="tips-section">
                        <span class="tips-message">Not getting a score?<a (click)="tipsPanel()">Tips and tricks</a></span>
                        
                        <div *ngIf="showTipsPanel" class="tips-panel">
                            <div class="close-container">
                                <img (click)="tipsPanelClose()" alt="close" class="close-btn" src="../../../assets/icons/close1.png">
                            </div>
                            
                            <div class="tips-container">
                                <div class="tips-top-section">
                                    <div class="tip">
                                        <h1>1.</h1>
                                        <div class="sub-section">
                                            <img alt="lightbulb" class="lightbulb" src="../../../assets/icons/lightbulb.svg">
                                            <h2>Select the suggest address<br>from the pop up</h2>
                                        </div>
                                    </div>
                                    
                                    <div class="divider"></div>

                                    <div class="tip">
                                        <h1>2.</h1>
                                        <div class="sub-section">
                                            <img alt="comma" class="comma" src="../../../assets/icons/comma.jpg">
                                            <img alt="blocked_comma" class="blocked" src="../../../assets/icons/block.svg">
                                            <h2>Do not add a comma between<br>state abbreviations and zip codes</h2>
                                        </div>
                                    </div>

                                    <div class="divider"></div>

                                    <div class="tip">
                                        <h1>3.</h1>
                                        <div class="sub-section">
                                            <img alt="blocked_periods" class="period" src="../../../assets/icons/Frame_24088.svg">
                                            <h2>Do not include periods<br>with abbreviations</h2>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="tips-bottom-section">
                                    <div class="tip">
                                        <h1>4.</h1>
                                        <div class="sub-section">
                                            <img alt="blocked_cardinal" class="lightbulb" src="../../../assets/icons/explore_off.svg">
                                            <h2>Do not include cardinal<br>directions in the street name</h2>
                                        </div>
                                    </div>

                                    <div class="divider"></div>

                                    <div class="tip">
                                        <h1>5.</h1>
                                        <div class="sub-section">
                                            <img alt="blocked_country" class="lightbulb" src="../../../assets/icons/No Country.svg">
                                            <h2>Do not enter country<br>(for example US or United States)</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="score-container" *ngIf="addressDDScore">
                    <div class="score-information-wrapper">
                        <span class="label">Low</span>
                        <span class="score-text">
                            Score: {{addressDDScore}}
                            <span class="material-symbols-outlined question-icon" (click)="showDDScoreCards()">help</span>
                        </span>
                        <span class="label">High</span>
                    </div>
                    
                    <div class="score-gradient-bar-container">
                        <div class="score-gradient-bar">
                            <input type="range" min="0" max="1000" value="{{addressDDScore}}" class="score-slider">
                        </div>
                    </div>
                    
                    <div class="score-range-labels">
                        <span>100</span>
                        <span>1000</span>
                    </div>
                    
                    <div class="scores" *ngIf="showDDScoreCardsFlag">
                        <div *ngFor="let score of scores; let i = index" class="score">
                            <div class="score-range">{{ score.range }}</div>
                            <div class="score-message">
                                <span *ngIf="i !== (scores.length - 1)"
                                      [innerHTML]="'deliveryDefenseScoreInfo.riskMessage' | translate: {
                                    range: score.range,
                                    maxRange: maxRange,
                                    multiplier: score.riskMultiplier
                                }" class="no-risk"></span>
                                <span *ngIf="i === (scores.length - 1)"
                                      [innerHTML]="'deliveryDefenseScoreInfo.noRiskMessage' | translate" class="no-risk"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        @if (environment.feature.addressUpload) {
            <!-- Upload Address File Component -->
            <upsc-dd-upload-address-file *ngIf="!isReviewingRecentUpload"> </upsc-dd-upload-address-file>

            <!-- Recent Uploads Component -->
            <upsc-recent-uploads *ngIf="(!isSubmissionComplete && !showReviewAddressDetails && !isReviewingRecentUpload)"> </upsc-recent-uploads>

            <!-- Recent Upload Review Component -->
            <upsc-dd-upload-address-file [recentUploadData]="recentUploadData" *ngIf="isReviewingRecentUpload"> </upsc-dd-upload-address-file>
        }

        <!-- Score History Table Component -->
        <upsc-dd-score-history-table class="score-history-table-address-confidence-tool" *ngIf="(!isSubmissionComplete && !showReviewAddressDetails && !isReviewingRecentUpload)" [lastColumnName]="'Access Point Location'" [tableName]="'Recently Searched Addresses'" [componentName]="'Address Confidence Tool'"></upsc-dd-score-history-table>
    </div>
</body>
