import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'upsc-rating-bar-component',
  templateUrl: './rating-bar-component.component.html',
  styleUrls: ['./rating-bar-component.component.scss']
})
export class RatingBarComponentComponent {
  @Input() emojiImagePaths: string[] = [];
  @Input() TypeID: number;
  @Input() Rating: number;
  public typeId = 0;
  // @Output() selectedEmojiImagePath = new EventEmitter<string>();
  @Output() payloadValueEvent = new EventEmitter<string>();
  selectedEmojiImageValue: string;
  newEmojiImagePath: string;

  constructor() { }

  onEmojiImageClick(emojiImagePath: string, id: string) {
    let selectedEmojiImage1 = document.querySelector('#emoji-img-0');
    let selectedEmojiImage2 = document.querySelector('#emoji-img-1');
    let selectedEmojiImage3 = document.querySelector('#emoji-img-2');
    let selectedEmojiImage4 = document.querySelector('#emoji-img-3');
    let selectedEmojiImage5 = document.querySelector('#emoji-img-4');
    
    selectedEmojiImage1.setAttribute('src','assets/icons/emoji1.png');
    selectedEmojiImage2.setAttribute('src','assets/icons/emoji2.png');
    selectedEmojiImage3.setAttribute('src','assets/icons/emoji3.png');
    selectedEmojiImage4.setAttribute('src','assets/icons/emoji4.png');
    selectedEmojiImage5.setAttribute('src','assets/icons/emoji5.png');
    switch (id) {
      case 'emoji-img-0':
        this.newEmojiImagePath = 'assets/icons/emoji6.png';       
        selectedEmojiImage1.setAttribute('src', this.newEmojiImagePath);
        break;
      case 'emoji-img-1':
        this.newEmojiImagePath = 'assets/icons/emoji7.png';       
        selectedEmojiImage2.setAttribute('src', this.newEmojiImagePath);
        break;
        case 'emoji-img-2':
        this.newEmojiImagePath = 'assets/icons/emoji8.png';       
        selectedEmojiImage3.setAttribute('src', this.newEmojiImagePath);
        break;
        case 'emoji-img-3':
        this.newEmojiImagePath = 'assets/icons/emoji9.png';       
        selectedEmojiImage4.setAttribute('src', this.newEmojiImagePath);
        break;
        case 'emoji-img-4':
        this.newEmojiImagePath = 'assets/icons/emoji10.png';       
        selectedEmojiImage5.setAttribute('src', this.newEmojiImagePath);
        break;
     
    }
    const numbersArray = id.match(/\d+/g);
    this.typeId =  parseInt(numbersArray[0], 10) + 1;
    
   
    this.payloadValueEvent.emit(this.typeId.toString());
  }
    
 
}
