import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import dayjs from 'dayjs';
import {ILoginFooterContent} from './models/login-footer-content.interface';
import {PrivacyDialogComponent} from '../../../shared/components/privacy/privacy-dialog/privacy-dialog.component';
import {
    TermsOfUseDialogComponent
} from '../../../shared/components/terms-of-use/terms-of-use-dialog/terms-of-use-dialog.component';
import {UtilityService} from '../../../shared/services/utility/utility.service';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'upsc-login-footer',
    templateUrl: './login-footer.component.html',
    styleUrls: ['./login-footer.component.scss'],
})
export class LoginFooterComponent implements OnInit {
    @Input() content: ILoginFooterContent;

    public isUS = false;
    public isDEGB = false;
    public isGB = false;
    public isDE = false;
    public isIT = false;
    public isFR = false;
    public shouldShowTermsOfUse = false;
    public countryCode = '';
    public currentYear = dayjs().format('YYYY');

    constructor(private dialog: MatDialog,
                private utilityService: UtilityService,
                @Inject(DOCUMENT) private document: Document) {
        const domainName = this.utilityService.getCurrentDomainName();
        this.isGB = this.utilityService.getDomain().toLowerCase() === 'en-gb' ? true : false;
        this.isDE = this.utilityService.getDomain().toLowerCase() === 'de' ? true : false;
        this.isIT = this.utilityService.getDomain().toLowerCase() === 'it' ? true : false;
        this.isFR = this.utilityService.getDomain().toLowerCase() === 'fr' ? true : false;
        this.countryCode = this.utilityService.getDomain().toLowerCase();        
        this.shouldShowTermsOfUse = !['upscapitalde.com', 'upscapitaluk.com'].some(
            (domain) => {
                return domainName.endsWith(domain);
            },
        );

        this.isDEGB = !this.shouldShowTermsOfUse;
        this.isUS = this.utilityService.getDomain().toLowerCase() === 'en' ? true : false;
        setTimeout(() => {
            if (this.isGB) {
              document.getElementById('linksTab').classList.add('compresswidth');
            }
            if (this.isDE) {
                document.getElementById('linksTab').classList.add('compresswidthuk');
              }
            if(this.isFR){
                document.getElementById('linksTab').classList.add('compresswidthfr');
            }
          }, 100);   
                
        this.showDisclaimer();
    }

    public ngOnInit() {
    }
public getContactUsUrl(){
    if(this.countryCode == 'en-gb'){
        return `https://www.parcelpro.com/gb/en/about-us/contact-us.html`; 
    }else{
        return `https://www.parcelpro.com/${this.countryCode}/${this.countryCode}/about-us/contact-us.html`; // Customize the URL structure here
    }
}
    public openPrivacyDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
        };

        let dialogRef: MatDialogRef<PrivacyDialogComponent>;
        dialogRef = this.dialog.open(PrivacyDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    public openTermsOfUseDialog(event) {
        event.preventDefault();

        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '70%',
        };

        let dialogRef: MatDialogRef<TermsOfUseDialogComponent>;
        dialogRef = this.dialog.open(TermsOfUseDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    private showDisclaimer() {
        this.isUS = this.utilityService.getDomain().toLowerCase() === 'en' ? true : false;
    }
}
