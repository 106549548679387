import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';


@Component({
  selector: 'upsc-dd-score-history-advanced-filter',
  templateUrl: './dd-score-history-advanced-filter.component.html',
  styleUrls: ['./dd-score-history-advanced-filter.component.scss']
})

export class DDScoreHistoryAdvancedFilterComponent implements OnInit {
    public minRange: number = 100;
    public maxRange: number = 1000;
    
    formGroup: FormGroup;

    @Input() componentName: string = "";
    
    constructor(private formBuilder: FormBuilder,
                private deliveryDefenseService: DeliveryDefenseService) {
    }

    ngOnInit(): void {
        this.initializeForm();
    }

    private initializeForm(): void {
        this.formGroup = this.formBuilder.group({
            minRange: new FormControl<number>(this.minRange, [
                RxwebValidators.lessThanEqualTo({
                    fieldName: 'maxRange', 
                    message: 'Minimum score must be less than or equal to maximum score.',
                }), 
                RxwebValidators.minNumber({
                    value: this.minRange, 
                    message: `Minimum must be greater than or equal to ${ this.minRange }`,
                }), 
                RxwebValidators.maxNumber({
                    value: this.maxRange, 
                    message: `Maximum must be less than or equal to ${ this.maxRange }`,
                }),
            ]), 
            maxRange: new FormControl<number>(this.maxRange, [
                RxwebValidators.greaterThanEqualTo({
                    fieldName: 'minRange', 
                    message: 'Maximum score must be greater than or equal to minimum score',
                }), 
                RxwebValidators.minNumber({
                    value: this.minRange, 
                    message: `Minimum must be greater than or equal to ${ this.minRange }`,
                }), 
                RxwebValidators.maxNumber({
                    value: this.maxRange, 
                    message: `Maximum must be less than or equal to ${ this.maxRange }`,
                }),
            ]),
        })
        this.formGroup.patchValue({
            minRange: this.minRange, 
            maxRange: this.maxRange,
        });
    }
    
    public closeFilter(): void {
        this.deliveryDefenseService.advancedFilterClosed$.next( {closed: true});
        this.resetForm(null);
    }

    public applyFilter(): void {
        if (this.componentName === "Recent Upload") {
            this.deliveryDefenseService.advancedFilterScoreChangedRecentUpload$.next({min: this.minRange, max: this.maxRange});
        } else {
            this.deliveryDefenseService.advancedFilterScoreChanged$.next({min: this.minRange, max: this.maxRange});
        }
        this.resetForm(null);
    }
    
    public resetForm(event: MouseEvent) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        
        this.formGroup.reset({
            minRange: 100,
            maxRange: 1000,
        });
    }

    public isResetDisabled() {
        return this.minRange === 100 && this.maxRange === 1000;
    }

    protected readonly close = close;
}
