import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SignUp } from '../model/sign-up.model';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { SignUpDropdowns } from '../../../config/dropdown-config';
import { SharedService } from '../services/shared.service';

@Component({
    selector: 'upsc-billing-information',
    templateUrl: './billing-information.component.html',
    styleUrls: ['./billing-information.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BillingInformationComponent implements OnInit {

    public country: string = 'US';
    public states = [];
    public signupDropdowns = SignUpDropdowns;
    public isBillingAddressSameAsBusiness = false;
    public maxLength: number = 5;
    // public isCCBillingAddressSameAsBusiness = false;
    billingInformationFormGroup:UntypedFormGroup;
    @Output() notifyForm: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

    constructor(private formBuilder: UntypedFormBuilder,
        private sharedService: SharedService,
        private validationService: ValidationService,
        public signUp: SignUp) {

    }

    ngOnInit() {
        this.states = this.signupDropdowns.states.filter(x => x.country == this.country);
        this.initForm();
        this.onValueChanges();
        this.notifyForm.emit(this.billingInformationFormGroup);
        this.onBusinessAddressChanges();
    }

    initForm() {
        this.billingInformationFormGroup = this.formBuilder.group({
            contactName: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            phoneNumber: [''],
            paymentType: ['', Validators.required],
            billingAddress: this.formBuilder.group({
                streetAddress: ['', Validators.required],
                suiteApt: [''],
                city: ['', Validators.required],
                stateProvince: [''],
                zip: [''],
                country: ['US'],
            }),
        });
        this.validationChanges();
    }

    onValueChanges() {
        this.billingInformationFormGroup.valueChanges
            .subscribe(() => {
                this.notifyForm.emit(this.billingInformationFormGroup);
            });
        this.billingInformationFormGroup.controls.billingAddress['controls'].country.valueChanges
            .subscribe((value) => {
                this.country = value;
                this.validationChanges();
            });
    }

    validationChanges() {
        if (this.country.toLowerCase() === 'us' || this.country.toLowerCase() === 'ca') {

            this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.billingAddress['controls'].stateProvince,
                Validators.compose([Validators.required, Validators.maxLength(2)]));

            this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.phoneNumber,
                Validators.compose([Validators.required, Validators.pattern(/^\d{3}\-\d{3}\-\d{4}$/)]));

            if (this.country.toLowerCase() === 'us') {
                this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.billingAddress['controls'].zip,
                    Validators.compose([Validators.required,
                    Validators.maxLength(5),
                    Validators.pattern("^[0-9]*$")])); // MV3-4812
                this.maxLength = 5;
            }
            else if (this.country.toLowerCase() === 'ca') {
                this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.billingAddress['controls'].zip,
                    Validators.compose([Validators.required,
                    Validators.maxLength(6),
                    this.validationService.containsAlphabet(),
                    this.validationService.containsNumber()]));
                this.maxLength = 6;
            }

            this.states = this.signupDropdowns.states.filter(x => x.country == this.country);
        }
        else {

            this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.billingAddress['controls'].stateProvince,
                Validators.compose([Validators.maxLength(15)]));

            this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.phoneNumber,
                Validators.compose([Validators.required, this.validationService.isInteger(), Validators.maxLength(15)]));

            this.validationService.setFormControlValidators(this.billingInformationFormGroup.controls.billingAddress['controls'].zip,
                Validators.compose([Validators.maxLength(10)]));
            this.maxLength = 10;
        }
    }

    onBusinessAddressChanges() {
        this.sharedService.businessAddress.subscribe(address => {
            this.signUp = address;
        });
    }

    toggleBillingAddressSameAsBusiness() {
        this.isBillingAddressSameAsBusiness = !this.isBillingAddressSameAsBusiness;
        this.bindAddressFormGroup((this.billingInformationFormGroup), this.isBillingAddressSameAsBusiness);
    }

    bindAddressFormGroup(formGroup: UntypedFormGroup, bind: boolean) {
        formGroup.controls.billingAddress['controls'].streetAddress.setValue(bind ? this.signUp.Address1 : '');
        formGroup.controls.billingAddress['controls'].suiteApt.setValue(bind ? this.signUp.Address2 : '');
        formGroup.controls.billingAddress['controls'].city.setValue(bind ? this.signUp.City : '');
        formGroup.controls.billingAddress['controls'].stateProvince.setValue(bind ? this.signUp.StateProvince : '');
        formGroup.controls.billingAddress['controls'].zip.setValue(bind ? this.signUp.Zip : '');
        formGroup.controls.billingAddress['controls'].country.setValue(bind ? this.signUp.Country : 'US');
        formGroup.controls.contactName.setValue(bind ? `${this.signUp.FirstName} ${this.signUp.LastName}` : '');
        formGroup.controls.phoneNumber.setValue(bind ? this.signUp.PhoneNum : '');
    }

    addHyphen(element) {
        this.sharedService.setHyphen(element, this.country);
    }
}
