import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upsc-table-popup',
  templateUrl: './table-popup.component.html',
  styleUrls: ['./table-popup.component.scss']
})
export class TablePopupComponent implements OnInit {
  public showPlanDetails = false;
  public showSubscriptionPlans = true;
  constructor() { }

  ngOnInit(): void {
  }
  subscriptionPlans() {
    this.showSubscriptionPlans = !this.showSubscriptionPlans;
  }
}
