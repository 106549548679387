import { Injectable } from '@angular/core';
import { ErrorMessageBindingStrategy, ReactiveFormConfig } from '@rxweb/reactive-form-validators';

@Injectable({ providedIn: 'root' })
export class ReactiveFormConfigService {
    public init(): void {
        ReactiveFormConfig.set({
            errorMessageBindingStrategy: ErrorMessageBindingStrategy.OnDirtyOrSubmit,
            validationMessage: {
                alpha: 'Only alphabets are allowed.',
                alphaNumeric: 'Only alphabet and numbers are allowed.',
                compare: 'inputs are not matched.',
                contains: 'value is not contains in the input',
                creditcard: 'credit card number is not correct',
                digit: 'Only digit are allowed',
                email: 'email is not valid',
                greaterThanEqualTo: 'please enter greater than or equal to the minimum number',
                greaterThan: 'please enter greater than to the minimum number',
                hexColor: 'please enter hex code',
                json: 'please enter valid json',
                lessThanEqualTo: 'please enter less than or equal to the maximum number',
                lessThan: 'please enter less than or equal to the maximum number',
                lowerCase: 'Only lowercase is allowed',
                maxLength: 'maximum length is {{x}} digit',
                maxNumber: 'enter value less than equal to {{x}}',
                minNumber: 'enter value greater than equal to {{x}}',
                password: 'please enter valid password',
                pattern: 'please enter valid zipcode',
                range: 'please enter number between {{x}} to {{y}}',
                required: 'this field is required',
                time: 'Only time format is allowed',
                upperCase: 'Only uppercase is allowed',
                url: 'Only url format is allowed',
                zipCode: 'enter valid zip code',
                minLength: 'minimum length is {{x}} digit',
            },
        });
    }
}
