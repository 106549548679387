import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'upsc-dd-score-history-find-pickup-location',
  templateUrl: './dd-score-history-find-pickup-location.component.html',
  styleUrls: ['./dd-score-history-find-pickup-location.component.scss']
})
export class DDScoreHistoryFindPickupLocationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public formGroup: UntypedFormGroup;

  public maxItems: number = 10;
  public maxItemsOptions: number[] = [10,15,20] 

  // TODO: REPLACE WITH ACTUAL API DATA
  public locations = [
    { name: 'THE UPS STORE', address: '1 ORIENT WAY STE#F', city: 'RUTHERFORD', state: 'NJ', zipcode: '07070' },
    { name: 'THE UPS STORE', address: '435 VALLEY BROOK AVE', city: 'LYNDHURTST', state: 'NJ', zipcode: '07071' },
  ];

  
  setResultPerPage(form) {
    // this.totalNumPages = (Math.ceil(this.length / this.pageSize));
    // this.pageIndex = 1;
    // const event: PageEvent = {
    //   length: this.length,
    //   pageIndex: this.pageIndex,
    //   pageSize: this.pageSize
    // };
    // // this.formGroup.patchValue({
    // //   txtPageNumber :this.pageIndex
    // // });
    // this.emitPageEvent(event);
  }
}
