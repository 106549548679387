import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { hmrBootstrap } from './hmr';
import 'hammerjs';

function loadScript(src: string, target: 'head' | 'body', innerHTML: string = '') {
  const script = document.createElement('script');
  if (src !== '')
    script.src = src;
  script.innerHTML = innerHTML;
  script.async = true;
  script.type = 'text/javascript';
  const targetElement = target === 'head' ? document.head : document.body;
  targetElement.appendChild(script);
}

function loadTealiumScripts(environment: string) {
  // Load utag.sync.js
  loadScript(`https://tags.tiqcdn.com/utag/ups/ups-capital/${environment}/utag.sync.js`, 'head');
  // Define Tealium universal data object
  loadScript('', 'body', 'var utag_data={"link_name": ""};');
  // Load Tealium main script
  const tealiumMainScript = ` (function (a, b, c, d) {     
      a = 'https://tags.tiqcdn.com/utag/ups/ups-capital/${environment}/utag.js';
      b = document; c = 'script'; d = b.createElement(c); d.src = a; d.type = 'text/java' + c; d.async = true;
      a = b.getElementsByTagName(c)[0]; a.parentNode.insertBefore(d, a);})();`;
  loadScript('', 'body', tealiumMainScript);
}

const tagEnv = environment.production ? 'prod' : 'dev';
loadTealiumScripts(tagEnv);

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap();
}
