<div class="login-footer">
   <div class="links" id="linksTab" *ngIf="!isUS">
      <a class="link " href="{{ content?.agreement?.link }}" target="{{ content?.agreement?.target || null }}">{{
         'SignInPage.Footer.TechnologyAgreement' | translate }}</a>
      <a *ngIf="isDE || isIT || isFR || isGB" class="link" href="{{ content?.terms.link }}"
         target="{{ content?.terms.target || null }}" [innerHtml]="content?.terms.text"></a>
      <a class="link " href="{{ content?.disclosure?.link }}" target="{{ content?.disclosure?.target || null }}"> {{
         'SignInPage.Footer.ProductDisclosure' | translate }}</a>
      <a class="link " href="{{ content?.complaints?.link }}" target="{{ content?.complaints?.target || null }}"> {{
         'SignInPage.Footer.Complaints' | translate }}</a>

      <a *ngIf="isDE" class="link" href="{{ content?.impressum?.link }}"
         target="{{ content?.impressum?.target || null }}">{{ 'SignInPage.Footer.Imprint' | translate }}</a>

      <a class="link " href="{{ content?.privacy?.link }}" target="{{ content?.privacy?.target || null }}"
         [innerHtml]="content?.privacy?.text"></a>
      <a class="link " href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);" title="Cookie Consent"
         id="cookie-consent">{{ 'SignInPage.Footer.CookieSettings' | translate }}</a>
      <a class="link " *ngIf="isDE || isIT || isFR || isGB"  [href]="getContactUsUrl()" target="_blank"> {{
         'SignInPage.Footer.ContactUs' | translate }}</a>
      <a class="link " href="{{ content?.email?.link }}" target="{{ content?.email?.target || null }}"
         [innerHtml]="content?.email?.text"></a>
      <!-- <a *ngIf="shouldShowTermsOfUse" class="link terms" href="javascript:;" (click)="openTermsOfUseDialog($event)"
         target="{{ content?.terms.target || null }}" [innerHtml]="content?.terms.text"></a> -->
      <a class="link " href="https://{{ content?.website?.link }}" target="{{ content?.website?.target || null }}"
         [innerHtml]="content?.website?.text"></a>

   </div>
   <!-- <div class="" *ngIf="!isUS">
      <a class="link " href="{{ content?.agreement?.link }}" target="{{ content?.agreement?.target || null }}"
         *ngIf="isGB || isDE">{{ 'SignInPage.Footer.TechnologyAgreement' | translate }}</a>
      <a class="link " href="{{ content?.disclosure?.link }}" target="{{ content?.disclosure?.target || null }}"
         *ngIf="isGB || isDE"> {{ 'SignInPage.Footer.ProductDisclosure' | translate }}</a>
      <a class="link " href="{{ content?.complaints?.link }}" target="{{ content?.complaints?.target || null }}"
         *ngIf="isGB || isDE"> {{ 'SignInPage.Footer.Complaints' | translate }}</a>
      <a class="link " href="{{ content?.privacy?.link }}" target="{{ content?.privacy?.target || null }}"
         *ngIf="isGB || isDE" [innerHtml]="content?.privacy?.text"></a>
      <a class="link " href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);"
         *ngIf="isGB || isDE" title="Cookie Consent" id="cookie-consent">{{ 'sidebar.footer.links.cookieSettings' | translate }}</a>
      <a class="link " href="{{ content?.email?.link }}" target="{{ content?.email?.target || null }}"
         *ngIf="isGB || isDE" [innerHtml]="content?.email?.text"></a>
   
      <a class="link " href="https://{{ content?.website?.link }}" target="{{ content?.website?.target || null }}"
        *ngIf="isGB || isDE"[innerHtml]="content?.website?.text"></a>
     
   </div> -->

   <div class="disclaimer" [innerHtml]="content?.disclaimer" *ngIf="!isUS"></div>

   <div class="copyrights" *ngIf="isDE || isIT || isFR || isGB">{{ 'SignInPage.Footer.CopyRights' | translate: { currentYear: currentYear } }}</div>

   <!--        isUS                      -->
   <div class="links" *ngIf="isUS">
      <a class="link " href="https://www.parcelpro.com/us/en/legal/website-terms-of-use.html"
         target="{{ content?.website?.target || null }}">{{ 'contactusUS.footer.terms'
         | translate }}</a>
      <a class="link " href="https://www.parcelpro.com/us/en/legal/technology-agreement?.html"
         target="{{ content?.website?.target || null }}" *ngIf="isUS">{{ 'SignInPage.Footer.TechnologyAgreement' |
         translate }}</a>
      <a class="link " href="https://www.parcelpro.com/us/en/legal/product-disclosure?.html"
         target="{{ content?.website?.target || null }}"> {{ 'contactusUS.footer.productDisclosure' | translate }}</a>
      <a class="link "
         href="https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/california-privacy-rights.page"
         target="{{ content?.website?.target || null }}" [innerHtml]="'Your California Privacy Rights'"
         *ngIf="!isDEGB"></a>


      <a class="link " href="https://www.parcelpro.com/us/en/legal/privacy-notice.html"
         target="{{ content?.privacy?.target || null }}" [innerHtml]="content?.privacy?.text" *ngIf="!isDEGB"></a>
      <a class="link " href="javascript:utag.gdpr.showConsentPreferences();window.scrollTo(0,0);" title="Cookie Consent"
         id="cookie-consent">{{ 'SignInPage.Footer.CookieSettings' | translate }}</a>

      <a class="link " href="https://www.ups.com/ppwa/doWork?loc=en_US" target="{{ content?.website?.target || null }}"
         [innerHtml]="'Do Not Sell or Share My Personal Information'" *ngIf="!isDEGB"></a>
      <a class="link " href="javascript:;" (click)="openPrivacyDialog($event)"
         target="{{ content?.privacy?.target || null }}" [innerHtml]="content?.privacy?.text" *ngIf="isDEGB"></a>

      <a class="link " href="{{ content?.email?.link }}" target="{{ content?.email?.target || null }}"
         [innerHtml]="content?.email?.text"></a>
      <a class="link " href="https://{{ content?.website?.link }}" target="{{ content?.website?.target || null }}"
         [innerHtml]="content?.website?.text"></a>
   </div>

   <div class="copyrights" *ngIf="isUS">Copyright ©{{ currentYear }} United Parcel Service of America, Inc. All rights reserved.
   </div>



</div>
