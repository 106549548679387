import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'creditType'
})
export class CreditTypePipe implements PipeTransform {

    transform(value: any): any {

        switch (value) {
            case 1:
                return 'Address Correction';
            case 2:
                return 'Adult Signature';
            case 3:
                return 'COD Surcharge Credit';
            case 4:
                return 'Courtesy Credit';
            case 5:
                return 'Direct Signature';
            case 6:
                return 'Duty and Taxes';
            case 7:
                return 'Late Delivery';
            case 8:
                return 'Late Fee';
            case 9:
                return 'Large Package Surcharge';
            case 10:
                return 'Other';
            case 11:
                return 'Out of Area Surcharge';
            case 12:
                return 'Pickup Fee';
            case 13:
                return 'Printer Fees';
            case 14:
                return 'Rebills';
            case 15:
                return 'Residential Delivery';
            case 16:
                return 'Saturday Delivery';
            case 17:
                return 'Saturday Pickup';
            case 18:
                return 'Shipping Charges';
            case 19:
                return 'Weight Credit';
            default:
                return 'Other';
        }
    }

}
