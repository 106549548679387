import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Carriers } from '../../../shared/enum/general-enum';
import { NotificationType } from '../../../shared/models/notification-type';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { Contact } from '../../../ship/models/contact.model';
import { PickupLocationsDialogService } from '../../../ship/pickup-locations-dialog/pickup-locations-dialog.service';
import { IPickupLocationRequest } from '../../../ship/pickup-locations/models/pickup-location-request.interface';
import { DeliveryDefenseService } from '../../services/delivery-defense.service';
import { IScoreHistory } from '../scoreHistory.interface';
import { IScoreHistoryTableLookup } from "./scoreHistoryTableLookup.interface";


@Component({
    selector: 'upsc-dd-score-history-table',
    templateUrl: './dd-score-history-table.component.html',
    styleUrls: ['./dd-score-history-table.component.scss'],
})

export class DDScoreHistoryTableComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject();
    public categoryFormGroup: UntypedFormGroup;
    public searchFormGroup: UntypedFormGroup;
    public searchCategory = ['Street', 'City', 'State', 'ZipCode'];
    public currentCategoryName = 'Street';
    public searchInputMaxLength: number;
    
    public scoreHistory: IScoreHistory[] = [];
    public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 10 };
    public pageIndex = 1;
    public pageSize = 1000;
    public isLoadingData: boolean = false;
    
    public showAdvancedFilterPanel = false;
    public minScoreRange: number = 100;
    public maxScoreRange: number = 1000;
    public isFilterApplied: boolean = false;
    public searchInputErrorMessage: string;
    public isAscendingSort: boolean = false;
    public sortedProperty: string = 'ExpiresInDate';
    
    public tableHeaders = ['StreetAddress', 'City', 'State', 'ZipCode', 'Score', 'ExpiresInDate']
        .map(sortKey => ({
            label: this.getLabelFromSortKey(sortKey),
            clickCounter: 0,
            sortKey,
            icon: '../../../assets/icons/filter_list.svg',
            class: 'filter-list-icon',
            isHidden: false,
            applySort: () => this.applySort(sortKey),
            isLastColumn: false,
        }));
    
    @Input() lastColumnName: string;
    @Input() tableName: string;
    @Input() componentName: string;
    
    @Input() isAddressUpload: boolean = false;
    @Input() addressUploadsData = [];

    constructor(private deliveryDefenseService: DeliveryDefenseService,
                private formBuilder: UntypedFormBuilder,
                private pickupLocationsDialogService: PickupLocationsDialogService,
                private utilityService: UtilityService,
                private notificationService: NotificationService,
    ) {}

    ngOnInit(): void {
        this.setUpForms();
        this.setUpTable();
        this.setAdvancedFilter();
        this.checkAddressConfidenceToolSearched();
        
        const scoreHistoryTableLookup: IScoreHistoryTableLookup = {
            pageNumber: this.pageIndex,
            pageSize: this.pageSize
        }
        this.getScoreHistory(scoreHistoryTableLookup);
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.addressUploadsData && changes.addressUploadsData.currentValue) {
          this.extendScoreHistory(this.addressUploadsData);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }

    private setUpForms() {
        this.searchFormGroup = this.formBuilder.group({ searchInput: [''] });
        this.categoryFormGroup = this.formBuilder.group({ selectCategory: [this.currentCategoryName] });
    }
    
    private setUpTable() {
        this.searchInputMaxLength = 35;

        this.tableHeaders.push({
            label: this.lastColumnName,
            clickCounter: null,
            sortKey: null,
            icon: null,
            class: null,
            isHidden: null,
            applySort: null,
            isLastColumn: true,
        });
    }

    private setAdvancedFilter() {
        // DD-555: Fix advanced filter being applied to all score history sub-components
        if (this.componentName == "Recent Upload") {
            this.callAdvancedFilter(this.deliveryDefenseService.advancedFilterScoreChangedRecentUpload$)
        }
        else {
            this.callAdvancedFilter(this.deliveryDefenseService.advancedFilterScoreChanged$)
        }
    }

    private callAdvancedFilter(mySubject: Subject<{min: number, max: number}>): void {
        mySubject
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                this.minScoreRange = value.min;
                this.maxScoreRange = value.max;

                // DD-411: Apply all filters to search
                if (this.onSearchSubmit()) {
                    this.isFilterApplied = true;
                    this.toggleAdvancedFilterPanel(true);
                } else {
                    this.isFilterApplied = false;
                    this.minScoreRange = 100;
                    this.maxScoreRange = 1000;
                    this.toggleAdvancedFilterPanel(false);
                }
            });
    }


    public toggleAdvancedFilterPanel(closed: boolean): void {
        this.showAdvancedFilterPanel = !closed;

        // DD-405: Reset score range values every time filter is closed
        this.deliveryDefenseService.advancedFilterClosed$
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                if (value.closed == true) {
                    this.showAdvancedFilterPanel = false;
                }
            });
    }

    private checkAddressConfidenceToolSearched(): void {
        // DD-436: Call score history API to refresh table after search on Address Confidence Tool page
        this.deliveryDefenseService.addressConfidenceToolSearched$
            .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((value) => {
                if (value.searched === true) {
                    this.refreshTable();
                }
            });
    }

    public getScoreHistory(scoreHistoryTableLookup: IScoreHistoryTableLookup): void {
        this.isLoadingData = true;
        
        // Use addressUploadsData if provided (Recent Uploads, Address Uploads, etc.)
        if (this.addressUploadsData?.length > 0) {
            this.scoreHistory = this.addressUploadsData.map(item => ({
                ...item,
                ExpiresInDate: this.getExpiresInDate(item.SearchDate),
            }));

            // Check if any basic filters were applied to score history in recent uploads popup
            if (scoreHistoryTableLookup?.street) {
                this.scoreHistory = this.scoreHistory.filter((obj) => {
                    return obj.StreetAddress == scoreHistoryTableLookup?.street;
                });
            }

            if (scoreHistoryTableLookup?.city) {
                this.scoreHistory = this.scoreHistory.filter((obj) => {
                    return obj.City == scoreHistoryTableLookup?.city;
                });
            }

            if (scoreHistoryTableLookup?.state) {
                this.scoreHistory = this.scoreHistory.filter((obj) => {
                    return obj.State == scoreHistoryTableLookup?.state;
                });
            }

            if (scoreHistoryTableLookup?.zipCode) {
                this.scoreHistory = this.scoreHistory.filter((obj) => {
                    return obj.ZipCode == scoreHistoryTableLookup?.zipCode;
                });
            }

            // Check if advanced filters were applied
            if (scoreHistoryTableLookup?.scoreFrom && scoreHistoryTableLookup?.scoreTo) {
                if (scoreHistoryTableLookup?.scoreFrom <= scoreHistoryTableLookup?.scoreTo) {
                    this.scoreHistory = this.scoreHistory.filter((obj) => {
                        return obj.Score >= scoreHistoryTableLookup?.scoreFrom && obj.Score <= scoreHistoryTableLookup?.scoreTo;
                    });
                }
            }

            this.paginate.totalItems = +this.scoreHistory.length || 0;
            this.isLoadingData = false;
            return;
        }

        this.deliveryDefenseService.getScoreHistory(scoreHistoryTableLookup)
            .pipe(
                map((items: IScoreHistory[]) => {
                    items.forEach((item) => {
                        item.CarrierCode = +(item.CarrierCode || '0');
                    });
                    return items;
                }),
            )
            .subscribe(
                (response: IScoreHistory[]) => {
                    if (response?.length > 0) {
                        this.extendScoreHistory(response);
                    }
                    this.isLoadingData = false;
                },
            )
    }

    private getScoreHistoryByFilters(scoreHistoryTableLookup: IScoreHistoryTableLookup): void {
        this.getScoreHistory(scoreHistoryTableLookup);
        this.paginate.currentPage = 1;
        this.pageIndex = 1;
    }

    private extendScoreHistory(response: IScoreHistory[]): void {
        // Extends array to include original fields (in scoreHistory interface) along with our own property (ExpiresInDate)
        let extendedScoreHistory: IScoreHistory[] = response.map(item => ({
            ...item,
            ExpiresInDate: this.getExpiresInDate(item.SearchDate),
        }));

        if (this.isAddressUpload) {
            this.scoreHistory = this.addressUploadsData;
        }
        else {
            // Apply any filters to updated score history data
            this.scoreHistory = this.filterScoreHistory(extendedScoreHistory);        
        }
        this.paginate.totalItems = +this.scoreHistory.length || 0;
    }
    
    private filterScoreHistory(data: IScoreHistory[]): IScoreHistory[] {
        // Filter data by IsLookUpTool = 1 or 0 based on page
        data = data.filter((obj: IScoreHistory) => {
            return obj.IsLookUpTool === ((this.componentName == "Score History") ? 0 : 1);
        });

        // DD-424: If more than one record exists for same address, show IsHoldAtLocation = true, if any records are true
        // Display only one address if Charge = 1 (with DDSearchStatus != 0), in case of duplicates
        const chargeRecords: IScoreHistory[] = data.filter((obj: IScoreHistory) => {
            return obj.Charge === 1 && obj.DDSearchStatus != 0;
        });
                
        // Using the charge records, iterate over original data to find duplicates
        const filteredData: IScoreHistory[] = [];
        for (const record of chargeRecords) {
            const duplicateRecords = data.filter((obj: IScoreHistory) => {
                return (
                    obj.StreetAddress === record.StreetAddress
                    && obj.ApartmentSuite === record.ApartmentSuite
                    && obj.City === record.City
                    && obj.State === record.State
                    && obj.ZipCode === record.ZipCode
                )
            });

            // Duplicate records exist
            if (duplicateRecords.length > 0) {
                // Check if at least one of the duplicate records has IsHoldAtLocation = true
                record.IsHoldAtLocation = duplicateRecords.some(obj => obj.IsHoldAtLocation == true);
            }
            filteredData.push(record);
        }

        return filteredData;
    }

    public onSearchSubmit(): boolean {
        const searchInput = this.searchFormGroup.get('searchInput').value;
        const selectedCategory = this.categoryFormGroup.get('selectCategory').value;
        this.searchInputErrorMessage = undefined;

        if (!this.validateSearchFields(selectedCategory, searchInput)) {
            return false;
        }

        this.pageIndex = 1;
        this.pageSize = 1000;
        const scoreHistoryTableLookup: IScoreHistoryTableLookup = {
            pageNumber: this.pageIndex,
            pageSize: this.pageSize,
            scoreFrom: this.minScoreRange,
            scoreTo: this.maxScoreRange,
            street: ((selectedCategory == "Street" && searchInput?.trim() !== "") ? searchInput : null),
            city: ((selectedCategory == "City" && searchInput?.trim() !== "")? searchInput : null),
            state: ((selectedCategory == "State" && searchInput?.trim() !== "") ? searchInput : null),
            zipCode: ((selectedCategory == "ZipCode" && searchInput?.trim() !== "") ? searchInput : null)
        }
        this.getScoreHistoryByFilters(scoreHistoryTableLookup);
        return true;
    }

    private validateSearchFields(category: string, input: string): boolean {
        if (category === 'State') {
            if (input.length != 2 || (/^[a-zA-Z]+$/.test(input) == false)) {
                this.searchInputErrorMessage = 'Please provide valid 2 character state code.';
                return false;
            }
        }
        if (category === 'ZipCode') {
            if (input.length != 5 || (/^\d+$/.test(input) == false)) {
                this.searchInputErrorMessage = 'Please provide 5 digit zipcode.';
                return false;
            }
        }
        return true;
    }

    public getExpiresInDate(searchDate: string): number {
        const currentDate = dayjs();
        const parsedSearchDate = dayjs(searchDate, { format: 'YYYY-MM-DD HH:mm:ss' });
        const daysPassed = currentDate.diff(parsedSearchDate, 'day');
        return 30 - daysPassed;
    }

    public paginationChange(paginationDetails) {
        this.pageIndex = paginationDetails.pageIndex;
        this.pageSize = paginationDetails.pageSize;

        this.paginate.currentPage = this.pageIndex;
        this.paginate.itemsPerPage = this.pageSize;
    }

    private sortByDesc(data, property?): IScoreHistory[] {
        return data.slice().sort((a, b) => this.compareValues(b[property], a[property]));
    }

    private sortByAsc(data, property?): IScoreHistory[] {
        return data.slice().sort((a, b) => this.compareValues(a[property], b[property]));
    }

    private compareValues(valueA, valueB): number {
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueB.localeCompare(valueA);
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueB - valueA;
        } else {
            return String(valueB).localeCompare(String(valueA));
        }
    }

    public removeAdvancedFilter(): void {
        this.minScoreRange = 100;
        this.maxScoreRange = 1000;
        this.isFilterApplied = false;
        this.refreshTable();
    }
    
    private refreshTable(): void {
        this.pageIndex = 1;
        this.pageSize = 1000;
        this.minScoreRange = 100;
        this.maxScoreRange = 1000;

        const scoreHistoryTableLookup: IScoreHistoryTableLookup = {
            pageNumber: this.pageIndex,
            pageSize: this.pageSize,
            scoreFrom: this.minScoreRange,
            scoreTo: this.maxScoreRange
        };
        this.getScoreHistory(scoreHistoryTableLookup);
    }

    public dynamicallyUpdateMaxLength(event: any): void {
        const maxLengthMap = {
            'Street': 35,
            'City': 35,
            'State': 2,
            'ZipCode': 5,
        };
        this.searchInputMaxLength = maxLengthMap[event?.value] || 35;
    }

    public applySort(property?): void {
        this.sortedProperty = property;
        const header = this.tableHeaders.find(header => header.sortKey === property);
        if (header) {
            header.clickCounter += 1;
            this.hideOrShowColumns(property, "hide");

            if (header.clickCounter == 1) {
                this.scoreHistory = this.sortByAsc(this.scoreHistory, property);
                this.isAscendingSort = false;
                this.updateHeaderIcon('desc_sort');
            }
            if (header.clickCounter == 2) {
                this.scoreHistory = this.sortByDesc(this.scoreHistory, property);
                this.isAscendingSort = true;
                this.updateHeaderIcon('asc_sort');
            }
            if (header.clickCounter == 3) {
                this.hideOrShowColumns(null, "show");
                header.clickCounter = 0;
                this.scoreHistory = this.sortByAsc(this.scoreHistory, 'ExpiresInDate');
                this.isAscendingSort = false;
                this.updateHeaderIcon('filter_list');
            }
        }
    }

    public getIconClass(): string {
        return 'filter-list-icon';
    }

    private getLabelFromSortKey(sortKey: string): string {
        switch (sortKey) {
            case 'StreetAddress':
                return 'Street';
            case 'ZipCode':
                return 'Zip Code';
            case 'ExpiresInDate':
                return 'Expires In';
            default:
                return sortKey;
        }
    }
    
    private updateHeaderIcon(icon_name: string): void {
        this.tableHeaders.forEach(header => {
            if (header.isLastColumn == false) {
                header.icon = `../../../assets/icons/${ icon_name }.svg`;
            }
        });
    }
    
    private hideOrShowColumns(property, option: string): void {
        this.tableHeaders.forEach(header => { 
            if (header.isLastColumn == false) {
                if (header.isLastColumn == false) {
                    if (option == "hide") {
                        header.isHidden = header.sortKey !== property;
                    }
                    else if (option == "show") {
                        header.isHidden = false;
                    }
                }
            }
        });
    }

    public openPickupLocationDialog(scoreHistoryItem: IScoreHistory) {
        const isValidCarrierCode = this.utilityService.isValueOfEnum(Carriers, scoreHistoryItem.CarrierCode);
        
        if (!scoreHistoryItem.CarrierCode || !isValidCarrierCode || scoreHistoryItem.CarrierCode === 0) {
            this.notificationService.notify(
                `The carrier code of ${ scoreHistoryItem.CarrierCode } is invalid.`,
                'Invalid Carrier Code',
                NotificationType.ERROR,
            );

            return;
        }

        const data: IPickupLocationRequest = {
            streetAddress: scoreHistoryItem.StreetAddress,
            apartmentSuite: scoreHistoryItem.ApartmentSuite,
            city: scoreHistoryItem.City,
            state: scoreHistoryItem.State,
            zip: scoreHistoryItem.ZipCode,
            miles: 10,
            noOfRecords: 10,
        };
        const isReadonly = true;
        const shipFrom: Partial<Contact> = null;
        const shipTo: Partial<Contact> = null;

        this.pickupLocationsDialogService.open(
            scoreHistoryItem.CarrierCode,
            data,
            isReadonly,
            shipFrom,
            shipTo,
            scoreHistoryItem.HoldAtLocationID,
        );
    }

}
