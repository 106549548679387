import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class PaymentHistoryService {

  constructor(private httpClientService:HttpClientService,
    private apiEndpointService: ApiEndpointService) {    
  }

  /**
   * Gets payment history.
   *
   * @param {string} pageIndex
   * @param {string} pageSize
   * @returns {Observable<any>}
   */
  public getPaymentHistory(pageIndex: string, pageSize: string) {
    const params = {};
    if (pageIndex) {
      params['page'] = pageIndex;
    }

    if (pageSize) {
      params['per_page'] = pageSize;
    }

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getPaymentPage'))
      .query(params)
      .toString();
    return this.httpClientService.get<any>(endpoint);      
  }

  /**
   * Gets Payment Receipt
   *
   * @param {string} paymentId
   * @returns {Observable<any>}
   */
  public getPaymentReceipt(paymentId: string) {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getPaymentReceipt'))
      .param('paymentId', paymentId)
      .toString();

    return this.httpClientService.get<any>(endpoint); 
  }

  /**
   * Gets Payment Invoice
   *
   * @param {string} invoiceId
   * @returns {Observable<any>}
   */
  public getInvoice(invoiceId: string) {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getInvoice'))
      .param('invoiceId', invoiceId)
      .toString();

    return this.httpClientService.get<any>(endpoint); 
  }
}
