<!-- Select Category Form Field Input -->
<div class="score-history-layout">
    <div class="score-history-content" [class.recent-upload-popup]="componentName == 'Recent Upload'">
        <div class="dd-score-history-table">
            <div class="table-actions-wrapper">
                <h2>{{tableName}}</h2>
                <span *ngIf="(componentName === 'Address Confidence Tool') && !isAddressUpload" class="table-description">
                    Access DeliveryDefense<sup class="trademark">TM</sup> Address Confidence scores for the addresses you've searched, along with UPS Access Point<sup class="trademark">®</sup> locations closest to the entered address.
                </span>

                <div class="score-history-table-actions">
                    <form [formGroup]="categoryFormGroup" class="category-form left-action">
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label>Select Category</mat-label>
                            <mat-select formControlName="selectCategory" (selectionChange)="dynamicallyUpdateMaxLength($event)">
                                <mat-option *ngFor="let category of searchCategory" [value]="category">
                                    {{category}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                    <!-- Search Table Form Field Input -->
                    <form (ngSubmit)="onSearchSubmit()" [formGroup]="searchFormGroup" class="search-table-form left-action">
                        <mat-form-field subscriptSizing="dynamic">
                            <input placeholder="Search Table" formControlName="searchInput" matInput type="text" [maxlength]="searchInputMaxLength">
                            <mat-icon class="search-icon" matPrefix>search</mat-icon>
                        </mat-form-field>
                    </form>
                    <button (click)="onSearchSubmit()" class="search-button" mat-raised-button>
                        <span class="button-label"> Search </span>
                    </button>
                    
                    <!-- Advanced Filter Button -->
                    <div class="right-actions">
                        <div class="advanced-filter">
                            <div class="advanced-filter-wrapper">
                                <button (click)="toggleAdvancedFilterPanel(false)" [class.active]="showAdvancedFilterPanel" class="advanced-filter-btn" id="advanced_filter_button" mat-flat-button>
                                    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
                                        <mask height="24" id="mask0_3342_9402" maskUnits="userSpaceOnUse" style="mask-type:alpha" width="24" x="0" y="0">
                                            <rect fill="#D9D9D9" height="24" width="24" />
                                        </mask>
                                        <g mask="url(#mask0_3342_9402)" transform="translate(8, 8)">
                                            <path d="M11.0359 20C10.7147 20 10.4457 19.8898 10.229 19.6694C10.0119 19.4494 9.90341 19.1764 9.90341 18.8503V13.0275L4.24 5.68807C3.96701 5.34557 3.92691 4.97859 4.1197 4.58716C4.31249 4.19572 4.62577 4 5.05955 4H18.9405C19.3742 4 19.6875 4.19572 19.8803 4.58716C20.0731 4.97859 20.033 5.34557 19.76 5.68807L14.0966 13.0275V18.8991C14.0966 19.2091 13.9921 19.47 13.7831 19.6818C13.5745 19.8939 13.3175 20 13.0121 20H11.0359ZM12 12.7093L17.3256 5.85952H6.67436L12 12.7093Z" fill="#121212" />
                                        </g>
                                    </svg>
                                    <span>Advanced Filter</span>
                                </button>
                            </div>
                            <div [class.hide]="!showAdvancedFilterPanel" class="advanced-filter-panel">
                                <!-- Call Advanced Filter Main Content Component -->
                                <upsc-dd-score-history-advanced-filter [componentName]="componentName"></upsc-dd-score-history-advanced-filter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span class="search-input-error-message" *ngIf="searchInputErrorMessage">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <g clip-path="url(#clip0_7802_9161)">
                        <path d="M12.753 10.757C13.1354 11.4151 12.6554 12.2376 11.8916 12.2376H1.94877C1.18347 12.2376 0.705641 11.4138 1.08733 10.757L6.0588 2.20069C6.44141 1.54237 7.39974 1.54356 7.78167 2.20069L12.753 10.757ZM6.92023 8.98803C6.39386 8.98803 5.96715 9.41161 5.96715 9.9341C5.96715 10.4566 6.39386 10.8802 6.92023 10.8802C7.4466 10.8802 7.87331 10.4566 7.87331 9.9341C7.87331 9.41161 7.4466 8.98803 6.92023 8.98803ZM6.01537 5.5874L6.16906 8.38448C6.17625 8.51537 6.28528 8.61783 6.41732 8.61783H7.42315C7.55519 8.61783 7.66422 8.51537 7.6714 8.38448L7.8251 5.5874C7.83287 5.44602 7.71947 5.32715 7.57684 5.32715H6.2636C6.12097 5.32715 6.0076 5.44602 6.01537 5.5874Z" fill="#DF2901"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_7802_9161">
                            <rect width="11.9342" height="11.9342" fill="white" transform="translate(0.953125 0.855164)"/>
                        </clipPath>
                    </defs>
                </svg>
                {{searchInputErrorMessage}}
            </span>
            
            <div class="adv-filter-chips" *ngIf="isFilterApplied">
                <img alt="bar_chart" class="bar-chart" src="../../../assets/icons/bar_chart.svg">
                <span> Score Range: {{this.minScoreRange}}-{{this.maxScoreRange}}</span>
                <img alt="advanced_filter_close_button" class="x-close-btn" (click)="removeAdvancedFilter()" src="../../../assets/icons/x.svg">
            </div>

            <!-- Start of Score History Table -->
            <div class="table-container">
                <div class="table-wrapper" *ngIf="!isLoadingData">
                    <table class="ups-table">
                        <thead>
                            <tr class="ups-table-rows">
                                <th *ngFor="let header of tableHeaders; let last = last">
                                    <div [ngClass]="{'header-wrapper': last}">
                                        <img *ngIf="last && lastColumnName === 'Access Point Location'" class="ups-logo" src="../../../../assets/icons/logo-ups.svg">
                                            {{ header.label }}
                                        <img [class]="getIconClass()" [src]="header.icon" *ngIf="!header.isHidden" (click)="header.applySort()">    
                                    </div>
                                    <div class="help-container" *ngIf="last">
                                        <img alt="help" [ngClass]="{'margin-top-2px': componentName === 'Score History'}" class="help-icon" src="../../../../assets/icons/help_icon3.svg">
                                        <div [style.margin-left.px]="componentName === 'Score History' ? 0 : 50" class="help-popup-message-bubble">
                                            <p> Click See Locations to view Hold for Pickup options/address information. </p>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody #tb *ngIf="scoreHistory?.length > 0">
                            <tr *ngFor="let item of scoreHistory | paginate:paginate">
                                <td>
                                    <ng-container *ngIf="item?.ApartmentSuite?.trim()?.length > 0; else showStreetAddressOnly">
                                        {{item?.StreetAddress}}, {{item?.ApartmentSuite}}
                                    </ng-container>
                                    <ng-template #showStreetAddressOnly>
                                        {{item?.StreetAddress}}
                                    </ng-template>
                                </td>
                                <td> {{item?.City}} </td>
                                <td> {{item?.State}} </td>
                                <td> {{item?.ZipCode}} </td>
                                
                                <td>
                                    <div class="score-backgrounds" [ngClass]="{
                                        'green-background': item?.Score >= 800,
                                        'yellow-background': item?.Score >= 500 && item?.Score <= 799, 
                                        'red-background': item?.Score >= 1 && item?.Score <= 499,
                                        'gray-background': item?.Score == 0}"> 
                                        {{ item?.Score }}
                                    </div>
                                </td>

                                <td> {{getExpiresInDate(item?.SearchDate)}} days </td>
                                
                                <!-- For address confidence tool page, show pick up location link for each record -->
                                <td class="pick-up-locations" *ngIf="item?.IsLookUpTool === 1 || item?.IsLookUpTool === 2; else showScoreHistoryPickupLocation">
                                    <a (click)="openPickupLocationDialog(item)">See Locations</a> 
                                </td>
                                
                                <!-- For score history page, only show pick up location if IsHoldAtLocation = 1 (true) -->
                                <ng-template #showScoreHistoryPickupLocation>
                                    <td class="pick-up-locations" *ngIf="item?.IsHoldAtLocation === true"> 
                                        <a (click)="openPickupLocationDialog(item)">See Locations</a> 
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>

                        <tbody #tb *ngIf="scoreHistory?.length === 0">
                            <tr>
                                <td class="text-center" colspan="10">
                                    <span style="font-size: 18px;"> {{ 'NoRecordsFound' | translate }} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="!isLoadingData && scoreHistory?.length > 0" class="pagination-wrapper">
                    <upsc-custom-paginator (data)="paginationChange($event)" [length]="paginate.totalItems" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSizeLabel]="'Addresses per page'"> </upsc-custom-paginator>
                </div>
            </div>

            <upsc-element-blocker *ngIf="isLoadingData"></upsc-element-blocker>
        </div>
    </div>
</div>
