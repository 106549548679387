<div class="recent-uploads-layout">
    <div class="recent-uploads-content">
        <div class="grid-actions-wrapper">
            <h2>Recent Uploads</h2>

            <div class="date-picker-container">
                <div class="date-picker-wrapper">
                    <mat-form-field>
                        <mat-label>Start Date</mat-label>
                        <input #startDateInput matInput readonly [matDatepicker]="startDatePicker" [formGroup]="startDateFormControl" [min]="minDate" [max]="maxDate" (dateChange)="updateMinEndDate($event)">
                        <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>End Date</mat-label>
                        <input #endDateInput matInput readonly [matDatepicker]="endDatePicker" [formGroup]="endDateFormControl" [min]="minEndDate" [max]="maxDate">
                        <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="date-picker-actions" *ngIf="!isLoadingData">
                    <a class="clear-link-button" (click)="resetDateFilters()">Clear</a>

                    <button mat-stroked-button
                            color="accent"
                            type="submit"
                            class="done-link-button"
                            (click)="applyDateFilters()">
                        <span>Done</span>
                        <span class="material-symbols-rounded" matSuffix>chevron_right</span>
                    </button>

                </div>
            </div>
        </div>

        <!-- <upsc-recent-upload-live-status-check
            [broadcastEventName]="'BatchAddressLookupStarted'"
            [title]="'Batch Upload Started'">
        </upsc-recent-upload-live-status-check> -->

        <upsc-recent-upload-live-status-check
            [broadcastEventName]="'BatchAddressLookupCompleted'"
            [title]="'Batch Upload Completed'"
            (messageReceived)="onMessageReceived($event)">
        </upsc-recent-upload-live-status-check>

        <div class="recent-uploads-grid-container">
            <div class="grid-wrapper" *ngIf="!isLoadingData">
                <table class="ups-table">
                    <thead>
                        <tr class="ups-table-rows">
                            <th *ngFor="let header of tableHeaders">
                                {{header.label}}
                            </th>
                        </tr>
                    </thead>
                    <tbody #tb *ngIf="recentUploadsData?.length > 0">
                        <tr *ngFor="let item of recentUploadsData | paginate:paginate; index as i">
                            <td class="scored-address-review-file">
                                <span (click)="openRecentUpload(i)">{{ item?.ScoredAddressReviewFileName }}</span>
                            </td>
                            <td class="download-link">
                                <span class="material-symbols-outlined download-icon" *ngIf="item?.IsDownload; else isNotDownload" (click)="downloadFile(i)">download</span>
                                <ng-template #isNotDownload>-</ng-template>
                            </td>
                            <td>{{ item?.ImportedOnDate | date }}</td>
                            <td>{{ item?.ExpiresInDate }} days</td>
                            <td class="upload-status-wrapper">
                                <ng-container *ngIf="!refreshingUploadStatus">
                                    <div [ngClass]="{'UPLOADED' : 'green-status', 'PROCESSING' : 'blue-status', 'DRAFT' : 'yellow-status'}[item?.ImportStatus]" class="chip">
                                        {{item?.ImportStatus}}
                                    </div>
                                    <div class="refresh-button" *ngIf="showRefreshButtons">
                                        <span class="material-symbols-outlined refresh-icon" *ngIf="item?.ImportStatus == 'PROCESSING'" (click)="onClickRefreshRecentUpload(i)">refresh</span>
                                    </div>
                                </ng-container>

                                <div class="refresh-spinner-container" *ngIf="refreshingUploadStatus">
                                    <mat-spinner mode="indeterminate" [diameter]="30"></mat-spinner>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody #tb *ngIf="recentUploadsData?.length === 0">
                        <tr>
                            <td class="text-center" colspan="5">
                                <span style="font-size: 18px;"> {{ 'NoRecordsFound' | translate }} </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="!isLoadingData && recentUploadsData?.length > 0" class="pagination-wrapper">
                <upsc-custom-paginator (data)="paginationChange($event)" [length]="paginate.totalItems" [pageIndex]="pageIndex" [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSizeLabel]="'Uploads per page'" [customDDLPageSize]="paginate.itemsPerPage"> </upsc-custom-paginator>
            </div>

            <upsc-element-blocker *ngIf="isLoadingData"></upsc-element-blocker>
        </div>
    </div>
</div>