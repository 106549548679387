<div class="payment-receipt-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'PaymentReceipt'|translate}}</h2>
        <button type="button" mat-raised-button class="icon-button close-button dont-print" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <div class="deprecated-key-values">
        <h4 class="key-values-title">{{'OrderInformation'|translate}}</h4>
        <div class="key-value">
            <div class="key">{{'CustomerId'|translate }}</div>
            <div class="value">{{ customer?.CustomerId }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'InvoiceNo'|translate}}</div>
            <div class="value">
                <a class="hidden-print"
                            (click)="downloadInvoice(paymentReceipt?.PaymentDetails[0].InvoiceID)"
                            href="javascript:;">{{ paymentReceipt?.PaymentDetails[0]?.InvoiceID }}</a>
                <span class="visible-print"> {{ paymentReceipt?.PaymentDetails[0]?.InvoiceID }}</span>
            </div>
        </div>
        <div class="key-value">
            <div class="key">{{'Description'|translate}}</div>
            <div class="value" *ngIf="paymentReceipt?.Payment?.PaymentMethod?.Value == 1" >{{ paymentReceipt?.PaymentDetails[0]?.Description }}</div>
            <div class="value" *ngIf="paymentReceipt?.Payment?.PaymentMethod?.Value != 1" >{{ 'PaymentSubmitted' | translate }}</div>
        </div>
        <!-- [MV3-3782] remove total field
             <div class="key-value">
            <div class="key">{{'Total'|translate}}</div>
            <div class="value">
                ${{ (paymentReceipt?.PaymentDetails[0].RemainingBalance +
                paymentReceipt?.PaymentDetails[0].PaymentAmount) | number : '1.2-2' }}
            </div>
        </div> -->
        <div class="key-value">
            <div class="key">{{'RemainingBalance'|translate}}</div>
            <div class="value">${{ paymentReceipt?.PaymentDetails[0]?.RemainingBalance | number : '1.2-2' }}</div>
        </div>
    </div>

    <div class="deprecated-key-values">
        <h4 class="key-values-title">{{'PaymentInformation'|translate}}</h4>
        <div class="key-value">
            <div class="key">{{'Merchant'|translate}}</div>
            <div class="value">{{ paymentReceipt?.Payment?.Merchant }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'PaymentAmount'|translate}}</div>
            <div class="value">${{ paymentReceipt?.PaymentDetails[0]?.PaymentAmount | number : '1.2-2' }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'CardNo'|translate}}</div>
            <div class="value">{{ paymentReceipt?.CreditCard?.Last4 ? paymentReceipt?.CreditCard?.Last4 : 'N/A' }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'CardType'|translate}}</div>
            <div class="value">{{ paymentReceipt?.CreditCard?.CreditCardType ? paymentReceipt?.CreditCard?.CreditCardType : 'N/A' }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'AuthorizationNo'|translate}}</div>
            <div class="value">{{ paymentReceipt?.Payment?.AuthorizationCode ? paymentReceipt?.Payment?.AuthorizationCode : 'N/A' }}</div>
        </div>
        <div class="key-value">
            <div class="key">{{'TransactionDate'|translate}}</div>
            <div class="value">
              <span *ngIf="localeId==='en-US'; then USDate else otherDate"></span>
                <ng-template #USDate>{{ paymentReceipt?.Payment?.TransactionDate | date : 'MM/dd/yyyy' }}</ng-template>
                <ng-template #otherDate>{{ paymentReceipt?.Payment?.TransactionDate | date : 'yyyy/MM/dd' }}</ng-template>                                               
                 {{ paymentReceipt?.Payment?.TransactionDate | date : 'hh:mm:ss' }}                
            </div>
        </div>
        <div class="key-value">
            <div class="key">{{'PaymentMethod'|translate}}</div>
            <div class="value">{{ paymentReceipt?.Payment?.PaymentMethod.Text }}</div>
        </div>
    </div>

    <button type="button" mat-raised-button class="print-button dont-print"  (click)="printReceipt()">{{'Print'|translate}}</button>
</ng-template>
