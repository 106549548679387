import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { EmailShippingLabelComponent } from './email-shipping-label/email-shipping-label.component';
import { EmailShippingLabel }from './models/email-shipping-label.model';
import { EmailShippingLabelService }from './services/email-shipping-label.service';
import { SpinnerService } from '../core/services/spinner/spinner.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
    ],
    declarations: [
        EmailShippingLabelComponent,
    ],
    providers: [
        EmailShippingLabel,
        EmailShippingLabelService,
        SpinnerService,
    ],
    exports: [EmailShippingLabelComponent]
})
export class DropShipmentModule { }
