import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '../../app.state';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import moment from 'moment';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { S3Service } from '../../shared/services/s3/s3.service';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { BlobService } from '../../shared/services/file/blob.service';
import * as FileSaver from 'file-saver';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { UserService } from '../../shared/services/user/user.service';
import { SharedService } from 'app/core/services/shared.service';
import { User } from 'app/shared/services/user/models/user.model';
import { Customer } from 'app/shared/models/customer/customer.model';
import { TranslateService } from '@ngx-translate/core';
import { DdFeedbackComponent } from '../dd-manage-subscription/dd-feedback/dd-feedback.component';
import { CancelSubscriptionPopupComponent } from '../dd-manage-subscription/cancel-subscription-popup/cancel-subscription-popup.component';
import { DeliveryDefenseService } from '../services/delivery-defense.service';
import { IDDSubscription } from '../../ship/models/dd-subscription.interface';
@Component({
  selector: 'upsc-delivery-defense-sign-up',
  templateUrl: './delivery-defense-sign-up.component.html',
  styleUrls: ['./delivery-defense-sign-up.component.scss']
})
export class DeliveryDefenseSignUpComponent implements OnInit {
  public user: User;
  public customer: Customer;
  public showTipsPanel = false;
  public showSubscriptionPlans = false;
  public scoringInfoPanel = false;
  public isDDEnabled = false;
  public showTipsSection = false;
  public cancelSubscriptionLink = false;
  //////////////
  public isExpanded = false;
  public noteVal = false;
  public volumeNoteVal = false;
  public isComplimentary = false;
  public fileName: string = '';

  public shipmentCount: string = '';
  public cardScore: string = '';
  public programNumber: string = '';
  public monthlyTierType = 0;

  public cardInfo = false;
  public is19_99_signup = false;
  currentDate: moment.Moment = moment();
  private downloadFileSubscription: Subscription;
  public cardHeader: string = '';
  public cardSubHeader: string = '';

  date = new Date();
  public nextRenewl = moment(this.currentDate, "DD-MM-YYYY").add(30, 'days').format('MMMM DD');
  public isSubscriptionCancelled: boolean = false;
  now = new Date();
  prevMonthLastDate = new Date(this.now.getFullYear(), this.now.getMonth(), 0).toLocaleDateString();
  prevMonthFirstDate = new Date(this.now.getFullYear() - (this.now.getMonth() > 0 ? 0 : 1), (this.now.getMonth() - 1 + 12) % 12, 1).toLocaleDateString();

  private customerSubscription: IDDSubscription;

  public constructor(private dialog: MatDialog, private router: Router,
                     private notificationService: NotificationService,
                     private blobService: BlobService,
                     private errorHandlerService: ErrorHandlerService,
                     private sharedService: SharedService,
                     private s3Service: S3Service,
                     private userService: UserService,
                     private translateService: TranslateService,
                     private readonly deliveryDefenseService: DeliveryDefenseService,
                     private readonly appState: AppState,
  ) {
    this.user = this.appState.user$();
    this.customer = this.appState.customer$();
  }

  ngOnInit(): void {
    this.isExpanded = true;
    this.getStatusCount();
    this.getCurrentSubscription();
  }

  private getCurrentSubscription(): void {
    this.deliveryDefenseService.getCustomerSubscription()
        .subscribe(
            (subscription) => {
              this.customerSubscription = subscription;
              
              if (this.customerSubscription?.CurrentSubType == 5 && this.customerSubscription?.CancelledOn && this.customerSubscription?.CurrentSubEndDate) {
                this.nextRenewl = this.customerSubscription?.CancelledOn;
                this.isSubscriptionCancelled = true;
              }
            },
        );
  }

  public getStatusCount() {
    this.monthlyTierType = this.customer?.MonthlyTierType || 1;
    this.isDDEnabled = this.customer?.DDEnabled;
    const hasSubscription = this.customerSubscription?.CurrentSubType !== null;

    switch (this.monthlyTierType) {
      case 1: {
        if (this.isDDEnabled === true || hasSubscription) {
          this.cardHeader = null;
          this.cardSubHeader = '50 Complimentary Scores per Month';
          this.cardScore = 'Complimentary';
          this.isComplimentary = true;
          this.is19_99_signup = true;
          this.cancelSubscriptionLink = true;
          break;
        }

        if (this.isDDEnabled === false && !hasSubscription) {
          this.cardHeader = '50 Complimentary Scores Now';
          this.cardSubHeader = 'then Complimentary for 50 scores';
          this.cardScore = 'Complimentary';
          this.noteVal = true;
          this.isComplimentary = false;
          break;
        }

        break;
      }
      case 2: {
        this.cardHeader = 'High Volume Program 1';
        this.cardSubHeader = '200 scores included per month';
        this.cardScore = '200 Scores';
        this.shipmentCount = '100';
        this.programNumber = '1';
        this.volumeNoteVal = true;
        this.isComplimentary = true;
        break;
      }
      case 3: {
        this.cardHeader = 'High Volume Program 2';
        this.cardSubHeader = '300 scores included per month';
        this.cardScore = '300 Scores';
        this.shipmentCount = '200';
        this.programNumber = '2';
        this.volumeNoteVal = true;
        this.isComplimentary = true;
        break;
      }
      case 4: {
        this.cardHeader = 'High Volume Program 3';
        this.cardSubHeader = '1500 scores included per month';
        this.cardScore = '1500 Scores';
        this.shipmentCount = '400';
        this.programNumber = '3';
        this.volumeNoteVal = true;
        this.isComplimentary = true;
        break;
      }

      default: {
        if (this.isDDEnabled === true || hasSubscription) {
          this.cardHeader = null;
          this.cardSubHeader = '50 Complimentary Scores per Month';
          this.cardScore = 'Complimentary';
          this.isComplimentary = true;
          this.is19_99_signup = true;
          this.cancelSubscriptionLink = true;
          break;
        }
        if (this.isDDEnabled === false && !hasSubscription) {
          this.cardHeader = '50 Complimentary Scores Now';
          this.cardSubHeader = 'then Complimentary for 50 scores';
          this.cardScore = 'Complimentary';
          this.noteVal = true;
          this.isComplimentary = false;
          break;
        }

        break;
      }
    }
  }

  public tipsPanel() {
    this.showTipsPanel = true;
  }
  public tipsPanelClose() {
    this.showTipsPanel = false;
  }
  public feedback() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,

      data: {
        editMode: false,

      },
    };
    const dialogRef: MatDialogRef<DdFeedbackComponent> = this.dialog.open(DdFeedbackComponent, dialogConfig);
  }

  public cancelSubscription() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '966px',
      data: {
        editMode: false,

      },
    };
    const dialogRef: MatDialogRef<CancelSubscriptionPopupComponent> = this.dialog.open(CancelSubscriptionPopupComponent, dialogConfig);

    // DD-485: Disable cancel subscription link on cancelling DD subscription
    dialogRef.afterClosed().subscribe(
      (result) => {        
        if (result?.isCancelled) {
          this.deliveryDefenseService.getSubscriberDetails().subscribe(
            (response) => {
              this.nextRenewl = new Date().toISOString().split('T')[0];
              this.customerSubscription.CurrentSubEndDate = response?.CurrentSubEndDate;
              this.isSubscriptionCancelled = true;
            }
          )
        }
      },
    );
  }
  public downloadPdf(event) {
    this.fileName = 'DeliveryDefense_Scoring_Info.pdf/';
    this.downloadFileSubscription = this.s3Service.getPDFFile(this.fileName)
      .subscribe(
        base64 => this.handleGetFileFromS3Success(base64),
        err => this.handleGetFileFromS3Failure(err),
      );

  }

  private handleGetFileFromS3Success(base64: string) {
    const blob = this.blobService.base64PdfToBlob(base64);
    FileSaver.saveAs(blob, this.fileName.replace("/", ""));
  }

  private handleGetFileFromS3Failure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Downloading File',
      NotificationType.ERROR);
  }
  signUpNow() {

    const dialogConfig: MatDialogConfig = {
      disableClose: true,

      data: {
        editMode: false,
        date: this.nextRenewl,
        cardHeader: this.cardHeader,
        cardScore: this.cardScore,
        monthlyTierType: this.monthlyTierType,
        programNumber: this.programNumber,
        shipmentCount: this.shipmentCount,
        subscription: this.customerSubscription,
      },
    };

    this.openConfirmationPopup(dialogConfig);


  }
  openConfirmationPopup(dialogConfig: MatDialogConfig) {
    let dialogRef: MatDialogRef<ConfirmPopupComponent>;
    dialogRef = this.dialog.open(ConfirmPopupComponent, dialogConfig);
  }
  shipIconClick() {
    this.router.navigate(['/ship/ups']);
  }
  fedexIconClick() {
    this.router.navigate(['/ship/fedex']);
  }
  subscriptionPlans() {
    this.showSubscriptionPlans = !this.showSubscriptionPlans;
    let elem: HTMLElement = document.getElementById('header-dropdown');
    let iconElem: HTMLElement = document.getElementById('expand_more');
    let sectionHeader: HTMLElement = document.getElementById('ddSection');
    if (this.showSubscriptionPlans) {
      elem.setAttribute("style", "border-bottom: none;");
      iconElem.setAttribute("style", "transform: rotate(180deg)");
      sectionHeader.setAttribute("style", "background: var(--ups-brand-colors-ups-grey-5, #F2F1EF");
    }
    else {
      elem.setAttribute("style", "border-bottom: 1px solid  #8C857E");
      iconElem.setAttribute("style", "transform: rotate(0deg)");
      sectionHeader.setAttribute("style", "background: none");
    }
  }

  showScoringInfo() {
    let iconElem: HTMLElement = document.getElementById('scoringInfoHeader');
    this.scoringInfoPanel = !this.scoringInfoPanel;
    if (this.scoringInfoPanel) {
      this.showTipsSection = true;
      iconElem.setAttribute("style", "font-weight: 700;line-height: 44.8px;text-decoration-line: underline");
      ;
    }
    else {
      this.showTipsSection = false;
      this.showTipsPanel = false;
      iconElem.setAttribute("style", "font-weight:4700;text-decoration-line: none");
    }
  }
}
