import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
//import { RatingBarComponentComponent } from './rating-bar-component/rating-bar-component.component';

@Component({
  selector: 'upsc-dd-feedback',
  templateUrl: './dd-feedback.component.html',
  styleUrls: ['./dd-feedback.component.scss']
})
export class DdFeedbackComponent implements OnInit {
  public showThankingmsg = false;
  constructor(private router: Router, public dialogRef: MatDialogRef<DdFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any , private deliveryDefenseService: DeliveryDefenseService) { }

  ngOnInit(): void {


  }
  // onSelectedEmojiImagePath(emojiImagePath: string) {
  //   const payload = this.ratingBarComponentComponent.createPayload();
  //   console.log("payload",payload)
  //   // this.httpClient.post('/api/rating', payload).subscribe((response) => {
  //   //   // Handle the response from the server.
  //   // });
  // }
  typeId: string;

  typeIdData(data: string) {
    this.typeId = data;
  }
  onsubmit(event) {
    event.preventDefault();
    // Get the textarea element by its ID
    const textarea = document.getElementById('descrpVal') as HTMLTextAreaElement;

    // Read the value of the textarea
    const textareaValue = textarea.value;
    const payload: { TypeID: string; Rating: string; Description: string; } = {
       TypeID: this.typeId,
       Rating: this.typeId,
      Description: textareaValue
    };
    this.deliveryDefenseService.feedbackDeliveryDefense(payload)
    .subscribe(
      result => this.showThankingmsg = true,
      err => console.log("errorrrrrr"),
    );
  }

}
