<div class="table-dialog ups-dialog">
    <div class="header">        
        <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div id="header-dropdown" class="header-dropdown">
        <!-- <div id="ddSection" class="ddSection">
            <h1>See All Monthly Subscription Plans</h1>

            <img id="expand_more" class="expand_more" (click)="subscriptionPlans()"
            src="../../../assets/icons/expand_more.svg" alt="expand_more">

        </div> -->
        <div *ngIf="showSubscriptionPlans" class="tablePanel">
            <table class="my-table">
                <thead class="my-table headers">
                    <tr>
                        <th></th>
                        <th class="sideHeader">Non Subscriber</th>
                        <th class="background1">Value Add Subscription</th>
                        <th class="background2">High Volume <br> Program 1</th>
                        <th class="background3">High Volume <br> Program 2</th>
                        <th class="background4">High Volume <br> Program 3</th>
                    </tr>
                </thead>
                <tbody class="my-table body">
                    <tr></tr>
                    <tr>
                        <td class="sideHeader">Monthly Subscription fee</td>
                        <td></td>
                        <td class="background1">Complimentary</td>
                        <td class="background2">Complimentary</td>
                        <td class="background3">Complimentary</td>
                        <td class="background4">Complimentary</td>

                    </tr>
                    <tr>
                        <td class="sideHeader">Eligibility</td>
                        <td></td>
                        <td class="background1">Ship less than 100 <br>
                            boxes per month</td>
                        <td class="background2">Ship 100 boxes<br>
                            per month</td>
                        <td class="background3">Ship 200 boxes<br>
                            per month</td>
                        <td class="background4">Ship 400 boxes<br>
                            per month</td>
                    </tr>
                    <tr>
                        <td class="sideHeader">Included Scores</td>
                        <td></td>
                        <td class="background1">50</td>
                        <td class="background2">200</td>
                        <td class="background3">300</td>
                        <td class="background4">1500</td>
                    </tr>
                    <tr>
                        <td class="sideHeader accessLabel">
                            <h1 class="accessUPS">UPS Access Point<span class="rated">®</span></h1>
                            <div class="accessPoint">Location</div>
                        </td>
                        <td class="dot">•</td>
                        <td class="dot background1">•</td>
                        <td class="dot background2">•</td>
                        <td class="dot background3">•</td>
                        <td class="dot background4">•</td>
                    </tr>
                    <tr>
                        <td class="sideHeader label">
                            Address Confidence Tool
                        </td>
                        <td></td>
                        <td class="dot background1">•</td>
                        <td class="dot background2">•</td>
                        <td class="dot background3">•</td>
                        <td class="dot background4">•</td>
                    </tr>
                    <tr>
                        <td class="sideHeader">Recently Searched Addresses*</td>
                        <td></td>
                        <td class="dot background1">•</td>
                        <td class="dot background2">•</td>
                        <td class="dot background3">•</td>
                        <td class="dot background4">•</td>
                    </tr>
                </tbody>
            </table>
            <h2>
                *Scores for duplicate addresses within 30 days of the original search will not be
                charged<br>
                For more information regarding plan eligibility, please email <a
                    href="mailto:amr@parcelpro.com">amr&#64;parcelpro.com</a>
            </h2>
        </div>

    </div>


</div>
