import { Injectable } from '@angular/core';
import { CreditCardTerms } from '../models/credit-card-terms.model';
import SafeUrlAssembler from 'safe-url-assembler';
// import { HttpService } from '../../core/services/http/http.service';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class AcceptCCTerms {
  Terms: CreditCardTerms;

  constructor(private httpClientService: HttpClientService,
    // private http: HttpService, 
    private apiEndpointService: ApiEndpointService) {
  }

  public getCreditCardTerms() {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('acceptCreditCardTerms'))
      .toString();

    return this.httpClientService.get<any>(endpoint);
  }

  public saveCreditCardTerms(CCTerms: CreditCardTerms) {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('acceptCreditCardTerms'))
      .toString();

    return this.httpClientService.post<any>(endpoint, CCTerms);
  }
}
