<head>
    <title> Score History </title>
</head>
<body>
    <div class="score-history-layout">
        <div class="score-history-content">
            <div class="header-panel">
                <div class="header"> Score History </div>
                <upsc-dd-remaining-credits [fullCredit]="ddFullCredit" [remainingCredit]="ddRemainingCredit" [subTypeId]="ddSubTypeID"></upsc-dd-remaining-credits>
            </div>
            <span class="page-description">Access DeliveryDefense<sup class="trademark">TM</sup> Address Confidence scores for the shipment labels you've generated, along with UPS Access Point<sup class="trademark">®</sup> location details for shipments where an alternate delivery location option was chosen.</span>
        </div>

        <upsc-dd-score-history-table [lastColumnName]="'Hold for Pickup'" [componentName]="'Score History'"> </upsc-dd-score-history-table>
    </div>
</body>

