import { Injectable } from '@angular/core';


@Injectable()
export class AddCCBounceService {

  public counter: number = 0;
  constructor() { }

  incrementCounter(){
    this.counter++;
  }

  resetCounter(){
    this.counter = 0;
  }
  
}
