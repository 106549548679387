import { Component, OnInit } from '@angular/core';
import { S3Service } from '../../shared/services/s3/s3.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { BlobService } from '../../shared/services/file/blob.service';
import * as FileSaver from 'file-saver';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
@Component({
  selector: 'upsc-delivery-defense-faq',
  templateUrl: './delivery-defense-faq.component.html',
  styleUrls: ['./delivery-defense-faq.component.scss']
})
export class DeliveryDefenseFaqComponent implements OnInit {
  public fileName: string = '';
  private downloadFileSubscription: Subscription;

  constructor(private utilityService: UtilityService,
    private notificationService: NotificationService,
    private blobService: BlobService,
    private errorHandlerService: ErrorHandlerService,
    private s3Service: S3Service) { }

  ngOnInit(): void {
  }
  public downloadPdf(event) {
    switch (event.target.id) {
      case 'id1': {
        this.fileName = 'DeliveryDefense_Scoring_Info.pdf/';
        break;
      }
      case 'id2': {
        this.fileName = 'DeliveryDefense_Tips_and_Tricks.pdf/';
        break;
      }
      case 'id3': {
        this.fileName = 'DeliveryDefense_Subscription_Plans.pdf/';
        break;
      }
      case 'id4': {
        this.fileName = 'DeliveryDefense Subscription Plans.pdf/';
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    this.downloadFileSubscription = this.s3Service.getPDFFile(this.fileName)
      .subscribe(
        base64 => this.handleGetFileFromS3Success(base64),
        err => this.handleGetFileFromS3Failure(err),
      );

  }
 
  private handleGetFileFromS3Success(base64: string) {    
    const blob = this.blobService.base64PdfToBlob(base64);
    FileSaver.saveAs(blob, this.fileName.replace("/",""));
  }

  private handleGetFileFromS3Failure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Downloading File',
      NotificationType.ERROR);
  }
}
