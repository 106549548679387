import { Component, ElementRef, HostBinding, Input, Output, Renderer2, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { IRecentUploadSignalRInterface } from './IRecentUploadSignalR.interface';


@Component({
  selector: 'upsc-recent-upload-live-status-check',
  templateUrl: './recent-upload-live-status-check.component.html',
  styleUrls: ['./recent-upload-live-status-check.component.scss'],
})

export class RecentUploadLiveStatusCheckComponent {
  @HostBinding('class') public hostClass = 'recent-upload-live-status-check';

  @Input() public title: string;
  @Input() public broadcastEventName: string;
  @Output() public messageReceived = new EventEmitter<any>();

  public statusName: string = null;
  private status: number = -1;
  public batchIdValue: string = null;
  public messageSubject$: Subject<IRecentUploadSignalRInterface> = new Subject<IRecentUploadSignalRInterface>();

  constructor(private renderer: Renderer2,
              private el: ElementRef,
  ) {}

  public onDataReceived(data: string): void {
    this.updateBatchStatus(data);
  }

  private updateBatchStatus = (batchId: string): void => {
    // Validate data type and format of batchId variable
    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(batchId)) {
      this.status = (this.broadcastEventName === "BatchAddressLookupCompleted") ? 1 : 2;
      this.statusName = (this.status === 1) ? "UPLOADED" : "PROCESSING";
      this.batchIdValue = batchId;
      this.renderer.addClass(this.el.nativeElement, this.statusName);
    }
    this.messageReceived.emit({ Status: this.status, BatchId: batchId, EventName: this.broadcastEventName });
  };

  protected readonly Notification = Notification;
}
