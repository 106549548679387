<section class="layout">
    <div class="content">
        <main class="main-container">
            <!-- Header and Download Template Section -->
            <section class="header-container" *ngIf="!isSubmissionComplete && !showReviewAddressDetails">
                <h2> Upload Address File </h2>
                <div class="download-template-container">
                    <upsc-s3-file-downloader [bucketName]="'members-xlsx.upsc'" [buttonClass]="'transparent-button'" [buttonText]="'Download Template'" [fileName]="'DeliveryDefense_Address_Template.xlsx'"></upsc-s3-file-downloader>
                    <img class="download-icon"
                         ngSrc="assets/icons/download.svg"
                         alt="download-icon"
                         height="14"
                         width="14">
                </div>
            </section>

            <div *ngIf="!isSubmissionComplete && !showReviewAddressDetails">
                <p class="looking-to-save-time"> Looking to save time? Simply upload a list of addresses using the provided template to receive scores at the same time. <b> Max {{ maxAddressUploads }} addresses per upload. </b> </p>
                <p class="how-to-fill-out"> How to fill out the address file template? <a (click)="tipsAndTricks()">Tips and Tricks</a> </p>    
            </div>

            <!-- Tip and Tricks section -->
            <section class="tips-and-tricks-container" *ngIf="openTipsAndTricks==true && !isSubmissionComplete && !showReviewAddressDetails">
                <div class="close-icon-container">
                    <img class="close-icon" (click)="closeTipsAndTricks()" src="../../../../assets/icons/close.png" alt="close-icon">
                </div>

                <div class="file-container" [ngClass]="{'first': first, 'last': last}" *ngFor="let header of headers; let first=first; let last=last">
                    <div class="divider" *ngIf="!last"> </div> 
                    <div class="number-and-icon-container">
                        <span>{{ header.number }}</span>
                        <div class="img-wrapper">
                            <img [src]="header.iconSrc" alt="header-icons">
                        </div>
                    </div>
                    <div class="text-and-icon-container">
                        <h3>{{ header.text }}</h3>
                        <img class="thumbs-icon" [src]="header.thumbsSrc" alt="thumbs-icon">
                    </div>
                </div>
            </section>

            <!-- Scoring Info section -->
            <div class="scoringInfo">
                <h1 (click)="showScoringInfo()" id="scoringInfoHeader">Scoring Info</h1>
                <div *ngIf="this.scoringInfoPanel" class="scoringInfoPanel">

                    <div class="labels">
                        <h2 class="lowConfidence">Low Confidence</h2>
                        <h2 class="highconfidence">High Confidence</h2>
                    </div>
                    <div class="sliderScale"></div>

                    <div class="infoTable">
                        <div class="labels">
                            <h2 class="lowConfidence">100</h2>
                            <h2 class="highconfidence">1000</h2>
                        </div>
                        <table class="my-table">
                            <thead class="my-table headers">
                            <tr>
                                <th>100-299</th>
                                <th>300-499</th>
                                <th>500-699</th>
                                <th>700-799</th>
                                <th style=" width: 97.368px;">800-1000</th>
                            </tr>
                            </thead>
                            <tbody class="my-table body">

                            <tr>
                                <td>The likelihood of loss is<br>
                                    <div class="size">16x</div>
                                    higher than a score of<br>
                                    800-1000
                                </td>
                                <td>The likelihood of loss is<br>
                                    <div class="size">13x</div>
                                    higher than a score of<br>
                                    800-1000
                                </td>
                                <td>The likelihood of loss is<br>
                                    <div class="size">9x</div>
                                    higher than a score of<br>
                                    800-1000
                                </td>
                                <td>The likelihood of loss is<br>
                                    <div class="size">4x</div>
                                    higher than a score of<br>
                                    800-1000
                                </td>
                                <td style=" width: 97.368px;">There is a low<br>
                                    likelihood <br>
                                    of loss
                                </td>

                            </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>


            <!-- File Upload Section -->
            <section class="click-or-drag-file-container" (click)="handleClick($event)" (drop)="handleDrop($event)" (dragover)="allowDrop($event)" *ngIf="!isSubmissionComplete && !showReviewAddressDetails">
                <div class="upload-wrapper" *ngIf="!selectedFileName">
                    <img class="drive-folder-icon"
                         ngSrc="assets/icons/Frame 22982.svg"
                         alt="drive-folder-icon"
                         height="44"
                         width="49">
                    <p>
                        <a>Click to upload</a> or drag and drop your file here
                    </p>
                    <span> CSV, XLS, OR XLSX </span>
                </div>

                <div class="selected-file-container">
                    <input #fileInput type="file" (change)="onFileInputChange($event)" [disabled]="isFileInputDisabled" style="display: none;" accept=".csv, .xls, .xlsx, /vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <div *ngIf="selectedFileName">
                        <div class="file-wrapper">
                            <img class="excel-4954" src="../../../../assets/icons//excel-4954 (1) 1.svg" alt="excel-icon">
                            <p> 
                                <span> {{ selectedFileName }} </span> 
                                <span *ngIf="!errorMessage"> is now ready to review </span>
                            </p>
                        </div>

                        <div class="error-message-container" *ngIf="errorMessage">
                            <img class="error-icon"
                                 ngSrc="assets/icons/Error_16x16.svg"
                                 alt="error-icon"
                                 height="13"
                                 width="13">
                            <mat-error class="error-message"> {{ errorMessage }} </mat-error>
                        </div>
                        
                        <div class="duplicate-addresses" *ngIf="duplicateAddressMessage">
                            {{ duplicateAddressMessage }}
                        </div>

                        <div class="btn-container">
                            <div class="submit-btn-wrapper">
                                <button mat-flat-button color="primary" [ngClass]="{'zero-scores-remaining-submit-btn': isZeroRemainingScores}" (click)="showSubmissionComplete($event)" [disabled]="isZeroRemainingScores"> {{ submitBtnMessage }} </button>
                            </div>
                            <div class="show-cancel-or-need-more-scores-wrapper">
                                <button mat-stroked-button color="primary" (click)="openDialog()"> {{cancelOrNeedMoreScoresMessage}} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Submission Complete Section (Only shows when user presses "Review" and no errors) -->
            <section class="submission-complete-container" *ngIf="isSubmissionComplete && !showReviewAddressDetails">
                <h2 class="submission-complete-message"> Submission Complete </h2>
                <p> {{ duplicateSubmissionCompleteMessage }} </p>
                <div class="submission-complete-progress-bar-container">
                    <div class="progress-bar-wrapper">
                        <img class="indicator-icon start-icon"
                             ngSrc="assets/icons/Indicator.svg"
                             alt="indicator-icon"
                             height="10"
                             width="10">
                        <span class="start-span"> Upload File </span>
                    </div>
                    <div class="progress-bar-wrapper">
                        <img class="indicator-icon middle-icon"
                             ngSrc="assets/icons/Indicator.svg"
                             alt="indicator-icon"
                             height="10"
                             width="10">
                        <span class="middle-span"> Review Data </span>
                    </div>
                    <div class="progress-bar-wrapper">
                        <img class="indicator-icon end-icon"
                             ngSrc="assets/icons/Indicator.svg"
                             alt="indicator-icon"
                             height="10"
                             width="10">
                        <span class="end-span"> Submission Complete </span>
                    </div>
                </div>
            </section>

            <!-- Review Errors Section (Only shows when user presses "Review" and has errors) -->
            @if (showReviewAddressDetails) {
                <upsc-dd-upload-address-file-review-errors [addressReviewsData]="addressReviewsData" [recentUploadData]="recentUploadData"></upsc-dd-upload-address-file-review-errors>
            }
        </main>
    </div>

    <!-- Scored Addresses Section -->
    <section class="scored-address-container" *ngIf="isSubmissionComplete && !showReviewAddressDetails"> 
        <!-- <upsc-recent-upload-live-status-check
            [broadcastEventName]="'BatchAddressLookupStarted'"
            [title]="'Batch Upload Started'">
        </upsc-recent-upload-live-status-check> -->

        <upsc-recent-upload-live-status-check
            [broadcastEventName]="'BatchAddressLookupCompleted'"
            [title]="'Batch Upload Completed'"
            (messageReceived)="onMessageReceived($event)">
        </upsc-recent-upload-live-status-check>
        
        <div class="message-container" *ngIf="showProcessingRecordsMessage">
            <span class="processing-records-message">
                Records are still processing. Please check the Recent Uploads table for the status of this upload.
            </span>
        </div>

        <!-- Show score history table if all records are finished uploading per service bus -->
        <upsc-dd-score-history-table class="score-history-table-upload-address-file" [addressUploadsData]="addressUploadsData" [isAddressUpload]="true" [lastColumnName]="'Access Point Location'" [tableName]="'Scored Addresses'" [componentName]="'Address Confidence Tool'" *ngIf="isDataProcessed"></upsc-dd-score-history-table>
        
        <div class="close-file-container">
            <div class="close-file-wrapper">
                <button mat-flat-button color="primary" (click)="closeFile()">
                    <span>Close File</span>
                    <span class="material-symbols-outlined">chevron_right</span>
                </button>    
            </div>
        </div>
    </section>

</section>
