import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ToastrConfig } from '../../config/toastr-config';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ApiEndpointService } from './services/api-endpoint.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { EnvironmentService } from './services/environment.service';
import { GlobalErrorHandler } from './services/global-error-handler/global-error-handler.service';
import { HttpClientService } from './services/http/http-client.service';
import { TokenInterceptor } from './services/http/token-interceptor.service';
import { UserRouteGuard } from './services/route-guards/user-route.guard';
import { SharedService } from './services/shared.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { StorageService } from './services/storage/storage.service';
import { UserRouteService } from './services/user-routes/user-routes.service';
import { VersionCheckService } from './services/version/version-check.service';

/**
 * This module is used to import singleton modules/services.
 * This includes HttpModule and other modules providing you services ONLY.
 * If a new instance of an imported module should be initialized every time a corresponding component/directive/pipe is injected,
 * use shared.module.ts instead.
 */
@NgModule({
    imports: [
        BrowserAnimationsModule,
        ToastrModule.forRoot(ToastrConfig)],
    declarations: [],
    providers: [
        StorageService,
        HttpClientService,
        AuthenticationService,
        SpinnerService,
        SharedService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        UserRouteGuard,
        UserRouteService,
        EnvironmentService,
        ApiEndpointService,
        VersionCheckService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
    exports: [
        BrowserAnimationsModule,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
