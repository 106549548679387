import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NotificationType } from '../../../shared/models/notification-type';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { UtilityService } from '../../../shared/services/utility/utility.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private zone: NgZone;

    public constructor(private injector: Injector) {
        this.zone = this.injector.get(NgZone);
    }

    public async handleError(err): Promise<void> {
        if (err.status === 0) {
            // console.error('API server is down?');
            this.handleAPIServerDown();
            return;
        }

        if (err.rejection?.status === 401 || err.status === 401) {
            await this.handleExpiredToken();
            return;
        }

        if (err.status === 500) {
            this.showGenericErrorMessage(err);
            return;
        }

        //#region ****** DO NOT DELETE ******
        // We need this code block for the development environment, or every error will be hidden which make it really hard to debug.
        if (environment.shouldUseGlobalExceptionHandler && err.message !== 'object unsubscribed') {
            this.showGenericErrorMessage(err);
        }
        //#endregion ****** DO NOT DELETE ******
    }

    private showGenericErrorMessage(err: any): void {
        let errorMessage = 'We are experiencing some technical difficulties. Please try again later or reach out to our support team for further assistance. We apologize for any inconvenience.';

        if (err instanceof Error && err.message) {
            errorMessage = err.message;
            errorMessage = err.message;
        } else {
            const utilityService = this.injector.get(UtilityService);
            const errorBody = utilityService.tryParseResponseJSON<any>(err);
            if (errorBody && errorBody.Message) {
                errorMessage = errorBody.Message;
            }
        }


        switch (true) {
            case (err instanceof HttpErrorResponse): {
                const errorHandlerService = this.injector.get(ErrorHandlerService);
                errorMessage = errorHandlerService.getHttpErrorMessage(err);
                break;
            }
            case (err instanceof Error && !!err.message):
                errorMessage = err.message;
                break;
            default: {
                const utilityService = this.injector.get(UtilityService);
                const errorBody = utilityService.tryParseResponseJSON<any>(err);
                if (errorBody && errorBody.Message) {
                    errorMessage = errorBody.Message;
                }

                break;
            }
        }

        const notificationService = this.injector.get(NotificationService);
        notificationService.notify(errorMessage, 'Technical Issue Alert', NotificationType.ERROR);

        const spinnerService = this.injector.get(SpinnerService);
        spinnerService.hide();

        console.error(err.statusText || errorMessage, err);
    }

    private handleAPIServerDown(): void {
        const notificationService = this.injector.get(NotificationService);

        notificationService.notify(
            'We are currently performing some maintenance on our server to enhance your experience. Please check back shortly. Thank you for your patience!',
            'Temporary Maintenance Notice',
            NotificationType.WARNING);
    }

    private async handleExpiredToken(): Promise<void> {
        // const notificationService = this.injector.get(NotificationService);
        // notificationService.notify(
        //     'You will be securely logged out. Please log in again to continue.',
        //     'Session Timeout',
        //     NotificationType.WARNING);
        console.warn('Session Timeout. You will be securely logged out. Please log in again to continue.');

        const authenticationService = this.injector.get(AuthenticationService);
        await authenticationService.logout();
        // setTimeout(
        //     async () => {
        //         await this.zone.run(async () => {
        //             await authenticationService.logout();
        //         });
        //     }, 5000);

        return;
    }
}
