import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { MatDialog } from '@angular/material/dialog';
import { DDUploadAddressPopupsComponent } from '../dd-upload-address-popups/dd-upload-address-popups.component';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
import { IReviewRecentUploadInterface } from '../../interfaces/IReviewRecentUpload.interface';

@Component({
  selector: 'upsc-dd-upload-address-file-review-errors',
  templateUrl: './dd-upload-address-file-review-errors.component.html',
  styleUrls: ['./dd-upload-address-file-review-errors.component.scss']
})
export class DDUploadAddressReviewErrorsTableComponent implements OnInit {
  public tableHeaders = ['Street', 'City', 'State', 'Zip Code']
    .map(value => ({
      label: value,
    }));
  public reviewErrorsData = [];

  public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 10 };
  public pageIndex: number = 1;
  public pageSize: number = 100;
  
  public savedAllErrorsData = [];
  public selectedRowIndex: number = -1;

  public isClosed: boolean = false;
  public isShowErrorsToggled: boolean = false;

  public editAddressFormGroup: FormGroup[] = [];  // Contains multiple formGroups inside
  public savedEditAddressFormGroup: FormGroup[] = [];

  public isError: boolean = false;
  public errorMessage: string = "";
  
  private updatedDataLength: number = 0;
  public isDeleteAllRecordsClicked: boolean = false;
  public onBlurInputHasNoError: boolean = false;
  public isRecentUpload: boolean = false;
  public isShowEdit = false;

  @Input() addressReviewsData = []; 
  @Input() recentUploadData: IReviewRecentUploadInterface;
  
  constructor(
    public dialog: MatDialog, 
    private formBuilder: FormBuilder,
    private deliveryDefenseService: DeliveryDefenseService,
  ) {}

  ngOnInit(): void {
    this.checkIfRecentUpload();
    this.subscribeAndUpdateFormGroup();
    this.getErrorMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {   
    if (changes.addressReviewsData && changes.addressReviewsData.currentValue) {
      if (!this.isDeleteAllRecordsClicked) {
        this.getReviewErrorsData();
      }
      this.updateEditAddressFormGroup();
      this.savedEditAddressFormGroup = this.deepCopyFormGroups(this.editAddressFormGroup);
      this.updatedDataLength = this.addressReviewsData.length;  
    }
  }

  private checkIfRecentUpload(): void {
    if (this.recentUploadData?.BatchId) {
      this.isRecentUpload = true;
    }
    if (this.isRecentUpload) {
      this.recentUploadData.AddressUploads.forEach(element => {
        element.IsChanged = 0;
      });
    }
  }

  private subscribeAndUpdateFormGroup(): void {
    if (this.isRecentUpload) {
      this.handleIsRecentUpload();
    }
    else {
      this.handleIsNotRecentUpload();
    }
  }

  private handleIsRecentUpload(): void {
    this.deliveryDefenseService.editRecentUploadData$.subscribe(  
      (result) => {
        const updatedData = result?.data;
        this.isDeleteAllRecordsClicked = result?.isDeleteAllErrorRecords;
        
        if (this.isShowErrorsToggled) {
          this.handleIfShowErrorsToggled(updatedData);
        }
        else {
          this.handleIfShowErrorsNotToggled(updatedData);
        }
        this.checkIfDeleteAllRecordsIsClicked();
        this.updateEditAddressFormGroup();
    });
  }

  private handleIsNotRecentUpload(): void {
    // Listens to editAddressData$ after we deleteAllRecords() in dd-upload-address-popups.component.ts to modify this.reviewErrorsData with result (result should hold no errors)
    this.deliveryDefenseService.editAddressData$.subscribe(  
      (result) => {
        const updatedData = result?.data;
        this.isDeleteAllRecordsClicked = result?.isDeleteAllErrorRecords;
  
        if (this.isShowErrorsToggled) {
          this.handleIfShowErrorsToggled(updatedData);
        }
        else {
          this.handleIfShowErrorsNotToggled(updatedData);
        }
        this.checkIfDeleteAllRecordsIsClicked();
        this.updateEditAddressFormGroup();
    });
  }

  private handleIfShowErrorsToggled(updatedData): void {
    if (this.isDeleteAllRecordsClicked) {
      this.reviewErrorsData = [];
      this.savedAllErrorsData = updatedData;
    }
    else {
      this.reviewErrorsData = updatedData;
    }
    this.updatedDataLength = updatedData.length;  
  }

  private handleIfShowErrorsNotToggled(updatedData: any[]): void {
    /* 
      Need to re-save and re-update here because we need to account for two scenarios
      Did the user press on "Remove all Error Addresses"?
        If so, then when the user toggles back and forth, we should show data that removed ALL the errors
        If not, then we can just show the original data
      *Note: I'm modifying same this.editAddressFormGroup, this.reviewErrorsData, this.savedAllErrors variables for all cases
    */
    this.savedAllErrorsData = updatedData;
    this.reviewErrorsData = updatedData;
    this.addressReviewsData = updatedData;
    this.updatedDataLength = updatedData?.length;
    this.resetPageIndex();
  }

  private setUpFormGroups(item: any): FormGroup {
    return this.formBuilder.group({
      street: [item.StreetAddress, [Validators.required, Validators.maxLength(35)]],
      city: [item.City, [Validators.required, Validators.maxLength(35)]],
      state: [item.State, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      zipCode: [item.Zip, [Validators.required, Validators.minLength(5), Validators.maxLength(5), this.zipCodeDigitsValidator()]],
    });
  }

  public updateAddress(index: number): void {
    const recordIndex = index + ((this.pageIndex - 1) * this.paginate.itemsPerPage);

    const recordToUpdate = this.reviewErrorsData[recordIndex];
    const formValue = this.editAddressFormGroup[index].value;

    recordToUpdate.StreetAddress = formValue.street;
    recordToUpdate.City = formValue.city;
    recordToUpdate.State = formValue.state;
    recordToUpdate.Zip = formValue.zipCode;
    
    recordToUpdate.invalidStreetaddress = false;
    recordToUpdate.invalidCity = false;
    recordToUpdate.invalidState = false;
    recordToUpdate.invalidZipCode = false;
    recordToUpdate.isEdited = true;
    recordToUpdate.Exceptions = []
    recordToUpdate.isModified = true;

    this.getErrorMessage();
    this.checkIfSelectedRow(true, index);

    if (this.isRecentUpload) {
      this.recentUploadData.AddressUploads[recordIndex].StreetAddress = formValue.street;
      this.recentUploadData.AddressUploads[recordIndex].City = formValue.city;
      this.recentUploadData.AddressUploads[recordIndex].State = formValue.state;
      this.recentUploadData.AddressUploads[recordIndex].Zip = formValue.zipCode;
      this.recentUploadData.AddressUploads[recordIndex].IsChanged = 1;
    }

    this.isShowEdit = false;
  }

  public onBlurFormInput(event: any, fieldType: string, index: number) {  
    this.editAddressFormGroup[index].get(fieldType).setValue(event.target?.value);

    const streetError = this.getStreetError(index);
    const cityError = this.getCityError(index);
    const stateError = this.getStateError(index);
    const zipError = this.getZipError(index);

    this.onBlurInputHasNoError = !streetError && !cityError && !stateError && !zipError;
  }

  public getStreetError(index: number): string {
    const streetControl = this.editAddressFormGroup[index].get('street');
    if (streetControl.hasError('required')) {
      return 'Street Address is required';
    } 
    else if (streetControl.hasError('maxlength')) {
      return 'Please enter Street Address up to 35 characters';
    }
    return "";
  }

  public getCityError(index: number): string {
    const cityControl = this.editAddressFormGroup[index].get('city');
    if (cityControl.hasError('required')) {
      return 'City is required';
    } 
    else if (cityControl.hasError('maxlength')) {
      return 'Please enter valid city up to 35 characters';
    }
    return "";
  }

  public getStateError(index: number): string {
    const stateControl = this.editAddressFormGroup[index].get('state');
    if (stateControl.hasError('required')) {
      return 'State code is required';
    } 
    else if (stateControl.hasError('minlength') || stateControl.hasError('maxlength')) {
      return 'Please enter 2 character state code required';
    }
    return "";
  }

  public getZipError(index: number): string {
    const zipCodeControl = this.editAddressFormGroup[index].get('zipCode');

    if (zipCodeControl.hasError('required')) {
      return 'Zip Code is required';
    } 
    else if (zipCodeControl.hasError('minlength') || zipCodeControl.hasError('maxlength') || zipCodeControl.hasError('allDigits')) {
      return 'Please enter 5 digit zipcode';
    }
    return "";
  }

  private zipCodeDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const zipCode = control.value;
      if (!/^\d+$/.test(zipCode)) {
        return { 'allDigits': true };
      }
      return null;
    };
  }

  public paginationChange(paginationDetails) {
    this.pageIndex = paginationDetails.pageIndex;
    this.pageSize = paginationDetails.pageSize;

    this.paginate.currentPage = this.pageIndex;
    this.paginate.itemsPerPage = this.pageSize;
    this.updateFormGroupToMatchCurrentPage();
  }

  private getCurrentPageData(): any[] {
    const startIndex = (this.pageIndex - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.reviewErrorsData.slice(startIndex, endIndex);
  }

  public updateFormGroupToMatchCurrentPage(): void {
    const currentPageData = this.getCurrentPageData();
    this.editAddressFormGroup = currentPageData.map(item => this.setUpFormGroups(item));
  }

  private getReviewErrorsData() {
    this.reviewErrorsData = this.addressReviewsData.map((item, index) => {
      // Add originalIndex property to each item
      item.originalIndex = index;
      return item;
    });

    this.checkInvalidErrors(this.reviewErrorsData);
    this.savedAllErrorsData = this.reviewErrorsData;
    this.paginate.totalItems = +this.reviewErrorsData.length || 0;
  }

  private checkInvalidErrors(reviewErrorsData): void {
    const errorMappings = {
      "Street Address is required.": "invalidStreetaddress",
      "Street Address exceeded max length of 35 characters.": "invalidStreetaddress",
      "City is required.": "invalidCity",
      "City exceeded max length of 35 characters/ Use code provided.": "invalidCity",
      "State is required, use a 2 letter code provided.": "invalidState",
      "Please provide valid state code.": "invalidState",
      "Please provide 5 digit zipcode": "invalidZipCode",
    };

    for (let i = 0; i < reviewErrorsData.length; i++) {
      for (const key in errorMappings) {
        if (reviewErrorsData[i].Exceptions.includes(key)) {
          reviewErrorsData[i][errorMappings[key]] = true;
        }
      }
    }
  }

  public getIcon(item): string {
    if (item.Exceptions.length >= 1) {
      return `../../../../../../../assets/icons/Error_16x16.svg`;
    }
    else if (item.isEdited) {
      return `../../../../../../../assets/icons/Error_16x16 (edited).svg`;
    }
    // else if (item.SearchStatus == 2) {
    //   return `../../../../../../../assets/icons/Error_16x16 (recently searched).svg`
    // }
    else {
      return '';
    }
  }

  public showErrors(): void {
    this.isShowErrorsToggled = true;
    if (this.isDeleteAllRecordsClicked) {   // If it's clicked, that means all errors should be deleted and it should display nothing
      this.reviewErrorsData = [];
    }
    else {    // Otherwise, the user never clicked on "Remove all Error Addresses", so we should just show normal errors
      this.reviewErrorsData = this.reviewErrorsData
        .map((item, index) => {
          // Add originalIndex property to each item
          item.originalIndex = index;
          return item;
        })
        .filter((item) => {
          return item.Exceptions.length > 0;
        });
    };

    this.resetPageIndex();
    this.updateEditAddressFormGroup();
  }

  public showAll(): void {
    this.reviewErrorsData = this.savedAllErrorsData;
    this.isShowErrorsToggled = false;
    this.resetPageIndex();
    this.updateEditAddressFormGroup();
  }
  
  private resetPageIndex(): void {
    this.pageIndex = 1;
    this.paginate.currentPage = 1;
    this.paginate.totalItems = +this.reviewErrorsData.length || 0;
  }

  public updateEditAddressFormGroup(): void {
    this.editAddressFormGroup = this.reviewErrorsData.map(item => this.setUpFormGroups(item));
  }

  public showEdit(index): void {
    this.selectedRowIndex = (this.selectedRowIndex === index) ? -1 : index;

    if (this.reviewErrorsData[index].isModified == false) {
      this.editAddressFormGroup[index].reset(this.savedEditAddressFormGroup[index].value);
    }
    if (this.pageIndex > 1 || this.isShowErrorsToggled || this.isDeleteAllRecordsClicked) {
      this.updateFormGroupToMatchCurrentPage();
    }

    this.isShowEdit = !this.isShowEdit;
  }
  
  public isEditVisible(index: number): boolean {
    return this.selectedRowIndex === index;
  }

  public cancelSubmission(): void {
    this.dialog.open(DDUploadAddressPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: "Cancel Submission",
        message: "Are you sure you want to cancel this process?",
        noMessage: "No, Don't Cancel",
        yesMessage: "Yes, Cancel Submission",
        isRecentUpload: this.isRecentUpload
      }
    });
  }

  public submitSubmission(): void {
    this.dialog.open(DDUploadAddressPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: "Submit for Scoring",
        message: `${this.updatedDataLength} searches will be submited for scoring. Do you want to proceed?`,
        noMessage: "No, Go Back",
        yesMessage: "Yes, Submit for Scoring",
        savedAllErrorsData: this.savedAllErrorsData,
        isRecentUpload: this.isRecentUpload,
        recentUploadData: this.recentUploadData,
      }
    });
  }

  public deleteRecords(recordIndex: number, removeAllErrors: boolean): void {    
    this.dialog.open(DDUploadAddressPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '312px',
      data: {
        title: "Delete Record(s)",
        message: "Removed record(s) will not be submitted for scoring. Are you sure you want to delete the record(s)?",
        noMessage: "No, Don't Delete",
        yesMessage: "Yes",
        removeAllErrors: removeAllErrors,
        index: recordIndex + ((this.pageIndex - 1) * this.paginate.itemsPerPage),
        isShowErrorsToggled: this.isShowErrorsToggled,
        editAddressFormGroup: this.editAddressFormGroup,
        savedAllErrorsData: this.savedAllErrorsData,
        isRecentUpload: this.isRecentUpload,
        recentUploadData: this.recentUploadData,
        reviewErrorsData: this.reviewErrorsData,
      }
    });
  }

  public saveProgress(): void {
    this.dialog.open(DDUploadAddressPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: "Save Progress",
        message: `Your editing progress will be saved, and you can click the pending file to review the data later.`,
        noMessage: "No, Go Back",
        yesMessage: "Yes, Save Progress",
        savedAllErrorsData: this.savedAllErrorsData,
        isRecentUpload: this.isRecentUpload,
        recentUploadData: this.recentUploadData,
        reviewErrorsData: this.reviewErrorsData,
      }
    });
  }

  private deepCopyFormGroups(formGroups: FormGroup[]): FormGroup[] {
    return formGroups.map(formGroup => this.cloneFormGroup(formGroup));
  }

  private cloneFormGroup(formGroup: FormGroup): FormGroup {
    const clonedGroup = this.formBuilder.group({});

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      clonedGroup.addControl(key, this.formBuilder.control(control.value));
    });

    return clonedGroup;
  }
  
  public closeEditAddressData(isClosed: boolean, index): void {
    this.checkIfSelectedRow(isClosed, index);
  
    if (isClosed) {
      if (!this.reviewErrorsData[index].isModified) {
        this.editAddressFormGroup[index].reset(this.savedEditAddressFormGroup[index].value);
      }
      if (this.pageIndex > 1) {
        this.updateFormGroupToMatchCurrentPage();
      }

      this.isShowEdit = false;
    }
  }

  public getErrorMessage(): void {
    let errorCount = 0;
    for (let i = 0; i < this.addressReviewsData.length; i++) {
      if (this.addressReviewsData[i].Exceptions.length >= 1) {
        errorCount += 1;
        this.isError = true; 
      }
      if (errorCount == 0) {
        this.isError = false;
      }
    }
    if (this.isError) {
      this.errorMessage = `${errorCount} Error(s) found. Please correct the errors before submitting.`;
    }

    else {
      this.errorMessage = `Errors have been resolved. Submit for re-validation.`;
    }
  }

  private checkIfDeleteAllRecordsIsClicked(): void {
    if (this.isDeleteAllRecordsClicked) {
      this.isError = false;
      this.errorMessage = `Errors have been resolved. Submit for re-validation.`;
    }
    if (!this.isDeleteAllRecordsClicked) {
      this.getErrorMessage();
    }
  }

  private checkIfSelectedRow(isClosed: boolean, index: number): void {
    this.selectedRowIndex = (this.selectedRowIndex === index) ? -1 : index;
    this.isClosed = isClosed && (this.selectedRowIndex == index);
  }
}

