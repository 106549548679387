import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ValidationService } from '../../shared/services/validation/validation.service';
// import { _keyValueDiffersFactory } from '@angular/core/src/application_module';
import { SharedService } from '../services/shared.service';
import { SignUpDropdowns } from '../../../config/dropdown-config';

@Component({
    selector: 'upsc-account-information',
    templateUrl: './account-information.component.html',
    styleUrls: ['./account-information.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class AccountInformationComponent implements OnInit {

    public country: string = 'US';
    public signUpDropdowns = SignUpDropdowns;
    public maxLength: number = 5;
    accountInformationFormGroup: UntypedFormGroup;
    public states = [];

    @Output() notifyForm: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

    constructor(private formBuilder: UntypedFormBuilder,
        private sharedService: SharedService,
        private validationService: ValidationService) {

    }

    ngOnInit() {
        this.states = this.signUpDropdowns.states.filter(x => x.country == this.country);
        this.initForm();
        this.onValueChanges();
        this.notifyForm.emit(this.accountInformationFormGroup);
        this.onCountryChanges();
    }

    initForm() {
        this.accountInformationFormGroup = this.formBuilder.group({
            streetAddress: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            suiteApt: ['', Validators.compose([Validators.maxLength(50)])],
            city: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            stateProvince: [''],
            zip: [''],
            website: [''],
            phoneNumber: [''],
            email: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator(), Validators.maxLength(256)])],
            confirmEmail: ['', Validators.compose([Validators.required, this.validationService.emailFormatValidator(), Validators.maxLength(256),])],
            contactPreference: ['', Validators.compose([Validators.required])], 
            requestedService: ['Bundled', Validators.compose([Validators.required])]
        }, {
            validators: this.validationService.isMatching('email', 'confirmEmail')
        });
    }

    onValueChanges() {
        this.accountInformationFormGroup.valueChanges
            .subscribe(() => {
                this.notifyForm.emit(this.accountInformationFormGroup);
            });
    }

    onCountryChanges() {
        this.sharedService.Country.subscribe(country => {
            this.country = country;
            this.validationChanges();
        });
    }

    validationChanges() {
        if (this.country.toLowerCase() === 'us' || this.country.toLowerCase() === 'ca') {

            this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.stateProvince,
                Validators.compose([Validators.required, Validators.maxLength(2)]));

            this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.phoneNumber,
                Validators.compose([Validators.required, Validators.pattern(/^\d{3}\-\d{3}\-\d{4}$/)]));

            if (this.country.toLowerCase() === 'us') {
                this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.zip,
                    Validators.compose([Validators.required,
                    Validators.maxLength(5),
                    Validators.pattern("^[0-9]*$")])); // MV3-4812 // this.validationService.isInteger()]));
                this.maxLength = 5;
            }
            else if (this.country.toLowerCase() === 'ca') {
                this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.zip,
                    Validators.compose([Validators.required,
                    Validators.maxLength(6),
                    this.validationService.containsAlphabet(),
                    this.validationService.containsNumber()]));
                this.maxLength = 6;
            }

            this.states = this.signUpDropdowns.states.filter(x => x.country == this.country);
        }
        else {

            this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.stateProvince,
                Validators.compose([Validators.maxLength(15)]));

            this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.phoneNumber,
                Validators.compose([Validators.required, this.validationService.isInteger(), Validators.maxLength(15)]));

            this.validationService.setFormControlValidators(this.accountInformationFormGroup.controls.zip,
                Validators.compose([Validators.maxLength(10)]));
            this.maxLength = 10;
        }
    }
    addHyphen(element) {
        this.sharedService.setHyphen(element, this.country);
    }
}