import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLs } from '../../../shared/components/side-bar-menu/URL-enum';
import { ILoginFooterContent } from './models/login-footer-content.interface';

@Injectable({ providedIn: 'root' })
export class LoginFooterService {
    public constructor(private readonly translateService: TranslateService) {}

    public getFooterContent(lang: string): Observable<ILoginFooterContent> {
        return this.translateService.getTranslation(lang)
                   .pipe(
                       map(langObj => this.generateFooterContent(lang, langObj)),
                   );
    }

    private generateFooterContent(lang: string, langObj: any): ILoginFooterContent {
        const keys = [
            'SignInPage.Footer.Email',
            'SignInPage.Footer.PrivacyNotice',
            'SignInPage.Footer.TermsOfUse',
            'SignInPage.Footer.ParcelProWebSite',
            'SignInPage.Footer.Disclaimer',
            'SignInPage.Footer.CopyRights',
            'SignInPage.Footer.siteLink',
        ];
        const param = { currentYear: dayjs().format('YYYY') };
        const t = this.translateService.getParsedResult(langObj, keys, param);

        const email = t['SignInPage.Footer.Email'];
        const website = t['SignInPage.Footer.ParcelProWebSite'];
        const siteLink = t['SignInPage.Footer.siteLink'] || website;

        switch (lang) {
            case 'en-gb':
                return {
                    agreement: {
                        text: t['SignInPage.Footer.TechnologyAgreement'],
                        link: URLs.TechnologyAgreementGBEN,
                        target: '_blank',
                    },
                    disclosure: {
                        text: t['SignInPage.Footer.ProductDisclosure'],
                        link: URLs.ProductDisclosureGBEN,
                        target: '_blank',
                    },
                    complaints: {
                        text: t['SignInPage.Footer.Complaints'],
                        link: URLs.ComplaintsGBEN,
                        target: '_blank',
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: URLs.PrivacyNoticeGBEN,
                        target: '_blank',
                    },
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: URLs.WebSiteTermofUseGBEN,
                        target: '_blank',
                    },
                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
            case 'de':
                return {
                    agreement: {
                        text: t['SignInPage.Footer.TechnologyAgreement'],
                        link: URLs.TechnologyAgreementDEDE,
                        target: '_blank',
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: URLs.WebSiteTermDEDE,
                        target: '_blank',
                    },
                    disclosure: {
                        text: t['SignInPage.Footer.ProductDisclosure'],
                        link: URLs.ProductDisclosureDEDE,
                        target: '_blank',
                    },
                    complaints: {
                        text: t['SignInPage.Footer.Complaints'],
                        link: URLs.ComplaintsDEDE,
                        target: '_blank',
                    },
                    impressum: {
                        text: t['SignInPage.Footer.Imprint'],
                        link: URLs.ImpressumDE,
                        target: '_blank',
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: URLs.PrivacyNoticeDEDE,
                        target: '_blank',
                    },
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },

                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
            case 'en':
                return {
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: this.translateService.instant('SignInPage.Footer.PrivacyNoticeLink'),
                        target: '_blank',
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: '/terms',
                    },
                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
            case 'it':
                return {
                    agreement: {
                        text: t['SignInPage.Footer.TechnologyAgreement'],
                        link: URLs.TechnologyAgreementITIT,
                        target: '_blank',
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: URLs.WebSiteTermofUseITIT,
                        target: '_blank',
                    },
                    disclosure: {
                        text: t['SignInPage.Footer.ProductDisclosure'],
                        link: URLs.ProductDisclosureITIT,
                        target: '_blank',
                    },
                    complaints: {
                        text: t['SignInPage.Footer.Complaints'],
                        link: URLs.ComplaintsITIT,
                        target: '_blank',
                    },
                    impressum: {
                        text: t['SignInPage.Footer.Imprint'],
                        link: URLs.ImpressumDE,
                        target: '_blank',
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: URLs.PrivacyNoticeITIT,
                        target: '_blank',
                    },
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },

                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
            case 'fr':
                return {
                    agreement: {
                        text: t['SignInPage.Footer.TechnologyAgreement'],
                        link: URLs.TechnologyAgreementFRFR,
                        target: '_blank',
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: URLs.WebSiteTermofUseFRFR,
                        target: '_blank',
                    },
                    disclosure: {
                        text: t['SignInPage.Footer.ProductDisclosure'],
                        link: URLs.ProductDisclosureFRFR,
                        target: '_blank',
                    },
                    complaints: {
                        text: t['SignInPage.Footer.Complaints'],
                        link: URLs.ComplaintsFRFR,
                        target: '_blank',
                    },
                    impressum: {
                        text: t['SignInPage.Footer.Imprint'],
                        link: URLs.ImpressumDE,
                        target: '_blank',
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: URLs.PrivacyNoticeFRFR,
                        target: '_blank',
                    },
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },

                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
            default:
                return {
                    email: {
                        text: email,
                        link: `mailto:${ email }`,
                    },
                    privacy: {
                        text: t['SignInPage.Footer.PrivacyNotice'],
                        link: this.translateService.instant('SignInPage.Footer.PrivacyNoticeLink'),
                        target: '_blank',
                    },
                    terms: {
                        text: t['SignInPage.Footer.TermsOfUse'],
                        link: '/terms',
                    },
                    website: {
                        text: website,
                        link: siteLink,
                        target: '_blank',
                    },
                    disclaimer: t['SignInPage.Footer.Disclaimer'],
                    copyrights: t['SignInPage.Footer.CopyRights'],
                };
        }
    }
}
