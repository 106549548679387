import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { ApiEndpointService } from './core/services/api-endpoint.service';
import { EnvironmentService } from './core/services/environment.service';

@Injectable()
export class PreLoadService {

    public constructor(private readonly environmentService: EnvironmentService,
                       private readonly apiEndpointService: ApiEndpointService,
                       private readonly storage: StorageMap,
                       private readonly appStateService: AppStateService,
    ) {
    }

    public load(): Promise<unknown> {
        return new Promise((resolve, reject) => {
            this.environmentService.getEnvironmentFile()
                .pipe(
                    catchError(
                        (err) => {
                            console.error(err);
                            reject(err);
                            return of(null);
                        },
                    ),
                    switchMap(
                        (env) => {
                            if (env) {
                                this.apiEndpointService.constructEndpoints(env);
                            }

                            return forkJoin([
                                this.storage.get('auth_token') as Observable<string>,
                            ]).pipe(
                                switchMap(
                                    (res) => {
                                        if (!res) {
                                            return of(true);
                                        }

                                        const token = res[0];

                                        if (token) {
                                            return this.appStateService.refreshAppState()
                                                       .pipe(
                                                           map((appState) => !!appState),
                                                       );
                                        }

                                        return of(true);
                                    },
                                ),
                            );
                        },
                    ),
                )
                .subscribe(
                    (res) => {
                        resolve(true);
                    },
                );
        });
    }
}
