import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginFooterComponent } from './login/login-footer/login-footer.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordService } from './services/change-password.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        /*LanguageModule,
        LocalizationModule,*/
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
    ],
    declarations: [
        LoginComponent,
        LoginFooterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
    ],
    providers: [
        //  TranslationService,
        //  LoginService,
        //  User
        ChangePasswordService,
    ],
    exports: [
        LoginComponent,
    ],
})
export class LoginModule {
}

