<div class="manage-credit-cards white-content-page">
    <upsc-page-header [pageTitle]="'ManageCreditCard'|translate"></upsc-page-header>

    <div class="page-actions">
        <a href="/payment-history" role="button" mat-raised-button class="alternative-button page-action">
            {{'ViewPaymentHistory'|translate}}
        </a>
        <button mat-raised-button type="button" class="page-action" (click)="openCCDialog($event)">
            {{'AddCard'|translate}}
        </button>
    </div>

    <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
        <table datatable
               [dtOptions]="dtOptions"
               [dtTrigger]="dtTrigger"
               class="stripe"
               [hidden]="!cardsList">
            <thead>
            <tr>
                <th class="all trigger-column"></th>
                <th>
                    {{'Type'|translate}}
                </th>
                <th>
                    {{'Last4Digits'|translate}}
                </th>
                <th *ngIf="false">
                    {{'ExpirationDate'|translate}}
                </th>
                <th>
                    {{'BillingAddress'|translate}}
                </th>
                <th class="text-center all">
                    {{'Primary'|translate}}
                </th>
                <th class="all">
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let card of cardsList">
                <td class="trigger-cell"></td>
                <td class="align-middle">
                    <img *ngIf="card.CreditCardType.includes('MasterCard')"
                         class="cc-logo"
                         src="../../../assets/images/Credit Card Logos/Mastercard logo/mastercard.png"
                         alt="Master Card"/>
                    <img *ngIf="card.CreditCardType.includes('AmericanExpress')"
                         class="cc-logo"
                         src="../../../assets/images/Credit Card Logos/Amex logo/AMEX.png"
                         alt="Amex Card"/>
                    <img *ngIf="card.CreditCardType.includes('Discover')"
                         class="cc-logo"
                         src="../../../assets/images/Credit Card Logos/Discover logo/discover.png"
                         alt="Discover Card"/>
                    <img *ngIf="card.CreditCardType.includes('Visa')"
                         class="cc-logo"
                         src="../../../assets/images/Credit Card Logos/Visa Logo/Visa.png"
                         alt="Visa Card"
                    />
                    <img *ngIf="card.CreditCardType.includes('PayPal')"
                         class="cc-logo"
                         src="../../../assets/images/Credit Card Logos/PayPal Logo/Paypay.png"
                         alt="Visa Card"/>
                </td>
                <td class="align-middle">
                    {{card.Last4}}
                </td>
                <td class="align-middle " *ngIf="false">
                    {{card.ExpDateMonth}}/{{card.ExpDateYear}}
                </td>
                <td class="align-middle">
                    {{card.BillingAddress}}
                </td>
                <td class="align-middle text-center">
                    <mat-radio-button name="default"
                                      (change)="changePrimaryCard(card.UserCreditCardID)"
                                      [checked]="card.IsPrimary.toLowerCase()=='true'">
                    </mat-radio-button>
                </td>
                <td>
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                class="alternative-button"
                                (click)="confirmRemoveCreditCard(card)"> {{'Remove'|translate}}
                        </button>
                    </div>
                    <div class="actions">
                        <button type="button"
                                mat-raised-button
                                (click)="openEditCreditCard(card, $event)"> {{'Edit'|translate}}
                        </button>
                    </div>
                </td>
            </tr>
            <!--<tr *ngIf="noRecordsFound">
              <td colspan="7" class="text-center">
                <span style="color:red;font-size:12px;"> {{'NoRecordsFound'|translate}} </span>
              </td>
            </tr>-->
            </tbody>
        </table>
    </div>
</div>
