import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

    transform(value: any): any {

        switch (value.PaymentMethod) {
            case 1: {
                if (value.PaymentSource && value.PaymentSource.toUpperCase() === 'INTRAPPI')
                    return 'PrePayment';
                else return 'Credit card';
            }
            case 2:
                return 'Check';
            case 3:
                return 'ACH';
            case 4:
                return 'Wire';
            case 8:
                return 'Credits'
            default:
                return '';
        }
    }

}
