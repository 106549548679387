import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { SignUp } from '../model/sign-up.model';

@Injectable()
export class SignUpService {

    public constructor(private readonly httpClient: HttpClient, private apiEndpointService: ApiEndpointService) {

    }

    /**
     * Register new user.
     */
    public registerUser(data: SignUp): Observable<string> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('signupUser'))
            .toString();
        return this.httpClient.post(endpoint, data, { responseType: 'text' });
    }
}
