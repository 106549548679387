import { Injectable } from '@angular/core';


@Injectable()
export class SignUp {
    HowDidYouHear: string;
    SalesPersonName?: string;
    DBA: string;
    FirstName: string;
    LastName: string;
    FederalTaxID: number;
    YearsInBusiness: number;
    YearIncorporated: number;
    NumberOfEmployees: number;
    NumberOfLocations: number;
    TypeOfBiz: string;
    PrimaryBiz: any;  // CRM API accepts list of strings whereas UI accepts string
    Products: any;    // CRM API accepts list of strings whereas UI accepts string 
    VerifiedBy: string;
    Address1: string;
    Address2: string;
    City: string;
    StateProvince: string;
    Zip: string;
    PhoneNum: string;
    FaxNum: string;
    Email: string;
    ContactPref: string;
    PaymentType: string;
    AnetProfileID: string;
    AnetPaymentProfileID: string;
    AnetAddress: string;
    AnetCity: string;
    AnetState: string;
    AnetCountry: string;
    AnetZip: string;
    AnetFirstName: string;
    AnetLastName: string;
    Courier: string;
    Insurer: string;
    UPSAcctNum: string;
    FedExAcctNum: string;
    ShippingFrequencyPackagesShipped: string;
    AverageShipmentVal: string;
    MaxValuePerPackage: string;
    ClaimHistory1_Year: number;
    ClaimHistory1_Courier: string;
    ClaimHistory1_ValueInsured: number;
    ClaimHistory1_AmtPaidByInsurer: number;
    ClaimHistory2_Year: number;
    ClaimHistory2_Courier: string;
    ClaimHistory2_ValueInsured: number;
    ClaimHistory2_AmtPaidByInsurer: number;
    ClaimHistory3_Year: number;
    ClaimHistory3_Courier: string;
    ClaimHistory3_ValueInsured: number;
    ClaimHistory3_AmtPaidByInsurer: number;
    BizRef1_Company: string;
    BizRef1_Contact: string;
    BizRef1_YearsKnown: number;
    BizRef1_Phone: string;
    BizRef2_Company: string;
    BizRef2_Contact: string;
    BizRef2_YearsKnown: number;
    BizRef2_Phone: string;
    BizRef3_Company: string;
    BizRef3_Contact: string;
    BizRef3_YearsKnown: number;
    BizRef3_Phone: string;
    Country: string;
    BillingPhone: string;
    BillingAddress1: string;
    BillingAddress2: string;
    BillingCity: string;
    BillingState: string;
    BillingZip: string;
    BillingCountry: string;
    Website: string;
    MemberAffiliateProg: string;
    CompanyName: string;
    BillingContact: string;
    Browser: string;
    IpAddress: string;
    BusinessOwner: string;
    reference?: string;
    campaignId?: string;
    origin?: string;
    BusinessToBusinessPercent: string;
    BusinessToConsumerPercent: string;
    ReturnPercent: string;
    NewPercent: string;
    UsedPercent: string;
    RequestedService: string;
    AverageValuePerPackage: number;
    MaximumValuePerPackage: number;
    MaximumValueFromSingleLocation: number;
    MaximumValueToSingleLocation: number;
    TotalAnnualPackagesInsured: number;
    TotalAnnualSales: number;
    DomesticPercent: string;
    InternationalPercent: string;
    AirTransportationPercent: string;
    GroundTransportationPercent: string;
    NoClaimsLast24Months: boolean;
    SalesPersonEmployeeId: string;
    LeadId: string;
}
