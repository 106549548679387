import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { EMPTY, filter, forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { ReCaptchaErrorCode } from '../../core/services/authentication/models/recaptcha-site-verify-response.interface';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { StorageService } from '../../core/services/storage/storage.service';
import { Customer } from '../../shared/models/customer/customer.model';
import { NotificationType } from '../../shared/models/notification-type';
import { BirService } from '../../shared/services/bir/bir.service';
import { CustomerService } from '../../shared/services/customer/customer.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { TranslationService } from '../../shared/services/translation/translation.service';
import { User } from '../../shared/services/user/models/user.model';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { SupportScriptService } from '../../support-scripts/support-script.service';
import { TermsDialogService } from '../../terms/dialog/terms-dialog.service';
import { TermsService } from '../../terms/services/terms.service';
import { LoginFooterService } from './login-footer/login-footer.service';
import { ILoginFooterContent } from './login-footer/models/login-footer-content.interface';

@Component({
    selector: 'upsc-login',
    templateUrl: './login-jwt.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('failedLogin') failedLogin: ElementRef;
    public redirect: string;
    public user: User;
    public formGroup: UntypedFormGroup;
    public isInProgress = false;
    public shouldShowSignUp = true;
    public isEUUser: boolean = false;
    public footerContent$: Observable<ILoginFooterContent>;
    public isParcelProDomain = false;

    private currentLanguage = 'en';

    private ngDestroyed$ = new Subject<void>();

    public constructor(private readonly formBuilder: UntypedFormBuilder,
                       private readonly authenticationService: AuthenticationService,
                       private readonly storageService: StorageService,
                       private readonly storage: StorageMap,
                       private readonly customerService: CustomerService,
                       private notificationService: NotificationService,
                       private birService: BirService,
                       private spinnerService: SpinnerService,
                       private utilityService: UtilityService,
                       private router: Router,
                       private errorHandlerService: ErrorHandlerService,
                       private translationService: TranslationService,
                       private route: ActivatedRoute,
                       private readonly supportScriptService: SupportScriptService,
                       private readonly loginFooterService: LoginFooterService,
                       private readonly termsService: TermsService,
                       private readonly termsDialogService: TermsDialogService,
                       private readonly recaptchaService: ReCaptchaV3Service,
    ) {
        this.isParcelProDomain = !this.utilityService.isEUDomainName();
    }

    public get supportTel(): string {
        const currentDomainName = this.utilityService.getCurrentDomainName();
        switch (true) {
            case currentDomainName.includes('upscapitalde.com'):
                return '+49 69 6630 8330';
            case currentDomainName.includes('upscapitaluk.com'):
                return '+44 20 7949 0144';
            case currentDomainName.includes('upscapitalfr.com'):
                return '+33 1 73 01 84 55';
            case currentDomainName.includes('upscapitalit.com'):
                return '+39 02 4527 9099';
            default:
                return '+1 (888) 683-2300';
        }
    }

    public get contactUsLink(): string {
        const currentDomainName = this.utilityService.getCurrentDomainName();
        switch (true) {
            case currentDomainName.includes('upscapitalde.com'):
                return 'https://www.parcelpro.com/de/de/about-us/contact-us.html';
            case currentDomainName.includes('upscapitaluk.com'):
                return 'https://www.parcelpro.com/gb/en/about-us/contact-us.html';
            case currentDomainName.includes('upscapitalfr.com'):
                return 'https://www.parcelpro.com/fr/fr/about-us/contact-us.html';
            case currentDomainName.includes('upscapitalit.com'):
                return 'https://www.parcelpro.com/it/it/about-us/contact-us.html';
            default:
                return '/contact-us';
        }
    }

    public onSubmit(event, form): void {
        event.preventDefault();

        if (!form.userName || !form.password) {
            return;
        }

        if (!environment.feature.reCAPTCHA) {
            this.login(form);
            return;
        }

        this.recaptchaService.execute('login')
            .pipe(
                switchMap((token) => this.authenticationService.verifyReCaptcha(token)),
            )
            .subscribe(
                (res) => {
                    if (!res.success) {
                        this.notificationService.notify(
                            'Verification failed. If the problem persists, please contact support.',
                            'Error verifying reCAPTCHA',
                            NotificationType.ERROR,
                        );

                        for (const err of res['error-codes']) {
                            const errorDescription = ReCaptchaErrorCode[err];
                            if (!errorDescription) {
                                return;
                            }

                            console.error(err, errorDescription);
                        }

                        return;
                    }

                    this.login(form);
                },
            );
    }

    public ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            userName: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
            password: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            isRememberMe: [false],
        });

        this.supportScriptService.loadDefaultScript();
        this.storage.get('lang')
            .pipe(
                map((lang: string) => lang || this.utilityService.getSiteLanguage() || 'en'),
            )
            .subscribe(
                (lang) => {
                    this.currentLanguage = lang ?? this.utilityService.getSiteLanguage();
                    this.isEUUser = ['DE', 'EN-GB', 'IT', 'FR'].includes(this.currentLanguage.toUpperCase());

                    this.shouldShowSignUp = !['de', 'en-gb', 'fr', 'it'].includes(lang);
                    this.footerContent$ = this.loginFooterService.getFooterContent(lang);
                },
            );
    }

    public ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    private login(form: any): void {
        if (!form) {
            return;
        }

        this.isInProgress = true;
        this.spinnerService.show();

        this.authenticationService.login(form.userName, form.password, form.isRememberMe)
            .pipe(
                catchError(err => this.handleJwtLoginFailure(err)),
                takeUntil(this.ngDestroyed$),
                finalize(() => {
                    this.isInProgress = false;
                }),
                filter(res => !!res),
                tap((res) => {
                    this.setCurrentLanguage(res?.customer).subscribe();
                    this.handleUserInfo(res?.user);
                    this.handleCustomerInfo(res?.customer);

                    if (!res?.user?.IsFirstLogon) {
                        this.checkProductTermsConditions();
                    }
                }),
                switchMap((res) => {
                    if (res?.customer?.Status === 11) {
                        this.router.navigate(['/account-suspended']).then();
                        return EMPTY;
                    }

                    return of(res);
                }),
                filter(res => !!res),
                switchMap(
                    (res) => {
                        return this.birService.getShipmentExceptions()
                                   .pipe(
                                       catchError(err => this.handleBIRError(err)),
                                   );
                    },
                ),
            )
            .subscribe(
                async (shipmentExceptions) => {
                    if (shipmentExceptions?.length) {
                        await this.router.navigate(['/bir']);
                        return;
                    }

                    await this.navigate();
                },
            );
    }

    private handleJwtLoginFailure(err): Observable<never> {
        this.spinnerService.hide();
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            'Failed logging in',
            NotificationType.ERROR);
        this.isInProgress = false;

        return EMPTY;
    }

    /*
        private handleGetUserSuccess(user) {
            const currentUser = {
                username: user.UserName,
                sessionid: user.MobileSessionID,
                defaultLocation: user.DefaultLocation,
                isAdmin: user.IsAdmin,
                isGuest: user.IsGuest,
                userId: user.UserId,
            };

            this.user = user;

            // sessionStorage.setItem('currentuser', JSON.stringify(currentUser));
            // this.storage.set('currentuser', currentUser).subscribe(() => {});
            this.sharedService.userSubject.next(user);

            this.utilityService.clearSubscriptions([this.getCustomerSubscription]);
            this.getCustomerSubscription
                = this.userService.getCustomer()
                      .pipe(
                          catchError((err) => {
                              this.notificationService.notify(
                                  this.errorHandlerService.getHttpErrorMessage(err),
                                  'Failed Getting Customer',
                                  NotificationType.ERROR);

                              return of(null);
                          }),
                      )
                      .subscribe(
                          async (customer) => {
                              if (!customer) {
                                  return;
                              }

                              this.sharedService.customerSubject.next(customer);
                              this.userRouteService.setUserGuards();

                              if (customer.Status === 11) {
                                  await this.router.navigate(['/account-suspended']);
                                  return;
                              }

                              // await this.handleFirstLogon();
                              // this.betaFeatureService.checkBetaPreferences(customer?.CustomerId);
                              this.checkBIR();
                              this.setCurrentLanguage(customer);
                          },
                      );

            this.supportScriptService.loadUserSupportScript(user);

            // this.isInProgress = false;
        }
    */

    private handleUserInfo(user: User): void {
        this.user = user;
        // this.sharedService.userSubject.next(user);
        this.supportScriptService.loadUserSupportScript(user);
    }

    private handleCustomerInfo(customer: Customer): void {
        // this.sharedService.customerSubject.next(customer);
    }

    private checkProductTermsConditions(): void {
        this.termsService.getCustomerProductTermsConditions()
            .pipe(
                catchError((err) => {
                    this.notificationService.notify(
                        err?.Message,
                        'Failed Saving Data',
                        NotificationType.ERROR,
                    );

                    return EMPTY;
                }),
                filter(terms => !!terms?.length),
                switchMap(terms => {
                    return this.termsDialogService.open(terms[0])
                               .pipe(
                                   map(isAccepted => ({ isAccepted, latestTerms: terms[0] })),
                               );
                }),
                filter(res => !!res?.isAccepted),
                map((res) => ({ latestTerms: res.latestTerms })),
                switchMap(res => this.termsService.saveCustomerProductTermsConditions(res?.latestTerms?.productTermsId)),
            )
            .subscribe(
                (res) => {
                    if (res) {
                        return;
                    }

                    this.notificationService.notify(
                        'Failed saving terms acceptance. Please contact administrator.',
                        'Failed Saving Data',
                        NotificationType.ERROR,
                    );
                },
            );
    }

    private setCurrentLanguage(customer: Customer): Observable<unknown> {
        const customerLanguage = this.customerService.getCustomerLanguage(customer);

        return forkJoin([
            this.storage.set('lang', customerLanguage),
            this.storage.set('site-locale', customerLanguage),
        ]).pipe(
            tap(() => {
                this.storageService.set('lang', customerLanguage);
                this.storageService.set('site-locale', customerLanguage);
                this.translationService.changeLanguage(customerLanguage);
            }),
        );
    }

    private handleBIRError(err): Observable<never> {
        console.error('Error Fetching BIR Exceptions', err);
        this.router.navigate(['dashboard']).then();
        return EMPTY;
    }

    private async navigate(): Promise<void> {
        this.redirect = this.route.snapshot.fragment;
        if (this.redirect) {
            await this.router.navigate([this.redirect]);
        } else if (this.authenticationService.redirectUrl) {
            await this.router.navigate([this.authenticationService.redirectUrl]);
            this.authenticationService.redirectUrl = null;
        } else {
            await this.router.navigate(['dashboard']);
        }
    }
}
