<div class="recent-uploads-table-dialog">
    <div class="dialog-header" [ngClass]="{'processing' : scoreHistoryData?.length === 0 }">
        <h2 mat-dialog-title>{{ fileName }}</h2>
        <button type="button" class="close-icon" mat-dialog-close tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <span *ngIf="scoreHistoryData?.length === 0" class="processing-records-message">Records are still being processed.</span>

    <upsc-dd-score-history-table [lastColumnName]="'Access Point Location'" [componentName]="'Recent Upload'" [tableName]="'Scored Addresses'" [addressUploadsData]="scoreHistoryData" *ngIf="scoreHistoryData.length > 0"></upsc-dd-score-history-table>
</div>