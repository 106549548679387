
<button class="tab-trap" tabindex="0"></button>


<div class="dialog-content">
    <div *ngIf="!subscribed" class="content">
        <div class="title">
            <h1 *ngIf="!subscribed">Confirm your subscription</h1>
            <h1 *ngIf="subscribed">Welcome Aboard</h1>
            <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
            <div class="separtor"> </div>
        </div>
        
        <div class="complementaryPanel">

            <div *ngIf="this.data.monthlyTierType == 1 || this.data.monthlyTierType == null" class="cmplmry-section">
                <div class="option-card">
                    <div class="radio-button">
                        <img class="Radio" src="../../../assets/icons/Radio.svg" alt="Radio">
                    </div>
                    <div class="card-info">
                        <div class="card-title">DeliveryDefense™ Address Confidence</div>
                        <div class="card-details">
                            <div class="pricing">{{this.data.cardScore}}</div>
                            <div class="renewal">Auto-Renew <span [innerHTML]="data.date"></span></div>
                        </div>
                    </div>
                </div>
            </div>

            @if (false) {
            <div *ngIf="this.data.monthlyTierType == 1 || this.data.monthlyTierType == null" class="cmplmry-section">
                <div class="row section">

                    <div class="col-sm-4 sub-section" style="padding: 16px 24px 15px 36px;">
                        <h3>Payment Method</h3>
                        <h4>Billed in your monthly invoice</h4>
                    </div>

                </div>
            </div>
            }

            <div *ngIf="this.data.monthlyTierType != 1 &&  this.data.monthlyTierType != null"
                class="lvlcmplmry-section">
                <div class="option-card">
                    <div class="radio-button">
                        <img class="Radio" src="../../../assets/icons/Radio.svg" alt="Radio">
                    </div>
                    <div class="card-info">
                        <div class="card-title">
                            <div *ngIf="this.data.monthlyTierType != 1">{{this.data.cardHeader}}</div>
                        </div>
                        <div class="card-details">
                            <div>{{this.data.cardScore}}</div>
                            <div class="renews">Auto-Renew <span [innerHTML]="data.date"></span></div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="agreement">
                <h3 *ngIf="this.data.monthlyTierType != 1 &&  this.data.monthlyTierType != null">
                    You are eligible for DeliveryDefense™ Address Confidence High Volume Program
                    {{this.data.programNumber}}*. Cancel any time.
                </h3>
                <div *ngIf="this.data.monthlyTierType != 1 &&  this.data.monthlyTierType != null"
                    class="agreementLabel">
                    <h1 class="label">*Must maintain shipment volume of {{this.data.shipmentCount}}
                        shipments per month through <a href="http://parcelpro.com/" target="_blank"> ParcelPro.com
                        </a> to qualify for this offering</h1>

                </div>
                <div *ngIf="this.data.monthlyTierType == 1 || this.data.monthlyTierType == null" class="agreementPanel">
                    @if (false) {
                    By clicking "Start Subscription," you agree that we may charge you $19.99/month plus applicable
                    taxes to your account on file. To avoid being charged, you must cancel before your 30-day
                    <span *ngIf="isFromTrialTier">window</span>
                    <span *ngIf="!isFromTrialTier">free trial period</span>
                    ends.
                    The monthly charges will automatically recur until you cancel.
                    You may cancel anytime via the DeliveryDefense™ Address Confidence Manage Subscription page.
                    }
                </div>
                <p>

                    <mat-checkbox color='primary' [(ngModel)]='isChecked' class="panel">
                        <h1 *ngIf="this.data.monthlyTierType == 2 || this.data.monthlyTierType == 3 ||
                         this.data.monthlyTierType == 4 || this.data.monthlyTierType == 5 ">
                            I agree to the <a href="https://www.insureshield.com/us/en/legal/deliverydefense-address-confidence-tool-terms-conditions.html" target="_blank">DeliveryDefense™ Address
                                Confidence Terms & Conditions. </a>
                            Your subscription will automatically renew if you maintain your monthly shipping volume. If
                            you do not meet the monthly shipping volume, your plan will be downgraded. If you do not
                            want to continue your subscription, you can cancel any time.
                        </h1>

                        <h1 *ngIf="this.data.monthlyTierType == 1 || this.data.monthlyTierType == null">I agree to the
                            <a href="https://www.insureshield.com/us/en/legal/deliverydefense-address-confidence-tool-terms-conditions.html"
                               target="_blank">
                                DeliveryDefense™ Address
                                Confidence Terms & Conditions.</a>
                            Your subscription will automatically renew. If you do not want to continue your
                            subscription, you can cancel any time.
                        </h1>

                    </mat-checkbox>


                </p>


            </div>
            <button type="submit" mat-raised-button [disabled]="!isChecked" (click)="startSubscribe()">Start
                Subscription</button>
       
            </div>
    </div>
   
        <div *ngIf="subscribed" class="subscribedPanel">
            <div class="title">
                <h1>Welcome Aboard</h1>
                <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
                    <mat-icon>close</mat-icon>
                </button>
                <div class="separtor"> </div>
            </div>
           
            <div class="confirmedLabel">
                You are now successfully opted into DeliveryDefense™ Address Confidence.
            </div>
            <button class="startNowBtn"  type="submit" mat-raised-button [disabled]="!isChecked"
                (click)="startSubscribeNow()">Start Shipping
                Now</button>
            
        </div>
  
</div>
