export interface IRecaptchaSiteVerifyResponse {
    success: boolean; // whether this request was a valid reCAPTCHA token for your site
    score: number; // the score for this request (0.0 - 1.0)
    action: string; // the action name for this request (important to verify)
    challenge_ts: string; // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
    hostname?: string; // the hostname of the site where the reCAPTCHA was solved
    apk_package_name?: string; // the package name of the Android app where the reCAPTCHA was solved
    'error-codes'?: string[]; // optional
}

export const ReCaptchaErrorCode: { [key: string]: string } = {
    'missing-input-secret': 'The secret parameter is missing.',
    'invalid-input-secret': 'The secret parameter is invalid or malformed.',
    'missing-input-response': 'The token is missing.',
    'invalid-input-response': 'The token is invalid or malformed.',
    'bad-request': 'The request is invalid or malformed.',
    'timeout-or-duplicate': 'The token is no longer valid: either is too old or has been used previously.',
};
