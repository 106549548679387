<div class="login-jwt">
    <div class="login-content-wrapper-v2">
        <div class="login-content-items login-form">
            <div class="login-content-item sign-in">
                <div class="logo">
                    <upsc-logo></upsc-logo>
                </div>
                <h1 class="page-title">{{ 'SignIn' | translate }}</h1>
                <form (ngSubmit)="onSubmit($event, formGroup.value)"
                      [formGroup]="formGroup"
                      class="ups-form">
                    <div class="form-body">
                        <mat-form-field>
                            <mat-label>{{ 'Username' | translate }}</mat-label>
                            <input formControlName="userName"
                                   matInput
                                   maxlength="30"
                                   required
                                   type="text">
                            <mat-error *ngIf="formGroup.controls.userName.errors?.required">
                                <span *ngIf="formGroup.controls.userName.errors?.required">
                                    {{ 'userNameRequired' | translate }}
                                </span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'Password' | translate }}</mat-label>
                            <input autocomplete="off"
                                   formControlName="password"
                                   matInput
                                   maxlength="100"
                                   required
                                   type="password">
                            <mat-error *ngIf="formGroup.controls.password.errors?.required">
                        <span *ngIf="formGroup.controls.password.errors?.required">
                            {{ 'PasswordRequired' | translate }}
                        </span>
                            </mat-error>
                        </mat-form-field>

                        <div *ngIf="false" class="form-options">
                            <div class="form-option-group">
                                <mat-checkbox formControlName="isRememberMe">{{ 'RememberMe' | translate }}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form-footer">
                        <button [disabled]="false" color="primary" mat-raised-button type="submit">
                            {{ 'SignIn' | translate }}
                        </button>

                        <a href="#" routerLink="/forgot-password">{{ 'ForgetPassword' | translate }}</a>

                        <!-- We need this message if we choose to hide the reCAPTCHA badge! -->
                        <small class="recaptcha-notice">{{ 'SignInPage.recaptchaNotice' | translate }}
                            <a href="https://policies.google.com/privacy">{{ 'SignInPage.recaptchaPrivacyPolicy' | translate }}</a> {{ 'SignInPage.recaptchaAnd' | translate }}
                            <a href="https://policies.google.com/terms">{{ 'SignInPage.recaptchaTermsOfService' | translate }}</a> {{ 'SignInPage.recaptchaApply' | translate }}
                        </small>
                    </div>
                </form>
            </div>
            <div class="login-content-item signup">
                <h1 class="page-title">{{ 'SignInPage.DontHaveAccount' | translate }}</h1>

                <div *ngIf="shouldShowSignUp" class="create-account">
                    <button class="alternative-button" mat-raised-button routerLink="/sign-up" type="button">
                        {{ 'SignInPage.CreateAccount' | translate }}
                    </button>
                </div>

                <div *ngIf="!shouldShowSignUp" class="create-account">
                    <a class="alternative-button"
                       href="{{ contactUsLink }}"
                       mat-raised-button>{{ 'ContactUs' | translate }}</a>
                </div>

                <div *ngIf="!isEUUser && shouldShowSignUp" class="trouble">
                    <span [innerHtml]="'SignInPage.Trouble' | translate"></span>
                    <span [innerHtml]="'SignInPage.TroubleSolution' | translate: { tel: supportTel }"></span>
                </div>
            </div>
        </div>

        @if (isParcelProDomain) {
            <div class="login-content-items">
                <img ngSrc="/assets/images/white-parcel-pro-diamond-graphic-2.svg"
                     class="parcelpro-diamond"
                     width="1441"
                     height="1392"
                     alt="parcelpro.com"
                     priority>
            </div>
        }
    </div>
    <div class="login-footer">
        <upsc-login-footer [content]="footerContent$ | async"></upsc-login-footer>
    </div>
</div>
