import { Component, OnInit, ViewChild } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import dayjs from 'dayjs';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { saveAs } from 'file-saver';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
import { IRecentUploadsInterface } from './IRecentUploads.interface';
import { IPostRecentUploadsInterface } from '../interfaces/IPostRecentUploads.interface';
import { IGetAddressUploadInterface } from '../interfaces/IGetAddressUpload.interface';
import { RecentUploadPopupComponent } from './recent-upload-popup/recent-upload-popup.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IScoreHistory } from 'app/delivery-defense/dd-score-history/scoreHistory.interface';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { Subject } from 'rxjs';
import { IReviewRecentUploadInterface } from '../interfaces/IReviewRecentUpload.interface';
import { RecentUploadStatusPipe } from "app/history/pipes/recent-upload-status.pipe";
import { IRecentUploadSignalRInterface } from './recent-upload-live-status-check/IRecentUploadSignalR.interface';
import { UserService } from 'app/shared/services/user/user.service';


@Component({
    selector: 'upsc-recent-uploads',
    templateUrl: './recent-uploads.component.html',
    styleUrls: ['./recent-uploads.component.scss'],
})

export class RecentUploadsComponent implements OnInit {
    public tableHeaders = ['Scored Address Review', 'Download', 'Imported On', 'Expires In', 'Import Status']
        .map(value => ({
            label: value,
        }));
    public recentUploadsData: IRecentUploadsInterface[] = [];
    public isLoadingData = true;

    public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 5 };
    public pageIndex = 1;
    public pageSize = 100;

    public startDateFormControl = new FormControl<Date | null>({ value: null, disabled: true});
    public endDateFormControl = new FormControl<Date | null>({ value: null, disabled: true});
    public minDate: Date;
    public maxDate: Date;
    public minEndDate: Date;

    private ngUnsubscribe = new Subject();
    public refreshingUploadStatus: boolean = false;
    public showRefreshButtons: boolean = false;
    private customerId: string = "";

    @ViewChild('startDateInput', {
        read: MatInput,
    }) startDateInput: MatInput;

    @ViewChild('endDateInput', {
        read: MatInput,
    }) endDateInput: MatInput;


    constructor(
        private deliveryDefenseService: DeliveryDefenseService,
        private dialog: MatDialog,
        private spinnerService: SpinnerService,
        private recentUploadStatusPipe: RecentUploadStatusPipe,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.userService.getUser()
            .subscribe((response) => {
                this.customerId = response?.CustomerId;
            })
        this.setDateFilters();
        this.getRecentUploadsData();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    }

    private setDateFilters() {
        // Minimum dates can be no longer than 30 days ago
        this.minDate = new Date();
        this.minDate.setDate(new Date().getDate() - 30);

        this.minEndDate = new Date();
        this.minEndDate.setDate(new Date().getDate() - 30);

        this.maxDate = new Date();  // maxDate will be today's date (applies to both date pickers)
    }

    public paginationChange(paginationDetails) {
        this.pageIndex = paginationDetails.pageIndex;
        this.pageSize = paginationDetails.pageSize;

        this.paginate.currentPage = this.pageIndex;
        this.paginate.itemsPerPage = this.pageSize;
    }

    public getExpiresInDate(searchDate: string): number {
        const currentDate = dayjs();
        const parsedSearchDate = dayjs(searchDate, { format: 'YYYY-MM-DD HH:mm:ss' });
        const daysPassed = currentDate.diff(parsedSearchDate, 'day');
        return 30 - daysPassed;
    }

    public updateMinEndDate(event: any) {
        // Clear end date value if current endDateInput is less than previous minEndDate
        const date1 = new Date(this.endDateInput.value);
        const date2 = new Date(event.value);

        if (date2 > date1) {
            this.endDateInput.value = '';
        }
        this.minEndDate = new Date(event.value);
    }

    public resetDateFilters() {
        // Reset the forms
        this.startDateFormControl.reset();
        this.endDateFormControl.reset();

        // Reset custom variables and values
        this.setDateFilters();
        this.startDateInput.value = '';
        this.endDateInput.value = '';

        this.resetGrid();
    }

    public applyDateFilters() {
        if (this.recentUploadsData?.length < 2) {
            return;
        }

        // Both startDate and endDate are required
        if (this.startDateInput.value && this.endDateInput.value) {
            const fromDate = new Date(this.startDateInput.value);
            const fromDateString = fromDate.toISOString().split('T')[0];

            const toDate = new Date(this.endDateInput.value);
            const toDateString = toDate.toISOString().split('T')[0];

            // Reset uploads data in grid
            this.recentUploadsData = [];
            this.getRecentUploadsData(fromDateString, toDateString);
        }
    }

    private getRecentUploadsData(fromDate?: string, toDate?: string) {
        this.isLoadingData = true;

        this.deliveryDefenseService.getRecentUploads(fromDate, toDate)
            .subscribe(
                response => {
                    const data = response as Array<IPostRecentUploadsInterface>;
                    
                    for (let i = 0; i < data?.length; i++) {
                        const recentUpload = {
                            ScoredAddressReviewFileName: data[i]?.BatchName,
                            IsDownload: data[i]?.BatchStatus === 1 ? true : false,     
                            ImportedOnDate: data[i]?.BatchUploadDate,
                            ExpiresInDate: this.getExpiresInDate(data[i]?.BatchUploadDate.toString()),
                            ImportStatus: this.getRecentUploadStatus(data[i]).toUpperCase(),
                            BatchId: data[i]?.BatchId
                        }
                        this.recentUploadsData.push(recentUpload);

                        if (data[i]?.BatchStatus == 2) {
                            this.refreshRecentUploadStatus(recentUpload);
                        }
                    }
                    this.paginate.totalItems = +this.recentUploadsData.length || 0;
                    
                    setTimeout(() => {
                        this.isLoadingData = false;
                    }, 2000);
                }, 
                error => {
                    console.error(error);
                    this.isLoadingData = false;
                }
            );
    }

    private resetGrid() {
        // Reset uploads data in grid
        this.recentUploadsData = [];
        this.getRecentUploadsData();
    }

    public downloadFile(index: number) {
        const recordIndex = index + ((this.pageIndex - 1) * this.paginate.itemsPerPage);

        this.deliveryDefenseService.getAddressUploadDownload(this.recentUploadsData[recordIndex]?.BatchId)
            .subscribe(
                response => {
                    const responseData = response as Array<IGetAddressUploadInterface>;

                    // Extract only address, city, state, zip, and score
                    const data = [];
                    for (let i = 0; i < responseData?.length; i++) {
                        data.push({
                            StreetAddress: responseData[i]?.StreetAddress,
                            City: responseData[i]?.City,
                            State: responseData[i]?.State,
                            Zip: responseData[i]?.Zip,
                            Score: responseData[i]?.Score
                        });
                    }

                    const header = Object.keys(data[0]).join(',') + '\n';
                    const rows = data.map(obj => Object.values(obj).join(',')).join('\n');
                    const csv = header + rows;

                    const fileName = this.recentUploadsData[recordIndex]?.ScoredAddressReviewFileName;
                    const fileType = /(?:\.([^.]+))?$/.exec(fileName)[1];

                    const blob = new Blob([csv], { type: `text/${fileType};charset=utf-8` });
                    saveAs(blob, fileName);
                }, 
                error => {
                    console.error(error);
                }
            )
    }

    public openRecentUpload(index: number) {
        const recordIndex = index + ((this.pageIndex - 1) * this.paginate.itemsPerPage);
        const recentUpload = this.recentUploadsData[recordIndex];

        if (['EXPIRED', 'VOIDED'].includes(recentUpload?.ImportStatus)) {
            return;
        }

        // Open review page so user can correct records and finalize submission
        if (recentUpload?.ImportStatus === 'DRAFT') {
            this.spinnerService.show();
            this.deliveryDefenseService.getAddressUpload(recentUpload?.BatchId)
                .subscribe(
                    response => {
                        const recentUploadData: IReviewRecentUploadInterface = {
                            Filename: recentUpload?.ScoredAddressReviewFileName,
                            BatchId: recentUpload?.BatchId,
                            AddressUploads: response as IGetAddressUploadInterface[],
                        };
                        this.deliveryDefenseService.reviewingRecentUpload$.next({ data: recentUploadData });
                        this.spinnerService.hide();
                    }, 
                    error => {
                        this.spinnerService.hide();
                        console.error(error);
                    }
                );

            return;
        }

        if (recentUpload?.ImportStatus === 'PROCESSING') {
            // Show popup with message "Records are still being processed."
            const dialogConfig: MatDialogConfig = {
                disableClose: true,
                width: '75em',
                data: {
                    recentUploadData: recentUpload,
                    scoreHistory: [],
                },
            };
            this.dialog.open(RecentUploadPopupComponent, dialogConfig);
            return;
        }

        const scoreHistoryData: IScoreHistory[] = [];
        this.deliveryDefenseService.getAddressUpload(recentUpload?.BatchId)
            .subscribe(
                response => {
                    const addressUploadData = response as Array<IGetAddressUploadInterface>;                    
                    addressUploadData.forEach((address: IGetAddressUploadInterface) => {
                        scoreHistoryData.push({
                            StreetAddress: address?.StreetAddress,
                            ApartmentSuite: null,
                            City: address?.City,
                            State: address?.State,
                            ZipCode: address?.Zip,
                            SearchDate: address?.SearchDate,
                            Score: address?.Score,
                            Charge: -1,
                            DDSearchStatus: address?.SearchStatus,
                            IsLookUpTool: 2,
                            CarrierCode: 1,
                            IsHoldAtLocation: true,
                            HoldAtAddress1: '',
                            HoldAtAddress2: '',
                            HoldAtCity: '',
                            HoldAtState: '',
                            HoldAtZip: '',
                            HoldAtCountry: '',
                            HoldAtLocationID: '',
                            ShipmentId: null,
                            TotalItems: addressUploadData?.length.toString(),
                        });
                    });

                    const dialogConfig: MatDialogConfig = {
                        disableClose: true,
                        width: '75em',
                        data: {
                            recentUploadData: recentUpload,
                            scoreHistory: scoreHistoryData,
                        },
                    };
                    const dialogRef: MatDialogRef<RecentUploadPopupComponent> = this.dialog.open(RecentUploadPopupComponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(
                        (result) => {
                            if (!result) {
                                return;
                            }
                        }
                    );
                },
                error => {
                    console.error(error);
                }
            );
    }

    public onClickRefreshRecentUpload(index: number) {
        const recordIndex = index + ((this.pageIndex - 1) * this.paginate.itemsPerPage);
        const recentUpload = this.recentUploadsData[recordIndex];
        this.refreshRecentUploadStatus(recentUpload);
    }

    private refreshRecentUploadStatus(recentUpload: IRecentUploadsInterface): void {
        // Call PUT deliverydefense/address-upload to check for recent upload status
        this.refreshingUploadStatus = true;
        this.deliveryDefenseService.putAddressUpload({ CustomerId: this.customerId, BatchId: recentUpload?.BatchId })
            .subscribe(
                response => {
                    setTimeout(() => {
                        if (response === "All records are finished processing.") {
                            recentUpload!.ImportStatus = this.recentUploadStatusPipe.transform(1).toUpperCase();
                            recentUpload.IsDownload = true;
                        }
                        this.refreshingUploadStatus = false;
                    }, 2000);
                },
                error => {
                    console.error(error);
                    this.refreshingUploadStatus = false;
                }
            );
    }

    public onMessageReceived(data: IRecentUploadSignalRInterface) {
        // SignalR service failed to listen
        if (data?.Status == -1) {
            // Show manual refresh buttons
            this.showRefreshButtons = true;
        }
        // Successfully uploaded batch to tables
        else if (data?.Status == 1) {
            this.showRefreshButtons = false;
            const recentUpload = this.recentUploadsData.find(obj => obj?.BatchId.toUpperCase() === data?.BatchId.toUpperCase());

            if (recentUpload) {
                // this.refreshRecentUploadStatus(recentUpload);

                setTimeout(() => {
                    recentUpload!.ImportStatus = this.recentUploadStatusPipe.transform(1).toUpperCase();
                    recentUpload.IsDownload = true;
                    this.refreshingUploadStatus = false;
                }, 3000);
            }
        }
    }

    private getRecentUploadStatus(recentUpload: IPostRecentUploadsInterface): string {
        const status = this.recentUploadStatusPipe.transform(recentUpload?.BatchStatus);
        return status;
    }
}
