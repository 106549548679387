import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaymentHistoryService } from '../services/payment-history.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { TranslateService } from '@ngx-translate/core';
import { PaginationInstance } from 'ngx-pagination';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { SiteConfig } from '../../../config/site-config';
import { IPaymentHistoryItem } from './models/payment-history-item.interface';
import * as _ from 'lodash';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'upsc-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent implements OnInit, OnDestroy {
  public paymentHistoryList: IPaymentHistoryItem[] = [];
  public noRecordsFound = false;
  public paymentReceipt: any;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  // Pagination
  public paginate: PaginationInstance;
  public tablePageSize = 25;
  public tablePageIndex = 0;
  public isDataLoading = false;
  private totalItems = 0;

  private getPaymentHistorySubscription: Subscription;

  @ViewChild('paymentReceiptModal') paymentReceiptModal: ElementRef;

  constructor(private paymentHistoryService: PaymentHistoryService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private utilityService: UtilityService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    @Inject(LOCALE_ID) public localeId: string) {

    this.translateService.onLangChange.subscribe(() => {
      this.updateDTOptionsLanguage();
    })

    this.getPaymentHistory();
    
  }

  public ngOnInit() {
    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language =  {
        emptyTable: this.translateService.instant('dashboard.noShipments'),
      }
    
    this.updatePaginateConfig();
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([this.getPaymentHistorySubscription]);
  }

  public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
        emptyTable: this.translateService.instant('dashboard.noShipments')
      };
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        if(this.totalItems === 0) {
          dtInstance.destroy();
        }
        // Call the dtTrigger to rerender again
        this.dtTrigger.next(null);
    });
}

  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  private getPaymentHistory(pageNumber = 1) {
    this.utilityService.clearSubscriptions([this.getPaymentHistorySubscription]);

    this.isDataLoading = true;
    this.getPaymentHistorySubscription = this.paymentHistoryService.getPaymentHistory(pageNumber.toString(), this.tablePageSize.toString())
      .subscribe(
        (paymentHistory) => {
          
          ////const groupedList = _.groupBy(paymentHistory, 'TransactionID');
          const groupedList = _.groupBy(paymentHistory, 'PaymentID');
          this.paymentHistoryList = _.map(groupedList, function (item: any) {
            let invoiceId = '';

            if (item.length > 1) {
              _.map(item, function (invoiceItem) {
                invoiceId += invoiceItem.InvoiceId + ','
              })
              invoiceId = invoiceId.slice(0, -1);
            }
            item = item[0];
            item.InvoiceId = invoiceId != '' ? invoiceId : item.InvoiceId;
            return item;
          });

          if (paymentHistory && paymentHistory.length) {
            this.totalItems = +paymentHistory[0].TotalPayments;
            this.tablePageIndex = pageNumber;

            this.updatePaginateConfig();
          } else {
            this.paymentHistoryList = [];
          }

          this.dtTrigger.next(null);
          this.isDataLoading = false;
        },
        (err) => {
          this.dtTrigger.next(null);
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('FetchingPaymentHistoryFailed_msg'),
            NotificationType.ERROR);
          this.isDataLoading = false;
        });
  }

  getPaymentReceipt(paymentId: string) {
    this.paymentHistoryService.getPaymentReceipt(paymentId).subscribe(
      (response) => {
        this.paymentReceipt = response;
        this.openPaymentReceipt();
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('FetchingPaymentReceiptFailed_msg'),
          NotificationType.ERROR);
      })
  }

  openPaymentReceipt() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        paymentReceipt: this.paymentReceipt,
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    let dialogRef: MatDialogRef<PaymentReceiptComponent>;
    dialogRef = this.dialog.open(PaymentReceiptComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  openInvoiceList(invoiceId: string) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        invoiceIds: invoiceId,
      },
    };

    let dialogRef: MatDialogRef<InvoiceListComponent>;
    dialogRef = this.dialog.open(InvoiceListComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  public onPageChanged(pageNumber: number) {
    this.getPaymentHistory(pageNumber);
  }

  public getStatusText(paymentHistoryItem: IPaymentHistoryItem): string {
    switch (paymentHistoryItem.PaymentMethod) {
      case 1:
        switch (paymentHistoryItem.ResponseCode) {
          case '1':
            switch (paymentHistoryItem.TransactionType) {
              case '1':
                return this.translateService.instant('Approved');
              case '2':
                return this.translateService.instant('Voided');
              case '3':
                return this.translateService.instant('Refunded');
              default:
                return 'N/A';
            }
          case '2':
            return this.translateService.instant('Declined');
          case '3':
            return this.translateService.instant('validationError');
          default:
            return 'N/A';
        }
      default:
        return paymentHistoryItem.IsVoided ? this.translateService.instant('Voided') : this.translateService.instant('Approved');
    }
  }

  public getStatusCssClass(paymentHistoryItem: IPaymentHistoryItem): string {
    const statusText = this.getStatusText(paymentHistoryItem);
    return _.kebabCase(statusText);
  }

  public getStatusTooltipText(paymentHistoryItem: IPaymentHistoryItem): string {
    return paymentHistoryItem.ResponseReasonCodeText.replace(/(<br>|<br\/>|<br >|<br \/>)/gi, '\n');
  }
}
