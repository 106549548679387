import { Component, HostBinding, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TermsConditionsStaticComponent } from '../terms-conditions-static.component';

@Component({
    selector: 'upsc-terms-conditions-dialog',
    templateUrl: './terms-conditions-static-dialog.component.html',
    styleUrls: ['./terms-conditions-static-dialog.component.scss'],
})
export class TermsConditionsStaticDialogComponent {
    @HostBinding('class') public hostClass = 'terms-conditions-static-dialog';

    public constructor(public dialogRef: MatDialogRef<TermsConditionsStaticDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: unknown,
    ) {
    }
}
