import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class CreditHistoryService {

  constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) { }

  /**
   * Gets credit history.
   *
   * @param {string} pageIndex
   * @param {string} pageSize
   * @returns {Observable<any>}
   */
  public getCreditHistory(pageIndex?: string, pageSize?: string, fromDate?: string, toDate?: string): Observable<any> {
    const params = {};
    if (pageIndex) {
      params['page'] = pageIndex;
    }

    if (pageSize) {
      params['per_page'] = pageSize;
    }

    if (fromDate) {
      params['from'] = fromDate;
    }

    if (toDate) {
      params['to'] = toDate;
    }

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getCreditHistory'))
      .query(params)
      .toString();
    return this.httpClientService.get<any>(endpoint);
  }
}
