<div class="credit-history white-content-page">
    <upsc-page-header [pageTitle]="'siteMenu.billing.creditHistory' | translate"></upsc-page-header>
    <div class="ups-content-block content-actions">
        <div class="left-actions">
            <div class="notice">
              <span>
                  {{'TotalRemainingCredit'|translate}}: <strong>{{totalRemainingCredit | userLocaleNumber:user}}
                  {{currencyCode}}</strong>
              </span>
            </div>
        </div>
        <div class="right-actions">

        </div>
    </div>
    <div class="ups-content-block content-actions">
        <div class=" left-actions">
            <upsc-date-range-selector
                [customDateRangeFormGroup]="customDateRangeFormGroup"
                [formattedStartDate]="formattedStartDate"
                [formattedEndDate]="formattedEndDate">
            </upsc-date-range-selector>
        </div>
    </div>
    <div [class.responsive-table]="dtOptions.responsive" class="table-wrapper">
        <table [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" [hidden]="!creditHistoryData" class="stripe" datatable>
            <thead>
            <tr>
                <th class="all trigger-column hide-me"></th>
                <th class="text-center">
                    {{'InvoiceId'|translate}}
                </th>
                <th class="text-center">
                    {{'IssuedAmount'|translate}}
                </th>
                <th class="text-center">
                    {{'IssuedDate'|translate}}
                </th>
                <th class="text-center all">
                    {{'CreditType'|translate}}
                </th>
                <th class="text-center">
                    {{'RemainingAmount'|translate}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of creditHistoryData | paginate:paginate">
                <td class="trigger-cell hide-me"></td>
                <td class="text-center"> {{item.InvoiceId }} </td>
                <td class="text-center"> {{item.IssuedAmount}} </td>
                <td class="text-center"> {{item.IssuedDate | date : this.tableDateFormat}} </td>
                <td class="text-center"> {{item.CreditType | creditType }} </td>
                <td class="text-center"> {{item.RemainingBalance}} </td>
            </tr>
            </tbody>
        </table>

        <pagination-controls (pageChange)="onPageChanged($event)" [autoHide]="true" [directionLinks]="true" [nextLabel]="''"
                             [previousLabel]="''"></pagination-controls>
        <upsc-element-blocker *ngIf="isDataLoading"></upsc-element-blocker>
    </div>
</div>
