<main class="main-layout">
    <header>
        <h2 class="section-header"> {{ data.title }} </h2>
    </header>

    <div class="body-container">
        <p> {{ data.message }} </p>
        <div class="cancel-or-submit-container">
            <button mat-stroked-button color="primary" (click)="noBtn()">
                <span class="material-symbols-outlined">chevron_left</span>
                <span> {{ data.noMessage }}</span>
            </button>
            <button mat-flat-button color="primary" (click)="yesBtn()">
                <span> {{ data.yesMessage }} </span>
                <span class="material-symbols-outlined">chevron_right</span>
            </button>
        </div>
    </div>
</main>

