import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DefaultTabIndex } from 'app/account/models/defaultTabIndex.model';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'upsc-report-shipments-upload',
  templateUrl: './report-shipments-upload.component.html',
  styleUrls: ['./report-shipments-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportShipmentsUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTabGroup) public tabGroup: MatTabGroup;
  @ViewChild('tabGroup', { read: ElementRef }) public tabGroupElement: ElementRef;
  public defaultTabIndex: number = 0;
  public isCheckboxChecked: boolean = false;
  public checkboxLeftPosition = 0;
  private user: User;
  private langChangeSubscription: Subscription;

  public constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly router: Router,
  ) { }

  public ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.user = data.user;
      if (this.user) {
        this.defaultTabIndex = this.user.DefaultUploadTabIndex;
        if (this.tabGroup) {
          this.tabGroup.selectedIndex = this.defaultTabIndex;
        }

        this.isCheckboxChecked = true;
      }
    });

    this.langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      // Use setTimeout to wait for the view to update
      setTimeout(() => this.updateCheckboxPosition(), 0);
    });
  }

  public ngAfterViewInit(): void {
    if (this.tabGroup) {
      this.tabGroup.selectedIndex = this.defaultTabIndex;
      const tabHeaderElement = this.tabGroupElement.nativeElement.querySelector('.mat-mdc-tab-header');
      const tabsWidth = tabHeaderElement.offsetWidth;
      this.checkboxLeftPosition = tabsWidth + 10;
    }
  }

  public ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  public onTabChange(index: number): void {
    this.isCheckboxChecked = index === this.defaultTabIndex;
  }

  public onCheckboxChange(): void {
    this.defaultTabIndex = this.tabGroup.selectedIndex;
    this.isCheckboxChecked = true;
    const defaultTabIndex = new DefaultTabIndex(this.defaultTabIndex);
    this.userService.updateDefaultTabIndex(defaultTabIndex).subscribe();
  }

  private updateCheckboxPosition(): void {
    if (this.tabGroupElement) {
      const tabHeaderElement = this.tabGroupElement.nativeElement.querySelector('.mat-mdc-tab-header');
      const tabsWidth = tabHeaderElement.offsetWidth;
      this.checkboxLeftPosition = tabsWidth + 10; // Adjust spacing as needed
    }
  }

  public routeToShipmentHistory():void{
    this.router.navigate(['report-history']);
  }
}
