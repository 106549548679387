<div class="required">
    * Required Fields
</div>
<div class="page-title">
    1. Business Information
</div>
<div class="business-information">
    <form class="ups-form" [formGroup]="businessInformationFormGroup">
        <div class="company-info">
            <div class="business-location">
                <label>What country/territory is your business located?*</label>
                <mat-form-field appearance="outline">
                    <mat-label>Select Country/Territory</mat-label>
                    <mat-select placeholder="Select Country/Territory" formControlName="businessLocation">
                        <mat-option *ngFor="let country of signUpDropdowns.countries" [value]="country.code">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="businessInformationFormGroup.controls.businessLocation?.errors">
                        <span *ngIf="businessInformationFormGroup.controls.businessLocation?.errors?.required">
                            Business Location is required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="business-location">
                <label>How did you hear about Parcel Pro?</label>
                <mat-form-field appearance="outline">
                    <mat-label>Select One</mat-label>
                    <mat-select placeholder="Select One" formControlName="howDidYouHear">
                        <mat-option *ngFor="let item of signUpDropdowns.knowledgeSource" [value]="item">{{item}}
                        </mat-option>
                    </mat-select>                    
                </mat-form-field>

                <mat-form-field *ngIf="isSalesNameSelected" appearance="outline">
                    <mat-label>Select a Sales Person</mat-label>
                    <mat-select required placeholder="Select a Sales Person" formControlName="salesName" [(value)]="selectedSalesPersonName">
                        <mat-option *ngFor="let salesPerson of salesPersons" [value]="salesPerson?.name">{{salesPerson?.name}}
                        </mat-option>
                    </mat-select>   
                    <mat-error *ngIf="businessInformationFormGroup.controls.salesName?.errors">
                        <span *ngIf="businessInformationFormGroup.controls.salesName?.errors?.required">
                            Sales Person is required.
                        </span>
                    </mat-error>                 
                </mat-form-field>
            </div>


            <div class="which-one" *ngIf="businessInformationFormGroup.controls.howDidYouHear?.value=='Other'">
                <mat-form-field appearance="outline">
                    <mat-label>Please provide</mat-label>
                    <input matInput
                        [required]="businessInformationFormGroup.controls.howDidYouHear?.value=='Other'?true:null"
                        formControlName="howDidYouHearOther" maxlength="200" autocomplete="off">
                    <mat-error *ngIf="businessInformationFormGroup.controls.howDidYouHearOther?.errors">
                        <span *ngIf="businessInformationFormGroup.controls.howDidYouHearOther?.errors?.required">
                            Other is required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Select your affiliation</mat-label>
                <mat-select required placeholder="Select your affiliation" formControlName="affilication">
                    <mat-option *ngFor="let affiliation of signUpDropdowns.affiliations" [value]="'(' + affiliation.code + ') ' + affiliation.name">
                        <div *ngIf="affiliation.code != 'None'">
                            ({{affiliation.code}}) {{affiliation.name}}
                        </div>
                        <div *ngIf="affiliation.code == 'None'">
                            {{affiliation.name}}
                        </div>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="businessInformationFormGroup.controls.affilication?.errors">
                    <span *ngIf="businessInformationFormGroup.controls.affilication?.errors?.required">
                        Affiliation is required
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>DBA</mat-label>
                <input matInput formControlName="DBA" maxlength="50" autocomplete="off">                
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Company Name</mat-label>
                <input matInput required formControlName="companyName" maxlength="50" autocomplete="off">
                <mat-error *ngIf="businessInformationFormGroup.controls.companyName?.errors">
                    <span *ngIf="businessInformationFormGroup.controls.companyName?.errors?.required">
                        Company Name is required
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput required formControlName="firstName" maxlength="50" autocomplete="off">
                <mat-error *ngIf="businessInformationFormGroup.controls.firstName?.errors">
                    <span *ngIf="businessInformationFormGroup.controls.firstName?.errors?.required">
                        First Name is required
                    </span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Business Owner</mat-label>
                <input matInput formControlName="businessOwner" maxlength="50" autocomplete="off">               
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput required formControlName="lastName" maxlength="50" autocomplete="off">
                <mat-error *ngIf="businessInformationFormGroup.controls.lastName?.errors">
                    <span *ngIf="businessInformationFormGroup.controls.lastName?.errors?.required">
                        Last Name is required
                    </span>
                </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Federal Tax ID</mat-label>
                <input matInput 
                (input)="addHyphen($event.target)"
                formControlName="federalTaxID" autocomplete="off" maxlength="15"
                [required]="businessInformationFormGroup.controls.businessLocation.value=='US'||businessInformationFormGroup.controls.businessLocation.value=='CA'">
                <mat-error *ngIf="businessInformationFormGroup.controls.federalTaxID?.errors">
                    <span *ngIf="businessInformationFormGroup.controls.federalTaxID?.errors?.required">
                        Federal Tax ID is required
                    </span>
                    <span *ngIf="businessInformationFormGroup.controls.federalTaxID?.errors?.pattern">
                        Federal Tax ID should be in 99-9999999 format
                    </span>
                </mat-error>
                <mat-hint><strong><i>Please note:</i></strong> International shipments will require an EIN#.
                </mat-hint>
            </mat-form-field>
        </div>

        <div class="business-scale">
            <div class="business-details">
                <div class="key">Years in Business</div>
                <div class="value">
                    <mat-form-field appearance="outline" class="years-in-business" [subscriptSizing]="'dynamic'">
                        <input matInput placeholder="0" maxlength="3" pattern="\d*" formControlName="yearsInBusiness"
                            autocomplete="off">
                        <mat-error *ngIf="businessInformationFormGroup.controls.yearsInBusiness?.errors">
                            <span *ngIf="businessInformationFormGroup.controls.yearsInBusiness?.errors">
                                Enter valid years in business (only numbers)
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="business-details">
                <div class="key">Year Business Incorporated</div>
                <div class="value">
                    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
                        <input matInput placeholder="YYYY" minlength="4" maxlength="4" pattern="\d*"
                            formControlName="yearIncorporated" autocomplete="off">
                        <mat-error *ngIf="businessInformationFormGroup.controls.yearIncorporated?.errors">
                            <span *ngIf="businessInformationFormGroup.controls.yearIncorporated?.errors">
                                Enter valid year. Year should be in YYYY format. (only numbers)
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="business-details">
                <div class="key">Number of Employees</div>
                <div class="value">
                    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
                        <mat-select placeholder="0" formControlName="numberOfEmployees">
                            <mat-option *ngFor="let item of signUpDropdowns.noOfEmployees" [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="business-details">
                <div class="key">Number of Locations </div>
                <div class="value">
                    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
                        <mat-select placeholder="0" formControlName="numberOfLocations">
                            <mat-option *ngFor="let item of signUpDropdowns.noOfLocations" [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="separator"></div>

        <div class="business-type">
            <div class="types">
                <label><strong>Type of Business</strong></label>
                <mat-radio-group formControlName="typeofBusiness">
                    <mat-radio-button *ngFor="let type of signUpDropdowns.businessTypes" [value]="type">
                        {{type}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="types">
                <label><strong>Primary Business</strong></label>
                <mat-checkbox *ngFor="let type of signUpDropdowns.primaryBusiness"
                    (change)="primaryBusinessChecked($event)" [value]="type">
                    {{type}}
                </mat-checkbox>
                <mat-form-field *ngIf="otherPrimaryBusiness" appearance="outline">
                    <input matInput autocomplete="off" formControlName="otherPrimaryBusiness" maxlength="200">
                </mat-form-field>
                <!-- <mat-radio-group required formControlName="primaryBusiness">
                    <mat-radio-button *ngFor="let type of signUpDropdowns.primaryBusiness" [value]="type">
                        {{type}}
                    </mat-radio-button>
                </mat-radio-group>
                <mat-form-field *ngIf="businessInformationFormGroup.controls.primaryBusiness.value=='Other'"
                    appearance="outline">
                    <input matInput autocomplete="off" formControlName="otherPrimaryBusiness" maxlength="200">
                </mat-form-field> -->
            </div>

            <div class="types">
                <label><strong>Product(s) <span class="red-asterik"> * </span> </strong></label>
                <mat-checkbox *ngFor="let product of signUpDropdowns.products" (change)="productChecked($event)"
                    [value]="product">
                    {{product}}
                    <mat-icon [matTooltipPosition]="'right'" matTooltip="Lawful goods other than jewelry, timepieces, precious metals and gemstones must be approved in writing prior to shipment. 
                    Please review the Parcel Pro Terms & Conditions before shipping other commodities."
                        *ngIf="product=='Other'">info_outline</mat-icon>
                </mat-checkbox>
                <mat-form-field *ngIf="otherProduct" appearance="outline">
                    <input matInput autocomplete="off" [required]="otherProduct == true ? true : null" formControlName="otherProduct" maxlength="200">
                    <mat-error *ngIf="businessInformationFormGroup.controls.otherProduct?.errors">
                        <span *ngIf="businessInformationFormGroup.controls.otherProduct?.errors?.required">
                            Other Product Information is required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="types movement-types-and-merchandise-condition-container">
                <div class="movement-types">
                    <label><strong>Movement Types <span class="red-asterik"> * </span> </strong></label>

                    <div class="first percent-container">
                        <span> Business To Business </span>
                        <mat-form-field class="percent-wrapper" appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="businessToBusinessPercent">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-error *ngIf="businessInformationFormGroup.get('businessToBusinessPercent')?.hasError('min') || businessInformationFormGroup.get('businessToBusinessPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
    
                    <div class="percent-container">
                        <span> Business To Consumer </span>
                        <mat-form-field class="percent-wrapper" appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="businessToConsumerPercent">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-error *ngIf="businessInformationFormGroup.get('businessToConsumerPercent')?.hasError('min') || businessInformationFormGroup.get('businessToConsumerPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
                    
                    <div class="percent-container">
                        <span> Returns </span>
                        <mat-form-field class="percent-wrapper" appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="returnPercent">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-error *ngIf="businessInformationFormGroup.get('returnPercent')?.hasError('min') || businessInformationFormGroup.get('returnPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>

                    <mat-error *ngIf="movementTypesSumError">
                        Enter numbers only must add up to 100.
                    </mat-error>
                </div>
                
                <div class="merchandise-condition">
                    <label><strong> Merchandise Condition <span class="red-asterik"> * </span> </strong></label>

                    <div class="first percent-container">
                        <span> New </span>
                        <mat-form-field class="percent-wrapper" appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="newPercent">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-error *ngIf="businessInformationFormGroup.get('newPercent')?.hasError('min') || businessInformationFormGroup.get('newPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
    
                    <div class="percent-container">
                        <span> Used </span>
                        <mat-form-field class="percent-wrapper" appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="usedPercent">
                            <span matSuffix>%</span>
                        </mat-form-field>
                        <mat-error *ngIf="businessInformationFormGroup.get('usedPercent')?.hasError('min') || businessInformationFormGroup.get('usedPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>

                    <mat-error *ngIf="merchandiseConditionSumError">
                        Enter numbers only must add up to 100.
                    </mat-error>
                </div>
            </div>

        </div>

        <div class="separator"></div>
    </form>
</div>
