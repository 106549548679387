import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validator, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LogoComponent } from '../../shared/components/logo/logo.component';
import { Router } from '@angular/router';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { ChangePasswordService } from '../services/change-password.service';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { SharedService } from '../../core/services/shared.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';

@Component({
  selector: 'upsc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit {

  public formGroup: UntypedFormGroup;
  private token = '';
  public validationMessages = false;
  public alpha: string = '';
  public numeric: string = '';
  public length: string = '';
  public blank: string = '';
  public isConfirmPasswordValid = false;
  public isPasswordValid = false;
  public isTokenValid = false;
  public success = false;
  private passwordControl = 'password';

  //TODO: Move it to validation service
  regularExp = {
    containsNumber: /\d+/,
    containsAlphabet: /[a-zA-Z]/,
  }

  private validValue: string = 'url(\'../../../assets/icons/valid-value.png\')';
  private invalidValue: string = 'url(\'../../../assets/icons/invalid-value.png\')';

  public constructor(private router: Router,
                     private formBuilder: UntypedFormBuilder,
                     private validationService: ValidationService,
                     private activatedRoute: ActivatedRoute,
                     private spinnerService: SpinnerService,
                     private sharedService: SharedService,
                     private notificationService: NotificationService,
                     private errorHandlerService: ErrorHandlerService,
                     private changePasswordService: ChangePasswordService,
                     private readonly utilityService: UtilityService,
  ) {
    this.validateToken();
  }

  public get supportTel(): string {
    const currentDomainName = this.utilityService.getCurrentDomainName();
    switch (true) {
      case currentDomainName.includes('upscapitalde.com'):
        return '+49 69 6630 8330';
      case currentDomainName.includes('upscapitaluk.com'):
        return '+44 20 7949 0144';
      case currentDomainName.includes('upscapitalfr.com'):
        return '+33 1 73 01 84 55';
      case currentDomainName.includes('upscapitalit.com'):
        return '+39 02 4527 9099';
      default:
        return '+1 (888) 683-2300';
    }
  }

  public onSubmit(event, formValue) {
    if (this.formGroup.valid) {     
      this.spinnerService.show();
      this.changePasswordService.changePassword(this.token,this.formGroup.controls.password.value).subscribe(response=>{
        this.success=true;
        this.spinnerService.hide();
      },error=>{
        this.spinnerService.hide();
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          'Failed Password Reset',
          NotificationType.ERROR);
      });
    }
  }

  public async BackToLogin(): Promise<void> {
    await this.router.navigate(['/login']);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required,
      Validators.minLength(8),
      this.validationService.containsAlphabet(),
      this.validationService.containsNumber()])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    },
      {
        validators: this.validationService.isMatching('password', 'confirmPassword')
      });

    this.formGroup.controls.password.valueChanges.subscribe(value => {
      if (value) {
        this.validatePassword(value);
      }
      else this.validatePassword('');
    });

    this.formGroup.controls.password.statusChanges.subscribe(status => {
      this.showHideValidationMessages(this.formGroup.controls.password.status);
    });
    this.formGroup.controls.confirmPassword.statusChanges.subscribe(status => {
      if (status === 'VALID')
        this.isConfirmPasswordValid = true;
      else this.isConfirmPasswordValid = false;
    });
  }

  private validateToken() {
    this.spinnerService.show();
    this.activatedRoute.params.subscribe(params => { this.token = params['token']; })
    this.changePasswordService.validateToken(this.token).subscribe(response => {
      this.spinnerService.hide();
      this.sharedService.isResetPasswordTokenValid.next(true);
    }, error => {
      this.spinnerService.hide();
      this.sharedService.isResetPasswordTokenValid.next(false);
      this.router.navigate(['forgot-password']);
    });
  }

  validatePassword(value) {
    this.alpha = this.regularExp.containsAlphabet.test(value) ? this.validValue : this.invalidValue;
    this.numeric = this.regularExp.containsNumber.test(value) ? this.validValue : this.invalidValue;
    this.length = value.trim().length >= 8 ? this.validValue : this.invalidValue;
    this.blank = (value && value.trim().length > 0) ? this.validValue : this.invalidValue;
  }

  public passwordFocus() {
    this.showHideValidationMessages(this.formGroup.controls.password.status);
  }

  public passwordFocusOut() {
    this.showHideValidationMessages(this.formGroup.controls.password.status);
  }

  private showHideValidationMessages(status) {
    if (status === 'VALID') {
      this.validationMessages = false;
      this.isPasswordValid = true;
    }
    else {
      this.validationMessages = true;
      this.isPasswordValid = false;
    }
  }
}
