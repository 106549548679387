<div class="add-credit-card ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'AddCreditCard'|translate}}</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #content>
    <div *ngIf="CCTermsAccepted">
        <form class="ups-form" [formGroup]="formGroup" (ngSubmit)="termsAccepted($event, formGroup.value)">
            <div class="form-body">
                <div class="terms">
                    <mat-checkbox id="ConfirmCheck"
                                  formControlName="isTermsAccepted">{{ TermsConditions?.TermsCondition }}</mat-checkbox>
                </div>
            </div>

            <div class="form-actions">
                <button mat-raised-button
                        class="form-action"
                        [disabled]="!formGroup.valid">{{'acceptSave'|translate}}</button>
            </div>
        </form>
    </div>

    <div class="iframe-container" *ngIf="!CCTermsAccepted">
        <div [innerHTML]="formHtml" [hidden]="true"></div>
        <iframe id="iframe" class="iframeCC" name="iframe"></iframe>
        <upsc-element-blocker [isPageBlocker]="false" *ngIf="!formHtml"></upsc-element-blocker>
    </div>
</ng-template>
