import { Component, Inject, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
import { IPostAddressUpload } from '../../interfaces/postAddressUpload.interface';
import { IPutAddressUploadDraft } from '../../interfaces/putAddressUploadDraft.interface';


@Component({
  selector: 'upsc-dd-upload-address-popups',
  templateUrl: './dd-upload-address-popups.component.html',
  styleUrls: ['./dd-upload-address-popups.component.scss'],
})
export class DDUploadAddressPopupsComponent implements OnInit {
  public fileName: string = "";

  constructor(public dialogRef: MatDialogRef<DDUploadAddressPopupsComponent>,
              private deliveryDefenseService: DeliveryDefenseService,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.subscribeToFileName();
  }

  private subscribeToFileName(): void {
    this.deliveryDefenseService.fileName$.subscribe(
      (result) => {
        this.fileName = result?.fileName
    })
  }
  
  public noBtn(): void {
    this.dialogRef.close();  

    if (this.data.isRecentUpload) {
      this.deliveryDefenseService.cancelRecentUploadSubmission$.next({ isCancelSubmissionClicked: false });
    }
    else {
      this.deliveryDefenseService.cancelSubmission$.next({ isCancelSubmissionClicked: false });
    }
  }
  
  public yesBtn(): void {
    if (this.data.title == "Delete Record(s)") {
      this.handleDeleteRecords();
    }
    else if (this.data.title == "Cancel Submission") {
      this.handleCancelSubmission();
    }
    else if (this.data.title == "Submit for Scoring") {
      this.handleSubmitForScoring();
    }
    else if (this.data.title == "Save Progress") {
      this.handleSaveProgress();
    }
    this.dialogRef.close();
  }


  private handleCancelSubmission(): void {
    if (this.data.isRecentUpload) {
      this.deliveryDefenseService.cancelRecentUploadSubmission$.next({ isCancelSubmissionClicked: true });
    }
    else {
      this.deliveryDefenseService.cancelSubmission$.next({ isCancelSubmissionClicked: true });
    }
  }

  private handleSubmitForScoring(): void {
    const noErrors: [] = this.data.savedAllErrorsData.filter(item => item.Exceptions.length === 0); 

    if (this.data.isRecentUpload) {
      // DD-577: In the case of batch drafts, need to check if any records were deleted prior to submission (unsaved progress)
      const ddAddressLookupData = this.saveBatchDraft();
      this.deliveryDefenseService.putAddressUploadDraft(ddAddressLookupData).subscribe(
        (response) => {}
      );

      const batchId = this.data.recentUploadData.BatchId;
      const fileName = this.data.recentUploadData.Filename;
      this.deliveryDefenseService.submitRecentUploadForScoring$.next( {data: noErrors, isSubmitForScoring: true, batchId: batchId, fileName: fileName });
    }
    else {
      this.deliveryDefenseService.submitForScoring$.next( {data: noErrors, isSubmitForScoring: true });
    }
  }

  private handleSaveProgress(): void {
    if (this.data.isRecentUpload) {
      this.fileName = this.data.recentUploadData.Filename;
      this.callPutAddressUploadDraft();
    }
    else {
      this.callPostAddressUploadDraft();

    }
  }

  private callPutAddressUploadDraft(): void {
    const ddAddressLookupData = this.saveBatchDraft();

    this.deliveryDefenseService.putAddressUploadDraft(ddAddressLookupData).subscribe(
      (response) => {
        if (response) {
          this.deliveryDefenseService.saveProgress$.next({ isSaveProgress: false });
        }
    });
  }

  private saveBatchDraft(): IPutAddressUploadDraft {
    const addressUploads = [];
    const data = this.data.recentUploadData.AddressUploads;

    for (let i = 0; i < data?.length; i++) {
      addressUploads.push({
        BatchRecordId: data[i]?.BatchRecordId,
        IsChanged: data[i]?.IsChanged,
        StreetAddress: data[i]?.StreetAddress,
        City: data[i]?.City,
        State: data[i]?.State,
        Zip: data[i]?.Zip,
      });
    }

    const ddAddressLookupData: IPutAddressUploadDraft = {
      "BatchId": data[0]?.BatchId,
      "AddressUploads": addressUploads,
    };
    return ddAddressLookupData;
  }

  private callPostAddressUploadDraft(): void {
    const addressUploads = [];
    const data = this.data.savedAllErrorsData;

    for (let i = 0; i < data.length; i++) {
      addressUploads.push({
        Streetaddress: data[i]?.StreetAddress || "",
        City: data[i]?.City || "",
        State: data[i]?.State || "",
        Zip: data[i]?.Zip || "",
      });
    }

    const ddAddressLookupData: IPostAddressUpload = {
      "Filename": this.fileName,
      "AddressUploads": addressUploads,
    };

    this.deliveryDefenseService.postAddressUploadDraft(ddAddressLookupData).subscribe(
      (response) => {
        if (response) {
          this.deliveryDefenseService.saveProgress$.next({ isSaveProgress: false });
        }
      });
  }

  private handleDeleteRecords(): void {
    if (this.data.removeAllErrors) {
      this.deleteAllErrorRecords();
    }
    else {
      this.deleteRecordByIndex();
    }
  }

  private deleteAllErrorRecords(): void {    
    const noErrors = [];

    for (let i = 0; i < this.data.savedAllErrorsData?.length; i++) {
      if (this.data.savedAllErrorsData[i]?.Exceptions.length === 0) {
        noErrors.push(this.data.savedAllErrorsData[i]);
      } else {
        if (this.data.isRecentUpload) {
          this.data.recentUploadData.AddressUploads[i].IsChanged = 2;
        }
      }
    }

    if (this.data.isRecentUpload) {
      this.deliveryDefenseService.editRecentUploadData$.next({ data: noErrors, isDeleteAllErrorRecords: true });
    }
    else {
      this.deliveryDefenseService.editAddressData$.next({ data: noErrors, isDeleteAllErrorRecords: true });
    }  
  }

  private deleteRecordByIndex(): void {
    let dataToSend: any[];
    const orignalIndex = this.data.reviewErrorsData[this.data.index]?.originalIndex;

    if (this.data.isRecentUpload) {
      this.data.recentUploadData.AddressUploads[orignalIndex].IsChanged = 2;
    }

    if (this.data.isShowErrorsToggled) {
      this.data.savedAllErrorsData.splice(orignalIndex, 1);
      this.data.reviewErrorsData.splice(this.data.index, 1);
      dataToSend = this.data.reviewErrorsData;
    }
    else {
      this.data.savedAllErrorsData.splice(this.data.index, 1);
      dataToSend = this.data.savedAllErrorsData;
    }

    if (this.data.isRecentUpload) {
      this.deliveryDefenseService.editRecentUploadData$.next({ data: dataToSend, isDeleteAllErrorRecords: false });
    }
    else {
      this.deliveryDefenseService.editAddressData$.next({ data: dataToSend, isDeleteAllErrorRecords: false });
    }
  }
}
