import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
import dayjs from 'dayjs';
import advancedFormat  from 'dayjs/plugin/advancedFormat';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { AppStateService } from '../../../app-state.service';

@Component({
    selector: 'upsc-confirm-popup',
    templateUrl: './confirm-popup.component.html',
    styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements OnDestroy {
    isChecked = false;
    subscribed = false;

    private ngDestroyed$ = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router,
                private deliveryDefenseService: DeliveryDefenseService,
                private readonly appStateService: AppStateService,
    ) {
        dayjs.extend(advancedFormat);

        if (this.data.date && dayjs(this.data.date).isValid()) {
            const ordinal = (dayjs(this.data.date).format('Do')).replace(/[0-9]/g, '');
            this.data.date = `${ dayjs(this.data.date).format('MMMM D') }<sup>${ ordinal }</sup>`;
        }
    }

    public get isFromTrialTier(): boolean {
        return this.data?.subscription?.CurrentSubType === 5
            && (this.data.monthlyTierType === 1 || this.data.monthlyTierType === null);
    }

    public ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    startSubscribe() {
        this.subscribed = true;
        // this.buttonChanged = 'Start Shipping Now';
        this.deliveryDefenseService.startSubscribe()
            .pipe(
                catchError((error) => {
                    console.log('error', error);
                    return of(false);
                }),
                takeUntil(this.ngDestroyed$),
                switchMap((res) => {
                    return this.appStateService.refreshAppState();
                }),
            )
            .subscribe();
    }

    public async startSubscribeNow() {
        this.dialogRef.close();
        await this.router.navigate(['/dashboard']);
    }
}
