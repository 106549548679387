import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '../../app.state';
import { AddCreditCardService } from '../services/add-credit-card.service';
import { CreditCardForm } from '../models/credit-card-form.model';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { Router } from '@angular/router';
import { SharedService } from '../../core/services/shared.service';
import { User } from '../../shared/services/user/models/user.model';
import { AcceptCCTerms } from '../services/accept-credit-card-terms.service';
import { CreditCardTerms } from '../models/credit-card-terms.model';
import { TermsConditions } from '../../shared/models/terms-conditions.model';
import { GetIPService } from '../../shared/services/get-ip/get-ip.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { TranslateService }from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'upsc-edit-credit-card',
  templateUrl: './edit-credit-card.component.html',
  styleUrls: ['./edit-credit-card.component.scss'],
})
export class EditCreditCardComponent implements OnInit {
  public formGroup: UntypedFormGroup;

  CCTermsAccepted = true;
  submitButton = false;
  isLoaded = false;
  creditCardForm: CreditCardForm;
  formHtml: string;
  user: User;
  CCTerms: CreditCardTerms = new CreditCardTerms();
  TermsConditions: TermsConditions;

  public constructor(private addcreditcardservice: AddCreditCardService,
                     private router: Router,
                     private notificationService: NotificationService,
                     private sharedService: SharedService,
                     private AcceptTerms: AcceptCCTerms,
                     private formBuilder: UntypedFormBuilder,
                     private errorHandlerService: ErrorHandlerService,
                     private translateService: TranslateService,
                     private GetIPAddress: GetIPService,
                     @Inject(MAT_DIALOG_DATA) public data: any,
                     private readonly appState: AppState,
  ) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      isTermsAccepted: [false, Validators.compose([Validators.requiredTrue])],
    });

    this.getCreditCardTerms();
    this.user = this.appState.user$();
  }

  termsAccepted(event, form) {
    event.preventDefault();

    this.CCTermsAccepted = false;
    // this.GetIPAddress.getUserIP().subscribe(
    // (res) => {
    this.CCTerms.IPAddress = '1.1.2';
    this.proceedCCIP()
    // });
  }

  private proceedCCIP() {
    this.CCTerms.TermsID = this.TermsConditions.TermsID;
    this.CCTerms.UserName = this.user.UserName;
    this.AcceptTerms.saveCreditCardTerms(this.CCTerms).subscribe(
      ccForm => this.handleSaveCCTermsSuccess(ccForm),
      err => this.handleSaveCCTermsFailure(err),
    )
  }

  enableSubmit(e) {
    this.submitButton = e.checked;
  }

  moveToManageCC() {
    const routeManageCC = '/manage-credit-card';
    this.router.navigate([routeManageCC]);
  }


  getCreditCardTerms() {
    this.AcceptTerms.getCreditCardTerms().subscribe(
      terms => this.handleSuccessTermsGet(terms),
      err => this.handleFailureTermsGet(err),
    )
  }

  getCreditCardFormData() {
    // console.log(this.data.AnetPaymentProfileID);
    this.addcreditcardservice.getCreditCardForm(
      this.user.AnetProfileID,
      this.data.AnetPaymentProfileID,
      window.location.origin + '/manage-cre',
      false,
      window.location.origin + '/add-credit-card-bounce').subscribe(
      ccForm => this.handleGettCCFormSuccess(ccForm),
      err => this.handleGetCCFormFailure(err),
    )
  }

  generateAnetForm() {
    this.addcreditcardservice.postCreditCardForm(
      this.creditCardForm.UrlEdit,
      this.creditCardForm.Token).subscribe(
      ccForm => this.handleGettAnetFormSuccess(ccForm),
      err => this.handleGetAnetFormFailure(err),
    )
  }


  // private postJquery(url: string, token: String) {

  //   $.ajax({
  //     url,
  //     type: 'POST',
  //     data: { token },
  //     success: (result) => {console.log(result)},
  //     dataType: 'application/json',
  //   });
  // }
  private submitJquery() {
    const form = $(this.formHtml);
    $(document.body).append(form);
    form.submit();
  }

  private handleGetCCFormFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('manageCC.userAnetRetrieveFailed'),
      NotificationType.ERROR);
  }

  private handleGettCCFormSuccess(ccFormData) {
    this.creditCardForm = ccFormData;
    this.formHtml = this.creditCardForm.EditForm;
    this.submitJquery();
  }

  private handleSuccessTermsGet(terms) {
    this.TermsConditions = terms;
    this.isLoaded = true;

  }

  private handleFailureTermsGet(err) {

  }

  private handleGetAnetFormFailure(err) {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      this.translateService.instant('manageCC.failedPostingAnetWebsite'),
      NotificationType.ERROR);
  }

  private handleGettAnetFormSuccess(anetFormData) {

  }

  private handleSaveCCTermsSuccess(ccForm: any) {
    this.getCreditCardFormData();
  }

  private handleSaveCCTermsFailure(err: any) {
    this.submitButton = false;
  }

  public printReceipt() {
    window.print();
  }
}
