import { Component, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { firstValueFrom } from 'rxjs';
import { User } from '../../../shared/services/user/models/user.model';
import { UserService } from '../../../shared/services/user/user.service';
import { PaymentProfileAdd } from '../../models/add-payment-profile.model';
import { AddCreditCardService } from '../../services/add-credit-card.service';

@Component({
    selector: 'upsc-add-cc-bounce',
    templateUrl: './add-cc-bounce.component.html',
    styleUrls: ['./add-cc-bounce.component.scss'],
})
export class AddCcBounceComponent implements OnInit {

    private addCCInfo: PaymentProfileAdd = new PaymentProfileAdd();
    private counter: number = 0;
    private user: User;

    public constructor(private addcreditcardservice: AddCreditCardService,
                       private userProfile: UserService,
                       private storage: StorageMap,
    ) {
    }

    public ngOnInit() {
        this.storage.has('counter').subscribe(
            async (hasCounter) => {
                if (!hasCounter) {
                    await firstValueFrom(this.storage.set('counter', this.counter));
                    return;
                }

                const user = await firstValueFrom(this.userProfile.getUser());
                if (!user) {
                    return;
                }

                // this.user = user;
                // this.addCCInfo.AnetProfileID = this.user.AnetProfileID;
                // this.addCCInfo.CustomerID = this.user.CustomerId;
                // this.addCCInfo.isTest = false;
                // this.addCCInfo.UserID = this.user.UserId.toString();
                // this.reloadPage();
            },
        );
    }

    private handleAddCCSuccess(success: any) {
        // console.log('success');
        //this.storage.remove('counter');
        //window.parent.parent.location.href = window.location.origin + '/manage-credit-card';
    }

    private handleGetAddCCFailure(err: any) {
    }


    private reloadPage() {
        this.addcreditcardservice.saveNewCreditCard(this.addCCInfo).subscribe(
            success => this.handleAddCCSuccess(success),
            err => this.handleGetAddCCFailure(err),
        );
    }
}





