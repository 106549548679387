import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IScoreHistory } from 'app/delivery-defense/dd-score-history/scoreHistory.interface';


@Component({
  selector: 'upsc-recent-upload-popup',
  templateUrl: './recent-upload-popup.component.html',
  styleUrls: ['./recent-upload-popup.component.scss'],
})

export class RecentUploadPopupComponent {
    public fileName: string = "";
    public scoreHistoryData: IScoreHistory[] = [];

    constructor(
        public dialogRef: MatDialogRef<RecentUploadPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.fileName = this.data?.recentUploadData?.ScoredAddressReviewFileName;
        this.scoreHistoryData = this.data?.scoreHistory;
    }
}