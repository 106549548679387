<div class="sign-up-layout">
    <div class="DD-sign-up">
        <div class="sign-up-wrapper">
            <div class="banner-Panel">

                <div class="dd-banner">
                    <img [ngSrc]="'assets/icons/logo-dd-shipping-intelligence.svg'"
                         alt="DeliveryDefense"
                         class="dd-logo"
                         height="282"
                         width="282">
                    <div class="video-wrapper">
                        <div class="filler-image">
                            <a href="https://www.youtube.com/watch?v=u_TQUvpOHrU" target="_blank">
                                <img [ngSrc]="'assets/images/play_circle.svg'"
                                     alt="play_circle"
                                     class="play_circle"
                                     height="80"
                                     width="80">
                            </a>
                        </div>
                        <a class="video-link" href="https://www.youtube.com/watch?v=u_TQUvpOHrU" target="_blank">
                            <span>Check out the demo video.</span>
                        </a>
                    </div>
                    <img alt="UPS" class="bottom-curve" src="assets/icons/ddbannervector.svg">
                </div>
            </div>
            <div class="content">
                <div class="section">
                    <div class="section-title">Features</div>
                    <ul class="features">
                        <li class="feature"><span>Proactive loss control to help improve outcomes</span></li>
                        <li class="feature"><span>Integrated decisioning to streamline workflows</span></li>
                        <li class="feature"><span>Delivery intelligence to support smarter shipping decisions</span></li>
                        <li class="feature">
                            <span>Flexible alternate delivery options such as UPS Access Point<sup>&reg;</sup> locations</span>
                        </li>
                    </ul>
                </div>
                <div class="subscription-Panel">
                    <h1>Start your subscription</h1>
                    <div class="subscription-option">
                        <img alt="radio" class="radio" [ngSrc]="'/assets/icons/Radio.svg'" width="24" height="24">
                        <div class="option-content">
                            <span class="option-content-header" *ngIf="cardHeader">{{cardHeader}}</span>
                            <span class="option-content-sub-header">{{cardSubHeader}}</span>
                        </div>
                        <div *ngIf="isComplimentary && false" class="renewal-date">
                            <span *ngIf="!isSubscriptionCancelled">Renews {{nextRenewl}}</span>
                            <span *ngIf="isSubscriptionCancelled">Cancelled on {{nextRenewl | date}}</span>
                        </div>
                    </div>
                    <!-- <h1 *ngIf="this.monthlyTierType == 1" class="label">*Must be used within 30 days</h1>
                     <h1 *ngIf="this.monthlyTierType != 1" class="label">*Must maintain
                        shipment volume of {{shipmentCount}}
                        shipments per month through </h1> 
                  
                    <h1 *ngIf=" this.monthlyTierType != 1" class="label"> <a
                             href="http://parcelpro.com/"> ParcelPro.com </a>to qualify for this
                        offering</h1>  -->
                    <div class="labelDescription">
                        <div *ngIf="!this.is19_99_signup"> Note:</div>
                        <div *ngIf="this.volumeNoteVal">1. Must maintain shipment volume of {{this.shipmentCount}}
                            shipments per month through <a
                                    href="http://parcelpro.com/" target="_blank"> ParcelPro.com </a> <br> to qualify for
                            this offering.
                        </div>
                        <div *ngIf="this.noteVal">1. Must be used within 30 days</div>

                        <div *ngIf="!this.is19_99_signup" class="trademark"> 2. DeliveryDefense™ Address Confidence tool
                            will currently be available only for packages<br>
                            valued less than $75,000
                        </div>
                        <div *ngIf="this.is19_99_signup" class="trademark">
                            <div class="note-header">Note:</div>
                            <ol>
                                <li>Scores must be used by the end of the calendar month, and will reset on the 1st of month.</li>
                                <li>DeliveryDefense™ Address Confidence tool will currently be available only for <br>
                                    packages valued less than $75,000.</li>
                            </ol>
                        </div>
                    </div>

                    <div class="sign-up">
                        <div class="sign-up-panel">
                            <button (click)="signUpNow()" class="sign-up-button" color="primary" mat-flat-button>
                                Sign Up Now
                            </button>
                        </div>
                        <h1>Cancel anytime. </h1>
                        <h2 *ngIf="isComplimentary">Plan automatically renews until canceled.</h2>
                    </div>

                </div>
                <div class="dropdown">
                    <div class="header-dropdown" id="header-dropdown">
                        <div class="ddSection" id="ddSection">
                            <h1>See All Monthly Subscription Plans</h1>
                            <img (click)="subscriptionPlans()" alt="expand_more" class="expand_more"
                                 id="expand_more" src="../../../assets/icons/expand_more.svg">

                        </div>
                        <div *ngIf="showSubscriptionPlans" class="tablePanel">
                            <table class="my-table">
                                <thead class="my-table headers">
                                <tr>
                                    <th></th>
                                    <th class="sideHeader">Non Subscriber</th>
                                    <th class="background1">Value Add Subscription</th>
                                    <th class="background2">High Volume <br> Program 1</th>
                                    <th class="background3">High Volume <br> Program 2</th>
                                    <th class="background4">High Volume <br> Program 3</th>
                                </tr>
                                </thead>
                                <tbody class="my-table body">
                                <tr></tr>
                                <tr>
                                    <td class="sideHeader">Monthly Subscription fee</td>
                                    <td></td>
                                    <td class="background1">Complimentary</td>
                                    <td class="background2">Complimentary</td>
                                    <td class="background3">Complimentary</td>
                                    <td class="background4">Complimentary</td>

                                </tr>
                                <tr>
                                    <td class="sideHeader">Eligibility</td>
                                    <td></td>
                                    <td class="background1">Ship less than 100 <br>
                                        boxes per month
                                    </td>
                                    <td class="background2">Ship 100 boxes<br>
                                        per month
                                    </td>
                                    <td class="background3">Ship 200 boxes<br>
                                        per month
                                    </td>
                                    <td class="background4">Ship 400 boxes<br>
                                        per month
                                    </td>
                                </tr>
                                <tr>
                                    <td class="sideHeader">Included Scores</td>
                                    <td></td>
                                    <td class="background1">50</td>
                                    <td class="background2">200</td>
                                    <td class="background3">300</td>
                                    <td class="background4">1500</td>
                                </tr>
                                <tr>
                                    <td class="sideHeader accessLabel">

                                        <h1 class="accessUPS">UPS Access Point<span class="rated">®</span></h1>
                                        <div class="accessPoint">Location</div>
                                    </td>
                                    <td class="dot">•</td>
                                    <td class="dot background1">•</td>
                                    <td class="dot background2">•</td>
                                    <td class="dot background3">•</td>
                                    <td class="dot background4">•</td>
                                </tr>
                                <tr>
                                    <td class="sideHeader label">
                                        Address Confidence Tool
                                    </td>
                                    <td></td>
                                    <td class="dot background1">•</td>
                                    <td class="dot background2">•</td>
                                    <td class="dot background3">•</td>
                                    <td class="dot background4">•</td>
                                </tr>
                                <tr>
                                    <td class="sideHeader">Recently Searched Addresses*</td>
                                    <td></td>
                                    <td class="dot background1">•</td>
                                    <td class="dot background2">•</td>
                                    <td class="dot background3">•</td>
                                    <td class="dot background4">•</td>
                                </tr>
                                </tbody>
                            </table>
                            <h2>
                                *Scores for duplicate addresses within 30 days of the original search will not be
                                charged<br>
                                For more information regarding plan eligibility, please email <a
                                    href="mailto:amr@parcelpro.com">amr&#64;parcelpro.com</a>
                            </h2>
                        </div>

                    </div>


                </div>

                <div class="scoringInfo">
                    <h1 (click)="showScoringInfo()" id="scoringInfoHeader">Scoring Info</h1>
                    <div *ngIf="this.scoringInfoPanel" class="scoringInfoPanel">

                        <div class="labels">
                            <h2 class="lowConfidence">Low Confidence</h2>
                            <h2 class="highconfidence">High Confidence</h2>
                        </div>
                        <div class="sliderScale"></div>

                        <div class="infoTable">
                            <div class="labels">
                                <h2 class="lowConfidence">100</h2>
                                <h2 class="highconfidence">1000</h2>
                            </div>
                            <table class="my-table">
                                <thead class="my-table headers">
                                <tr>
                                    <th>100-299</th>
                                    <th>300-499</th>
                                    <th>500-699</th>
                                    <th>700-799</th>
                                    <th style=" width: 97.368px;">800-1000</th>
                                </tr>
                                </thead>
                                <tbody class="my-table body">

                                <tr>
                                    <td>The likelihood of loss is<br>
                                        <div class="size">16x</div>
                                        higher than a score of<br>
                                        800-1000
                                    </td>
                                    <td>The likelihood of loss is<br>
                                        <div class="size">13x</div>
                                        higher than a score of<br>
                                        800-1000
                                    </td>
                                    <td>The likelihood of loss is<br>
                                        <div class="size">9x</div>
                                        higher than a score of<br>
                                        800-1000
                                    </td>
                                    <td>The likelihood of loss is<br>
                                        <div class="size">4x</div>
                                        higher than a score of<br>
                                        800-1000
                                    </td>
                                    <td style=" width: 97.368px;">There is a low<br>
                                        likelihood <br>
                                        of loss
                                    </td>

                                </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div *ngIf="false" class="tipsSection">
                    <h1 class="label">Not getting a score? <span (click)="tipsPanel()" class="tipslink">Tips and
                            tricks</span></h1>
                    <div *ngIf="showTipsPanel" class="tipsPanel1">

                        <div class="section1">
                            <h1>1.</h1>
                            <div class="sub-section">
                                <img alt="lightbulb" class="lightbulb" src="../../../assets/icons/lightbulb.svg">
                                <h2>Select the suggest address<br>
                                    from the pop up</h2>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="line"></div>
                            <h1>2.</h1>
                            <div class="sub-section">
                                <img alt="comma" class="comma" src="../../../assets/icons/comma.jpg">
                                <img alt="lightbulb" class="light" src="../../../assets/icons/block.svg">

                                <h2>Do not add a comma between<br>
                                    state abbreviations and zip codes</h2>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="line"></div>
                            <h1>3.</h1>
                            <div class="sub-section">
                                <img alt="lightbulb" class="lightbulb" src="../../../assets/icons/Frame_24088.svg">
                                <h2>Do not include periods<br>
                                    with abbreviations</h2>
                            </div>
                        </div>
                        <img (click)="tipsPanelClose()" alt="close" class="close"
                             src="../../../assets/icons/close1.png">
                    </div>
                    <div *ngIf="showTipsPanel" class="tipsPanel2">
                        <div class="section1">
                            <h1>4.</h1>
                            <div class="sub-section">
                                <img alt="lightbulb" class="lightbulb" src="../../../assets/icons/explore_off.svg">
                                <h2>Do not include cardinal<br>
                                    directions in the street name</h2>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="line"></div>
                            <h1>5.</h1>
                            <div class="sub-section">
                                <img alt="lightbulb" class="lightbulb" src="../../../assets/icons/No Country.svg">
                                <h2>Do not enter country<br>
                                    (for example US or United States)</h2>
                            </div>
                        </div>


                    </div>

                </div>
                <div class="faqSignup">
                    <h1>Frequently Asked Questions</h1>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="isExpanded" class="expansionPanel">
                            <mat-expansion-panel-header class="panelHeader">
                                What is DeliveryDefense™ Address Confidence?
                            </mat-expansion-panel-header>
                            <div class="panelBody">
                                DeliveryDefense™ Address Confidence score provides AI-enabled address confidence
                                scoring that <br>
                                leverages machine learning and enriched delivery data to predict shipping outcomes
                                before
                                you even
                                <br>create a label – helping shippers make smarter, safer shipping decisions.
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel class="expansionPanel">
                            <mat-expansion-panel-header class="panelHeader">
                                How does DeliveryDefense™ Address Confidence work?
                            </mat-expansion-panel-header>
                            <div class="panelBody">
                                DeliveryDefense™ Address Confidence score looks at historical delivery performance
                                and
                                uses<br>
                                powerful data models to determine the likelihood of a successful delivery to its
                                destination.<br>
                                <a (click)="downloadPdf($event)" id="id1">Click here</a> for a scoring information
                                overview.
                                Factors considered in the scoring
                                include:

                                <li>Loss Frequency</li>
                                <li>Returns Volume</li>
                                <li> Delivery Attempts</li>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="linksSection">
                    <div class="links">
                        <ng-container *ngIf="customer?.DDEnabled">
                            <div class="divider"></div>
                            <div class="section1">
                                <a (click)="feedback()" class="link" target="_blank">Send Feedback</a>
                            </div>
                        </ng-container>
                        <div *ngIf="this.cancelSubscriptionLink" class="divider"></div>
                        <div *ngIf="this.cancelSubscriptionLink && (this.user.IsAdmin === true || this.user.IsGuest === false)" class="section2">
                            <a (click)="cancelSubscription()" class="link cancelSubscription" *ngIf="!isSubscriptionCancelled; else showDisabledCancelSubscriptionLink">Cancel Subscription</a>
                            <ng-template #showDisabledCancelSubscriptionLink>
                                <a class="link cancelSubscription disabled" matTooltip="{{ 'DDCancelledSubscription' | translate: {date: customerSubscription.CurrentSubEndDate | date} }}" matTooltipPosition="above">Cancel Subscription</a>
                            </ng-template>
                        </div>
                        <div class="divider"></div>
                        <div class="section3">
                            <a class="link" routerLink="/delivery-defense-faq">FAQ</a>
                        </div>
                        <div class="divider"></div>
                    </div>
                </div>
                <div class="disclaimer">
                    DeliveryDefense™ services are offered through UPS Capital Corporation. UPS Capital Corporation
                    reserves the right to change or cancel any <br>
                    DeliveryDefense™ service at any time. Each DeliveryDefense™ service is governed by the applicable
                    terms and conditions and certain limitations and <br>
                    exclusions apply. This document/site is for informational purposes only, is intended only as a brief
                    summary of the services, and does not constitute <br>
                    a contract, agreement, or offer of any kind, with respect to any DeliveryDefense™ service or other
                    subject matter described herein. DeliveryDefense™<br>
                    services may not be available in all jurisdictions. Statistics are valid as of the last day of the
                    most recently ended calendar quarter. Actual scores are
                    <br>based on delivery, claims and other data collected during the 24 months preceding the date the
                    score is rendered.
                </div>
            </div>

        </div>

    </div>
    <div class="layout-icons">
        <div class="label">
            <h1>Ship With</h1>
        </div>
        <div class="ship">
            <img (click)="shipIconClick()" alt="UPS_Flat_Shield_2Color_RGB"
                 class="ups_Flat_Shield" src="../../../assets/icons/UPS_Flat_Shield_2Color_RGB 1.svg">
        </div>
        <div class="fedex">
            <img (click)="fedexIconClick()" alt="fedex-express" class="fedex_express"
                 src="../../../assets/icons/fedex-express-6 1.svg">
        </div>


    </div>
</div>
