import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../app.state';
import { CreditCardsService } from '../services/credit-cards.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddCreditCardService } from '../services/add-credit-card.service';
import { PaymentProfileAdd } from '../models/add-payment-profile.model';
import { PaymentProfileEdit } from '../models/edit-payment-profile.model';
import { UserService } from '../../shared/services/user/user.service';
import { User } from '../../shared/services/user/models/user.model';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { AddCreditCardComponent } from '../add-credit-card/add-credit-card.component';
import { EditCreditCardComponent } from '../edit-credit-card/edit-credit-card.component';
import { Subject, Subscription } from 'rxjs';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { AnetProfile } from '../models/add-profile.model';
import { SharedService } from '../../core/services/shared.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { SiteConfig } from 'config/site-config';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'upsc-manage-credit-card',
  templateUrl: './manage-credit-card.component.html',
  styleUrls: ['./manage-credit-card.component.scss'],
})
export class ManageCreditCardComponent implements OnInit, OnDestroy {
  public cardsList: any[];
  public customMessage: string;
  public cardIdToRemove: string;
  public anetProfile: AnetProfile = new AnetProfile();
  addCCInfo: PaymentProfileAdd = new PaymentProfileAdd();
  editCCInfo: PaymentProfileEdit = new PaymentProfileEdit();
  user: User;
  public primaryCardWarning = 'PrimaryCCWarning';

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;

  private saveCreditCardSubscription: Subscription;

  public constructor(private creditcardservice: CreditCardsService,
                     private router: Router,
                     private userService: UserService,
                     private dialogService: DialogService,
                     private notificationService: NotificationService,
                     private addcreditcardservice: AddCreditCardService,
                     private utilityService: UtilityService,
                     private errorHandlerService: ErrorHandlerService,
                     private sharedService: SharedService,
                     private translateService: TranslateService,
                     private dialog: MatDialog,
                     private readonly appState: AppState,
  ) {
    this.user = this.appState.user$();
    this.getCreditCards();
    this.translateService.onLangChange.subscribe(() =>{
      this.updateDTOptionsLanguage();
    });
  }

  public ngOnInit() {
    if (this.user.AnetProfileID === '') {
      this.createAnetProfile();
    } else {
      this.addCCInfo.AnetProfileID = this.user.AnetProfileID;
      this.addCCInfo.CustomerID = this.user.CustomerId;
      this.addCCInfo.isTest = false;
      this.addCCInfo.UserID = this.user.UserId.toString();
    }

    this.saveCreditCard(this.addCCInfo);

    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('dashboard.noShipments'),
    };
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([this.saveCreditCardSubscription]);
  }

  createAnetProfile() {
    this.anetProfile.FirstName = this.user.FirstName;
    this.anetProfile.LastName = this.user.LastName;
    this.anetProfile.CustomerID = this.user.CustomerId;
    this.anetProfile.Email = this.user.Email;
    this.anetProfile.UserID = this.user.UserId.toString();
    this.anetProfile.isTest = false;
    this.addcreditcardservice.createAnetProfile(this.anetProfile).subscribe();
  }

  getCreditCards() {
    this.creditcardservice.getCreditCards().subscribe(
      (cards) => {
        this.cardsList = cards;
        this.dtTrigger.next(null);
      },
      (err) => {
        this.dtTrigger.next(null);
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('manageCC.errorLoadingCC'),
          NotificationType.ERROR);
      });
  }

  changePrimaryCard(UserCreditCardID: string) {
    const userCreditCardID = { UserCreditCardID: UserCreditCardID };
    this.creditcardservice.changePrimaryCreditCard(userCreditCardID).subscribe(
      (response) => {
        this.getCreditCards();
        this.notificationService.notify(this.translateService.instant('PrimaryCardChanged'), '', NotificationType.SUCCESS);
      },
      (err) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(err),
          this.translateService.instant('manageCC.failedChangingPrimaryCC'),
          NotificationType.ERROR);
        this.getCreditCards();
      });
  }

  confirmRemoveCreditCard(card) {
    if (card.IsPrimary.toString().toLowerCase() === 'true') {
      this.notificationService.notify('', this.translateService.instant(this.primaryCardWarning), NotificationType.WARNING);
    } else {
      this.cardIdToRemove = card.UserCreditCardID;
      this.removeCreditCardConfirmation(this.cardIdToRemove);
    }
  }

  removeCreditCardConfirmation(cardId: string) {
    this.dialogService.confirm('', this.translateService.instant('manageCC.confirmCCRemove')).subscribe(
      (isConfirmation) => {
        if (isConfirmation) {
          this.removeCreditCard(cardId);
        }
      });
  }

  removeCreditCard(cardId: string) {
    if (cardId) {
      this.creditcardservice.removeCreditCard(cardId).subscribe(
        (response) => {
          this.getCreditCards();
          this.notificationService.notify(this.translateService.instant('manageCC.CCRemoved'), this.translateService.instant('Success'), NotificationType.SUCCESS);
        },
        (err) => {
          this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(err),
            this.translateService.instant('manageCC.removeCCFailed'),
            NotificationType.ERROR);
          this.getCreditCards();
        });
    }

  }

  public moveToAddCC() {
    const routeAddCC = '/add-credit-card';
    this.router.navigate([routeAddCC]);
  }

  private saveCreditCard(creditCardInfo: PaymentProfileAdd) {
    this.utilityService.clearSubscriptions([this.saveCreditCardSubscription]);
    this.saveCreditCardSubscription = this.addcreditcardservice.saveNewCreditCard(creditCardInfo).subscribe(
      success => this.handleAddCCSuccess(success),
      err => this.handleGetAddCCFailure(err),
    );
  }

  private editCreditCard(creditCardInfo: PaymentProfileEdit) {
    this.utilityService.clearSubscriptions([this.saveCreditCardSubscription]);
    this.saveCreditCardSubscription = this.addcreditcardservice.editCreditCard(creditCardInfo).subscribe(
      success => this.handleAddCCSuccess(success),
      err => this.handleGetAddCCFailure(err),
    );
  }

  public openCCDialog(event) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '34.625rem',
      panelClass: 'mobile-fullscreen-dialog',
      data: {},
    };

    const dialogRef: MatDialogRef<AddCreditCardComponent> = this.dialog.open(AddCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (result) => {
        this.addCCInfo.AnetProfileID = this.user.AnetProfileID;
        this.addCCInfo.CustomerID = this.user.CustomerId;
        this.addCCInfo.isTest = false;
        this.addCCInfo.UserID = this.user.UserId.toString();

        this.saveCreditCard(this.addCCInfo);
      });
  }

  public openEditCreditCard(card, event) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '34.625rem',
      panelClass: 'mobile-fullscreen-dialog',
      data: {
        AnetProfileID: card.AnetProfileID,
        AnetPaymentProfileID: card.AnetPaymentProfileID,

      },
    };
    const dialogRef: MatDialogRef<EditCreditCardComponent> = this.dialog.open(EditCreditCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (result) => {
        this.editCCInfo.AnetProfileID = this.user.AnetProfileID;
        this.editCCInfo.AnetPaymentProfileID = card.AnetPaymentProfileID;
        this.editCCInfo.CustomerID = this.user.CustomerId;
        this.editCCInfo.isTest = false;
        this.editCCInfo.UserID = this.user.UserId.toString();

        this.editCreditCard(this.editCCInfo);
      });
  }

  handleAddCCSuccess(success: any) {
    this.getCreditCards();
  }

  handleGetAddCCFailure(err: any) {
  }

  public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
            emptyTable: this.translateService.instant('dashboard.noShipments')
        };

    this.rerender();
  }

  private rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        if(this.cardsList.length === 0) {
            dtInstance.destroy();
        }
        // Call the dtTrigger to rerender again
        this.dtTrigger.next(null);
    });
  }
}


