<div style="padding: 0 4rem">
    <p>
        These Terms and Conditions (<strong>T&amp;Cs</strong>) govern and control
        your rights with respect to any products or services provided by Parcel Pro,
        Inc. (“Parcel Pro”). These T&amp;Cs are a legally binding contract, and you
        should read them carefully. To contact Parcel Pro with any questions, please
        call us at 1-888-683-2300 or email us at <a href="mailto:customerservice@parcelpro.com">customerservice&#64;parcelpro.com</a>.
    </p>
    <p>
        <strong
        >THESE T&amp;Cs CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
            REMEDIES, AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND
            EXCLUSIONS THAT MIGHT APPLY TO YOU. THESE T&amp;Cs FURTHER REQUIRE THE USE
            OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
            JURY TRIALS OR CLASS ACTIONS.</strong
        >
    </p>
    <ol>
        <li>
            <strong> REVIEW AND ACCEPTANCE; UPDATES TO T&amp;Cs AND RATES: </strong
            >You should fully review and understand these T&amp;Cs and any applicable
            rates before you request any products or services from Parcel Pro. Use of
            Parcel Pro’s products or services constitutes acceptance of these T&amp;Cs
            and to the applicable effective rates. We may update or otherwise modify
            these T&amp;Cs or our applicable rates from time to time in our sole
            discretion with or without providing notice to you. Each time that you
            request any products or services from Parcel Pro, you will be deemed to
            have accepted and agreed to both the then-current version of the T&amp;Cs
            and the rates that are in effect at the time of the applicable request. It
            is your sole responsibility to keep apprised of any changes to the
            published T&amp;Cs or applicable rates, as may be verified online after
            login at
            <a href="https://web.parcelpro.com/">https://web.parcelpro.com/</a> or by
            contacting your Parcel Pro representative. Parcel Pro further reserves the
            right to suspend, cancel or close your Parcel Pro account at any time and
            for any reason.
        </li>
    </ol>
    <ol start="2">
        <li>
            <strong> SCOPE OF SERVICES: </strong>Parcel Pro acts as a shipper’s-agent
            to arrange, organize and/or manage transportation and provide ancillary
            services, including, but not limited to, U.S. export filing services (if
            applicable) and billing solutions. Upon request of customers, Parcel Pro
            may also provide you with the opportunity to protect your eligible
            shipments of goods under an insurance policy issued to Parcel Pro (the
            "Policy"). Parcel Pro is not an insurance company, customs broker, or air
            carrier of any type. Adherence to these T&amp;Cs is a condition precedent
            for customers’ goods to be included and protected under the Policy. You
            are also not permitted to offer or resell any Parcel Pro materials,
            products or services to any third-party without the prior written consent
            of Parcel Pro, and any such offer or resale will be null and void.
        </li>
    </ol>
    <ol start="3">
        <li>
            <strong> ENGAGED TRANSPORTATION CARRIERS: </strong>As the customer, you
            authorize Parcel Pro to select and engage transportation carriers and
            other vendors on your behalf pursuant to their contractual terms to which
            you will be bound in all instances. In the event Parcel Pro engages a
            motor carrier for independent U.S. interstate surface transportation,
            Parcel Pro does so in its capacity as property broker under operating
            authority identified as U.S. DOT No. 2803887 issued by the Federal Motor
            Carrier Safety Administration, under Docket Number MC-934626.
            Shipper’s-agent services may also be performed by Parcel Pro relating to
            transportation carriers and other vendors selected by the customer and/or
            engaged directly by the customer on the customer’s independent account. In
            either event, transportation carriers and other vendors to whom goods are
            entrusted MAY limit their liability. If you intend to protect your goods
            under the Policy (AS DEFINED IN SECTION 2 ABOVE), do <u>not</u> declare a
            value of those goods with any transportation carriers or other vendors.
            You may forego using the Policy and increase a transportation carrier or
            vendor’s liability by declaring a higher value and paying an additional
            charge subject to their contractual terms, but you must arrange for such
            additional liability through Parcel Pro if the transportation carrier or
            other vendor is engaged by Parcel Pro or under Parcel Pro’s account. Any
            declaration of a higher value by you directly to any transportation
            carrier or vendor engaged by Parcel Pro or engaged under a Parcel Pro
            affiliated account will <u>not</u> result in increased liability of that
            carrier or vendor. Parcel Pro will request additional liability from the
            transportation carrier or vendor on your behalf upon your written
            instruction, for which additional charges will apply. Transportation
            carriers and other vendors may refuse a higher declared value for goods
            with increased liability. In the absence of your written instruction to
            declare a value with a transportation carrier or other vendor, or if any
            transportation carrier or other vendor refuses a declared value that
            increases their liability, the goods may be tendered to transportation
            carriers or other vendors subject to their particular liability
            limitations and in some instances special liability waivers, which will
            apply to the transportation of your goods and to which you will be bound.
            In the event of an instruction by you or any other party to any
            transportation carrier or other vendor that conflicts with any contractual
            terms between Parcel Pro and that transportation carrier or other vendor,
            the contractual terms between Parcel Pro and that transportation carrier
            or other vendor shall prevail.
        </li>
    </ol>
    <ol start="4">
        <li>
            <strong> COVERAGE DETAILS</strong>: To the extent Parcel Pro provides you
            with the opportunity to protect your eligible shipments of goods under the
            Policy, you must provide specific information regarding the shipment to
            Parcel Pro, in each case in accordance with our instructions and the
            documentation made available to you from time to time (collectively, the
            “<u>Instructions</u>”). Although coverage under the Policy may be extended
            to your shipments, THE POLICY is not intended to and does not provide
            insurance directly to you. You are neither an insured nor an additional
            insured under THE Policy. In the event of a loss covered under THE Policy,
            any resulting claim payment may be directed to you as a loss payee and
            only to the extent of your interest in the protected shipment. The Policy
            under which shipments are insured for physical loss and/or damage in the
            course of transit is subject to conditions, limitations and exclusions.
            Parcel Pro, in its sole discretion, reserves the right to decline certain
            shipments under the Policy. The terms of the Policy are summarized as
            follows:
        </li>
    </ol>
    <p>
        <strong>(a) Commodities: </strong>Exclusively covering jewelry, time pieces,
        precious metals, and pearls and gemstones (loose or set). Other lawful goods
        may be covered subject to Parcel Pro’s execution of a written addendum to
        these T&amp;Cs prior to shipment.
    </p>
    <p>
        <strong>(b) Valuation: </strong>You should insure your goods for selling
        price if sold prior to shipment or otherwise replacement value. You should
        insure for 100% of the selling price or replacement value (as applicable).
        If you understate price or value, you will bear the extent of deficit in the
        event of a partial loss. In the event you overstate price or value, your
        coverage will be limited to actual price or value (as applicable). You must
        provide Parcel Pro with the appropriate price or value prior to shipment in
        accordance with the Instructions.
    </p>
    <p>
        <strong>(c) Limit of Liability: </strong>The Policy may provide for
        different terms, limits, deductibles, and fees depending upon the commodity
        types being shipped. A country-specific sublimit may also apply. The maximum
        coverage in all events will be limited to the amount for which you insure
        your goods.
    </p>
    <p>
        <strong>(d) Requirement of Signature at Delivery: </strong>Coverage applies
        exclusively to shipments with designated approved carriers under terms
        requiring an adult signature upon delivery. You must not tender any shipment
        to a carrier under a “waiver of signature,” “release signature status” or
        similar service booking, agreement, or arrangement with the carrier as you
        will void coverage under the Policy by doing so. If the consignee allows the
        carrier to effect delivery without a signature, coverage under the Policy
        will be void. Except if released by the carrier without signature against
        your instruction, without your knowledge and as a result of exclusive fault
        of the carrier, no insurance claim can be made for any shipment delivered
        without a signature for any reason if the shipment is confirmed delivered by
        the carrier’s tracking information.
    </p>
    <p>
        <strong>(e) Duration: </strong>Confirmed coverage commences upon goods being
        retrieved by or tendered to Parcel Pro or the designated approved carrier,
        as determined by carrier tracking information. Coverage continues in the
        course of transit and until delivery is made at the consigned destination or
        until the expiry of fourteen (14) days, whichever occurs first. Delivery is
        made when a package is signed for at the consigned location or when the
        package is left at the consigned location without a signature, as determined
        by the carrier’s tracking information. Shipments signed for by any apparent
        representative (e.g., concierge, doorman, front desk clerk, residence
        occupant, security guard) at the delivery address shall be deemed delivered.
        Notwithstanding the foregoing, coverage may be cancelled in the course of
        transit in the event you, the shipper, or consignee do not cooperate or are
        unresponsive to facilitate or accept delivery; in such event, it is your
        burden to prove any loss occurred during the coverage period.&nbsp;
    </p>
    <p>
        <strong>(f) Refused Shipments Returned: </strong>In the event of coverage
        and a shipment is returned to the shipper because of refusal or inability of
        the consignee to facilitate or accept delivery, coverage is extended until
        the sooner of redelivery to the shipper or the expiry of thirty (30) days
        from the date that the goods were retrieved by or tendered to Parcel Pro or
        the designated approved carrier, as determined by carrier tracking
        information.&nbsp;
    </p>
    <p>
        <strong
        >(g) Extended Coverage for Deliveries to Designated UPS Access Point®
            Locations: </strong
        >Notwithstanding Section 4(e) of this Agreement, packages that are processed
        through a UPS shipping system, Parcel Pro Ship or any other Parcel
        Pro-approved shipping platform and shipped to any Designated UPS Access
        Point location (as such term is defined below) shall be insured if confirmed
        by a scanned receipt by the carrier and upon proof of receipt by the
        Designated UPS Access Point location with coverage extended until (i) final
        receipt and pick up from said location by final consignee; (ii) the shipment
        is returned to the shipper; or (iii) the expiry of fourteen (14) days,
        whichever occurs first. This extended coverage shall apply exclusively to
        U.S. domestic outbound shipments of packages (a) made with select service
        levels available to you when shipping via Parcel Pro Ship or where prior
        written approval to the service level was provided by Parcel Pro; and (b)
        made under the eligible shipper number(s) designated on the Parcel Pro
        account to which transportation services are provided. For purposes of this
        extended coverage, a “Designated UPS Access Point” is a location that has
        been (i) designated as a UPS Access Point location by UPS where a consignee
        or other recipient may, where available, receive a delivery, and (ii) either
        (A) made available as a delivery address via Parcel Pro Ship, or (B)
        approved in writing by Parcel Pro prior to shipping.
    </p>
    <p>
        <strong>(h) Exclusions: </strong>As with all insurance, coverage under the
        Policy is subject to exclusions which specifically include: (I) seizure of
        goods, including seizures by custom authorities; (II) war risks for domestic
        U.S. shipments; (III) radioactive contamination; (IV) delay; (V) inherent
        vice; (VI) loss of, damage to or breach of data or other non-tangible
        content; (VII) fraud by you, the shipper or consignee; (VIII) cyber attacks;
        (IX) illicit trade; (X) concealed damages and losses (within sealed packages
        delivered intact); (XI) mechanical/electronic derangement to used or
        unsealed new merchandise; (XII) damages attributable to insufficient
        packing; (XIII) breakage to fragile and delicate items such as those used
        for clocks or glassware; (XIV) false/erroneous information provided by you,
        the shipper or consignee; package labels being changed or revised in the
        course of transportation for the purpose of fraudulent redirection; (XV)
        shipments being sent as a result of fraud or trick upon the customer,
        shipper and/or consignee (including but not limited to product orders made
        by imposters); (XVI) abandonment by you, the shipper or the consignee; and
        (XVII) loss or theft of a shipment where the shipper shows, provides notice
        of, or otherwise discloses the contents of a shipment to the carrier that
        consists of high-value items, including but not limited to jewelry, fine
        art, antiques, collectibles, electronics, or other items that have a
        significant monetary value. Coverage under the Policy is also excluded for:
        (XVIII) all risks of a shipment being re-routed, stopped in transit, or
        retrieved at a point other than the original consigned delivery point
        pursuant to any instruction or representation to the designated approved
        carrier by a fraudster or imposter. Collect on delivery (“C.O.D.”) shipments
        may be insured, however the C.O.D. payments are not covered and there is no
        coverage for the collection/return of any C.O.D. payments. Customer is
        liable (not Parcel Pro) for any such C.O.D. services or issues arising from
        or in connection with a C.O.D. shipment, including but not limited to, the
        failure to collect at delivery and any consequential or indirect damages,
        such as loss of market, depreciation, or diminution in value as a result of
        repair, which are not covered. In the event of loss or damage to pairs or
        sets, recovery will be limited exclusively to the item lost or damaged.
    </p>
    <p>
        <strong
        >(i) Requirement of Inspection and Exception to Carrier at Time of
            Delivery: </strong
        >The consignee or its representative must carefully inspect the shipment
        immediately at time of delivery in the presence of the delivering carrier's
        personnel and make written exception with such personnel for any evidence of
        damage, loss, tampering, penetration or invasion of the shipping package.
        Except for disappearance of the entire package in transit prior to delivery,
        failure to make written exception with the delivering carrier will preclude
        insurance coverage for any loss or damage which you may discover. Delivery
        accepted under a condition of “subject to inspection” or similar language
        will not constitute the required written exception.
    </p>
    <p>
        <strong>(j) Claim Reporting Limitation:</strong> You should immediately
        report to Parcel Pro in writing any loss or damage to insured goods, but, in
        order for a claim to be eligible for coverage under the Policy, you must in
        all events do so no later than sixty (60) days from the date of shipment
        delivery, or in the event of non-delivery, (60) days from the date of
        scheduled delivery or if none then from the date the shipment should have
        reasonably been delivered to the original consignee. In the event of a
        covered refused shipment returned, this period shall be increased to
        seventy-five (75) days.
    </p>
    <p>
        <strong>(k) Claim Process:</strong> By making any claim, you warrant that
        you are the sole owner of such claim and that you have not released any
        third party from liability for the claim (including but not limited to the
        designated approved carrier). The underwriter of the applicable Policy (the
        “Insurance Underwriter”) has the exclusive authority to determine all issues
        of insurance coverage and claim settlement. You, the shipper and consignee
        must all fully cooperate in providing all documentation and information
        pertinent to a claim, including an examination under oath if requested.
        Should the Insurance Underwriter dispute liability or coverage for any
        reason, you shall have no recourse against Parcel Pro and Parcel Pro shall
        not be under any responsibility or liability in relation thereto. Unless
        prohibited by applicable law, any dispute between you and the Insurance
        Underwriter shall be resolved by binding arbitration pursuant to Section 7
        of this Agreement.
    </p>
    <p>
        <strong>(l) Effect of Claim Payment:</strong> Payment of any claim shall be
        without precedent as to any other or future claim and shall be without
        prejudice to any rights, remedies or defenses inuring to the Insurance
        Underwriter’s benefit under the Policy and these T&amp;Cs. Upon any claim
        payment, the Insurance Underwriter is automatically assigned and subrogated
        to any and all rights of recoveries against all third parties relating to
        the claim, including but not limited to the designated approved carrier. You
        agree you will cooperate with subrogation efforts by the Insurance
        Underwriter and you hereby allow the Insurance Underwriter to pursue claims
        with, and file legal actions against, third parties in your name. All
        subrogation recoveries from third parties by the Insurance Underwriter shall
        be the sole property of the Insurance Underwriter.&nbsp;
    </p>
    <p>
        <strong>(m) Notice of Full and Additional Terms: </strong>Under all
        circumstances, the Policy shall control and determine insurance coverage.
        However, in the event the Policy conflicts with any terms and conditions
        stated herein, the contractual terms of these T&amp;Cs and/or of any
        applicable signed written addendum shall control and be binding. The name
        and contact information of the Insurance Underwriter is available upon
        request and you may also make a written request to Parcel Pro to obtain a
        copy of the Policy. Parcel Pro reserves the right to change Insurance
        Underwriters and to allow insuring terms or rates for its coverage to be
        modified in Parcel Pro’s sole discretion at any time and without notice.
    </p>
    <ol start="5">
        <li>
            <strong> SHIPPING RULES &amp; RESTRICTIONS FOR COVERAGE:</strong> In order
            to ship with Parcel Pro and to maintain eligibility for coverage under the
            Policy (if applicable), you must strictly abide by the following shipping
            rules and restrictions which are condition precedents for any claim
            recovery:
        </li>
    </ol>
    <p>
        <strong>(a) Notify the Consignee:</strong> You must notify the consignee of
        the shipment and content prior to delivery and of the consignee’s obligation
        to give timely notice of non-receipt and to carefully inspect the shipment
        at time of delivery in the presence of the delivering carrier's personnel
        and to make written exception with such personnel for any evidence of
        damage, loss, tampering, penetration or invasion of the shipping package.
        The consignee’s failure to do so will preclude recovery.&nbsp;
    </p>
    <p>
        <strong>(b) Double Box Required; Envelopes Not Eligible: </strong>Unless
        otherwise authorized by Parcel Pro in a signed written addendum to these
        T&amp;Cs, (1) all packages must be double boxed in new boxes and packaging
        and must include the tracking number on the inner box, which must not be a
        small jewelry box which could be easily identified and/or discarded; and (2)
        envelopes and packs are not eligible for coverage.
    </p>
    <p>
        <strong>(c) Billing Options; Restrictions on Use: </strong>When preparing
        packages, you may use the carrier account number associated with Parcel Pro,
        as may be provided by Parcel Pro, or you may use your independent account
        number with the carrier if that carrier has been designated by Parcel Pro as
        an approved carrier for your shipments. You may not authorize the usage of
        your account number by any other person or entity without the prior written
        consent of Parcel Pro. You shall be solely and fully responsible for charges
        resulting from the use of YOUR ACCOUNT OR OF any account numbers provided to
        you by Parcel Pro, whether or not such use is authorized by you. YOU SHALL
        ALSO BE SOLELY AND FULLY RESPONSIBLE FOR ANY REBILLING, PROCESSING FEES OR
        RELATED EXPENSES ASSOCIATED WITH SUCH ACCOUNT(S) OR THE MISUSE THEREOF.
    </p>
    <p>
        <strong>(d) Package and Tender to Carrier: </strong>All packages must be
        properly packed and sealed to withstand the ordinary rigors of shipment.
        Unless otherwise authorized by Parcel Pro in writing, and except as
        otherwise set forth herein, packages must be picked up at a shipper
        location, handed to a carrier staff driver or taken to a carrier or Parcel
        Pro staffed location. You must obtain a receipt for any packages dropped off
        at a retail location that has been authorized by Parcel Pro in writing. You
        must not use unmanned drop boxes or any location without carrier employed
        personnel.
    </p>
    <p>
        <strong
        >(e) Requesting Coverage; Report Shipments to Parcel Pro Same Day: </strong
        >To request coverage under the Policy for any shipment, you must provide
        specific information regarding the shipment to Parcel Pro, in each case in
        accordance with the Instructions. Further, as a condition precedent to
        protection under the Policy, you must report shipments to Parcel Pro on the
        same day of shipping. IT IS YOUR SOLE RESPONSIBILITY TO CONFIRM ANY ELECTED
        COVERAGE FOR YOUR SHIPMENT, AS MAY BE VERIFIED ONLINE AFTER LOGIN AT
        <a href="https://web.parcelpro.com/">HTTPS://WEB.PARCELPRO.COM/</a> OR BY
        CONTACTING YOUR PARCEL PRO REPRESENTATIVE.
    </p>
    <p>
        <strong>(f) Provide an Insurable Value: </strong>Packages reported without a
        value for insurance shall be uninsured shipments and any recovery under
        these T&amp;Cs will be limited to USD $100 or less. IT IS YOUR SOLE
        RESPONSIBILITY TO REPORT A VALUE FOR INSURANCE IN ACCORDANCE WITH THE
        INSTRUCTIONS, AND YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY CHARGES
        RESULTING FROM SUCH REPORT.
    </p>
    <p>
        <strong>(g) Do not Reveal Package Content: </strong>Unless otherwise
        authorized by Parcel Pro in writing, you must not allow any indication of
        high value content to appear on a waybill, labeling or package exterior. For
        example, never reference brands or auction houses or use words such as
        “jewelry,” “watch,” “diamond,” “pearl,” or abbreviations such as DIA. This
        rule does not apply where international regulations require that such
        information be included.&nbsp;
    </p>
    <p>
        <strong>(h) Do not Declare a Value with the Carrier: </strong>&nbsp;If you
        intend to protect your goods under the Policy, unless otherwise authorized
        by Parcel Pro in writing, you should not declare a value for liability or
        any other purpose with the approved carrier or any other vendor. Otherwise,
        you may void coverage under the Policy. In the event Parcel Pro instructs
        you to declare a value with any vendor, such declaration of value shall be
        for other purposes and shall not result in the increased liability of that
        vendor.
    </p>
    <p>
        <strong>(i) Shipping Reports: </strong>Unless otherwise authorized by Parcel
        Pro in writing, you must never give to the carrier or ship with the package
        your daily shipping report and the values shipped; these are for your
        records only and must be treated by you as confidential.
    </p>
    <p>
        <strong>(j) Certain origin Zip Codes Require Special Instructions: </strong
        >On packages sent from New York zip codes 10036, 10017 and from Los Angeles
        zip codes 90013, 90014, 90015, 90016 and 90017, the shipper must use an
        alternate address on the carrier’s waybill that is not one of these zip
        codes. You may use Parcel Pro’s office address for this purpose.
    </p>
    <p>
        <strong
        >(k) Certain destination Zip Codes Require Special Permission and
            Instructions: </strong
        >Packages with a declared value for insurance of US$5,000 or greater and
        being sent to New York zip codes 10036 or 10017 are subject to special
        permission and instructions. All packages being sent to Los Angeles zip
        codes 90007, 90013, 90014, 90015, 90016, 90017, 90057 or to Beverly Hills
        area zip codes 90048, 90209, 90210, 90211, 90212, 90213 or to New York zip
        codes 10011, 10016, 10017, 10019, 10021, 10036, 10065, 10075 are also
        subject to special permission and instructions. For shipments not generated
        using Parcel Pro’s online tools (website, mobile applications, API), call
        Parcel Pro for additional information and instructions. Parcel Pro reserves
        the right to implement special permission and instruction requirements for
        additional regions upon notice.
    </p>
    <p>
        <strong>(l) Furnish Accurate and Complete Information:</strong> You must
        accurately and comprehensively provide all information and documentation
        necessary for Parcel Pro to perform its services hereunder, upon which
        Parcel Pro and the Insurance Underwriter will rely. Your errors or omissions
        in furnishing information and documentation may void insurance coverage and
        may result in losses and liabilities for which you will be responsible.
    </p>
    <p>
        <strong>(m) Maintain Packaging: </strong>No claims for loss or damage shall
        be valid unless the package, inner cartons, packing and contents have been
        preserved until made available for inspection by Parcel Pro or the Insurance
        Underwriter.&nbsp;
    </p>
    <ol start="6">
        <li>
            <strong> COMPENSATION OF PARCEL PRO: </strong>The compensation of Parcel
            Pro for its services shall be included with and is in addition to the
            rates and charges of all vendors selected by Parcel Pro for the services
            provided. However, duties and taxes for shipments are
            <strong><u>not</u></strong> included. In the event that any duties and/or
            taxes for any shipment associated with you are invoiced to the account of
            Parcel Pro and/or paid by Parcel Pro, then Parcel Pro will charge you such
            amount(s) plus a US$30 administrative fee per shipment at the first
            instance and up to $100 for each instance thereafter, and you agree that
            Parcel Pro may automatically charge such amount(s) plus the administrative
            fee to the credit card associated with your account. You agree to pay all
            charges and to comply with all terms of credit, if any. The shipper,
            consignee and you shall be jointly and severally liable for all charges
            owed to Parcel Pro and for any fines, penalties or other damages or
            liabilities to third parties incurred by Parcel Pro as a result of any
            shipment. Charges shall be refunded in the event of cancellation prior to
            time of package tender but shall not be refunded in the event of any loss
            not covered by the Policy. Parcel Pro shall have a general lien on any
            claim settlement and on all goods which are pending delivery for all
            outstanding charges, expenses or advances incurred by Parcel Pro in
            connection with any of your shipments. Unless all outstanding charges are
            satisfied within thirty (30) days of written notice of lien from Parcel
            Pro, Parcel Pro shall have the right to deduct such charges, expenses or
            advances from any claim payment otherwise payable to you and/or to sell
            goods at public or private sale or auction, with any net payment or
            proceeds being paid or refunded to you and any net deficiencies remaining
            owing.&nbsp;
        </li>
    </ol>
    <ol start="7">
        <li>
            <strong>AGREEMENT TO ARBITRATE CLAIMS: </strong>You and Parcel Pro agree
            that, except for disputes that qualify for state courts of limited
            jurisdiction (such as small claims, justice of the peace, magistrate
            court, and similar courts with monetary limits of less than $30,000 on
            their jurisdictions over civil disputes), any controversy or claim,
            whether at law or equity, arising out of or related to these T&amp;Cs or
            the provision of services by Parcel Pro, regardless of the date of accrual
            of such dispute, shall be resolved in its entirety by individual (not
            class-wide nor collective) binding arbitration. You and Parcel Pro
            expressly agree that the foregoing obligation to arbitrate disputes
            regardless of the date of accrual of such disputes includes, but is not
            limited to, preexisting disputes and disputes that arise from or relate to
            shipments made at the time of a previous version of these T&amp;Cs.<br /><br />
            Arbitration is the submission of a dispute to a neutral arbitrator,
            instead of a judge or jury, for a final and binding decision, known as an
            “award.” Arbitration provides for more limited discovery than in court and
            is subject to limited review by courts. Each party has an opportunity to
            present evidence to the arbitrator in writing or through witnesses. An
            arbitrator can only award the same damages and relief that a court can
            award under the law and must honor these T&amp;Cs. You and Parcel Pro
            agree that our sole relationship is a contractual one governed by the
            T&amp;Cs or other written agreements between you and Parcel Pro.
        </li>
    </ol>
    <ul>
        <li>
            <strong>Institutional Arbitration: </strong>The arbitration shall be
            conducted by the American Arbitration Association (<strong>AAA</strong>)
            in accordance with its Commercial Arbitration Rules or, provided that you
            are an individual consumer and using Parcel Pro’s services for personal
            (not business) use, the Consumer Arbitration Rules (collectively referred
            to herein as the <strong>AAA Rules</strong>), and judgment on the award
            may be entered in any court of competent jurisdiction. The AAA Rules,
            including instructions for how to initiate arbitration, are available at
            http://www.adr.org or by calling the AAA at 1-800-778-7879. The arbitrator
            shall decide all issues of the case on the basis of the applicable law,
            not equity. If you initiate arbitration, you must serve Parcel Pro’s
            registered agent for service of process, Corporation Service Company,
            which has locations in every state. Information also can be found on the
            website of your local Secretary of State.
        </li>
    </ul>
    <p>
        <strong
        >Any arbitration under these T&amp;Cs will take place on an individual
            basis; class, mass, consolidated or combined actions or arbitrations or
            proceeding as a private attorney general are not permitted. You and Parcel
            Pro each waive the right to trial by jury. You and Parcel Pro are further
            giving up the ability to participate in a class, mass, consolidated or
            combined action or arbitration.</strong
        >
    </p>
    <ul>
        <li>
            <strong
            >Place of Arbitration/Number of Arbitrators/Costs of
                Arbitration/Governing Law/Survival: </strong
            >Any arbitration will take place in the county where the claimant resides
            and will be determined by a single arbitrator. Solely with respect to a
            claimant that does not reside in the United States, the arbitration will
            take place in Fulton County, Georgia. The initial filing fee required of
            claimant by the AAA Rules shall be paid by claimant to the extent such fee
            does not exceed the amount of the fee required to commence a similar
            action in a court that otherwise would have jurisdiction. For all
            non-frivolous complaints, Parcel Pro will pay the amount of such fee in
            excess of that amount. The arbitrator will allocate the administrative
            costs and arbitral fees consistent with the applicable rules of the AAA.
            Reasonable attorney’s fees and expenses will be allocated or awarded only
            to the extent such allocation or award is available under applicable law.
        </li>
    </ul>
    <p>
        All issues are for the arbitrator to decide, except that issues relating to
        the scope, application, and enforceability of the arbitration provision,
        issues relating to the interpretation or application of the provision
        guarantying access to state courts of limited jurisdiction for qualifying
        disputes, and issues relating to interpretation or application of the
        prohibition on participation in class, mass, consolidated or combined
        actions or arbitrations or proceedings as a private attorney general, are
        all issues for a court to decide. The Federal Arbitration Act governs the
        interpretation and enforcement of this provision. This agreement to
        arbitrate shall survive termination of these T&amp;Cs.
    </p>
    <p>
        The arbitration demand must plead with particularity facts that demonstrate
        the dispute does not qualify for state courts of limited jurisdiction (such
        as small claims, justice of the peace, magistrate court, and similar courts
        with monetary limits of less than $30,000 on their jurisdictions over civil
        disputes). Where the dispute relates to a shipment, in order to commence
        arbitration, the arbitration demand must provide particularized information
        that identifies the shipment or shipments, such as by the date(s) of
        shipment and tracking number(s).&nbsp;
    </p>
    <ul>
        <li>
            <strong>Severability: </strong>Notwithstanding anything to the contrary in
            the AAA Rules, if any part of this arbitration provision is deemed invalid
            or ineffective for any reason, this shall not affect the validity or
            enforceability of the remainder of this arbitration provision, and the
            arbitrator shall have the authority to amend any provisions deemed invalid
            or ineffective to make the same valid and enforceable.
        </li>
    </ul>
    <ul>
        <li>
            <strong>Desk Arbitration: </strong>For all disputes concerning an amount
            less than fifteen thousand dollars ($15,000.00), the parties shall submit
            their arguments and evidence to the arbitrator in writing and the
            arbitrator shall make an award based only on the documents; no hearing
            will be held unless the arbitrator in his or her discretion, and upon
            request of a party, decides it is a necessity to require an in-person
            hearing. For a dispute governed by the AAA Consumer Arbitration Rules
            where the arbitrator issues an award in favor of claimant, and concerning
            an award between fifteen thousand dollars ($15,000.00) and fifty thousand
            dollars ($50,000.00), inclusive, Parcel Pro shall refund your filing fee
            under the AAA Rules following conclusion of the arbitration, provided that
            you agree that both parties shall submit their arguments and evidence to
            the arbitrator in writing and that the arbitrator shall make an award
            based only on the documents, without a hearing being held. Notwithstanding
            this provision, the parties may agree to proceed with desk arbitration at
            any time.
        </li>
    </ul>
    <ul>
        <li>
            <strong>Access to Small Claims Courts: </strong>All parties shall retain
            the right to seek adjudication in a state court of limited jurisdiction,
            such as small claims, justice of the peace, magistrate court, and similar
            courts with monetary limits of less than $30,000 on their jurisdiction
            over civil disputes, for individual disputes within the scope of such
            court’s jurisdiction. If the claims asserted in any request or demand for
            arbitration could have been brought in such a court of limited
            jurisdiction, then either party may elect to require that the claims be
            heard in such court of limited jurisdiction, rather than in arbitration,
            by notifying the other party of that election in writing.
        </li>
    </ul>
    <ul>
        <li>
            <strong>Acknowledgements: </strong>Pursuant to these T&amp;Cs, you and
            Parcel Pro acknowledge and agree that each party is hereby:
        </li>
    </ul>
    <ol>
        <li>
            WAIVING THE RIGHT TO HAVE A TRIAL BY JURY TO RESOLVE ANY DISPUTE ALLEGED
            AGAINST YOU, PARCEL PRO, OR ANY PERSON OR ENTITY IN CONNECTION WITH THESE
            T&amp;Cs, ANY POLICY AND/OR COVERAGE OF SHIPMENTS THEREUNDER;
        </li>
    </ol>
    <ol>
        <li>
            WAIVING THE RIGHT TO HAVE A COURT, OTHER THAN A STATE COURT OF LIMITED
            JURISDICTION AS DEFINED ABOVE, RESOLVE ANY DISPUTE ALLEGED AGAINST YOU,
            PARCEL PRO OR ANY PERSON OR ENTITY IN CONNECTION WITH THESE T&amp;Cs, ANY
            POLICY AND/OR COVERAGE OF SHIPMENTS THEREUNDER;
        </li>
    </ol>
    <ol>
        <li>
            WAIVING THE RIGHT TO HAVE A COURT REVIEW ANY DECISION OR AWARD OF AN
            ARBITRATOR, WHETHER INTERIM OR FINAL, EXCEPT FOR APPEALS BASED ON THOSE
            GROUNDS FOR VACATUR EXPRESSLY SET FORTH IN SECTION 10 OF THE FEDERAL
            ARBITRATION ACT; AND
        </li>
    </ol>
    <ol>
        <li>
            WAIVING THE RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY
            GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, JOIN AS A CLASS MEMBER,
            AND/OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CUSTOMERS IN ANY CLASS,
            MASS, CONSOLIDATED OR COMBINED ACTION OR ARBITRATION FILED AGAINST YOU,
            PARCEL PRO, OR ANY PERSON OR ENTITY IN CONNECTION WITH THESE T&amp;Cs, ANY
            POLICY AND/OR COVERAGE OF SHIPMENTS THEREUNDER.
        </li>
    </ol>
    <ul>
        <li>
            <strong>Award: </strong>The arbitrator may award money or equitable relief
            in favor of only the individual party seeking relief and only to the
            extent necessary to provide relief warranted by that party’s individual
            claim. Similarly, an arbitration award and any judgment confirming the
            arbitration award apply only to that specific case; the judgment cannot be
            used in any other case except to enforce the award itself. To reduce the
            time and expense of the arbitration, the arbitrator will not provide a
            statement of reasons for the award unless a brief explanation of the
            reasons is requested by one of the parties. Unless both you and us agree
            otherwise, the arbitrator may not consolidate more than one person’s
            claims and may not otherwise preside over any form of a representative,
            private attorney general or class proceeding.
        </li>
    </ul>
    <ul>
        <li>
            <strong>Confidentiality of Arbitration: </strong>Notwithstanding anything
            to the contrary in the AAA Rules, you and Parcel Pro agree that the filing
            of arbitration, the arbitration proceeding, any documents exchanged or
            produced during the arbitration proceeding, any briefs or other documents
            prepared for the arbitration, and the arbitral award shall all be kept
            fully confidential and shall not be disclosed to any other person or
            entity, except to the extent necessary to enforce this arbitration
            provision, any arbitral award or other rights of the parties, or as
            required by law or court order. This confidentiality provision does not
            foreclose the AAA from reporting certain consumer arbitration case
            information as required by state law.
        </li>
    </ul>
    <ol start="8">
        <li>
            <strong> LIMITATION OF LIABILITY and DISCLAIMER OF Warranties</strong
            ><strong>:</strong>
        </li>
    </ol>
    <p>
        <strong>(a)</strong> <strong>LIABILITY OF PARCEL PRO: </strong>As a
        shipper’s-agent in arranging, organizing and/or managing transportation and
        providing ancillary services, including facilitating coverage under the
        Policy, Parcel Pro has no carriage liability and is only liable for its
        independent negligence, errors and omissions for which it limits its
        liability to USD $50 per shipment. In the event your goods are lost or
        damaged while in the custody of Parcel Pro, liability shall be limited to
        USD $100 per shipment unless you separately declare in writing a higher
        value with Parcel Pro for liability purposes. You agree to indemnify Parcel
        Pro of any third-party claim arising from services performed for you which
        seeks greater liability than that assumed by Parcel Pro under these
        T&amp;Cs. NOTWITHSTANDING THE FOREGOING LIMITATION, EXCEPT AS OTHERWISE
        REQUIRED BY APPLICABLE LAW, YOU AGREE THAT PARCEL PRO WILL NOT BE LIABLE TO
        YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR
        SPECIAL DAMAGES WHATSOEVER, INCLUDING LOST PROFITS, REGARDLESS OF THE FORM
        OF THE ACTION OR THE THEORY OF RECOVERY (INCLUDING NEGLIGENCE OR
        RECKLESSNESS), AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR
        WHETHER PARCEL PRO OR ANY PERSON OR ENTITY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p>
        <strong>(b) Disclaimer of Warranties: </strong>EXCEPT AS OTHERWISE EXPRESSLY
        STATED IN THESE T&amp;Cs, PARCEL PRO MAKES NO WARRANTIES, EITHER EXPRESS OR
        IMPLIED, OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY LAW. EXCEPT AS
        OTHERWISE EXPRESSLY STATED IN THESE T&amp;Cs, PARCEL PRO EXPRESSLY
        DISCLAIMS, AND YOU EXPRESSLY WAIVE, ANY AND ALL WARRANTIES, INCLUDING THOSE
        OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NO DESCRIPTIONS OR
        SPECIFICATIONS, WHETHER OR NOT INCORPORATED INTO THESE T&amp;Cs, SHALL
        CONSTITUTE WARRANTIES OF ANY KIND.
    </p>
    <ol start="9">
        <li>
            <strong> INDEMNIFICATION: </strong>You agree to indemnify, defend, and
            hold harmless, at your sole cost and expense, Parcel Pro and its
            affiliated companies, their officers, directors, employees, agents, and
            their successors and assigns, from and against any and all any losses,
            damages, demands, claims, liabilities, costs (including legal costs) and
            expenses of any kind, suffered or incurred by any of them, arising from or
            relating to any breach or violation of these T&amp;Cs, or any violation of
            applicable law, rule or regulation or any rights of another, by you or
            your officers, directors, employees or agents.
        </li>
    </ol>
    <ol start="10">
        <li>
            <strong> EFFECTIVENESS; GOVERNING LAW:</strong> These T&amp;Cs shall
            become effective when accepted (or deemed to have been accepted) by you
            and shall be governed by and construed in accordance with the internal
            laws of the State of Georgia, without regard to its conflict of laws
            principles.
        </li>
    </ol>
    <ol start="11">
        <li>
            <strong>
                ENTIRE AGREEMENT; CUSTOMER WARRANTIES; TERMS AND CONDITIONS SUBJECT TO
                CHANGE:</strong
            >
            Any waiver of or amendment to any provision of these T&amp;Cs shall be
            effective only if in writing and signed by the party against which
            enforcement is sought. These T&amp;Cs together with the rate sheet and
            agreement shall constitute the entire agreement between you and Parcel
            Pro. You warrant all goods tendered are legally compliant for the shipment
            requested and that you have the authority of your company, of the shipper,
            of the consignee and of any third party with any interest in the goods
            shipped to bind them to these T&amp;Cs and you hereby do so. In preparing
            and making export filings and performing all other services hereunder,
            Parcel Pro shall be entitled to rely upon the accuracy and completeness of
            all information provided by you and/or the shipper. Parcel Pro shall have
            the agency authority which is hereby granted to act on behalf of the
            shipper, consignee and/or any other beneficial owner of the goods to make
            export filings and perform all other tasks reasonably necessary to perform
            the services.&nbsp;
        </li>
    </ol>
    <section style="text-align: right">
        <div style="font-size: 0.8em; font-weight: 500">
            U.S. and Canada - February 22, 2024
        </div>
    </section>
</div>
