<head>
    <title> Find a Pickup Location </title>
</head>

<body>
    <section class="find-pickup-location">
        <h2> Find a Pickup Location <a> Learn More </a> </h2>
        <div class="top-actions">
            <button mat-dialog-close class="close-icon" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </section>
    
    <section class="origin"> 
        <h3> Origin </h3>
        <p> 36 HOBART AVE, RUTHERFORD, NJ, 07070 </p>
    </section>

    <section class="slider-range-container">
        <div class="slider-range-wrapper">
            <mat-label class="range-label"> Range </mat-label>
            
            <mat-slider>
                <input matSliderThumb>
            </mat-slider>

            <p> 10 miles </p>

            <div class="max-items-dropdown"> 
                <form [formGroup]="formGroup">
                    <mat-form-field appearance="outline" style="width: 124px;" floatLabel="always">
                        <mat-label> Max Items </mat-label>
                        <mat-select placeholder="{{ maxItems }}" [(ngModel)]="maxItems" (selectionChange)="setResultPerPage(formGroup.value)" formControlName="ddlPageSize">
                            <mat-option *ngFor="let item of maxItemsOptions" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </section>

    <section class="pickup-locations">
        <ol>
            <li *ngFor="let location of locations">
                <span> {{ location.name }}</span>
                <p> {{ location.address }} <br> {{ location.city }} <br> {{ location.state }} <br> {{ location.zipcode }} </p>
                <p> Drop-off Time <br> Mon-Fri: 9:00AM- 7:30pm <br> Sat:9:00am-4:00pm <br> Sun: Closed </p>
                <div class="divider"> </div>
            </li>
            
        </ol>
    </section>
    
</body>
