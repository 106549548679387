import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    TermsConditionsStaticModule,
} from '../shared/components/terms-conditions-static/terms-conditions-static.module';
import { SharedModule } from '../shared/shared.module';
import { AccountInformationComponent } from './account-information/account-information.component';
import { BillingInformationComponent } from './billing-information/billing-information.component';
import { BusinessInformationComponent } from './business-information/business-information.component';
import { InsuranceShipmentsComponent } from './insurance-shipments/insurance-shipments.component';
import { SignUp } from './model/sign-up.model';
import { SharedService } from './services/shared.service';
import { SignUpService } from './services/signup.service';

import { SignUpComponent } from './sign-up.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        TermsConditionsStaticModule,
    ],
    declarations: [
        SignUpComponent,
        BillingInformationComponent,
        InsuranceShipmentsComponent,
        BusinessInformationComponent,
        AccountInformationComponent,
    ],
    providers: [
        SignUp,
        SharedService,
        SignUpService,
    ],
    exports: [
        BillingInformationComponent,
        InsuranceShipmentsComponent,
        BusinessInformationComponent,
        AccountInformationComponent,
    ],
})
export class SignUpModule {
}
