import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

import { SignUpDropdowns } from '../../../config/dropdown-config';
import { TermsConditionsStaticDialogService} from '../../shared/components/terms-conditions-static/dialog/services/terms-conditions-static-dialog.service';
import { ValidationService } from '../../shared/services/validation/validation.service';
import { SharedService } from '../services/shared.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'upsc-insurance-shipments',
    templateUrl: './insurance-shipments.component.html',
    styleUrls: ['./insurance-shipments.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class InsuranceShipmentsComponent implements OnInit {

    @Output() notifyForm: EventEmitter<any> = new EventEmitter<any>();
    insuranceFormGroup: UntypedFormGroup;
    claimHistories: UntypedFormArray;
    public signUpDropdowns = SignUpDropdowns;
    public claimYears = [];
    public country: string = 'US';
    public geographicalSumError: boolean = false;
    public serviceLevelSumError: boolean = false;
    public noClaims: boolean = false;

    public averageValuePerPackageDropdown = this.signUpDropdowns.averageValuePerPackageDropdown;
    public maximumValuePerPackageDropdown = this.signUpDropdowns.maximumValuePerPackageDropdown;

    public constructor(private formBuilder: UntypedFormBuilder,
                       private sharedService: SharedService,
                       private validationService: ValidationService,
                       private readonly termsConditionsStaticService: TermsConditionsStaticDialogService,
    ) { }

    ngOnInit() {
        this.populateClaimYears();
        this.initForm();
        this.monitorValueChanges();
        this.onCountryChanges();
    }

    initForm() {
        this.insuranceFormGroup = this.formBuilder.group({
            averageValuePerPackage: ['', Validators.compose([Validators.required])],
            maximumValuePerPackage: ['', Validators.compose([Validators.required])],
            maximumValueFromSingleLocation: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(500000)])],
            maximumValueToSingleLocation: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(500000)])],
            totalAnnualPackagesInsured: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(500000)])],
            totalAnnualSales: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(500000)])],
            domesticPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            internationalPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            airTransportationPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            groundTransportationPercent: ['0', Validators.compose([Validators.required, Validators.min(0), Validators.max(100), Validators.maxLength(3)])],
            shippingFrequencyPackagesShipped: ['', Validators.compose([Validators.required])],
            noClaimsLast24Months: [false],

            claimHistory1Year: [''],
            claimHistory1Courier: [''],
            claimHistory1InsuredValue: [''],
            claimHistory1AmountPaidByInsurer: [''],
            claimHistory2Year: [''],
            claimHistory2Courier: [''],
            claimHistory2InsuredValue: [''],
            claimHistory2AmountPaidByInsurer: [''],
            claimHistory3Year: [''],
            claimHistory3Courier: [''],
            claimHistory3InsuredValue: [''],
            claimHistory3AmountPaidByInsurer: [''],
            BusinessRef1Company: ['', Validators.compose([Validators.required])],
            BusinessRef1Contact: ['', Validators.compose([Validators.required])],
            BusinessRef1YearsKnown: ['', Validators.compose([Validators.required, Validators.max(255)])],
            BusinessRef1Phone: ['', Validators.compose([Validators.required])],
            BusinessRef2Company: [''],
            BusinessRef2Contact: [''],
            BusinessRef2YearsKnown: ['', Validators.compose([Validators.max(255)])],
            BusinessRef2Phone: [''],
            BusinessRef3Company: [''],
            BusinessRef3Contact: [''],
            BusinessRef3YearsKnown: ['', Validators.compose([Validators.max(255)])],
            BusinessRef3Phone: [''],
            fullName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            title: ['', Validators.compose([Validators.required])],
            agreement: [false, Validators.requiredTrue],
            restrictedCommoditiesAcknowledgement: [false, Validators.requiredTrue],
        });
        this.monitorValueChanges();
    }

    private getSumValidationErrorForGeographicalLimits(): void {
        const controls = ['domesticPercent', 'internationalPercent'];
        controls.forEach(control => {
            this.insuranceFormGroup.get(control)?.valueChanges.subscribe(() => {
                const domesticPercent = this.insuranceFormGroup.get('domesticPercent')?.value;
                const internationalPercent = this.insuranceFormGroup.get('internationalPercent')?.value;
                this.geographicalSumError = Number(domesticPercent) + Number(internationalPercent) !== 100;
            });
        });
    }

    private getSumValidationErrorForServiceLevel(): void {
        const controls = ['airTransportationPercent', 'groundTransportationPercent'];
        controls.forEach(control => {
            this.insuranceFormGroup.get(control)?.valueChanges.subscribe(() => {
                const airTransportationPercent = this.insuranceFormGroup.get('airTransportationPercent')?.value;
                const groundTransportationPercent = this.insuranceFormGroup.get('groundTransportationPercent')?.value;
                this.serviceLevelSumError = Number(airTransportationPercent) + Number(groundTransportationPercent) !== 100;
            });
        });
    }

    monitorValueChanges() {
        this.insuranceFormGroup.valueChanges
            .subscribe(() => {
                this.notifyForm.emit(this.insuranceFormGroup);
            });
        this.getSumValidationErrorForGeographicalLimits();
        this.getSumValidationErrorForServiceLevel();
    }

    onCountryChanges() {
        this.sharedService.Country.subscribe(country => {
            this.country = country;
            this.validationChanges();
        });
    }

    validationChanges() {
        if (this.country.toLowerCase() === 'us' || this.country.toLowerCase() === 'ca') {
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef1Phone,
                Validators.compose([Validators.required, Validators.pattern(/^\d{3}\-\d{3}\-\d{4}$/)]));
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef2Phone,
                Validators.compose([Validators.pattern(/^\d{3}\-\d{3}\-\d{4}$/)]));
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef3Phone,
                Validators.compose([Validators.pattern(/^\d{3}\-\d{3}\-\d{4}$/)]));
        } else {
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef1Phone,
                Validators.compose([Validators.required, this.validationService.isInteger(), Validators.maxLength(15)]));
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef2Phone,
                Validators.compose([this.validationService.isInteger(), Validators.maxLength(15)]));
            this.validationService.setFormControlValidators(this.insuranceFormGroup.controls.BusinessRef3Phone,
                Validators.compose([this.validationService.isInteger(), Validators.maxLength(15)]));
        }
    }

    populateClaimYears() {
        let exitPoint;
        let i = 0;
        while (exitPoint !== '1970') {
            exitPoint = moment().subtract(i, 'years').format('YYYY');
            this.claimYears.push(exitPoint);
            i++;
        }
    }

    addHyphen(element) {
        this.sharedService.setHyphen(element, this.country);
    }

    /* createClaimHistory(): FormGroup {
         return this.formBuilder.group({
             year: '',
             courier: '',
             insuredValue: '',
             amountPaidByInsurer: ''
         });
     }
 
     createBusinessReference(): FormGroup {
         return this.formBuilder.group({
             company: '',
             contact: '',
             yearsKnown: '',
             phone: ''
         });
     }
 
     addClaimHistory(): void {
         this.claimHistories = this.insuranceFormGroup.get('claimHistories') as FormArray;
         this.claimHistories.push(this.createClaimHistory());
     } 

    toggleClaimHistory(event) {
        console.log(event.source.value);
        if (!event.source.value) {

        }
    }
    */
    public openTermsConditionsStaticDialog(event: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.termsConditionsStaticService.open();
    }

    public onNoClaimsChange(event: MatCheckboxChange) {
        this.noClaims = event.checked;
        this.insuranceFormGroup.get('noClaimsLast24Months')?.setValue(event.checked);

        if (event.checked) {
            this.resetClaimsHistoryValues();
        }
    }

    public resetClaimsHistoryValues(): void {
        this.insuranceFormGroup.patchValue({
            claimHistory1Year: '',
            claimHistory1Courier: '',
            claimHistory1InsuredValue: '',
            claimHistory1AmountPaidByInsurer: '',
            claimHistory2Year: '',
            claimHistory2Courier: '',
            claimHistory2InsuredValue: '',
            claimHistory2AmountPaidByInsurer: '',
            claimHistory3Year: '',
            claimHistory3Courier: '',
            claimHistory3InsuredValue: '',
            claimHistory3AmountPaidByInsurer: ''
        });
    }
}
