<div class="subscription-layout">
    <div class="subscription-content">
        <div class="header-panel">
            <div class="header">Hi, {{customer?.CorpContact.FirstName}} {{customer?.CorpContact.LastName}}.</div>
            <upsc-dd-remaining-credits [fullCredit]="ddFullCredit" [remainingCredit]="ddRemainingCredit"
                [subTypeId]="ddSubTypeID"></upsc-dd-remaining-credits>
        </div>

        <!-- The two greenish containers -->
        <div class="subscriber-panel-container">
            <div class="subscriber">
                <p> Subscriber since {{fullMonthNameYear}} </p>
                <div class="current-plan-container">
                    <img class="radio-icon" src="../../../assets/icons/Radio.svg" alt="Radio">
                    <div class="current-plan-info">
                        <strong>
                            <p class="current-plan-msg"> Current Plan </p>
                        </strong>
                        <p> {{this.subscribedDetails?.Searches}} scores per month </p>
                        <div class="renewal-container">
                            <p> {{this.level}} </p>
                            <p class="renew-date" *ngIf="!isSubscriptionCancelled">Auto-Renew <span [innerHTML]="formattedRenewDate"></span></p>
                            <p class="renew-date" *ngIf="isSubscriptionCancelled"> Cancelled on {{renewDate | date}} </p>
                        </div>
                    </div>
                </div>
                <div class="green-footer-of-container"></div>
            </div>

            <div class="subscriber">
                <p> Complimentary Tier Goal </p>
                <div class="monthly-shipments-container">
                    <div class="monthly-shipments-text">
                        <div class="monthly=shipments-title"> Monthly Shipments </div>
                        <mat-icon [matTooltip]="toolTip_popupMessage">info_outline</mat-icon>
                    </div>

                    <div id="gaugeContainer" class="gauge-container"
                        style="--gauge-width: 93%; --bullet-size: 0.563rem;">
                        <div class="gauge-wrapper">
                            <div id="gauge-progress-bar" class="gauge-progress"
                                style="--percent: {{labelPercent}} ; --label: {{labelVal}}"></div>
                            <div *ngIf="this.subscribedDetails?.CurrentSubType === 1">
                                <!-- 1 -->
                                <ul class="milestones">
                                    <li class="milestone active" style="--percent: 0; --label: '0'"></li>
                                    <li id="checkpoint1" class="milestone" style="--percent: 100; --label: '100'">
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="this.subscribedDetails?.CurrentSubType === 2">
                                <!-- 2 -->
                                <ul class="milestones">
                                    <li class="milestone active" style="--percent: 0; --label: '0'"></li>
                                    <!-- <li id="checkpoint1" class="milestone " style="--percent: 25; --label: '100'"></li>  -->
                                    <li id="checkpoint2" class="milestone " style="--percent: 50; --label: '100'"></li>
                                    <li id="checkpoint3" class="milestone" style="--percent: 100; --label: '200'"></li>

                                </ul>
                            </div>
                            <div
                                *ngIf="this.subscribedDetails?.CurrentSubType === 3 || this.subscribedDetails?.CurrentSubType === 4">
                                <!-- 3 -->
                                <ul class="milestones">
                                    <li class="milestone active" style="--percent: 0; --label: '0'"></li>
                                    <li id="checkpoint1" class="milestone " style="--percent: 25; --label: '100'"></li>
                                    <li id="checkpoint2" class="milestone " style="--percent: 50; --label: '200'"></li>
                                    <li id="checkpoint3" class="milestone" style="--percent: 100; --label: '400'"></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="learn-more-container">
                        <p class="upgrade-msg" *ngIf="!countExceeded && this.subscribedDetails?.CurrentSubType !== 4">Ship {{remainingValue}} more shipments to upgrade
                            to High Volume Program {{this.subscribedDetails?.CurrentSubType}}.</p>
                            <p class="upgrade-msg" *ngIf="!countExceeded  && this.subscribedDetails?.CurrentSubType === 4">Ship {{remainingValue}} more shipments to stay on High Volume Program 3.</p>
                        <p *ngIf="countExceeded">Ship 0 More</p>
                        <p class="learn-more-msg" (click)="learnMore()"> Learn More</p>
                    </div>
                </div>
                <div class="green-footer-of-container"></div>
            </div>
        </div>

        <!-- Features -->
        <div class="section">
            <div class="section-title">Features</div>
            <ul class="features">
                <li class="feature"><span>Proactive loss control to help improve outcomes</span></li>
                <li class="feature"><span>Integrated decisioning to streamline workflows</span></li>
                <li class="feature"><span>Delivery intelligence to support smarter shipping decisions</span></li>
                <li class="feature">
                    <span>Flexible alternate delivery options such as UPS Access Point<sup>&reg;</sup> locations</span>
                </li>
            </ul>
        </div>

        <!-- Links -->
        <div class="linksSection">
            <div class="links">
                <a class="link" (click)="feedback()" target="_blank">Send Feedback</a>
                <a class="link" *ngIf="((this.user.IsAdmin === true || this.user.IsGuest === false) && !isSubscriptionCancelled); else showDisabledCancelSubscriptionLink" (click)="cancelSubscription()" target="_blank">Cancel Subscription</a>
                <ng-template #showDisabledCancelSubscriptionLink>
                    <a class="link cancelSubscription disabled" matTooltip="{{ 'DDCancelledSubscription' | translate: {date: subscribedDetails.CurrentSubEndDate | date} }}" matTooltipPosition="above">Cancel Subscription</a>
                </ng-template>
                <a class="link" routerLink="/delivery-defense-faq">FAQ</a>
            </div>
        </div>

        <!-- Auto Renewal -->
        @if (false) {
        <div class="auto-Renewel">
            <div class="dropdown">
                <p class="auto-renewal-subscription-text"> Auto-Renewal Subscription </p>
                <img class="ddown" (click)="openRenwelPanel()" src="../../../assets/icons/arrow_drop_down.svg"
                    alt="arrow_drop_down">
            </div>
            <div *ngIf="showRenewelPanel" class="toggleBtn">
                <div class="togglePanel">
                    <p class="on-text">On</p>
                    <div class="custom-control custom-switch custom-switch-sm">
                        <input type="checkbox" disabled (change)="setValue($event)" class="custom-control-input"
                            id="customSwitch2" checked>
                        <label class="custom-control-label" for="customSwitch2"></label>
                    </div>
                </div>
            </div>
            <div *ngIf="showRenewelPanel" class="section">
                <div class="panel1">
                    <img class="checked" src="../../../assets/icons/checked.svg" alt="checked">
                    <p class="renewal-reminder">Renewal reminder</p>
                </div>
                <p>We’ll remind you about your renewal 3 days before you’re charged</p>
            </div>
        </div>
        }
    </div>

    <div class="layout-icons">
        <div class="label">
            <p class="ship-with-text">Ship With</p>
        </div>

        <div class="create-carrier-label create-ups-label-container">
            <div class="ship">
                <img class="ups_Flat_Shield" (click)="shipIconClick()"
                    src="../../../assets/icons/UPS_Flat_Shield_2Color_RGB 1.svg" alt="UPS_Flat_Shield_2Color_RGB">
            </div>
            <div class="label">
                <p class="create-ups-label"> Create UPS Label </p>
            </div>
        </div>

        <div class="create-carrier-label create-fedex-label-container">
            <div class="fedex">
                <img class="fedex_express" (click)="fedexIconClick()" src="../../../assets/icons/fedex-express-6 1.svg"
                    alt="fedex-express">
            </div>
            <div class="label">
                <p class="create-fedex-label"> Create Fedex Label </p>
            </div>
        </div>

    </div>
</div>



<!-- (ALVIN's msg) Don't know what this is, keep and ask kiang tmmr if we can remove -->
<!-- 
        </div>
        <div class="dropdown" *ngIf="showPlanDetails">
            <div id="header-dropdown" class="header-dropdown">
                <div id="ddSection" class="ddSection">
                    <h1>See All Monthly Subscription Plans</h1>
                    <img id="expand_more" class="expand_more" (click)="subscriptionPlans()"
                        src="../../../assets/icons/expand_more.svg" alt="expand_more">

                </div>
                <div *ngIf="showSubscriptionPlans" class="tablePanel">
                    <table class="my-table">
                        <thead class="my-table headers">
                            <tr>
                                <th></th>
                                <th class="sideHeader">Non Subscriber</th>
                                <th class="background1">Subscription</th>
                                <th class="background2">High Volume <br> Program 1</th>
                                <th class="background3">High Volume <br> Program 2</th>
                                <th class="background4">High Volume <br> Program 3</th>
                            </tr>
                        </thead>
                        <tbody class="my-table body">
                            <tr></tr>
                            <tr>
                                <td class="sideHeader">Monthly Subscription fee</td>
                                <td></td>
                                <td class="background1">Complimentary</td>
                                <td class="background2">Complimentary</td>
                                <td class="background3">Complimentary</td>
                                <td class="background4">Complimentary</td>

                            </tr>
                            <tr>
                                <td class="sideHeader">Eligibility</td>
                                <td></td>
                                <td class="background1">Ship less than 100 <br>
                                    boxes per month</td>
                                <td class="background2">Ship 100 boxes<br>
                                    per month</td>
                                <td class="background3">Ship 200 boxes<br>
                                    per month</td>
                                <td class="background4">Ship 400 boxes<br>
                                    per month</td>
                            </tr>
                            <tr>
                                <td class="sideHeader">Included Scores</td>
                                <td></td>
                                <td class="background1">50</td>
                                <td class="background2">200</td>
                                <td class="background3">300</td>
                                <td class="background4">1500</td>
                            </tr>
                            <tr>
                                <td class="sideHeader accessLabel">
                                    <h1 class="accessUPS">UPS Access Point<span class="rated">®</span></h1>
                                    <div class="accessPoint">Location</div>
                                </td>
                                <td class="dot">•</td>
                                <td class="dot background1">•</td>
                                <td class="dot background2">•</td>
                                <td class="dot background3">•</td>
                                <td class="dot background4">•</td>
                            </tr>
                            <tr>
                                <td class="sideHeader label">
                                    <div class="comingSoon">Coming Soon</div>
                                    Address Confidence Tool
                                </td>
                                <td></td>
                                <td class="dot background1">•</td>
                                <td class="dot background2">•</td>
                                <td class="dot background3">•</td>
                                <td class="dot background4">•</td>
                            </tr>
                            <tr>
                                <td class="sideHeader">Recently Searched Addresses*</td>
                                <td></td>
                                <td class="dot background1">•</td>
                                <td class="dot background2">•</td>
                                <td class="dot background3">•</td>
                                <td class="dot background4">•</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>
                        *Scores for duplicate addresses within 30 days of the original search will not be
                        charged<br>
                        For more information regarding plan eligibility, please email <a
                            href="mailto:amr@parcelpro.com">amr@parcelpro.com</a>
                    </h2>
                </div>

            </div> -->
