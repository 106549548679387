import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppState } from '../../app.state';
import { DeliveryDefenseService } from '../services/delivery-defense.service';
import { User } from '../../shared/services/user/models/user.model';
import { Customer } from '../../shared/models/customer/customer.model';
import { SharedService } from '../../core/services/shared.service';
import { NotificationType } from '../../shared/models/notification-type';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { IPostScoreAddress } from './interfaces/postScoreAddress.interface';
import { IPostScoreAddressResponse } from './interfaces/postScoreAddressResponse.interface';
import { IAddressByZipCode } from './interfaces/addressByZipCode.interface';
import { Subject } from 'rxjs';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { IAddressSearchLookup } from './interfaces/addressSearchLookup.interface';
import { IDDCredit } from '../../ship/models/dd-credit.interface';
import { IReviewRecentUploadInterface } from './interfaces/IReviewRecentUpload.interface';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'upsc-dd-address-confidence-tool',
    templateUrl: './dd-address-confidence-tool.component.html',
    styleUrls: ['./dd-address-confidence-tool.component.scss'],
})

export class DDAddressConfidenceToolComponent implements OnInit, OnDestroy {
    public environment = environment; 

    public user: User;
    public customer: Customer;

    // Address Search Form variables
    public addressLookupFormGroup: FormGroup;
    public ddFullCredit: number = 0;
    public ddRemainingCredit: number = 0;
    public ddSubTypeID: number = 0;
    public isFieldRequired: boolean = true;
    public missingFieldMessage: string = '';
    public isAddressRecentlySearched: boolean = false;
    public isNotValidAddress: boolean = false;
    public isNoRemainingScores: boolean = false;
    public showTipsPanel: boolean = false;
    
    // Score Gradient UI variables
    public addressDDScore: number;
    public showDDScoreCardsFlag: boolean = false;
    public scores = [
        { range: '100-299', riskMultiplier: 16 },
        { range: '300-499', riskMultiplier: 13 },
        { range: '500-699', riskMultiplier: 9 },
        { range: '700-799', riskMultiplier: 4 },
        { range: '800-1000', riskMultiplier: 0 },
    ];
    public maxRange: string;
    private ngUnsubscribe = new Subject();
    
    // Listening variables for address upload file
    public isSubmissionComplete: boolean = false;
    public showReviewAddressDetails: boolean = false;

    // Listening variables for reviewing recent upload
    public isReviewingRecentUpload: boolean = false;
    public recentUploadData: IReviewRecentUploadInterface = null;

    public constructor(private formBuilder: FormBuilder,
                       private sharedService: SharedService,
                       private notificationService: NotificationService,
                       private translateService: TranslateService,
                       private errorHandlerService: ErrorHandlerService,
                       private deliveryDefenseService: DeliveryDefenseService,
                       private spinnerService: SpinnerService,
                       private readonly appState: AppState,
    ) {
        this.user = this.appState.user$();
        this.customer = this.appState.customer$();
    }

    public ngOnInit(): void {
        setTimeout(() => {
            document.getElementById('gaugeContainer')?.classList?.add('animated');
        }, 500);
        this.maxRange = this.scores.slice(-1)?.[0]?.range || '800-1000';
        this.updateDDCredit();
        this.setUpForms();
        this.handleHideAndShowingComponents();
    }

    public ngOnDestroy(): void {
        // Unsubscribe to avoid memory leaks
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();

        this.deliveryDefenseService.submitForScoring$?.next(null);
        this.deliveryDefenseService.submitForScoring$?.complete();

        this.deliveryDefenseService.reviewAddressDetails$?.next(null);
        this.deliveryDefenseService.reviewAddressDetails$?.complete();

        this.deliveryDefenseService.cancelSubmission$?.next(null);
        this.deliveryDefenseService.cancelSubmission$?.complete();

        this.deliveryDefenseService.fileName$?.next(null);
        this.deliveryDefenseService.fileName$?.complete();
    }

    private handleHideAndShowingComponents(): void {
        this.handleIsRecentUpload();
        this.handleIsNotRecentUpload();
    }

    private handleIsRecentUpload(): void {
        this.deliveryDefenseService.reviewingRecentUpload$.subscribe(
            (result) => {
                if (result?.data?.BatchId) {
                    this.recentUploadData = result?.data;
                    this.isReviewingRecentUpload = true;
                    this.updateDDCredit();
                }
            }
        );
        this.deliveryDefenseService.reviewRecentUploadAddressDetails$.subscribe(
            (result) => {
                this.isReviewingRecentUpload = result.isShowReviewAddressDetails;
            }
        );
        this.deliveryDefenseService.submissionRecentUploadComplete$.subscribe(
            (result) => {
                if (!result?.isSubmissionComplete) {
                    this.showReviewAddressDetails = false;
                    this.isReviewingRecentUpload = false;
                }
                this.isSubmissionComplete = result.isSubmissionComplete;
                this.updateDDCredit();
            }
        );
        this.deliveryDefenseService.cancelRecentUploadSubmission$.subscribe(
            (result) => {
                if (!result) {
                    return;
                }
                this.isReviewingRecentUpload = !result.isCancelSubmissionClicked;
            }
        );
    }

    private handleIsNotRecentUpload(): void {
        this.deliveryDefenseService.submitForScoring$.subscribe(
            (result) => {
                if (result?.isSubmitForScoring) {
                    this.isSubmissionComplete = true;
                    this.showReviewAddressDetails = false;
                    this.updateDDCredit();
                }
            }
        );
        this.deliveryDefenseService.reviewAddressDetails$.subscribe(
            (result) => {
                this.showReviewAddressDetails = result?.isShowReviewAddressDetails;
            }
        );
        this.deliveryDefenseService.submissionComplete$.subscribe(
            (result) => {
                this.isSubmissionComplete = result?.isSubmissionComplete;
                this.updateDDCredit();
            }
        );
        this.deliveryDefenseService.cancelSubmission$.subscribe(
            (result) => {
                if (result?.isCancelSubmissionClicked) {
                    this.isSubmissionComplete = false;
                    this.showReviewAddressDetails = false;
                }
                else {
                    this.isSubmissionComplete = true;
                    this.showReviewAddressDetails = true;
                }
            }
        );
        this.deliveryDefenseService.saveProgress$.subscribe(
            (result) => {
              this.showReviewAddressDetails = result?.isSaveProgress;
              this.isSubmissionComplete = result?.isSaveProgress;
              this.isReviewingRecentUpload = result?.isSaveProgress;
            }
        );
    }

    private setUpForms(): void {
        this.addressLookupFormGroup = this.formBuilder.group({
            addressLineOne: [''],
            addressLineTwo: [''],
            zipCode: [''],
            city: [''],
            state: [''],
        });
    }

    private updateDDCredit(): void {
        const isUSUser = this.user?.CountryCode === 'US';
        const isDDCustomer = this.customer?.DDEnabled === true;

        if (!isUSUser || !isDDCustomer) {
            return;
        }

        this.deliveryDefenseService.getRemainingDDCredit()
            .subscribe(
                (ddCredit: IDDCredit) => {
                    if (!ddCredit) {
                        return;
                    }
                    this.ddRemainingCredit = +(ddCredit?.ScoresRemaining || 0);
                    this.ddFullCredit = +(ddCredit?.TotalScores || 0);
                    this.ddSubTypeID = +(ddCredit?.SubTypeID || 0);
                },
            );
    }
    
    public searchAddress() {
        const addressSearchData = this.addressLookupFormGroup['value'] as IAddressSearchLookup;
        
        // Reset score flags, variables
        this.addressDDScore = undefined; 
        this.isAddressRecentlySearched = false; 
        this.isNotValidAddress = false;
        this.isNoRemainingScores = false;
        this.deliveryDefenseService.addressConfidenceToolSearched$.next( {searched: false});
        
        if (this.ddRemainingCredit <= 0) {
            this.showDeliveryDefenseSignUpDialog(null);
            this.isNoRemainingScores = true;
            return;
        }
        
        if (!this.checkMissingAddressFields(addressSearchData)) {
            this.isFieldRequired = true;
            return;
        }
        this.missingFieldMessage = '';
        this.isFieldRequired = false;
        
        if (!this.validateAddress(addressSearchData)) {
            return;
        }
        this.spinnerService.show();
        this.postScore(addressSearchData);
        this.spinnerService.hide();
    }
    
    private postScore(addressSearchData: IAddressSearchLookup): void {
        const ddAddressLookupData: IPostScoreAddress = {
            'StreetAddress': addressSearchData.addressLineOne,
            'ApartmentSuite': addressSearchData.addressLineTwo,
            'Zip': addressSearchData.zipCode,
            'City': addressSearchData.city,
            'State': addressSearchData.state,
        };
        this.deliveryDefenseService.postScore(ddAddressLookupData)
            .subscribe(
                (response: IPostScoreAddressResponse) => {
                    if (response) {
                        if (parseInt(response?.DDSearchStatus) === 0) {
                            this.isNotValidAddress = true;
                            this.spinnerService.hide();
                            return;
                        }
                        else if (parseInt(response?.DDSearchStatus) === 2) {
                            this.isAddressRecentlySearched = true;
                        }
                        this.addressDDScore = response?.score;
                        this.updateDDCredit();
                        
                        // DD-436: Call score history API to refresh table after successful search
                        this.deliveryDefenseService.addressConfidenceToolSearched$.next( {searched: true});
                    }
                },
            );
    }

    private checkMissingAddressFields(addressSearchData: IAddressSearchLookup): boolean {
        // Check that Address Line 1, Zip Code, City, State fields are provided
        if (addressSearchData === null || addressSearchData === undefined) {
            return false;
        }
        
        if (!addressSearchData?.addressLineOne) {
            this.missingFieldMessage = 'Address Line 1 is required.';
            return false;
        }

        if (!addressSearchData?.zipCode) {
            this.missingFieldMessage = 'Zip Code is required.';
            return false;
        }
        
        if (!addressSearchData?.city) {
            this.missingFieldMessage = 'City is required.';
            return false;
        }

        if (!addressSearchData?.state) {
            this.missingFieldMessage = 'State is required.';
            return false;
        }
        return true;
    }

    // DD-417: Only show error messages if user clicks on required field (ZipCode check separately in populateCityStateByZipCode()
    public onBlurFormInput(event: any, fieldType: string): void {
        this.missingFieldMessage = '';
        
        const fieldTypes: string[] = ['Address Line 1', 'City', 'State'];
        if (fieldTypes.includes(fieldType)) {
            if (!event?.target?.value || event?.target.value.trim().length < 1) {
                this.missingFieldMessage = `${fieldType} is required.`;
            }
        }
    }
    
    private validateAddress(addressSearchData: IAddressSearchLookup): boolean {
        // Fields should not be over/under defined X number of characters
        if (addressSearchData.addressLineOne.length > 35 || addressSearchData.addressLineTwo.length > 35 ||
            addressSearchData.zipCode.length !== 5 || addressSearchData.city.length > 30 || addressSearchData.state.length !== 2) {
            return false;
        }
        
        // Zip code accepts 5 digits
        for (let i = 0; i < addressSearchData.zipCode.length; i++) {
            if (addressSearchData.zipCode[i] < '0' || addressSearchData.zipCode[i] > '9') {
                return false;
            }
        }
        return true;
    }
    
    public populateCityStateByZipCode(event: any): boolean {
        const zipCodeInput = event?.target?.value as string;
        if (!zipCodeInput || zipCodeInput.trim().length < 1) {
            this.missingFieldMessage = 'Zip Code is required.';
            return false;
        }
        
        // After user leaves focus on ZipCode input, auto-populate City and State fields
        if (zipCodeInput && zipCodeInput?.length === 5) {
            this.deliveryDefenseService.getAddressByZipCode(zipCodeInput)
                .subscribe(
                    (response: IAddressByZipCode) => {
                        if (response) {
                            this.addressLookupFormGroup['controls']['city'].setValue(response?.City);
                            this.addressLookupFormGroup['controls']['state'].setValue(response?.State);
                            this.missingFieldMessage = '';
                            return true;
                        }
                    },
                );
        } else {
            this.missingFieldMessage = 'Zip Code entered is invalid.';
        }
        return false;
    }
    
    public showDDScoreCards(): void {
        this.showDDScoreCardsFlag = !this.showDDScoreCardsFlag;
    }

    public showDeliveryDefenseSignUpDialog(event: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        let dialogType: 'complimentary' | 'regular' | 'form' | 'upgrade' | 'downgrade';
        switch (this.ddSubTypeID) {
            case 1: // paid customer
                // [MV3-6599] Show `Need More Scores` dialog instead of the paid subscription dialog.
                dialogType = 'form';
                break;
            case 5: // trial customer
                dialogType = 'complimentary';
                break;
            case 2: // high volume customer program 1
            case 3: // high volume customer program 2
            case 4: // high volume customer program 3
                dialogType = 'form';
                break;
            default:
                throw new Error(`Unsupported SubTypeID of ${ this.ddSubTypeID }`);
        }

        this.deliveryDefenseService.showOfferDialog(dialogType);
    }

    public tipsPanel(): void {
        this.showTipsPanel = true;
    }

    public tipsPanelClose(): void {
        this.showTipsPanel = false;
    }    
}
