<div class="login-jwt">
    <div class="login-content-wrapper-v2">
        <div class="login-content-items">
            <div class="login-content-item sign-in">
                <div class="logo">
                    <upsc-logo></upsc-logo>
                </div>
                <h1 class="page-title">{{ 'ForgetPasswordHeading' | translate }}</h1>
                <form [formGroup]="formGroup"
                      class="ups-form"
                      (ngSubmit)="onSubmit($event, formGroup.value)">
                    <div class="form-body">
                        <mat-form-field>
                            <mat-label>{{ 'Email'|translate }}</mat-label>
                            <input matInput
                                   required
                                   type="email"
                                   formControlName="email">
                            <mat-error *ngIf="formGroup.controls.email.errors">
                                    <span *ngIf="formGroup.controls.email.errors">
                                        {{ 'validation.emailRequired' | translate }}
                                    </span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'Username' | translate }}</mat-label>
                            <input matInput
                                   required
                                   type="text"
                                   maxlength="30"
                                   formControlName="userName">
                            <mat-error *ngIf="formGroup.controls.userName.errors?.required">
                                    <span *ngIf="formGroup.controls.userName.errors?.required">
                                        {{ 'validation.usernameRequired'|translate }}
                                    </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-footer">
                        <div class="action">
                            <button mat-raised-button
                                    color="primary"
                                    type="submit"
                                    *spinOn="isInProgress"
                                    [disabled]="!formGroup.valid">{{ 'Reset'|translate }}
                            </button>
                        </div>
                        <a href="#" routerLink="/login"><i
                                class="material-icons">keyboard_backspace</i> {{ 'backtoSignIn'|translate }}</a>
                    </div>
                </form>
            </div>
            <div class="login-content-item sign-up">
                <div class="trouble">
                    <span [innerHtml]="'SignInPage.Trouble' | translate"></span>&nbsp;
                    <span [innerHtml]="'SignInPage.TroubleSolution' | translate: { tel: supportTel }"></span>
                </div>
            </div>
        </div>
    </div>
</div>
