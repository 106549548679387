import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../../../app.state';
import { Customer } from '../../../shared/models/customer/customer.model';
import { PaymentHistoryService } from '../../services/payment-history.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NotificationType } from '../../../shared/models/notification-type';
import { FileDownloadService } from '../../../shared/services/file/file-download.service';
import { BlobService } from '../../../shared/services/file/blob.service';
import { ErrorHandlerService } from '../../../shared/services/error-handler/error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../core/services/shared.service';


@Component({
  selector: 'upsc-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss'],
})
export class PaymentReceiptComponent implements OnInit {

  public customer: Customer;
  public paymentReceipt;
  @ViewChild('Receipt') receiptSection: ElementRef;

  public constructor(private paymentHistoryService: PaymentHistoryService,
                     @Inject(MAT_DIALOG_DATA) public data: any,
                     private notificationService: NotificationService,
                     private fileDownloadService: FileDownloadService,
                     private blobService: BlobService,
                     private errorHandlerService: ErrorHandlerService,
                     private translateService: TranslateService,
                     private sharedService: SharedService,
                     @Inject(LOCALE_ID) public localeId: string,
                     private readonly appState: AppState,
  ) {
    this.customer = this.appState.customer$();
  }

  public ngOnInit() {
    if (this.data && this.data.paymentReceipt) {
      this.paymentReceipt = this.data.paymentReceipt;
    }
  }

  printReceipt() {
    window.print();
  }

  downloadInvoice(invoiceId) {
    this.paymentHistoryService.getInvoice(invoiceId.trim()).subscribe(
      (invoice) => {
        this.fileDownloadService.downloadAsPDF(invoice.InvoiceEncodedByte, 'invoice.pdf');
      },
      (error) => {
        this.notificationService.notify(
          this.errorHandlerService.getHttpErrorMessage(error),
          this.translateService.instant('errorInvoiceDownload'),
          NotificationType.ERROR);
      });
  }
}
