<div class="main">
    <ng-container [ngTemplateOutlet]="header"></ng-container>
    <div class="description">
        Seamless multicarrier Coverage: UPS, FedEx, USPS, DHL & More.<br>
        Select an upload option based on your shipping platform and follow the instructions. Smarter, Safer Insured
        Shipping
        Solutions. We’ve got you covered!
    </div>

    <div class="tab-container">
        <div class="tab-header">
            <mat-tab-group #tabGroup (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start"
                animationDuration="0ms" class="no-active-tab-indicator">
                <mat-tab [label]="'siteMenu.ship.worldShipUpload' | translate">
                    <upsc-world-ship-upload></upsc-world-ship-upload>
                </mat-tab>
                <mat-tab label="Bulk Report Upload">
                    <upsc-report-shipments-bulkupload></upsc-report-shipments-bulkupload>
                </mat-tab>
                <mat-tab class="custom-tab-class" label="Single Report Upload">
                    <upsc-report-shipments-single-upload></upsc-report-shipments-single-upload>
                </mat-tab>
            </mat-tab-group>
            <mat-checkbox [checked]="isCheckboxChecked" [disabled]="isCheckboxChecked"
                (change)="onCheckboxChange()"
                [style.left.px]="checkboxLeftPosition"
                style="position: absolute; top: 0;">Default View</mat-checkbox>
        </div>
    </div>

    <ng-template #header>
        <div class="ups-page-header">
            <h1 class="page-title">{{'reportShipment.title'|translate}}</h1>
            <div class="page-actions"></div>
            <button mat-raised-button class ="shipment-history-nav" (click) = "routeToShipmentHistory()">View Shipment History</button>
        </div>
    </ng-template>
</div>