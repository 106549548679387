<div class="payment-history white-content-page">
    <upsc-page-header class="hidden-print" [pageTitle]="'PaymentHistory'|translate"></upsc-page-header>

    <div class="hidden-print">
        <div class="table-wrapper" [class.responsive-table]="dtOptions.responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="stripe"
                [hidden]="!paymentHistoryList">
                <thead>
                    <tr>
                        <th class="all trigger-column"></th>
                        <th>{{'TransactionId'|translate}}</th>
                        <th class="text-center">{{'Invoices'|translate}}</th>
                        <th class="text-center">{{'Date'|translate}}</th>
                        <th class="text-center">{{'Last4Digits'|translate}}</th>
                        <th class="text-center">{{'Authorization'|translate}}</th>
                        <th class="text-center">{{'Amount'|translate}}</th>
                        <th class="text-center">{{'Status'|translate}}</th>
                        <th class="text-center">{{'PaymentMethod'|translate}}</th>
                        <th class="text-center all">{{'Receipt'|translate}}</th>
                    </tr>
                </thead>
                <tbody #tb>
                    <tr *ngFor="let payment of paymentHistoryList | paginate:paginate">
                        <td class="trigger-cell"></td>
                        <td> {{ payment.TransactionID == '0' ? 'N/A' : payment.TransactionID }}</td>
                        <td class="text-center"> {{ (payment.InvoiceId && payment.InvoiceId.split(',').length > 1) ?
                            payment.InvoiceId.split(',')[0] : payment.InvoiceId }}                            
                                <mat-icon (click)="openInvoiceList(payment.InvoiceId)"
                                *ngIf="payment.InvoiceId && payment.InvoiceId.split(',').length > 1">add</mat-icon>                            
                        </td>
                        <td class="text-center">
                            <span *ngIf="localeId==='en-US'; then USDate else otherDate"></span>
                            <ng-template #USDate>{{payment.TransactionDate | date : 'MM/dd/yyyy' }}</ng-template>
                            <ng-template #otherDate>{{payment.TransactionDate | date : 'yyyy/MM/dd' }}</ng-template>
                        </td>
                        <td class="text-center"> {{ payment.CreditCardNumber }}</td>
                        <td class="text-center"> {{ payment.AuthorizationCode }}</td>
                        <td class="text-center">
                            <span *ngIf="payment.TransactionType !== '3'">${{ payment.PaymentAmount | number : '1.2-2'
                                }}</span>
                            <span *ngIf="payment.TransactionType === '3'">-${{ payment.PaymentAmount | number : '1.2-2'
                                }}</span>
                        </td>
                        <td class="text-center">
                            <span class="{{ getStatusCssClass(payment) }}"
                                matTooltip="{{ getStatusTooltipText(payment) }}" [matTooltipPosition]="'right'">{{
                                getStatusText(payment) }}</span>
                        </td>
                        <td class="text-center"> {{ payment | paymentMethod }}</td>
                        <td class="text-center">
                            <div class="actions">
                                <a href="javascript:;"
                                    *ngIf="payment.ResponseDescription && payment.ResponseDescription.toLowerCase().includes('approved') || (payment.PaymentMethod == 2 || payment.PaymentMethod == 3 || payment.PaymentMethod == 4)"
                                    role="button" mat-raised-button
                                    (click)="getPaymentReceipt(payment.PaymentID)">{{'ViewReceipt'|translate}}</a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="noRecordsFound">
                        <td colspan="7" class="text-center">
                            <span style="color:red;font-size:12px;"> {{'NoRecordsFound'|translate}} </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls [directionLinks]="true" [previousLabel]="''" [nextLabel]="''" [autoHide]="true"
                (pageChange)="onPageChanged($event)"></pagination-controls>
            <upsc-element-blocker *ngIf="isDataLoading"></upsc-element-blocker>
        </div>
    </div>
</div>
<!--<a href="#paymentReceipt" #paymentReceiptModal role="button" class="hidden" data-toggle="modal" data-dismiss="modal"> </a>-->
<!--<upsc-payment-receipt [paymentReceipt]="paymentReceipt"></upsc-payment-receipt>-->