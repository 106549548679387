import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { DeliveryDefenseService } from 'app/delivery-defense/services/delivery-defense.service';
import { IDDCredit } from 'app/ship/models/dd-credit.interface';
import * as XLSX from 'xlsx';
import { IPostAddressUpload } from '../interfaces/postAddressUpload.interface';
import { IPostAddressUploadResponse } from '../interfaces/postAddressUploadResponse.interface';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { finalize } from 'rxjs';
import { IPostAddressReview } from '../interfaces/postAddressReview.interface';
import { IPostAddressReviewResponse, IAddressDetails } from '../interfaces/postAddressReviewResponse.interface';
import { IReviewRecentUploadInterface } from '../interfaces/IReviewRecentUpload.interface';
import { IRecentUploadSignalRInterface } from '../recent-uploads/recent-upload-live-status-check/IRecentUploadSignalR.interface';
import { IGetAddressUploadInterface } from '../interfaces/IGetAddressUpload.interface';


@Component({
  selector: 'upsc-dd-upload-address-file',
  templateUrl: './dd-upload-address-file.component.html',
  styleUrls: ['./dd-upload-address-file.component.scss']
})
export class DDUploadAddressFileComponent implements OnInit {
  public openTipsAndTricks: boolean = false;
  public selectedFileName: string | null = null;
  
  public errorMessage: string = '';
  public submitBtnMessage: string;
  public duplicateAddressMessage: string;
  public cancelOrNeedMoreScoresMessage: string;
  public isFileInputDisabled: boolean = false;
  public isSubmissionComplete: boolean = false;
  public duplicateSubmissionCompleteMessage: string = '';
  
  public addressUploadsData = [];
  public addressReviewsData: IAddressDetails[] = [];
  public headers = [
    { number: '1.', iconSrc: this.getIcons('Group 22583'), text: 'No blank fields', thumbsSrc: this.getIcons('thumb_up') },
    { number: '2.', iconSrc: this.getIcons('Frame 24168'), text: 'Do not include punctuation', thumbsSrc: this.getIcons('thumb_down')},
    { number: '3.', iconSrc: this.getIcons('Frame 24169'), text: 'State must be abbreviated', thumbsSrc: this.getIcons('thumb_up') },
    { number: '4.', iconSrc: this.getIcons('Group 22586'), text: 'Enter a 5 digit zip code', thumbsSrc: this.getIcons('thumb_up') },
  ];

  public areAnyFileErrors: boolean = false;
  public showReviewAddressDetails: boolean = false;

  private noDuplicateAddresses: string[] = [];
  public isSubmitRecentUploadForScoring: boolean = false;
  public maxAddressUploads: number = 0;
  public showProcessingRecordsMessage: boolean = false;
  public isDataProcessed: boolean = false;

  public isZeroRemainingScores: boolean = false;
  public isCancelButtonClicked: boolean = false;
  public columnHeaders: string[];
  public scoringInfoPanel = false;
  public showTipsSection = false;
  public showTipsPanel = false;

  @Input() recentUploadData: IReviewRecentUploadInterface;
  public isRecentUpload: boolean = false;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(private deliveryDefenseService: DeliveryDefenseService,
              private spinnerService: SpinnerService,
            ) {}

  ngOnInit(): void {
    this.getMaxAddressUploads();
    this.handleFileForRecentUpload();
    this.handleHideAndShowingComponents();
    this.handleIfSubjectsGotNgDestroyed();
    this.handleRecentUploadsRaceCondition();
  }

  private handleFileForRecentUpload(): void {
    if (this.recentUploadData?.BatchId) {
      this.isRecentUpload = true;

      this.recentUploadData?.AddressUploads.forEach(obj => {
        this.addressReviewsData.push({
          StreetAddress: obj?.StreetAddress,
          City: obj?.City,
          State: obj?.State,
          Zip: obj?.Zip,
          Exceptions: obj?.Exceptions,
        })
      });
    }
  }

  private async getMaxAddressUploads(): Promise<void> {
    const ddCredit: IDDCredit = await this.deliveryDefenseService.getRemainingDDCredit().toPromise();
    const subTypeID: number = ddCredit.SubTypeID;

    if (subTypeID == 1) {
      this.maxAddressUploads = 50;  
    }
    if (subTypeID == 2 || subTypeID == 3 || subTypeID == 4) {
      this.maxAddressUploads = 100;
    }
  }

  private handleRecentUploadsRaceCondition(): void {
    // Initially, if recent upload is not empty, then this component is being called with recent uploads data
    // Immediately go to review page and let user review/edit any records
    if (this.isRecentUpload) {
      this.handleErrorsInFile(this.addressReviewsData);
    }
  }
  
  private handleIfSubjectsGotNgDestroyed(): void {
    // If ngOnDestroy is activated, then we can't listen to .subscribes anymore (if we hide and show the same components), so we just check if there's a value
    const cancelRecentUploadSubmissionSubjectValue = this.deliveryDefenseService.cancelRecentUploadSubmission$.value;    
    if (cancelRecentUploadSubmissionSubjectValue) {   
      this.ifIsCancelSubmissionClicked(cancelRecentUploadSubmissionSubjectValue);
    }
  }

  private handleHideAndShowingComponents(): void {
    if (this.isRecentUpload) {
      this.handleIsRecentUpload();
    }
    else {
      this.handleIsNotRecentUpload();
    }
  }

  private handleIsRecentUpload(): void {
    this.deliveryDefenseService.fileName$.subscribe( 
      (result) => {
        if (!result) {
          return;
        }
        this.selectedFileName = result.fileName;
    });

    this.deliveryDefenseService.submitRecentUploadForScoring$.subscribe(
      (result) => {
        if (result?.isSubmitForScoring == true) {
          this.callSubmissionComplete(result);
        }
    });

    this.deliveryDefenseService.cancelRecentUploadSubmission$.subscribe(
      (result) => {
        if (!result) {
          return;
        }
        this.ifIsCancelSubmissionClicked(result);
    });
  }

  private callSubmissionComplete(result): void {
    this.isSubmissionComplete = true;
    this.showReviewAddressDetails = false;
    this.isSubmitRecentUploadForScoring = true;
    this.getAddressUploadsTableAfterFixingErrors(result);
  } 

  private ifIsCancelSubmissionClicked(result: any): void {
    if (result.isCancelSubmissionClicked == true) {
      this.isSubmissionComplete = false;
      this.showReviewAddressDetails = false;
      this.selectedFileName = "";
    }
    else {
      this.isSubmissionComplete = true;
      this.showReviewAddressDetails = true;
    }
  }

  private handleIsNotRecentUpload(): void {
    this.deliveryDefenseService.fileName$.subscribe( 
      (result) => {
        if (!result) {
          return;
        }
        this.selectedFileName = result.fileName;
    });

    this.deliveryDefenseService.submitForScoring$.subscribe(
      (result) => {
          if (result?.isSubmitForScoring == true) {
              this.isSubmissionComplete = true;
              this.showReviewAddressDetails = false;
              this.getAddressUploadsTableAfterFixingErrors(result);
          }
    });
    this.deliveryDefenseService.cancelSubmission$.subscribe(
      (result) => {
        // If user pressed "Cancel", --> "Are you sure you want to cancel this process?" --> "Yes, Cancel Submission", then just show original components
        if (result?.isCancelSubmissionClicked == true) {
          this.isSubmissionComplete = false;
          this.showReviewAddressDetails = false;
          this.selectedFileName = "";
        }
        else {
          this.isSubmissionComplete = true;
          this.showReviewAddressDetails = true;
        }
    });

    this.deliveryDefenseService.saveProgress$.subscribe(
      (result) => {
        this.showReviewAddressDetails = result?.isSaveProgress;
        this.isSubmissionComplete = result?.isSaveProgress;
        this.selectedFileName = "";
      }
    )
  }

  private getIcons(iconName: string): string {
    return `../../../../assets/icons/${iconName}.svg`;
  }

  public tipsAndTricks(): void {
    this.openTipsAndTricks = true;
  }

  public closeTipsAndTricks() {
    this.openTipsAndTricks = false;
  }

  public allowDrop(event: DragEvent): void {
    event.preventDefault();
  }

  public handleDrop(event: DragEvent): void {
    event.preventDefault();
    const files: FileList = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFiles(files);
    }
  }

  public handleClick(event: MouseEvent): void {
    // Trigger a click event on the hidden file input
    this.fileInput.nativeElement.click();
    if (this.isZeroRemainingScores) {
      this.isFileInputDisabled = true;

      if (this.isCancelButtonClicked) {
        this.isFileInputDisabled = false;
      }
    }

    else {
      this.isFileInputDisabled = false;
    }

    (event.target as HTMLInputElement).value = "";
    const files: FileList = (event?.target as HTMLInputElement).files;
    this.handleFiles(files);
  }

  public onFileInputChange(event: Event): void {
    // Handle file input change (e.g., when a user selects a file using the file explorer)
    const files: FileList = (event?.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.handleFiles(files);
    }
  }

  private handleFiles(files: FileList): void {
    // 2024-03-04: APIs are only accepting .csv, .xls, .xlsx extensions
    const allowedExtensions: string[] = ['.csv', '.xls', 'xlsx', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    let file: File;
    if (files) {
      file = files[0];
    }
    if (file && allowedExtensions.includes(file.type)) {
      this.isFileInputDisabled = true;
      this.selectedFileName = file.name;
      this.readExcelFile(file);
    }
  }

  private readExcelFile(file: File): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
      const sheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
      const parsedData: string[] = this.sheetToArray(worksheet);
      this.throwExcelErrors(parsedData);
    };
    reader.readAsBinaryString(file);
  }

  private sheetToArray(sheet: XLSX.WorkSheet): any[] {
    return XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
  }

  private async throwExcelErrors(parsedData: any[]): Promise<void> {
    const ddCredit: IDDCredit = await this.deliveryDefenseService.getRemainingDDCredit().toPromise();
    const subTypeID: number = ddCredit.SubTypeID;
    const numOfAddresses: number = parsedData.length - 1;  // length-1 because parsedData includes column headers, so we want exclude the first row (i.e everything after the first row)
    this.resetDuplicateAndErrorMessages();
    this.cancelOrNeedMoreScoresMessage = "Cancel";
    this.columnHeaders = parsedData[0];
    if (this.ifZeroScoresRemaining(ddCredit, subTypeID)) {
      return;
    }

    if (!this.isColumnHeadersMatched(parsedData)) {
      this.handleIncorrectColumnHeaderFormat();
      return;
    }

    const numOfDuplicateAddresses = this.getNumOfDuplicateAddresses(parsedData);
    const numOfNonDuplicateAddresses = numOfAddresses - numOfDuplicateAddresses;
    this.noDuplicateAddresses = this.getUniqueAddresses(parsedData);
    
    if (this.ifExceedsAddressLimit(ddCredit, subTypeID, numOfAddresses)) {
      return;
    }
    if (this.ifInsufficientRemainingScores(ddCredit, numOfAddresses)) {
      return;
    }    
    if (this.ifDuplicateAddresses(numOfDuplicateAddresses, numOfNonDuplicateAddresses, numOfAddresses)) {
      return;
    }
    this.noDuplicateAddresses = this.getUniqueAddresses(parsedData);
  }

  private isColumnHeadersMatched(parsedData): boolean {
    const columnHeaders: string[] = parsedData[0];
    const areAllStrings: boolean = columnHeaders.every(header => typeof header === 'string');
    if (columnHeaders.length > 4 || !areAllStrings) {
      return false;
    }
    const lowercaseColumnHeaders: string[] = columnHeaders.map(header => header.toLowerCase().replace(/\s/g, '').trim()).sort();
    const requiredColumnHeaders: string[] = ['city', 'state', 'street', 'zipcode'];
    return (JSON.stringify(lowercaseColumnHeaders) == JSON.stringify(requiredColumnHeaders))
  } 

  private getNumOfDuplicateAddresses(parsedData): number {
    let numOfDuplicates = 0;
    let addressDictionary: { [key: string] : number} = {};
    parsedData = parsedData.slice(1);  // Exclude the first column because it's the column header

    for (let i = 0; i < parsedData.length; i++) {
      const addressString = parsedData[i].join(' ');
      let normalizeAddressString = this.normalizeAddress(addressString); 
      
      if (normalizeAddressString in addressDictionary) {
        addressDictionary[normalizeAddressString] += 1;
        numOfDuplicates += 1;
      }
      else {
        addressDictionary[normalizeAddressString] = 1;
      }
    }
    return numOfDuplicates;
  }

  private getUniqueAddresses(parsedData): string[] {
    let nonDuplicatedAddresses: string[] = [];
    let addressDictionary: { [key: string]: boolean } = {};
    parsedData = parsedData.slice(1);  // Exclude the first column because it's the column header

    for (let i = 0; i < parsedData.length; i++) {
      const addressString = parsedData[i].join(' ');
      let normalizedAddressString = this.normalizeAddress(addressString);
      
      if (!addressDictionary[normalizedAddressString]) {
        addressDictionary[normalizedAddressString] = true;
        nonDuplicatedAddresses.push(parsedData[i]);
      }
    }
    return nonDuplicatedAddresses;
  }

  private normalizeAddress(address: string): string {
    const abbreviationMap = {
      "st": "street",
      "ave": "avenue",
      "n": "north",
      "e": "east",
      "s": "south",
      "w": "west",
      "ne": "northeast",
      "nw": "northwest",
      "se": "southeast",
      "sw": "southwest",
    };
    let normalizedAddress = address.toLowerCase();
    for (const [abbreviation, fullForm] of Object.entries(abbreviationMap)) {
      normalizedAddress = normalizedAddress.replace(new RegExp(`\\b${abbreviation}\\b`, 'g'), fullForm);  // Replace common abbreviations with full forms
    }
    normalizedAddress = normalizedAddress.replace(/\s+/g, ' ').trim();
    return normalizedAddress;
  }

  private resetDuplicateAndErrorMessages(): void {
    this.duplicateAddressMessage = "";
    this.duplicateSubmissionCompleteMessage = "";
    this.errorMessage = "";
    this.submitBtnMessage = "Review";
  }

  private handleIncorrectColumnHeaderFormat(): void {
    this.errorMessage = "File is not formatted properly. Please ensure the column headers match the downloaded template.";
    this.submitBtnMessage = "Upload Again";
    this.isFileInputDisabled = false;
  }

  private ifExceedsAddressLimit(ddCredit: IDDCredit, subTypeID: number, numOfAddresses: number): boolean {
    let limit: number = 0;
    let scoresRemaining: number = 0;
    
    if (subTypeID == 1) {
      limit = 50;  
      scoresRemaining = ddCredit.ScoresRemaining;
    }
    if (subTypeID == 2 || subTypeID == 3 || subTypeID == 4) {
      limit = 100;
      if (ddCredit.ScoresRemaining <= limit) {
        scoresRemaining = ddCredit.ScoresRemaining;
      }
      else {
        scoresRemaining = limit;
      }
    }

    if (numOfAddresses > limit) {
      this.errorMessage = `File has more than ${limit} addresses. Only the first ${scoresRemaining} addresses will be processed.`;
      return true;
    }
    return false;
  }

  private ifDuplicateAddresses(numOfDuplicateAddresses: number, numOfNonDuplicateAddresses: number, numOfAddresses: number): boolean {
    if (numOfDuplicateAddresses > 0) {
      this.duplicateAddressMessage = `${numOfNonDuplicateAddresses}/${numOfAddresses} unique addresses are found. ${numOfDuplicateAddresses} addresses are duplicated and will not be deducted from your scores.`;
      this.duplicateSubmissionCompleteMessage = `${numOfNonDuplicateAddresses}/${numOfAddresses} unique addresses are deducted from your search remaining. ${numOfDuplicateAddresses} addresses are duplicated and will not be deducted.`;
      return true;
    }
    return false;
  }
  
  private ifZeroScoresRemaining(ddCredit: IDDCredit, subTypeID: number): boolean {
    if (ddCredit.ScoresRemaining == 0) {
      if (subTypeID == 1) {
        this.errorMessage = `Not enough scores remaining. Please purchase more scores.`;
        this.duplicateAddressMessage = "";
        this.duplicateSubmissionCompleteMessage = "";
        this.isFileInputDisabled = true;
        // DD-567: Change button message to show Add Scores for user with subtype 1
        this.submitBtnMessage = "Add Scores";
        this.isZeroRemainingScores = false;
        return true;
      }

      if (subTypeID == 2 || subTypeID == 3 || subTypeID == 4) {
        this.cancelOrNeedMoreScoresMessage = "Need more scores";
        this.errorMessage = `0 scores remaining. Please click “Need more scores” to talk with our representatives.`
        this.duplicateAddressMessage = "";
        this.duplicateSubmissionCompleteMessage = "";
        this.isZeroRemainingScores = true;
        this.isFileInputDisabled = true;
        return true;
      }
    }
    this.isZeroRemainingScores = false;
    return false;
  }

  private ifInsufficientRemainingScores(ddCredit: IDDCredit, numOfAddresses: number): boolean {    
    if (ddCredit.ScoresRemaining < numOfAddresses) {
      this.cancelOrNeedMoreScoresMessage = "Cancel";
      this.errorMessage = `${ddCredit.ScoresRemaining} scores remain. You can click "Review" and only the first ${ddCredit.ScoresRemaining} of addresses will be processed.`
      this.submitBtnMessage = "Review";
      return true;
    }
    return false;
  }

  public openDialog(): void {
    this.isFileInputDisabled = true;
    if (this.cancelOrNeedMoreScoresMessage == "Cancel") {
      this.isFileInputDisabled = true;
      this.isCancelButtonClicked = true;
      this.closeFile(this.isCancelButtonClicked);
    }
    if (this.cancelOrNeedMoreScoresMessage == "Need more scores") {
      this.deliveryDefenseService.showOfferDialog('form');
    }
    this.deliveryDefenseService.offerDialogClosed$.subscribe(() => {
      this.isFileInputDisabled = false;
    });
  }

  public showSubmissionComplete(event?: Event): void {
    if (this.submitBtnMessage == "Upload Again") {
      this.onFileInputChange(event);
    }
    else if (this.submitBtnMessage == "Review") {
      this.spinnerService.show();
      this.isFileInputDisabled = true;
      this.getAddressReviewTable(this.noDuplicateAddresses);
    }
    else if (this.submitBtnMessage == "Add Scores") {
      this.showDeliveryDefenseSignUpDialog(null);
      this.isFileInputDisabled = true;
      this.isCancelButtonClicked = true;
      this.closeFile(this.isCancelButtonClicked);
    }
  }

  public async showDeliveryDefenseSignUpDialog(event: MouseEvent) {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    const ddCredit: IDDCredit = await this.deliveryDefenseService.getRemainingDDCredit().toPromise();
    const subTypeID: number = ddCredit.SubTypeID;

    let dialogType: 'complimentary' | 'regular' | 'form' | 'upgrade' | 'downgrade';
    switch (subTypeID) {
      case 1: // paid customer
          // [MV3-6599]: Show `Need More Scores` dialog instead of the paid subscription dialog.
        dialogType = 'form';
        break;
      case 5: // trial customer
        dialogType = 'complimentary';
        break;
      case 2: // high volume customer program 1
      case 3: // high volume customer program 2
      case 4: // high volume customer program 3
        dialogType = 'form';
        break;
      default:
        throw new Error(`Unsupported SubTypeID of ${ subTypeID }`);
    }
    this.deliveryDefenseService.showOfferDialog(dialogType);
  }

  public closeFile(isCancelButtonClicked?: boolean): void {
    this.duplicateSubmissionCompleteMessage = "";
    this.selectedFileName = "";
    this.showFile(false, isCancelButtonClicked);
  }

  private showFile(isSubmissionComplete: boolean, isCancelButtonClicked?: boolean): void {
    this.isSubmissionComplete = isSubmissionComplete;
    this.isFileInputDisabled = isSubmissionComplete;

    if (isCancelButtonClicked) {
      this.isFileInputDisabled = true;
    }

    if (this.isRecentUpload) {
      // Destroy all subscribe variables before updating submissionComplete$ subscribe variable
      if (!this.isSubmissionComplete) {
        this.deliveryDefenseService.editRecentUploadData$.next(null);
        this.deliveryDefenseService.editRecentUploadData$.complete();

        this.deliveryDefenseService.cancelRecentUploadSubmission$.next(null);
        this.deliveryDefenseService.cancelRecentUploadSubmission$.complete();

        this.deliveryDefenseService.fileName$.next(null);
        this.deliveryDefenseService.fileName$.complete();
      }

      this.deliveryDefenseService.submissionRecentUploadComplete$.next({ isSubmissionComplete: isSubmissionComplete });
    }
    else {
      this.deliveryDefenseService.submissionComplete$.next({ isSubmissionComplete: isSubmissionComplete });
    }
  }

  private handleColumnHeaderOrdering(addressReview: any[], parsedData: string[]): void {
    // Handles all possible orderings of the column headers
    for (let i = 0; i < parsedData.length; i++) {
      const lowercaseColumnHeaders: string[] = this.columnHeaders.map(header => header.toLowerCase().replace(/\s/g, '').trim());

      const streetIndex = lowercaseColumnHeaders.indexOf("street");
      const cityIndex = lowercaseColumnHeaders.indexOf("city");
      const stateIndex = lowercaseColumnHeaders.indexOf("state");
      const zipIndex = lowercaseColumnHeaders.indexOf("zipcode");
  
      const address = {
        "Streetaddress": parsedData[i][streetIndex],
        "City": parsedData[i][cityIndex],
        "State": parsedData[i][stateIndex],
        "Zip": parsedData[i][zipIndex]
      };
      addressReview.push(address);
    }
  }

  public getAddressReviewTable(parsedData?: string[]): void {
    this.isFileInputDisabled = true;
    let addressReview = [];

    const ddAddressLookupData: IPostAddressReview = {
      Filename: this.selectedFileName,
      AddressUploads: addressReview,
    };
    this.handleColumnHeaderOrdering(addressReview, parsedData);

    this.deliveryDefenseService.postAddressReview(ddAddressLookupData)
    .subscribe(
        (response: IPostAddressReviewResponse) => {
          if (response) {
            this.addressReviewsData = response.AddressUploads; 
            this.deliveryDefenseService.fileName$.next({ fileName: response.Filename });
            this.handleErrorsInFile(this.addressReviewsData);
            return;
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.errorMessage = error.error.Message;
        }
    );
  }

  public onMessageReceived(data: IRecentUploadSignalRInterface) {
    if (data?.Status == 1 && !this.showProcessingRecordsMessage) {
      // Call GET deliverydefense/address-upload/{batchId} to retrieve addresses and scores
      this.deliveryDefenseService.getAddressUpload(data?.BatchId)
        .subscribe(
          (data: Array<IGetAddressUploadInterface>) => {
            this.addressUploadsData = [];

            for (let i = 0; i < data?.length; i++) {
              this.addressUploadsData.push({
                StreetAddress: data[i]?.StreetAddress,
                ApartmentSuite: null,
                City: data[i]?.City,
                State: data[i]?.State,
                ZipCode: data[i]?.Zip,
                SearchDate: data[i]?.SearchDate,
                Score: data[i]?.Score,
                Charge: -1,
                DDSearchStatus: data[i]?.SearchStatus,
                IsLookUpTool: 2,
                CarrierCode: 1,
                IsHoldAtLocation: true,
                HoldAtAddress1: '',
                HoldAtAddress2: '',
                HoldAtCity: '',
                HoldAtState: '',
                HoldAtZip: '',
                HoldAtCountry: '',
                HoldAtLocationID: '',
                ShipmentId: null,
                TotalItems: data?.length.toString(),
              })
            }

            // Set isDataProcessed flag to signify that records are done uploading
            this.isDataProcessed = true;
          }
        )
    } else {
      this.showProcessingRecordsMessage = true;
    }
  }

  private getAddressUploadsTableAfterFixingErrors(result): void {
    this.spinnerService.show();
    const ddAddressLookupData: IPostAddressUpload = {
      "Filename": !this.recentUploadData?.Filename ? this.selectedFileName : this.recentUploadData?.Filename,
      "BatchId": !this.recentUploadData?.BatchId ? null : this.recentUploadData?.BatchId,
      "AddressUploads": result?.data,
    };

    this.deliveryDefenseService.postAddressUpload(ddAddressLookupData)
      .pipe(
        finalize(
          () => {
            this.showFile(true);
            this.selectedFileName = "";
            this.isFileInputDisabled = false;

            // Give service 5 seconds to retrieve scores and upload addresses
            // If more than 5 seconds elapsed, then show processing message, else show scored addresses grid
            setTimeout(() => {
              this.spinnerService.hide();
              
              // From here, if isDataProcessed flag is not set from onMessageReceived(), then records are still being uploaded
              if (!this.isDataProcessed) {
                this.showProcessingRecordsMessage = true;
              }
            }, 5000);
          }
        )
      )
      .subscribe(
        (response: IPostAddressUploadResponse) => {
          // if (response) {
          //   this.addressUploadsData = response.AddressUploads;
          // }
        },
      );
  }

  public getAddressUploadTable(parsedData?: string[]): void {
    this.isFileInputDisabled = true;
    let addressUploads = [];
    this.spinnerService.show();
    
    const ddAddressLookupData: IPostAddressUpload = {
      "Filename": this.selectedFileName,
      "BatchId": !this.recentUploadData?.BatchId ? null : this.recentUploadData?.BatchId,
      "AddressUploads": addressUploads,
    };

    for (let i = 0; i < parsedData.length; i++) {
      addressUploads.push({
        "Streetaddress": parsedData[i][0],
        "City": parsedData[i][1],
        "State": parsedData[i][2],
        "Zip": parsedData[i][3],
      })
    }

    this.deliveryDefenseService.postAddressUpload(ddAddressLookupData)
      .pipe(
        finalize(
          () => {
            this.showFile(true);
            this.selectedFileName = "";
            this.isFileInputDisabled = false;
            this.spinnerService.hide();
          }
        )
      )
      .subscribe(
        (response: IPostAddressUploadResponse) => {
          // if (response) {
          //   this.addressUploadsData = response.AddressUploads;    
          //   // this.handleErrorsInFile(this.addressUploadsData);
          //   // return;
          // }
        },
      );
  }

  private handleErrorsInFile(data: IAddressDetails[]): void {  
    this.addressUploadsData = [];
    this.showProcessingRecordsMessage = false;
    this.isDataProcessed = false;
    this.areAnyFileErrors = false;  
    
    for (let i = 0; i < data.length; i++) {
      if (data[i].Exceptions.length >= 1) {
        this.areAnyFileErrors = true;
      }
    }

    if (this.areAnyFileErrors || !this.isSubmitRecentUploadForScoring) {
      this.showReviewAddressDetails = true;
      this.isFileInputDisabled = true;
      if (this.isRecentUpload) {
        this.deliveryDefenseService.reviewRecentUploadAddressDetails$.next({ isShowReviewAddressDetails: true });
      } 
      else {
        this.deliveryDefenseService.reviewAddressDetails$.next({ isShowReviewAddressDetails: true });
      }
      this.spinnerService.hide();
    }
    else {
      // In this case, getAddressUploadTable() will be called if no errors are present after clicking "Review" button
      // However, we updated requirements to always show "Review Errors" page for user to finalize, thus this case will not happen
      // this.showFile(true);
      // this.showReviewAddressDetails = false;
      this.getAddressUploadTable(this.noDuplicateAddresses)
    }

    this.isFileInputDisabled = false;
  }

  public showScoringInfo() {
    let iconElem: HTMLElement = document.getElementById('scoringInfoHeader');
    this.scoringInfoPanel = !this.scoringInfoPanel;
    if (this.scoringInfoPanel) {
      this.showTipsSection = true;
      iconElem.setAttribute("style", "font-weight: 700;line-height: 44.8px;text-decoration-line: underline");
      ;
    }
    else {
      this.showTipsSection = false;
      this.showTipsPanel = false;
      iconElem.setAttribute("style", "font-weight:4700;text-decoration-line: none");
    }
  }
}
