import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'recentUploadStatus',
})

export class RecentUploadStatusPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 0:
                return 'Draft';
            case 1:
                return 'Uploaded';
            case 2:
                return 'Processing';
            case 3:
                return 'Expired';  // Previously submitted
            case 4:
                return 'Expired';  // Last saved as draft
            case 5:
                return 'Voided';
            default:
                return value;
        }
    }
}
