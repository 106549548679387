import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ShipModule } from '../ship/ship.module';
import {
    DDUploadAddressPopupsComponent
} from './dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-popups/dd-upload-address-popups.component';
import { DeliveryDefenseFaqComponent } from './delivery-defense-faq/delivery-defense-faq.component';
import { DeliveryDefenseComponent } from './delivery-defense.component';
import { DeliveryDefenseSignUpComponent } from './delivery-defense-sign-up/delivery-defense-sign-up.component';
import { ConfirmPopupComponent } from './delivery-defense-sign-up/confirm-popup/confirm-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DdManageSubscriptionComponent } from './dd-manage-subscription/dd-manage-subscription.component';
import { DeliveryDefenseSubscriptionRenewDialogComponent } from './delivery-defense-subscription-renew-dialog/delivery-defense-subscription-renew-dialog.component';
import { ParcelProTermsConditionsLinkModule } from '../shared/components/parcel-pro-terms-conditions-link/parcel-pro-terms-conditions-link.module';
import { DeliveryDefenseTermsConditionsLinkModule } from '../shared/components/delivery-defense-terms-conditions-link/delivery-defense-terms-conditions-link.module';
import { DdFeedbackComponent } from './dd-manage-subscription/dd-feedback/dd-feedback.component';
import { RatingBarComponentComponent } from './dd-manage-subscription/dd-feedback/rating-bar-component/rating-bar-component.component';
import { CancelSubscriptionPopupComponent } from './dd-manage-subscription/cancel-subscription-popup/cancel-subscription-popup.component';
import { TablePopupComponent } from './dd-manage-subscription/table-popup/table-popup.component';
import { DDScoreHistoryComponent } from './dd-score-history/dd-score-history.component';
import { MatSliderModule} from '@angular/material/slider';
import { CustomPaginatorModule } from 'app/account/digital-wallet/components/custom-paginator/custom-paginator.module';
import { DDScoreHistoryAdvancedFilterComponent} from './dd-score-history/dd-score-history-advanced-filter/dd-score-history-advanced-filter.component';
import { DDAddressConfidenceToolComponent } from "./dd-address-confidence-tool/dd-address-confidence-tool.component";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DDScoreHistoryTableComponent } from './dd-score-history/dd-score-history-table/dd-score-history-table.component';
import { DDUploadAddressFileComponent } from './dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-file.component';
import { S3FileDownloaderModule } from 'app/shared/components/s3-file-downloader/s3-file-downloader.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DDUploadAddressReviewErrorsTableComponent } from './dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-file-review-errors/dd-upload-address-file-review-errors.component';import { RecentUploadsComponent } from './dd-address-confidence-tool/recent-uploads/recent-uploads.component';
import { RecentUploadPopupComponent} from './dd-address-confidence-tool/recent-uploads/recent-upload-popup/recent-upload-popup.component';
import { DDScoreHistoryFindPickupLocationComponent } from './dd-score-history/dd-score-history-find-pickup-location/dd-score-history-find-pickup-location.component';
import { RecentUploadStatusPipe } from 'app/history/pipes/recent-upload-status.pipe';
import { RecentUploadLiveStatusCheckComponent } from './dd-address-confidence-tool/recent-uploads/recent-upload-live-status-check/recent-upload-live-status-check.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ShipModule,
        ParcelProTermsConditionsLinkModule,
        DeliveryDefenseTermsConditionsLinkModule,
        NgOptimizedImage,
        MatSliderModule,
        CustomPaginatorModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        S3FileDownloaderModule,
        MatButtonToggleModule,
    ],
    declarations: [
        DeliveryDefenseComponent,
        DeliveryDefenseFaqComponent,
        DeliveryDefenseSignUpComponent,
        ConfirmPopupComponent,
        DdManageSubscriptionComponent,
        DeliveryDefenseSubscriptionRenewDialogComponent,
        CancelSubscriptionPopupComponent,
        DdFeedbackComponent,
        RatingBarComponentComponent,
        TablePopupComponent,
        DDScoreHistoryComponent,
        DDScoreHistoryAdvancedFilterComponent,
        DDAddressConfidenceToolComponent,
        DDScoreHistoryTableComponent,
        DDScoreHistoryFindPickupLocationComponent,
        DDUploadAddressFileComponent,
        DDUploadAddressReviewErrorsTableComponent,
        RecentUploadsComponent,
        RecentUploadPopupComponent,
        RecentUploadStatusPipe,
        RecentUploadLiveStatusCheckComponent,
        DDUploadAddressPopupsComponent,
    ],
    exports: [
        RecentUploadLiveStatusCheckComponent
    ],
    providers: [
        RecentUploadStatusPipe,
        RecentUploadLiveStatusCheckComponent
    ]
})
   
export class DeliveryDefenseModule {
}
