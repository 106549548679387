import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TermsConditionsStaticDialogComponent } from './dialog/terms-conditions-static-dialog.component';
import { TermsConditionsStaticComponent } from './terms-conditions-static.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
    declarations: [
        TermsConditionsStaticComponent,
        TermsConditionsStaticDialogComponent,
    ],
    providers: [],
    exports: [TermsConditionsStaticComponent],
})
export class TermsConditionsStaticModule {
}
