import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services/authentication/authentication.service';

@Component({
  selector: 'upsc-logout',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService) {
  }

  public ngOnInit() {
    this.authenticationService.logout();
  }
}
