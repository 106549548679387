import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/core/services/shared.service';
import { Customer } from 'app/shared/models/customer/customer.model';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import dayjs from 'dayjs';
import { AppState } from '../../app.state';
import { IDDCredit } from '../../ship/models/dd-credit.interface';
import { DeliveryDefenseService } from '../services/delivery-defense.service';


@Component({
    selector: 'upsc-dd-score-history',
    templateUrl: './dd-score-history.component.html',
    styleUrls: ['./dd-score-history.component.scss'],
})
export class DDScoreHistoryComponent implements OnInit {
    public user: User;
    public customer: Customer;
    public ddFullCredit = 0;
    public ddRemainingCredit = 0;
    public ddSubTypeID: number;

    public subscribedDetails: any;
    public fullMonthNameYear: string;
    public renewDate: string;

    constructor(private sharedService: SharedService,
                private deliveryDefenseService: DeliveryDefenseService,
                private notificationService: NotificationService,
                private translateService: TranslateService,
                private errorHandlerService: ErrorHandlerService,
                private readonly appState: AppState,
    ) {
        this.user = this.appState.user$();
        this.customer = this.appState.customer$();
    }

    ngOnInit(): void {
        this.getSubscriberDetails();
        this.updateDDCredit();
    }

    private getSubscriberDetails(): void {
        this.deliveryDefenseService.getSubscriberDetails().subscribe(response => {
            this.subscribedDetails = response;
            const date = dayjs(this.subscribedDetails.SubscribedOn);
            this.fullMonthNameYear = date.format('MMMM YYYY');
            const originalDateMoment = dayjs(this.subscribedDetails.CurrentSubStartDate);
            const newDateMoment = originalDateMoment.add(29, 'days');
            this.renewDate = newDateMoment.format('MMMM DD');
        }, error => {
            console.log(error);
        });

        setTimeout(() => {
            document.getElementById('gaugeContainer')?.classList?.add('animated');
        }, 500);
    }

    private updateDDCredit(): void {
        const isUSUser = this.user?.CountryCode === 'US';
        const isDDCustomer = this.customer?.DDEnabled === true;

        if (!isUSUser || !isDDCustomer) {
            return;
        }

        this.deliveryDefenseService.getRemainingDDCredit()
            .subscribe(
                (ddCredit: IDDCredit) => {
                    if (!ddCredit) {
                        return;
                    }
                    this.ddRemainingCredit = +(ddCredit?.ScoresRemaining || 0);
                    this.ddFullCredit = +(ddCredit?.TotalScores || 0);
                    this.ddSubTypeID = +(ddCredit?.SubTypeID || 0);
                },
            );
    }
}
