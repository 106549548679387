import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'upsc-terms-conditions-static',
    templateUrl: './terms-conditions-static.component.html',
    styleUrls: ['./terms-conditions-static.component.scss'],
})
export class TermsConditionsStaticComponent {
    @HostBinding('class') public hostClass = 'terms-conditions-static';
}
