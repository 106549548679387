<div class="feedback-dialog ups-dialog">
    <div class="contentPanel">
        <div class="dialog-header">
            <div *ngIf="!showThankingmsg" class="header">
                <h1>Send Feedback</h1>
                <h2>We appreciate your feedback to make our<br>
                    service better</h2>
            </div>
            <button mat-raised-button class="icon-button close-icon close-button" tabindex="-1" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="showThankingmsg" class="thanksPanel">
            <img class="guaranteedbadge" src="../../../../assets/icons/guaranteedbadge.svg" alt="guaranteedbadge">
            <div class="thanksNote">
                <h3>Feedback Received</h3>
                <h4>Thank you for your feedback!</h4>
            </div>
        </div>

        <div *ngIf="!showThankingmsg" class="emojiPanel">
            <!-- <upsc-rating-bar-component [emojiImagePaths]="['assets/emoji2.png', 'assets/emoji3.png', 'assets/emoji4.png', 'assets/emoji5.png']" (selectedEmojiImagePath)="onSelectedEmojiImagePath($event)"></upsc-rating-bar-component> -->
            <h1>How was your DeliveryDefense™ Address<br> Confidence experience today?</h1>
            <upsc-rating-bar-component
                    [emojiImagePaths]="['assets/icons/emoji1.png','assets/icons/emoji2.png', 'assets/icons/emoji3.png', 'assets/icons/emoji4.png', 'assets/icons/emoji5.png']"
                    (payloadValueEvent)="typeIdData($event)"></upsc-rating-bar-component>
        </div>
        <div *ngIf="!showThankingmsg" class="description">
            <h2>Description (optional)</h2>
            <mat-form-field subscriptSizing="dynamic">
                <textarea cols="50" id="descrpVal" matInput
                          name="review" placeholder="Tell us more about your opinion" rows="4"></textarea>
            </mat-form-field>
        </div>
        <button *ngIf="!showThankingmsg" type="submit" (click)="onsubmit($event)" class="submit" mat-flat-button color="primary">Submit</button>
    </div>
</div>
