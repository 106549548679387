import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { environment }from '../../../environments/environment';

@Injectable()
export class ChangePasswordService {

  private httpClient:HttpClient;

  constructor(private apiEndpointService: ApiEndpointService,
    private httpClientService: HttpClientService,
    private httpBackend: HttpBackend) {
      this.httpClient = new HttpClient(this.httpBackend);    
  }

  /**
   * Validate Token.
   *   
   * @param {string} token
   * @returns {Observable<any>}
   */
  public validateToken(token: string) {
    // console.log(environment.v3BaseApiUrl);
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('validateToken'))
      .param('token', token)
      .toString();
    return this.httpClient.get<any>(endpoint);
  }

  /**
   * Change Password.
   *   
   * @param {string} token
   * @param {string} password
   * @returns {Observable<any>}
   */
  public changePassword(token: string, password: string) {
    var data = {
      Password: password
    };    

    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('changePasswordByToken'))
      .param('token', token)
      .toString();
    return this.httpClient.put<any>(endpoint, data);
  }


}
