import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpHeaders,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    public constructor(private inj: Injector) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        const request = this.createAuthorizationHeader(req);

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    return this.handleError401(err);
                }

                return throwError(() => err);
            }),
        );
    }

    /**
     * Creates an authorization request header.
     */
    private createAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
        const auth = this.inj.get(AuthenticationService);
        const authToken = auth.token;
        const tokenType = 'bearer';

        if (!authToken || !tokenType) {
            return req.method === 'PUT' ? req.clone({ // MV3-4690 adding Content-type only for put as this is overriding other methods
                    headers: new HttpHeaders({
                        Authorization: ``,
                        'Accept-Language': sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en',
                        'Content-Type': 'application/json; charset=utf-8', //for some reason HttpService <put> call content-type is sent as text/pain. So manually interupting the Http Headers below.
                    }),
                }) :
                req.clone({
                    headers: new HttpHeaders({
                        Authorization: ``,
                        'Accept-Language': sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en',
                    }),
                });
        }
        const containAPI = req.url.includes('/api/invoices?page=');
        return req.method === 'PUT' ? req.clone({ // MV3-4690 adding Content-type only for put as this is overriding other methods
                headers: new HttpHeaders({
                    Authorization: `${ tokenType } ${ authToken }`,
                    'Accept-Language': sessionStorage.getItem('lang') ? (sessionStorage.getItem('lang') === 'de') && (containAPI) ? 'en' : sessionStorage.getItem('lang') : 'en',
                    'Content-Type': 'application/json; charset=utf-8', //for some reason HttpService <put> call content-type is sent as text/pain. So manually interupting the Http Headers below.
                }),
            }) :
            req.clone({
                headers: new HttpHeaders({
                    Authorization: `${ tokenType } ${ authToken }`,
                    'Accept-Language': sessionStorage.getItem('lang') ? (sessionStorage.getItem('lang') === 'de') && (containAPI) ? 'en' : sessionStorage.getItem('lang') : 'en',
                }),
            });
    }

    // Response Interceptor
    private handleError401(error: HttpErrorResponse): Observable<any> {
        // Check if we had 401 response
        if (error && error.status === 401) {
            // const notificationService = this.inj.get(NotificationService);

            // notificationService.notify(
            //     'Your Session has expired. For security reasons, you will be automatically logged out. Please log in again to continue.',
            //     'Session Expired',
            //     NotificationType.ERROR);

            console.warn('Your Session has expired. For security reasons, you will be automatically logged out. Please log in again to continue.');
            setTimeout(async () => {
                const authenticationService = this.inj.get(AuthenticationService);
                await authenticationService.logout();
            }, 1000);
        }

        return of(error);
    }
}
