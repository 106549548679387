import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentHistoryService } from './services/payment-history.service';
import { PaymentReceiptComponent } from './payment-history/payment-receipt/payment-receipt.component';
import { ManageCreditCardComponent } from './manage-credit-card/manage-credit-card.component';
import { CreditCardsService } from './services/credit-cards.service';
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { AddCreditCardService } from './services/add-credit-card.service';
import { AddCcBounceComponent } from './add-credit-card/add-cc-bounce/add-cc-bounce.component';
import { EditCreditCardComponent } from './edit-credit-card/edit-credit-card.component';
import { AcceptCCTerms } from './services/accept-credit-card-terms.service';
import { AddCCBounceService } from './add-credit-card/add-cc-bounce/add-cc-bounce.service';
import { TranslateModule } from '@ngx-translate/core';
import { CreditHistoryComponent } from './credit-history/credit-history.component';
import { CreditTypePipe }from './pipes/credit-type.pipe';
import { PaymentMethodPipe }from './pipes/payment-method.pipe';
import { UserLocaleNumberModule } from '../shared/pipes/user-locale-number/user-locale-number.module';
import { InvoiceListComponent } from './payment-history/invoice-list/invoice-list.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule,
        UserLocaleNumberModule,
    ],
    declarations: [
        PaymentHistoryComponent,
        PaymentReceiptComponent,
        ManageCreditCardComponent,
        AddCreditCardComponent,
        AddCcBounceComponent,
        EditCreditCardComponent,
        CreditHistoryComponent,
        CreditTypePipe,
        PaymentMethodPipe,
        InvoiceListComponent,
    ],
    providers: [
        PaymentHistoryService,
        CreditCardsService,
        AddCreditCardService,
        AcceptCCTerms,
        AddCCBounceService,
        CreditTypePipe,
        PaymentMethodPipe,
    ],
    exports: [
        PaymentReceiptComponent,
    ]
})
export class BillingModule {
}
