import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SignUpOriginHandlerService {
    public constructor(private readonly translateService: TranslateService) {}

    public async getWelcomeMessage(origin: string): Promise<string> {
        if (!origin) {
            return null;
        }

        const welcomeMessage: { [key: string]: string } = {
            jck: await lastValueFrom<string>(this.translateService.get('signUp.welcome.jck')),
        };

        return welcomeMessage[origin.toLowerCase()];
    }
}
