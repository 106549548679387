<div class="required">
    * Required Fields
</div>
<div class="page-title">
    4. Insurance & Shipment History
</div>
<div class="insurance-shipments">
    <form class="ups-form" [formGroup]="insuranceFormGroup">
        <div class="shipment-information">
            <label>Shipment Information <span class="red-asterik">*</span></label>

            <div class="grid">
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Average value per package </span>
                        <mat-form-field class="mat-form-field-dropdown" appearance="outline">
                            <mat-select formControlName="averageValuePerPackage" placeholder="Select One">
                                <mat-option *ngFor="let value of averageValuePerPackageDropdown" [value]="value">
                                    {{ value }} 
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('averageValuePerPackage')?.hasError('min') || insuranceFormGroup.get('averageValuePerPackage')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>
    
                <div class="value-containers">
                    <div class="value-wrapper maxmimum-value-per-package-dropdown">
                        <span class="header"> Maximum value per package </span>
                        <mat-form-field class="mat-form-field-dropdown" appearance="outline">
                            <mat-select formControlName="maximumValuePerPackage" placeholder="Select One">
                                <mat-option *ngFor="let value of maximumValuePerPackageDropdown" [value]="value">
                                    {{ value }} 
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('maximumValuePerPackage')?.hasError('min') || insuranceFormGroup.get('maximumValuePerPackage')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>                    
            </div>

            <div class="grid">
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Maximum daily value from single location </span>
                        <mat-form-field appearance="outline">
                            <input placeholder="0" matInput min="0" max="500000" maxLength=6 formControlName="maximumValueFromSingleLocation">
                            <span matSuffix class="mat-suffix">USD</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('maximumValueFromSingleLocation')?.hasError('min') || insuranceFormGroup.get('maximumValueFromSingleLocation')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>
    
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Maximum daily value to single location </span>
                        <mat-form-field appearance="outline">
                            <input placeholder="0" matInput min="0" max="500000" maxLength=6 formControlName="maximumValueToSingleLocation">
                            <span matSuffix class="mat-suffix">USD</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('maximumValueToSingleLocation')?.hasError('min') || insuranceFormGroup.get('maximumValueToSingleLocation')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>
            </div>
        
            <div class="grid">
                <div class="value-containers">
                    <div class="value-wrapper total-annual-packages-insured">
                        <span class="header"> Total annual packages to be insured </span>
                        <mat-form-field appearance="outline" class="total-annual-packages-insured-mat-form-field">
                            <input placeholder="0" matInput min="0" max="500000" maxLength=6 formControlName="totalAnnualPackagesInsured">
                            <span matSuffix class="mat-suffix"></span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('totalAnnualPackagesInsured')?.hasError('min') || insuranceFormGroup.get('totalAnnualPackagesInsured')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>
    
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Total annual sales </span>
                        <mat-form-field appearance="outline">
                            <input placeholder="0" matInput min="0" max="500000" maxLength=6 formControlName="totalAnnualSales">
                            <span matSuffix class="mat-suffix">USD</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('totalAnnualSales')?.hasError('min') || insuranceFormGroup.get('totalAnnualSales')?.hasError('max')">
                            Enter numbers only up to 500,000
                        </mat-error>
                    </div>
                </div>

                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Shipping Frequency = Packages Shipped </span>
                        <mat-form-field class="shipping-frequency-form-field" appearance="outline">
                            <mat-label>Select One</mat-label>
                            <mat-select placeholder="Select One" formControlName="shippingFrequencyPackagesShipped">
                                <mat-option *ngFor="let shipments of signUpDropdowns.ShippingFrequencyPackages" [value]="shipments">
                                    {{shipments}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="geographical-limits-of-coverage">
            <label> Geographical limits of coverage <span class="red-asterik">*</span></label>

            <div class="grid">
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Domestic </span>
                        <mat-form-field appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="domesticPercent">
                            <span matSuffix class="mat-suffix">%</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('domesticPercent')?.hasError('min') || insuranceFormGroup.get('domesticPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
                </div>

                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header second"> International </span>
                        <mat-form-field appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="internationalPercent">
                            <span matSuffix class="mat-suffix">%</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('internationalPercent')?.hasError('min') || insuranceFormGroup.get('internationalPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
                </div>
            </div>

            <mat-error class="geographical-sum-error" *ngIf="geographicalSumError">
                Enter numbers only must add up to 100.
            </mat-error>
        </div>

        <div class="service-level-coverage">
            <label> Service level for coverage<span class="red-asterik">*</span></label>

            <div class="grid">
                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header"> Air Transportation </span>
                        <mat-form-field appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="airTransportationPercent">
                            <span matSuffix class="mat-suffix">%</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('airTransportationPercent')?.hasError('min') || insuranceFormGroup.get('airTransportationPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
                </div>

                <div class="value-containers">
                    <div class="value-wrapper">
                        <span class="header second"> Ground Transportation </span>
                        <mat-form-field appearance="outline">
                            <input matInput min="0" max="100" maxLength=3 formControlName="groundTransportationPercent">
                            <span matSuffix class="mat-suffix">%</span>
                        </mat-form-field>
                        <mat-error *ngIf="insuranceFormGroup.get('groundTransportationPercent')?.hasError('min') || insuranceFormGroup.get('groundTransportationPercent')?.hasError('max')">
                            Value must be between 0 and 100.
                        </mat-error>
                    </div>
                </div>
            </div>

            <mat-error class="service-level-sum-error" *ngIf="serviceLevelSumError">
                Enter numbers only must add up to 100.
            </mat-error>
        </div>

        <div class="separator">
        </div>
        <div class="claim-history">
            <label class="title">
                Claim History (Optional)
            </label>

            <div class="checkbox-container">
                <mat-checkbox (change)="onNoClaimsChange($event)" formControlName="noClaimsLast24Months">
                    No claims last 24 months
                </mat-checkbox>
            </div>

            <div class=claim-detail-container *ngIf="!noClaims">
                <div class="claim-detail">
                    <mat-form-field>
                        <mat-label>Enter Year</mat-label>
                        <mat-select placeholder="Select Year" formControlName="claimHistory1Year">
                            <mat-option *ngFor="let year of claimYears" [value]="year">{{year}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Select Carrier</mat-label>
                        <mat-select placeholder="Select Carrier" formControlName="claimHistory1Courier">
                            <mat-option *ngFor="let carrier of signUpDropdowns.carriers" [value]="carrier">{{carrier}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Insured Value</mat-label>
                        <input matInput formControlName="claimHistory1InsuredValue" pattern="\d*" maxlength="9"
                            autocomplete="off">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory1InsuredValue?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory1InsuredValue?.errors?.pattern">
                                Insured Amount should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Amount Paid By Insurer</mat-label>
                        <input matInput autocomplete="off" formControlName="claimHistory1AmountPaidByInsurer" pattern="\d*"
                            maxlength="9">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory1AmountPaidByInsurer?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory1AmountPaidByInsurer?.errors?.pattern">
                                Amount Paid should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="claim-detail">
                    <mat-form-field>
                        <mat-label>Enter Year</mat-label>
                        <mat-select placeholder="Select Year" formControlName="claimHistory2Year">
                            <mat-option *ngFor="let year of claimYears" [value]="year">{{year}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Select Carrier</mat-label>
                        <mat-select placeholder="Select Carrier" formControlName="claimHistory2Courier">
                            <mat-option *ngFor="let carrier of signUpDropdowns.carriers" [value]="carrier">{{carrier}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Insured Value</mat-label>
                        <input matInput autocomplete="off" formControlName="claimHistory2InsuredValue" pattern="\d*"
                            maxlength="9">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory2InsuredValue?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory2InsuredValue?.errors?.pattern">
                                Insured Amount should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Amount Paid By Insurer</mat-label>
                        <input matInput autocomplete="off" formControlName="claimHistory2AmountPaidByInsurer" pattern="\d*"
                            maxlength="9">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory2AmountPaidByInsurer?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory2AmountPaidByInsurer?.errors?.pattern">
                                Amount Paid should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="claim-detail">
                    <mat-form-field>
                        <mat-label>Enter Year</mat-label>
                        <mat-select placeholder="Select Year" formControlName="claimHistory3Year">
                            <mat-option *ngFor="let year of claimYears" [value]="year">{{year}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Select Carrier</mat-label>
                        <mat-select placeholder="Select Carrier" formControlName="claimHistory3Courier">
                            <mat-option *ngFor="let carrier of signUpDropdowns.carriers" [value]="carrier">{{carrier}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Insured Value</mat-label>
                        <input matInput autocomplete="off" formControlName="claimHistory3InsuredValue" pattern="\d*"
                            maxlength="9">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory3InsuredValue?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory3InsuredValue?.errors?.pattern">
                                Insured Amount should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Amount Paid By Insurer</mat-label>
                        <input matInput autocomplete="off" formControlName="claimHistory3AmountPaidByInsurer" pattern="\d*"
                            maxlength="9">
                        <mat-error *ngIf="insuranceFormGroup.controls.claimHistory3AmountPaidByInsurer?.errors">
                            <span *ngIf="insuranceFormGroup.controls.claimHistory3AmountPaidByInsurer?.errors?.pattern">
                                Amount Paid should be number
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="separator">
        </div>
        <div class="business-references">
            <label class="title">
                Business References
            </label>
            <label class="subtitle">By submitting these references, I acknowledge and accept that Parcel Pro may contact
                the Companies and/or Contacts.</label>
            <div class="business-reference">
                <mat-form-field [subscriptSizing]="insuranceFormGroup.controls.BusinessRef1Company.touched && insuranceFormGroup.controls.BusinessRef1Company.errors ? 'dynamic' : 'fixed'">
                    <mat-label>Company Name</mat-label>
                    <input matInput required formControlName="BusinessRef1Company" maxlength="50" autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef1Company?.errors">
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1Company?.errors?.required">
                            Reference Company is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field [subscriptSizing]="insuranceFormGroup.controls.BusinessRef1Contact.touched && insuranceFormGroup.controls.BusinessRef1Contact.errors ? 'dynamic' : 'fixed'">
                    <mat-label>Contact Name</mat-label>
                    <input matInput required formControlName="BusinessRef1Contact" maxlength="50" autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef1Contact?.errors">
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1Contact?.errors?.required">
                            Reference Contact is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field [subscriptSizing]="insuranceFormGroup.controls.BusinessRef1YearsKnown.touched && insuranceFormGroup.controls.BusinessRef1YearsKnown.errors ? 'dynamic' : 'fixed'">
                    <mat-label>No. years known</mat-label>
                    <input matInput required pattern="\d*" formControlName="BusinessRef1YearsKnown" type="text" max="255"
                        autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef1YearsKnown?.errors">
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1YearsKnown?.errors?.required">
                            Reference Years Known is required
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1YearsKnown?.errors?.max">
                            Years Known should be less than 255
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1YearsKnown?.errors?.pattern">
                            Only numeric values allowed
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field [subscriptSizing]="insuranceFormGroup.controls.BusinessRef1Phone.touched && insuranceFormGroup.controls.BusinessRef1Phone.errors ? 'dynamic' : 'fixed'">
                    <mat-label>Phone Number</mat-label>
                    <input matInput required formControlName="BusinessRef1Phone" maxlength="15" 
                        autocomplete="off" (input)="addHyphen($event.target)">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef1Phone?.errors">
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1Phone?.errors?.required">
                            Reference Phone Number is required
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1Phone?.errors?.pattern">
                            Reference Phone Number should be of format 999-999-9999
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef1Phone?.errors?.notInteger">
                            Phone Number should be numeric
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="business-reference">
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="BusinessRef2Company" maxlength="50" autocomplete="off">                   
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Contact Name</mat-label>
                    <input matInput formControlName="BusinessRef2Contact" maxlength="50" autocomplete="off">                    
                </mat-form-field>
                <mat-form-field>
                    <mat-label>No. years known</mat-label>
                    <input matInput pattern="\d*" formControlName="BusinessRef2YearsKnown" type="text" max="255"
                        autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef2YearsKnown?.errors">                        
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef2YearsKnown?.errors?.max">
                            Years Known should be less than 255
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef2YearsKnown?.errors?.pattern">
                            Only numeric values allowed
                        </span> 
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="BusinessRef2Phone" maxlength="15" 
                        autocomplete="off" (input)="addHyphen($event.target)">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef2Phone?.errors">                        
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef2Phone?.errors?.pattern">
                            Reference Phone Number should be of format 999-999-9999
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef2Phone?.errors?.notInteger">
                            Phone Number should be numeric
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="business-reference">
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="BusinessRef3Company" maxlength="50" autocomplete="off">                    
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Contact Name</mat-label>
                    <input matInput formControlName="BusinessRef3Contact" maxlength="50" autocomplete="off">                    
                </mat-form-field>
                <mat-form-field>
                    <mat-label>No. years known</mat-label>
                    <input matInput pattern="\d*" formControlName="BusinessRef3YearsKnown" type="text" max="255"
                        autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef3YearsKnown?.errors">                       
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef3YearsKnown?.errors?.max">
                            Years Known should be less than 255
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef3YearsKnown?.errors?.pattern">
                            Only numeric values allowed
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="BusinessRef3Phone" maxlength="15" 
                        autocomplete="off" (input)="addHyphen($event.target)">
                    <mat-error *ngIf="insuranceFormGroup.controls.BusinessRef3Phone?.errors">                        
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef3Phone?.errors?.pattern">
                            Reference Phone Number should be of format 999-999-9999
                        </span>
                        <span *ngIf="insuranceFormGroup.controls.BusinessRef3Phone?.errors?.notInteger">
                            Phone Number should be numeric
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <ng-container [ngTemplateOutlet]="businessReference"></ng-container>
            <ng-container [ngTemplateOutlet]="businessReference"></ng-container>
            <ng-container [ngTemplateOutlet]="businessReference"></ng-container> -->
        </div>
        <div class="separator">
        </div>
        <div class="terms">
            <h3>Terms and Conditions</h3>
            <p>In order to open and account with Parcel Pro, you must first read and agree to the Parcel Pro
                <a href="https://www.parcelpro.com/us/en/legal/website-terms-of-use.html" target="_blank">Terms
                    and Conditions</a> and <a href="https://www.parcelpro.com/us/en/legal/privacy-notice.html"
                    target="_blank">Privacy Policy</a>.</p>
            <div class="signature">
                <mat-form-field>
                    <mat-label>Full Name</mat-label>
                    <input matInput required formControlName="fullName"
                        [maxlength]="100 - (insuranceFormGroup.controls.title.value.length)" autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.fullName?.errors">
                        <span *ngIf="insuranceFormGroup.controls.fullName?.errors?.required">
                            Full Name is required
                        </span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Title</mat-label>
                    <input matInput required formControlName="title"
                        [maxlength]="100 - (insuranceFormGroup.controls.fullName.value.length)" autocomplete="off">
                    <mat-error *ngIf="insuranceFormGroup.controls.title?.errors">
                        <span *ngIf="insuranceFormGroup.controls.title?.errors?.required">
                            Title is required
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
            <p>
                <mat-checkbox formControlName="agreement">
                    By checking this box, I certify that the information I have provided on this Parcel Pro, Inc.
                    application
                    is correct and true, and that I am
                    an authorized representative of the company/applicant. I acknowledge and agree to the <a href="#" (click)="openTermsConditionsStaticDialog($event)">Parcel Pro
                        Terms and Conditions</a> and <a href="https://www.parcelpro.com/us/en/legal/privacy-notice.html" target="_blank">Privacy
                        Policy</a>.
                </mat-checkbox>
            </p>

            <p>
                <mat-checkbox formControlName="restrictedCommoditiesAcknowledgement">
                    Restricted Commodities Acknowledgement: I hereby acknowledge that there are restrictions on shipping, including those set forth in the tariff of your transportation carrier(s).
                    Those restrictions include, but are not limited to, firearms, ammunition, tobacco, vape and vape products, marijuana and hemp, and alcoholic beverages.
                </mat-checkbox>
            </p>
        </div>
        <!-- <div class="terms1">
            <mat-checkbox formControlName="agreement">                
            </mat-checkbox>
            By checking this box, I certify that the information I have provided on this Parcel Pro, Inc.
                application
                is correct and true, and that I am
                an authorized representative of the company/applicant. I acknowledge and agree to the
                <a href="https://www.parcelpro.com/pages/termsandcondition.aspx?c=US" target="_blank">Parcel Pro Terms and Conditions</a> and 
                <a href="https://www.parcelpro.com/public/upscprivacynotice.aspx" target="_blank">Privacy Policy</a>.
        </div> -->
        <div class="separator">
        </div>
    </form>
</div>
<ng-template #claimDetail>
    <div class="claim-detail">
        <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Enter Year">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-select placeholder="Select Carrier">
                <mat-option selected value="0">Test</mat-option>
                <mat-option selected value="0">Test</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Insured Value">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Amount Paid By Insurer">
        </mat-form-field>
    </div>
</ng-template>
<ng-template #businessReference>
    <div class="business-reference">
        <mat-form-field>
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Company Name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Contact Name</mat-label>
            <input matInput placeholder="Contact Name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>No. years known</mat-label>
            <input matInput placeholder="No. years known">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Phone Number</mat-label>
            <input matInput placeholder="Phone Number">
        </mat-form-field>
    </div>
</ng-template>
