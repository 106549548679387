<div class="invoice-list-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>Invoice ID List</h2>
        <mat-dialog-actions>
        </mat-dialog-actions>
        <button type="button" mat-raised-button class="icon-button close-button dont-print" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ol>
            <li *ngFor="let id of InvoiceIds">
                {{ id }}
            </li>
        </ol>
    </mat-dialog-content>
</div>