<!-- <a class="batch-info"
   [matTooltip]="(statusName + ' ' + batchIdValue)">
    <div class="key">{{ title | uppercase }}</div>
    <div class="value"><span>{{ batchIdValue }}</span></div>
    <upsc-signalrify [hubName]="'AddressLookup'"
                     [broadcastEventName]="broadcastEventName"
                     (dataReceived)="onDataReceived($event)">
    </upsc-signalrify>
</a> -->

<upsc-signalrify [hubName]="'AddressLookup'"
                 [broadcastEventName]="broadcastEventName"
                 (dataReceived)="onDataReceived($event)">
</upsc-signalrify>