import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import SafeUrlAssembler from 'safe-url-assembler';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { CreditCardForm } from '../models/credit-card-form.model';
import { PaymentProfileAdd } from '../models/add-payment-profile.model';
import { AnetProfile } from '../models/add-profile.model';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { PaymentProfileEdit } from '../models/edit-payment-profile.model';

@Injectable()
export class AddCreditCardService {

  constructor(private httpClientService: HttpClientService,
    // private http: HttpService, 
    private apiEndpointService: ApiEndpointService) {
  }

  public getCreditCardForm(anetprofileid: string,
    anetpaymentprofileid: string,
    redirecturl: string,
    istest: boolean,
    bounceurl: string): Observable<CreditCardForm> {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getCreditCardForms'))
      .query({
        AnetProfileID: anetprofileid,
        AnetPaymentProfileID: anetpaymentprofileid,
        ReDirectURL: redirecturl,
        IsTest: istest,
        BounceURL: bounceurl,
      })
      .toString();

    return this.httpClientService.get<CreditCardForm>(endpoint);
    // .map(res => res.json());
  }

  public postCreditCardForm(anetURL: string, token: string): Observable<any> {

    const formData = new FormData();
    formData.append('token', token);

    return this.httpClientService.postFormData<any>(anetURL, formData);
  }

  createAnetProfile(AnetProfile: AnetProfile) {
    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('createAnetUser'))
      .toString();
    return this.httpClientService.post<any>(endpoint, AnetProfile); // .map(res => res.json());
  }

  public saveNewCreditCard(cardInfo: PaymentProfileAdd): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('addPaymentProfile'))
      .toString();

    return this.httpClientService.post<any>(endpoint, cardInfo).pipe(retry(2));
  }

  public editCreditCard(cardInfo: PaymentProfileEdit): Observable<any> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('editPaymentProfile'))
      .toString();

    return this.httpClientService.put<any>(endpoint, cardInfo).pipe(retry(2));
  }
}
