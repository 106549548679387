import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    AuthenticationService as JwtAuthenticationService,
} from '../../core/services/authentication/authentication.service';
import { UserService } from '../../shared/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class LoginResolver implements Resolve<Observable<any>> {
    public constructor(private readonly jwtAuthenticationService: JwtAuthenticationService,
                       private readonly userService: UserService,
                       private readonly router: Router,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.jwtAuthenticationService.isLoggedIn()
                   .pipe(
                       switchMap(
                           (isLoggedIn) => {
                               if (!isLoggedIn) {
                                   return of(null);
                               }

                               return this.userService.getUser();
                           },
                       ),
                       switchMap(
                           (user) => {
                               if (!user) {
                                   return of(true);
                               }

                               // Handle user's first login.
                               if (user.IsFirstLogon) {
                                   return from(this.router.navigate(['/terms/terms-conditions']))
                                       .pipe(
                                           map(() => EMPTY),
                                       );
                               }

                               // Take user to dashboard if already logged in.
                               return from(this.router.navigate(['dashboard'],
                                   {
                                       state: {
                                           user,
                                       },
                                   }))
                                   .pipe(
                                       map(() => EMPTY),
                                   );
                           },
                       ),
                   );
    }
}
